// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var S$RescriptStruct = require("rescript-struct/src/S.bs.js");

var storageKey = "insecure-sandbox-jwt";

var struct = S$RescriptStruct.object(function (o) {
      return {
              jwt: S$RescriptStruct.field(o, "jwt", S$RescriptStruct.string(undefined)),
              req: S$RescriptStruct.field(o, "req", S$RescriptStruct.$$float(undefined))
            };
    });

function set(jwt) {
  localStorage.setItem(storageKey, JSON.stringify(S$RescriptStruct.serializeOrRaiseWith({
                jwt: jwt,
                req: Date.now()
              }, struct)));
}

function get(param) {
  return Belt_Option.getWithDefault(Belt_Option.map(Caml_option.null_to_opt(localStorage.getItem(storageKey)), (function (string) {
                    return Belt_Result.mapWithDefault(S$RescriptStruct.parseAnyWith(string, S$RescriptStruct.json(struct)), undefined, (function (param) {
                                  var goodUntil = param.req + 1000 * 60 * 60 * 47.5;
                                  if (goodUntil > Date.now()) {
                                    return param.jwt;
                                  }
                                  
                                }));
                  })), undefined);
}

function $$delete(param) {
  localStorage.removeItem(storageKey);
}

exports.storageKey = storageKey;
exports.struct = struct;
exports.set = set;
exports.get = get;
exports.$$delete = $$delete;
/* struct Not a pure module */
