// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../utils/Common.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Table = require("@mui/joy/Table").default;
var QueryVar$Thick = require("../../utils/QueryVar.bs.js");
var Checkbox = require("@mui/joy/Checkbox").default;
var TableFilter$Thick = require("./TableFilter.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var CustomScalars$Thick = require("../../utils/CustomScalars.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CabinTable_cabin_graphql$Thick = require("../../__generated__/CabinTable_cabin_graphql.bs.js");
var CabinTableQuery_query_graphql$Thick = require("../../__generated__/CabinTableQuery_query_graphql.bs.js");
var CabinTableQueryRefetchQuery_graphql$Thick = require("../../__generated__/CabinTableQueryRefetchQuery_graphql.bs.js");

var convertFragment = CabinTableQuery_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(CabinTableQuery_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, CabinTableQuery_query_graphql$Thick.node, convertFragment);
}

var makeRefetchVariables = CabinTableQueryRefetchQuery_graphql$Thick.Types.makeRefetchVariables;

var convertRefetchVariables = CabinTableQueryRefetchQuery_graphql$Thick.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(CabinTableQuery_query_graphql$Thick.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

var convertFragment$1 = CabinTable_cabin_graphql$Thick.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(CabinTable_cabin_graphql$Thick.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, CabinTable_cabin_graphql$Thick.node, convertFragment$1);
}

var CabinFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1
};

function CabinTable$CabinTableRow(props) {
  var bookingUrl = props.bookingUrl;
  var handleCabinToggle = props.handleCabinToggle;
  var cabin = RescriptRelay_Fragment.useFragment(CabinTable_cabin_graphql$Thick.node, convertFragment$1, props.fragmentRefs);
  var node = Belt_Array.get(cabin.booking.nodes, 0);
  var match;
  if (node !== undefined) {
    var version = Belt_Array.get(node.bookingVersion.nodes, 0);
    match = version !== undefined ? [
        CustomScalars$Thick.BookingId.serialize(node.bookingId),
        String(version.bookingVersionNumber)
      ] : [
        CustomScalars$Thick.BookingId.serialize(node.bookingId),
        undefined
      ];
  } else {
    match = [
      undefined,
      undefined
    ];
  }
  var bookingVersionNumber = match[1];
  var bookingId = match[0];
  var slug = cabin.voyageClassSlug;
  var slug$1 = cabin.voyageSubclassSlug;
  var slug$2 = cabin.voyageCategorySlug;
  var tmp;
  var exit = 0;
  if (bookingId !== undefined && bookingVersionNumber !== undefined) {
    tmp = JsxRuntime.jsx(Typography, {
          color: "primary",
          noWrap: true,
          sx: {
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline"
            }
          },
          children: Caml_option.some(bookingId),
          onClick: (function (param) {
              RescriptReactRouter.push("" + bookingUrl + "/" + bookingId + "/" + bookingVersionNumber + "");
            })
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(Typography, {
          noWrap: true,
          children: "N/A"
        });
  }
  var range = cabin.occRange;
  var tmp$1;
  if (range !== undefined) {
    var match$1 = range.start;
    tmp$1 = match$1 !== undefined ? (
        match$1.inclusive ? String(match$1.value) : String(match$1.value + 1 | 0)
      ) : "N/A";
  } else {
    tmp$1 = "N/A";
  }
  var range$1 = cabin.occRange;
  var tmp$2;
  if (range$1 !== undefined) {
    var match$2 = range$1.end;
    tmp$2 = match$2 !== undefined ? (
        match$2.inclusive ? String(match$2.value) : String(match$2.value - 1 | 0)
      ) : "N/A";
  } else {
    tmp$2 = "N/A";
  }
  var $$int = cabin.totBerths;
  var seg = cabin.voyageSegment;
  var pubNote = cabin.publicNoteMd;
  var privNote = cabin.privateNoteMd;
  return JsxRuntime.jsxs("tr", {
              children: [
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Checkbox, {
                            checked: Belt_Set.has(props.selectedCabins, cabin.cabinNumber),
                            onChange: (function (param) {
                                Curry._1(handleCabinToggle, cabin.cabinNumber);
                              })
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(slug !== undefined ? CustomScalars$Thick.CabinClassSlug.serialize(Caml_option.valFromOption(slug)) : "N/A")
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(slug$1 !== undefined ? CustomScalars$Thick.CabinSubclassSlug.serialize(Caml_option.valFromOption(slug$1)) : "N/A")
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(slug$2 !== undefined ? CustomScalars$Thick.CabinCategorySlug.serialize(Caml_option.valFromOption(slug$2)) : "N/A")
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(CustomScalars$Thick.CabinNumber.serialize(cabin.cabinNumber))
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: tmp
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(tmp$1)
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(tmp$2)
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some($$int !== undefined ? String($$int) : "N/A")
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(seg !== undefined ? seg : "N/A")
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(pubNote !== undefined ? Caml_option.valFromOption(pubNote) : "N/A")
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(privNote !== undefined ? Caml_option.valFromOption(privNote) : "N/A")
                          })
                    })
              ]
            });
}

var CabinTableRow = {
  make: CabinTable$CabinTableRow
};

function CabinTable(props) {
  var handleCabinToggle = props.handleCabinToggle;
  var setSelectedCabins = props.setSelectedCabins;
  var selectedCabins = props.selectedCabins;
  var allCabins = props.allCabins;
  var voyage = props.voyage;
  var brandFamily = props.brandFamily;
  var fragmentRefs = props.fragmentRefs;
  var match = useRefetchable(fragmentRefs);
  var refetch = match[1];
  var data = match[0];
  var refetch$1 = function (param) {
    return Curry._4(refetch, Curry._5(makeRefetchVariables, undefined, undefined, undefined, undefined, undefined), /* StoreAndNetwork */2, undefined, undefined);
  };
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var path = "/" + Belt_Array.joinWith(Belt_List.toArray(url.path), "/", (function (prim) {
          return prim;
        })) + "";
  var qStruct = QueryVar$Thick.parse(url.search);
  var page = qStruct.page;
  var page$1 = page !== undefined ? Belt_Option.getWithDefault(Curry._1(QueryVar$Thick.IntArg.Filter.parse_Int, page), 1) : 1;
  var bookingUrl = "/" + brandFamily + "/" + voyage + "/bookings";
  var match$1 = data.cabins;
  var total = match$1 !== undefined ? match$1.totalCount : 0;
  var offsetFromPage = function (page) {
    return Math.imul(page - 1 | 0, 50);
  };
  var onLoadNext = function (newPage) {
    var newOffset = offsetFromPage(newPage);
    if (newOffset > total) {
      console.log("No more cabins to load");
      return ;
    }
    var newQuery_cabinFilters = qStruct.cabinFilters;
    var newQuery_page = {
      TAG: /* EqualTo */0,
      _0: newPage
    };
    var newQuery_bogus = qStruct.bogus;
    var newQuery = {
      cabinFilters: newQuery_cabinFilters,
      page: newQuery_page,
      bogus: newQuery_bogus
    };
    var newUrl_path = url.path;
    var newUrl_hash = url.hash;
    var newUrl_search = QueryVar$Thick.serialize(newQuery);
    RescriptReactRouter.push("" + path + "" + (
          newUrl_search === "" ? "" : "?"
        ) + "" + newUrl_search + "");
  };
  var offset = offsetFromPage(page$1);
  var match$2 = data.cabins;
  var tmp;
  var exit = 0;
  if (match$2 !== undefined) {
    var edges = match$2.edges;
    if (edges.length !== 0) {
      tmp = JsxRuntime.jsxs(Table, {
            borderAxis: "xBetween",
            stripe: "odd",
            hoverRow: true,
            sx: {
              "& tbody": {
                bgcolor: "background.surface"
              },
              "& td": {
                textAlign: "center"
              },
              "& th": {
                textAlign: "center"
              }
            },
            size: "sm",
            children: [
              JsxRuntime.jsx("thead", {
                    children: JsxRuntime.jsxs("tr", {
                          children: [
                            JsxRuntime.jsx("th", {
                                  children: JsxRuntime.jsx(Checkbox, {
                                        checked: Belt_Set.eq(selectedCabins, allCabins),
                                        onChange: (function (param) {
                                            if (Belt_Set.eq(selectedCabins, allCabins)) {
                                              return Curry._1(setSelectedCabins, (function (param) {
                                                            return Belt_Set.make(Common$Thick.CabinCmp);
                                                          }));
                                            } else {
                                              return Curry._1(setSelectedCabins, (function (param) {
                                                            return allCabins;
                                                          }));
                                            }
                                          })
                                      }),
                                  style: {
                                    width: "40px"
                                  }
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Class"
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Subclass"
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Category"
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Cabin #"
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Booking ID"
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Sales Min",
                                  style: {
                                    width: "7%"
                                  }
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Sales Max",
                                  style: {
                                    width: "7%"
                                  }
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Total Berths",
                                  style: {
                                    width: "7%"
                                  }
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Segment"
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Note (Public)"
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Note (Private)"
                                })
                          ]
                        })
                  }),
              JsxRuntime.jsx("tbody", {
                    children: Belt_Array.map(edges, (function (edge) {
                            return JsxRuntime.jsx(CabinTable$CabinTableRow, {
                                        fragmentRefs: edge.node.fragmentRefs,
                                        handleCabinToggle: handleCabinToggle,
                                        selectedCabins: selectedCabins,
                                        bookingUrl: bookingUrl
                                      }, edge.node.id);
                          }))
                  })
            ]
          });
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(Stack, {
          justifyContent: "center",
          alignItems: "center",
          sx: {
            height: "15rem",
            width: "100%"
          },
          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                    level: "title-lg",
                    sx: {
                      color: "neutral.500"
                    },
                    children: "No cabins found"
                  }))
        });
  }
  return JsxRuntime.jsx(Stack, {
              spacing: 1,
              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                        spacing: 1,
                        children: Caml_option.some(JsxRuntime.jsxs(Sheet, {
                                  variant: "outlined",
                                  sx: {
                                    borderRadius: "4px",
                                    borderLeft: "1px solid",
                                    borderRight: "1px solid",
                                    borderTop: "1px solid",
                                    borderColor: "neutral.200"
                                  },
                                  children: [
                                    JsxRuntime.jsxs(Sheet, {
                                          variant: "solid",
                                          color: "primary",
                                          sx: {
                                            px: 3,
                                            py: 2,
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px"
                                          },
                                          children: [
                                            JsxRuntime.jsx(Typography, {
                                                  level: "title-lg",
                                                  textColor: "neutral.50",
                                                  children: "Cabin Inventory"
                                                }),
                                            JsxRuntime.jsx(Typography, {
                                                  level: "body-sm",
                                                  textColor: "neutral.300",
                                                  children: Caml_option.some("" + brandFamily + " | " + voyage + "")
                                                })
                                          ]
                                        }),
                                    JsxRuntime.jsx(TableFilter$Thick.make, {
                                          fragmentRefs: fragmentRefs,
                                          total: total,
                                          offset: offset,
                                          onLoadNext: onLoadNext,
                                          page: page$1,
                                          path: path,
                                          selectedCabins: selectedCabins,
                                          setSelectedCabins: setSelectedCabins,
                                          refetch: refetch$1,
                                          voyage: voyage,
                                          brandFamily: brandFamily
                                        }),
                                    tmp,
                                    JsxRuntime.jsx(TableFilter$Thick.make, {
                                          fragmentRefs: fragmentRefs,
                                          total: total,
                                          offset: offset,
                                          onLoadNext: onLoadNext,
                                          page: page$1,
                                          path: path,
                                          bottom: true,
                                          selectedCabins: selectedCabins,
                                          setSelectedCabins: setSelectedCabins,
                                          refetch: refetch$1,
                                          voyage: voyage,
                                          brandFamily: brandFamily
                                        })
                                  ]
                                }))
                      }))
            });
}

var make = CabinTable;

exports.QueryFragment = QueryFragment;
exports.CabinFragment = CabinFragment;
exports.CabinTableRow = CabinTableRow;
exports.make = make;
/* Common-Thick Not a pure module */
