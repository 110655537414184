// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var CabinTable$Thick = require("../components/cabins/CabinTable.bs.js");
var JsxRuntime = require("react/jsx-runtime");

function CabinInventoryPage(props) {
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsx(CabinTable$Thick.make, {
                        fragmentRefs: props.fragmentRefs,
                        brandFamily: props.brandFamily,
                        voyage: props.voyage,
                        allCabins: props.allCabins,
                        selectedCabins: props.selectedCabins,
                        setSelectedCabins: props.setSelectedCabins,
                        handleCabinToggle: props.handleCabinToggle
                      }))
            });
}

var make = CabinInventoryPage;

exports.make = make;
/* CabinTable-Thick Not a pure module */
