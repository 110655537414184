// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var Drawer = require("@mui/joy/Drawer").default;
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var ModalClose = require("@mui/joy/ModalClose").default;
var DialogTitle = require("@mui/joy/DialogTitle").default;

function Drawer$1(props) {
  var children = props.children;
  var desktopAnchor = props.desktopAnchor;
  var onClose = props.onClose;
  var title = props.title;
  var open_ = props.open_;
  var desktopAnchor$1 = desktopAnchor !== undefined ? desktopAnchor : "right";
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Stack, {
                      sx: {
                        display: {
                          xs: "none",
                          md: "flex"
                        }
                      },
                      children: Caml_option.some(JsxRuntime.jsxs(Drawer, {
                                children: [
                                  JsxRuntime.jsx(ModalClose, {}),
                                  title !== undefined ? JsxRuntime.jsx(DialogTitle, {
                                          children: Caml_option.some(title)
                                        }) : null,
                                  JsxRuntime.jsx(Divider, {}),
                                  children
                                ],
                                open: open_,
                                anchor: desktopAnchor$1,
                                disablePortal: true,
                                onClose: onClose,
                                slotProps: {
                                  backdrop: {
                                    sx: {
                                      backdropFilter: "none",
                                      backgroundColor: "rgba(16, 13, 12, 0.7)"
                                    }
                                  },
                                  content: {
                                    sx: {
                                      "--Drawer-horizontalSize": "clamp(500px, 45%, 75%)",
                                      borderRadius: "4px",
                                      margin: "8px",
                                      height: "calc(100% - 16px)",
                                      overflow: "auto"
                                    }
                                  }
                                }
                              }))
                    }),
                JsxRuntime.jsx(Stack, {
                      sx: {
                        display: {
                          xs: "flex",
                          md: "none"
                        }
                      },
                      children: Caml_option.some(JsxRuntime.jsxs(Drawer, {
                                children: [
                                  JsxRuntime.jsx(ModalClose, {}),
                                  title !== undefined ? JsxRuntime.jsx(DialogTitle, {
                                          children: Caml_option.some(title)
                                        }) : null,
                                  JsxRuntime.jsx(Divider, {}),
                                  children
                                ],
                                open: open_,
                                anchor: "bottom",
                                disablePortal: true,
                                onClose: onClose,
                                size: "md",
                                slotProps: {
                                  backdrop: {
                                    sx: {
                                      backdropFilter: "none",
                                      backgroundColor: "rgba(16, 13, 12, 0.7)"
                                    }
                                  },
                                  content: {
                                    sx: {
                                      "--Drawer-verticalSize": "clamp(500px, 75%, 75%)",
                                      borderTopLeftRadius: "4px",
                                      borderTopRightRadius: "4px",
                                      ml: "8px",
                                      mr: "8px",
                                      width: "calc(100% - 16px)"
                                    }
                                  }
                                }
                              }))
                    })
              ]
            });
}

var make = Drawer$1;

exports.make = make;
/* @mui/joy/Stack Not a pure module */
