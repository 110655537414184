'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function encodeRequestMethod(method_) {
  if (typeof method_ !== "number") {
    return method_._0;
  }
  switch (method_) {
    case /* Get */0 :
        return "GET";
    case /* Head */1 :
        return "HEAD";
    case /* Post */2 :
        return "POST";
    case /* Put */3 :
        return "PUT";
    case /* Delete */4 :
        return "DELETE";
    case /* Connect */5 :
        return "CONNECT";
    case /* Options */6 :
        return "OPTIONS";
    case /* Trace */7 :
        return "TRACE";
    case /* Patch */8 :
        return "PATCH";
    
  }
}

function encodeReferrerPolicy(param) {
  switch (param) {
    case /* None */0 :
        return "";
    case /* NoReferrer */1 :
        return "no-referrer";
    case /* NoReferrerWhenDowngrade */2 :
        return "no-referrer-when-downgrade";
    case /* SameOrigin */3 :
        return "same-origin";
    case /* Origin */4 :
        return "origin";
    case /* StrictOrigin */5 :
        return "strict-origin";
    case /* OriginWhenCrossOrigin */6 :
        return "origin-when-cross-origin";
    case /* StrictOriginWhenCrossOrigin */7 :
        return "strict-origin-when-cross-origin";
    case /* UnsafeUrl */8 :
        return "unsafe-url";
    
  }
}

function encodeRequestMode(param) {
  switch (param) {
    case /* Navigate */0 :
        return "navigate";
    case /* SameOrigin */1 :
        return "same-origin";
    case /* NoCORS */2 :
        return "no-cors";
    case /* CORS */3 :
        return "cors";
    
  }
}

function encodeRequestCredentials(param) {
  switch (param) {
    case /* Omit */0 :
        return "omit";
    case /* SameOrigin */1 :
        return "same-origin";
    case /* Include */2 :
        return "include";
    
  }
}

function encodeRequestCache(param) {
  switch (param) {
    case /* Default */0 :
        return "default";
    case /* NoStore */1 :
        return "no-store";
    case /* Reload */2 :
        return "reload";
    case /* NoCache */3 :
        return "no-cache";
    case /* ForceCache */4 :
        return "force-cache";
    case /* OnlyIfCached */5 :
        return "only-if-cached";
    
  }
}

function encodeRequestRedirect(param) {
  switch (param) {
    case /* Follow */0 :
        return "follow";
    case /* Error */1 :
        return "error";
    case /* Manual */2 :
        return "manual";
    
  }
}

var HeadersInit = {};

function append(arg1, arg2, obj) {
  obj.append(arg1, arg2);
}

function $$delete(arg1, obj) {
  obj.delete(arg1);
}

function get(arg1, obj) {
  return Caml_option.null_to_opt(obj.get(arg1));
}

function has(arg1, obj) {
  return obj.has(arg1);
}

function set(arg1, arg2, obj) {
  obj.set(arg1, arg2);
}

var $$Headers = {
  append: append,
  $$delete: $$delete,
  get: get,
  has: has,
  set: set
};

var BodyInit = {};

function map(f, v) {
  if (v !== undefined) {
    return Caml_option.some(Curry._1(f, Caml_option.valFromOption(v)));
  }
  
}

function make(method_, headers, body, referrer, referrerPolicyOpt, mode, credentials, cache, redirect, integrityOpt, keepalive, signal) {
  var referrerPolicy = referrerPolicyOpt !== undefined ? referrerPolicyOpt : /* None */0;
  var integrity = integrityOpt !== undefined ? integrityOpt : "";
  var partial_arg = integrity;
  var partial_arg$1 = map(encodeRequestRedirect, redirect);
  var partial_arg$2 = map(encodeRequestCache, cache);
  var partial_arg$3 = map(encodeRequestCredentials, credentials);
  var partial_arg$4 = map(encodeRequestMode, mode);
  var partial_arg$5 = encodeReferrerPolicy(referrerPolicy);
  var partial_arg$6 = map(encodeRequestMethod, method_);
  return function (param) {
    var tmp = {};
    if (partial_arg$6 !== undefined) {
      tmp.method = partial_arg$6;
    }
    if (headers !== undefined) {
      tmp.headers = Caml_option.valFromOption(headers);
    }
    if (body !== undefined) {
      tmp.body = Caml_option.valFromOption(body);
    }
    if (referrer !== undefined) {
      tmp.referrer = referrer;
    }
    if (partial_arg$5 !== undefined) {
      tmp.referrerPolicy = partial_arg$5;
    }
    if (partial_arg$4 !== undefined) {
      tmp.mode = partial_arg$4;
    }
    if (partial_arg$3 !== undefined) {
      tmp.credentials = partial_arg$3;
    }
    if (partial_arg$2 !== undefined) {
      tmp.cache = partial_arg$2;
    }
    if (partial_arg$1 !== undefined) {
      tmp.redirect = partial_arg$1;
    }
    if (partial_arg !== undefined) {
      tmp.integrity = partial_arg;
    }
    if (keepalive !== undefined) {
      tmp.keepalive = keepalive;
    }
    if (signal !== undefined) {
      tmp.signal = Caml_option.valFromOption(signal);
    }
    return tmp;
  };
}

function method_(self) {
  var method_$1 = self.method;
  switch (method_$1) {
    case "CONNECT" :
        return /* Connect */5;
    case "DELETE" :
        return /* Delete */4;
    case "GET" :
        return /* Get */0;
    case "HEAD" :
        return /* Head */1;
    case "OPTIONS" :
        return /* Options */6;
    case "PATCH" :
        return /* Patch */8;
    case "POST" :
        return /* Post */2;
    case "PUT" :
        return /* Put */3;
    case "TRACE" :
        return /* Trace */7;
    default:
      return /* Other */{
              _0: method_$1
            };
  }
}

function type_(self) {
  var e = self.type;
  switch (e) {
    case "" :
        return /* None */0;
    case "audio" :
        return /* Audio */1;
    case "font" :
        return /* Font */2;
    case "image" :
        return /* Image */3;
    case "script" :
        return /* Script */4;
    case "style" :
        return /* Style */5;
    case "track" :
        return /* Track */6;
    case "video" :
        return /* Video */7;
    default:
      throw {
            RE_EXN_ID: "Failure",
            _1: "Unknown requestType: " + e,
            Error: new Error()
          };
  }
}

function destination(self) {
  var e = self.destination;
  switch (e) {
    case "" :
        return /* None */0;
    case "document" :
        return /* Document */1;
    case "embed" :
        return /* Embed */2;
    case "font" :
        return /* Font */3;
    case "image" :
        return /* Image */4;
    case "manifest" :
        return /* Manifest */5;
    case "media" :
        return /* Media */6;
    case "object" :
        return /* Object */7;
    case "report" :
        return /* Report */8;
    case "script" :
        return /* Script */9;
    case "serviceworker" :
        return /* ServiceWorker */10;
    case "sharedworder" :
        return /* SharedWorker */11;
    case "style" :
        return /* Style */12;
    case "worker" :
        return /* Worker */13;
    case "xslt" :
        return /* Xslt */14;
    default:
      throw {
            RE_EXN_ID: "Failure",
            _1: "Unknown requestDestination: " + e,
            Error: new Error()
          };
  }
}

function referrerPolicy(self) {
  var e = self.referrerPolicy;
  switch (e) {
    case "" :
        return /* None */0;
    case "no-referrer" :
        return /* NoReferrer */1;
    case "no-referrer-when-downgrade" :
        return /* NoReferrerWhenDowngrade */2;
    case "origin" :
        return /* Origin */4;
    case "origin-when-cross-origin" :
        return /* OriginWhenCrossOrigin */6;
    case "same-origin" :
        return /* SameOrigin */3;
    case "strict-origin" :
        return /* StrictOrigin */5;
    case "strict-origin-when-cross-origin" :
        return /* StrictOriginWhenCrossOrigin */7;
    case "unsafe-url" :
        return /* UnsafeUrl */8;
    default:
      throw {
            RE_EXN_ID: "Failure",
            _1: "Unknown referrerPolicy: " + e,
            Error: new Error()
          };
  }
}

function mode(self) {
  var e = self.mode;
  switch (e) {
    case "cors" :
        return /* CORS */3;
    case "navigate" :
        return /* Navigate */0;
    case "no-cors" :
        return /* NoCORS */2;
    case "same-origin" :
        return /* SameOrigin */1;
    default:
      throw {
            RE_EXN_ID: "Failure",
            _1: "Unknown requestMode: " + e,
            Error: new Error()
          };
  }
}

function credentials(self) {
  var e = self.credentials;
  switch (e) {
    case "include" :
        return /* Include */2;
    case "omit" :
        return /* Omit */0;
    case "same-origin" :
        return /* SameOrigin */1;
    default:
      throw {
            RE_EXN_ID: "Failure",
            _1: "Unknown requestCredentials: " + e,
            Error: new Error()
          };
  }
}

function cache(self) {
  var e = self.cache;
  switch (e) {
    case "default" :
        return /* Default */0;
    case "force-cache" :
        return /* ForceCache */4;
    case "no-cache" :
        return /* NoCache */3;
    case "no-store" :
        return /* NoStore */1;
    case "only-if-cached" :
        return /* OnlyIfCached */5;
    case "reload" :
        return /* Reload */2;
    default:
      throw {
            RE_EXN_ID: "Failure",
            _1: "Unknown requestCache: " + e,
            Error: new Error()
          };
  }
}

function redirect(self) {
  var e = self.redirect;
  switch (e) {
    case "error" :
        return /* Error */1;
    case "follow" :
        return /* Follow */0;
    case "manual" :
        return /* Manual */2;
    default:
      throw {
            RE_EXN_ID: "Failure",
            _1: "Unknown requestRedirect: " + e,
            Error: new Error()
          };
  }
}

function classify(t) {
  if (typeof t === "string") {
    return {
            NAME: "String",
            VAL: t
          };
  } else {
    return {
            NAME: "File",
            VAL: t
          };
  }
}

var EntryValue = {
  classify: classify
};

function append$1(arg1, arg2, obj) {
  obj.append(arg1, arg2);
}

function $$delete$1(arg1, obj) {
  obj.delete(arg1);
}

function get$1(arg1, obj) {
  return obj.get(arg1);
}

function getAll(arg1, obj) {
  return obj.getAll(arg1);
}

function set$1(arg1, arg2, obj) {
  obj.set(arg1, arg2);
}

function has$1(arg1, obj) {
  return obj.has(arg1);
}

function appendObject(arg1, arg2, filename, obj) {
  obj.append(arg1, arg2, filename !== undefined ? Caml_option.valFromOption(filename) : undefined);
}

function appendBlob(arg1, arg2, filename, obj) {
  obj.append(arg1, arg2, filename !== undefined ? Caml_option.valFromOption(filename) : undefined);
}

function appendFile(arg1, arg2, filename, obj) {
  obj.append(arg1, arg2, filename !== undefined ? Caml_option.valFromOption(filename) : undefined);
}

function setObject(arg1, arg2, filename, obj) {
  obj.set(arg1, arg2, filename !== undefined ? Caml_option.valFromOption(filename) : undefined);
}

function setBlob(arg1, arg2, filename, obj) {
  obj.set(arg1, arg2, filename !== undefined ? Caml_option.valFromOption(filename) : undefined);
}

function setFile(arg1, arg2, filename, obj) {
  obj.set(arg1, arg2, filename !== undefined ? Caml_option.valFromOption(filename) : undefined);
}

var $$FormData = {
  EntryValue: EntryValue,
  Iterator: undefined,
  append: append$1,
  $$delete: $$delete$1,
  get: get$1,
  getAll: getAll,
  set: set$1,
  has: has$1,
  appendObject: appendObject,
  appendBlob: appendBlob,
  appendFile: appendFile,
  setObject: setObject,
  setBlob: setBlob,
  setFile: setFile
};

var $$AbortController = {
  abort: (function (prim) {
      prim.abort();
    })
};

function Body_arrayBuffer(prim) {
  return prim.arrayBuffer();
}

function Body_blob(prim) {
  return prim.blob();
}

function Body_formData(prim) {
  return prim.formData();
}

function Body_json(prim) {
  return prim.json();
}

function Body_text(prim) {
  return prim.text();
}

var Body = {
  arrayBuffer: Body_arrayBuffer,
  blob: Body_blob,
  formData: Body_formData,
  json: Body_json,
  text: Body_text
};

var RequestInit = {
  make: make
};

function Request_arrayBuffer(prim) {
  return prim.arrayBuffer();
}

function Request_blob(prim) {
  return prim.blob();
}

function Request_formData(prim) {
  return prim.formData();
}

function Request_json(prim) {
  return prim.json();
}

function Request_text(prim) {
  return prim.text();
}

var $$Request = {
  method_: method_,
  type_: type_,
  destination: destination,
  referrerPolicy: referrerPolicy,
  mode: mode,
  credentials: credentials,
  cache: cache,
  redirect: redirect,
  arrayBuffer: Request_arrayBuffer,
  blob: Request_blob,
  formData: Request_formData,
  json: Request_json,
  text: Request_text
};

function Response_clone(prim) {
  return prim.clone();
}

function Response_arrayBuffer(prim) {
  return prim.arrayBuffer();
}

function Response_blob(prim) {
  return prim.blob();
}

function Response_formData(prim) {
  return prim.formData();
}

function Response_json(prim) {
  return prim.json();
}

function Response_text(prim) {
  return prim.text();
}

var $$Response = {
  clone: Response_clone,
  arrayBuffer: Response_arrayBuffer,
  blob: Response_blob,
  formData: Response_formData,
  json: Response_json,
  text: Response_text
};

exports.$$AbortController = $$AbortController;
exports.HeadersInit = HeadersInit;
exports.$$Headers = $$Headers;
exports.BodyInit = BodyInit;
exports.Body = Body;
exports.RequestInit = RequestInit;
exports.$$Request = $$Request;
exports.$$Response = $$Response;
exports.$$FormData = $$FormData;
/* No side effect */
