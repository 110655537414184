// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var NavBar$Thick = require("../components/common/molecules/NavBar.bs.js");
var Spinner$Thick = require("../components/common/atoms/Spinner.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var InsecureJWTStorage$Util = require("util/src/InsecureJWTStorage.bs.js");
var StandardLayout_query_graphql$Thick = require("../__generated__/StandardLayout_query_graphql.bs.js");

var convertFragment = StandardLayout_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(StandardLayout_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, StandardLayout_query_graphql$Thick.node, convertFragment);
}

var QueryFragment_signonStatus_decode = StandardLayout_query_graphql$Thick.Utils.signonStatus_decode;

var QueryFragment_signonStatus_fromString = StandardLayout_query_graphql$Thick.Utils.signonStatus_fromString;

var QueryFragment = {
  signonStatus_decode: QueryFragment_signonStatus_decode,
  signonStatus_fromString: QueryFragment_signonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function StandardLayout(props) {
  var query = RescriptRelay_Fragment.useFragment(StandardLayout_query_graphql$Thick.node, convertFragment, props.fragmentRefs);
  React.useEffect((function () {
          var match = query.currentUserSignonStatus;
          if (match === "CLEAR_JWT") {
            InsecureJWTStorage$Util.$$delete(undefined);
            ((location.reload()));
          }
          
        }), [query.currentUserSignonStatus]);
  return JsxRuntime.jsx(React.Suspense, {
              children: Caml_option.some(JsxRuntime.jsxs(Sheet, {
                        sx: {
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "background.level1"
                        },
                        children: [
                          JsxRuntime.jsx(NavBar$Thick.make, {
                                profile: query.fragmentRefs,
                                brandFamily: undefined
                              }),
                          JsxRuntime.jsx(Sheet, {
                                sx: {
                                  flex: 1
                                },
                                children: Caml_option.some(props.children)
                              })
                        ]
                      })),
              fallback: Caml_option.some(JsxRuntime.jsx(Spinner$Thick.make, {}))
            });
}

var make = StandardLayout;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
