// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var RevokeInvitation_RevokeBrandFamilyInvitationMutation_graphql$Thick = require("../../../__generated__/RevokeInvitation_RevokeBrandFamilyInvitationMutation_graphql.bs.js");

var convertVariables = RevokeInvitation_RevokeBrandFamilyInvitationMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = RevokeInvitation_RevokeBrandFamilyInvitationMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = RevokeInvitation_RevokeBrandFamilyInvitationMutation_graphql$Thick.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables, RevokeInvitation_RevokeBrandFamilyInvitationMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables, RevokeInvitation_RevokeBrandFamilyInvitationMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param);
}

var RevokeBrandFamilyInvitationMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(brandFamily, email, refreshTeam) {
  var match = use(undefined);
  var mutate = match[0];
  var onClick = function (e, setRevokeError) {
    e.preventDefault();
    Curry._1(setRevokeError, (function (param) {
            
          }));
    Curry._8(mutate, {
          input: {
            forEmail: email,
            fromBrandFamilySlug: brandFamily
          }
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err !== undefined) {
              Curry._1(setRevokeError, (function (param) {
                      return "An error occurred revoking invitation.";
                    }));
              return ;
            }
            var match = resp.revokeBrandFamilyInvitation;
            if (match !== undefined) {
              var match$1 = match.boolean;
              if (match$1 !== undefined && match$1) {
                console.log("Invitation revoked");
                Curry._1(refreshTeam, undefined);
                return ;
              }
              
            }
            Curry._1(setRevokeError, (function (param) {
                    return "An error occurred while revoking invitation.";
                  }));
          }), (function (param) {
            Curry._1(setRevokeError, (function (param) {
                    return "Revoking invitation failed.";
                  }));
          }), undefined, undefined);
  };
  return {
          isMutating: match[1],
          onClick: onClick
        };
}

exports.RevokeBrandFamilyInvitationMutation = RevokeBrandFamilyInvitationMutation;
exports.useMutation = useMutation;
/* RescriptRelay_Mutation Not a pure module */
