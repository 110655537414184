// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Float = require("rescript/lib/js/belt_Float.js");
var Link$Thick = require("../components/common/atoms/Link.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var LogIn$Thick = require("../components/login/mutations/LogIn.bs.js");
var Common$Thick = require("../utils/Common.bs.js");
var Input = require("@mui/joy/Input").default;
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var AcceptInvite$Thick = require("../components/login/mutations/AcceptInvite.bs.js");
var Typography = require("@mui/joy/Typography").default;
var PasswordInput$Thick = require("../components/common/molecules/PasswordInput.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var FormHelperText = require("@mui/joy/FormHelperText").default;
var LoginPageQuery_graphql$Thick = require("../__generated__/LoginPageQuery_graphql.bs.js");
var LoginPage_query_graphql$Thick = require("../__generated__/LoginPage_query_graphql.bs.js");

var convertFragment = LoginPage_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(LoginPage_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, LoginPage_query_graphql$Thick.node, convertFragment);
}

var makeRefetchVariables = LoginPageQuery_graphql$Thick.Types.makeRefetchVariables;

var convertRefetchVariables = LoginPageQuery_graphql$Thick.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(LoginPage_query_graphql$Thick.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment_signonStatus_decode = LoginPage_query_graphql$Thick.Utils.signonStatus_decode;

var QueryFragment_signonStatus_fromString = LoginPage_query_graphql$Thick.Utils.signonStatus_fromString;

var QueryFragment = {
  signonStatus_decode: QueryFragment_signonStatus_decode,
  signonStatus_fromString: QueryFragment_signonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function LoginPage(props) {
  var match = useRefetchable(props.fragmentRefs);
  var refetch = match[1];
  var query = match[0];
  var refetch$1 = function (param) {
    return Curry._4(refetch, Curry._1(makeRefetchVariables, undefined), /* StoreAndNetwork */2, undefined, undefined);
  };
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var searchParams = new URLSearchParams(url.search);
  var selectedBF = Common$Thick.LS.getSelectedBrand(undefined);
  var val = searchParams.get("to");
  var redirect = (val == null) ? (
      selectedBF !== undefined ? "/" + selectedBF + "" : "/dashboard"
    ) : val;
  var match$1 = LogIn$Thick.useMutation(refetch$1);
  var loginError = match$1.error;
  var setPassword = match$1.setPassword;
  var password = match$1.password;
  var setEmail = match$1.setEmail;
  var email = match$1.email;
  var match$2 = AcceptInvite$Thick.useMutation(undefined);
  var onSubmitClaim = match$2.onSubmit;
  var claimError = match$2.error;
  var setupSuccess = match$2.setupSuccess;
  var setConfirmPasswd = match$2.setConfirmPasswd;
  var confirmPasswd = match$2.confirmPasswd;
  var setNewPasswd = match$2.setNewPasswd;
  var newPasswd = match$2.newPasswd;
  React.useEffect((function () {
          var match = query.currentUserSignonStatus;
          if (match === "SIGNED_ON") {
            RescriptReactRouter.push(redirect);
          }
          
        }), [query.currentUserSignonStatus]);
  var token = searchParams.get("token");
  var expiry = searchParams.get("expiry");
  var expired;
  if (expiry == null) {
    expired = true;
  } else {
    var expiry$1 = Belt_Float.fromString(expiry);
    if (expiry$1 !== undefined) {
      var now = Date.now();
      var expiry$2 = expiry$1 * 1000.0;
      expired = now >= expiry$2;
    } else {
      expired = true;
    }
  }
  return JsxRuntime.jsxs(Sheet, {
              variant: "outlined",
              sx: {
                width: 300,
                mx: "auto",
                my: 4,
                py: 3,
                px: 2,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                borderRadius: "sm",
                boxShadow: "md",
                backgroundColor: "background.level1"
              },
              children: [
                (token == null) ? JsxRuntime.jsx(JsxRuntime.Fragment, {
                        children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                  direction: "column",
                                  component: "form",
                                  spacing: 1,
                                  onSubmit: match$1.onSubmit,
                                  children: [
                                    JsxRuntime.jsx(Typography, {
                                          level: "h4",
                                          component: "h1",
                                          children: "Login"
                                        }),
                                    JsxRuntime.jsx(Typography, {
                                          level: "body-sm",
                                          component: "p",
                                          children: "Login to your account"
                                        }),
                                    JsxRuntime.jsxs(Stack, {
                                          spacing: 2,
                                          children: [
                                            JsxRuntime.jsxs(FormControl, {
                                                  children: [
                                                    JsxRuntime.jsx(FormLabel, {
                                                          children: "Email"
                                                        }),
                                                    JsxRuntime.jsx(Input, {
                                                          type: "email",
                                                          value: email,
                                                          onChange: (function (e) {
                                                              var val = e.currentTarget.value;
                                                              Curry._1(setEmail, (function (param) {
                                                                      return val;
                                                                    }));
                                                            })
                                                        })
                                                  ]
                                                }),
                                            JsxRuntime.jsxs(FormControl, {
                                                  children: [
                                                    JsxRuntime.jsx(FormLabel, {
                                                          children: "Password"
                                                        }),
                                                    JsxRuntime.jsx(PasswordInput$Thick.make, {
                                                          value: password,
                                                          onChange: (function (e) {
                                                              var val = e.currentTarget.value;
                                                              Curry._1(setPassword, (function (param) {
                                                                      return val;
                                                                    }));
                                                            })
                                                        })
                                                  ]
                                                }),
                                            JsxRuntime.jsx(Button, {
                                                  loading: match$1.isMutating,
                                                  type: "submit",
                                                  disabled: email === "" || password === "",
                                                  children: "Login"
                                                })
                                          ]
                                        }),
                                    JsxRuntime.jsxs(Typography, {
                                          level: "body-xs",
                                          children: [
                                            "Forgot your password? ",
                                            JsxRuntime.jsx(Link$Thick.make, {
                                                  to: "/forgot-password",
                                                  children: "Reset it"
                                                })
                                          ]
                                        })
                                  ]
                                }))
                      }) : (
                    !expired && !setupSuccess ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                            children: [
                              JsxRuntime.jsx(Typography, {
                                    level: "h4",
                                    component: "h1",
                                    children: "Finish setting up your account"
                                  }),
                              JsxRuntime.jsx(Typography, {
                                    level: "body-sm",
                                    component: "p",
                                    children: "Enter and confirm your password"
                                  }),
                              JsxRuntime.jsxs(Stack, {
                                    direction: "column",
                                    component: "form",
                                    spacing: 2,
                                    onSubmit: (function (e) {
                                        Curry._2(onSubmitClaim, e, token);
                                      }),
                                    children: [
                                      JsxRuntime.jsxs(FormControl, {
                                            children: [
                                              JsxRuntime.jsx(FormLabel, {
                                                    children: "Password"
                                                  }),
                                              JsxRuntime.jsx(PasswordInput$Thick.make, {
                                                    disabled: false,
                                                    value: newPasswd,
                                                    onChange: (function (e) {
                                                        var val = e.currentTarget.value;
                                                        Curry._1(setNewPasswd, (function (param) {
                                                                return val;
                                                              }));
                                                      })
                                                  })
                                            ]
                                          }),
                                      JsxRuntime.jsxs(FormControl, {
                                            children: [
                                              JsxRuntime.jsx(FormLabel, {
                                                    children: "Confirm Password"
                                                  }),
                                              JsxRuntime.jsx(PasswordInput$Thick.make, {
                                                    disabled: false,
                                                    value: confirmPasswd,
                                                    onChange: (function (e) {
                                                        var val = e.currentTarget.value;
                                                        Curry._1(setConfirmPasswd, (function (param) {
                                                                return val;
                                                              }));
                                                      })
                                                  }),
                                              JsxRuntime.jsx(FormHelperText, {
                                                    children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                              color: "danger",
                                                              level: "body-xs",
                                                              component: "p",
                                                              children: Caml_option.some(newPasswd !== confirmPasswd ? "Passwords do not match" : "")
                                                            }))
                                                  })
                                            ]
                                          }),
                                      JsxRuntime.jsx(Button, {
                                            loading: match$2.isMutating,
                                            type: "submit",
                                            disabled: confirmPasswd !== newPasswd,
                                            children: "Set Password"
                                          })
                                    ]
                                  })
                            ]
                          }) : (
                        setupSuccess ? JsxRuntime.jsx(JsxRuntime.Fragment, {
                                children: Caml_option.some(JsxRuntime.jsxs(Typography, {
                                          level: "body-md",
                                          component: "p",
                                          children: [
                                            "Success! Please ",
                                            JsxRuntime.jsx(Link$Thick.make, {
                                                  to: "/login",
                                                  children: "log in"
                                                }),
                                            " to your new account!"
                                          ]
                                        }))
                              }) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsx(Typography, {
                                        level: "h4",
                                        component: "h1",
                                        children: "Finish setting up your account"
                                      }),
                                  JsxRuntime.jsx(Typography, {
                                        level: "body-md",
                                        component: "p",
                                        children: "Your login link has expired."
                                      })
                                ]
                              })
                      )
                  ),
                loginError !== undefined ? JsxRuntime.jsx(Typography, {
                        color: "danger",
                        level: "body-sm",
                        children: Caml_option.some(loginError)
                      }) : JsxRuntime.jsx(JsxRuntime.Fragment, {}),
                claimError !== undefined ? JsxRuntime.jsx(Typography, {
                        color: "danger",
                        level: "body-sm",
                        children: Caml_option.some(claimError)
                      }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
              ]
            });
}

var make = LoginPage;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
