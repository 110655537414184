// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var AcceptInvite_ClaimInvitationWithTokenMutation_graphql$Thick = require("../../../__generated__/AcceptInvite_ClaimInvitationWithTokenMutation_graphql.bs.js");

var convertVariables = AcceptInvite_ClaimInvitationWithTokenMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = AcceptInvite_ClaimInvitationWithTokenMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = AcceptInvite_ClaimInvitationWithTokenMutation_graphql$Thick.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables, AcceptInvite_ClaimInvitationWithTokenMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables, AcceptInvite_ClaimInvitationWithTokenMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param);
}

var ClaimInvitationWithTokenMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(param) {
  var match = use(undefined);
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setNewPasswd = match$1[1];
  var newPasswd = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setConfirmPasswd = match$2[1];
  var match$3 = React.useState(function () {
        return false;
      });
  var setSetupSuccess = match$3[1];
  var match$4 = React.useState(function () {
        
      });
  var setError = match$4[1];
  var onSubmit = function (e, token) {
    e.preventDefault();
    Curry._1(setError, (function (param) {
            
          }));
    Curry._8(mutate, {
          input: {
            allegedToken: token,
            newPasswd: newPasswd
          }
        }, undefined, undefined, (function (store, param) {
            store.invalidateStore();
          }), (function (resp, err) {
            if (err !== undefined) {
              return Curry._1(setError, (function (param) {
                            return "An error occurred while setting up your account";
                          }));
            }
            var match = resp.claimInvitationWithToken;
            if (match !== undefined) {
              var match$1 = match.boolean;
              if (match$1 !== undefined && match$1) {
                Curry._1(setNewPasswd, (function (param) {
                        return "";
                      }));
                Curry._1(setConfirmPasswd, (function (param) {
                        return "";
                      }));
                return Curry._1(setSetupSuccess, (function (param) {
                              return true;
                            }));
              }
              
            }
            Curry._1(setError, (function (param) {
                    return "An error occurred while finishing setting up your account";
                  }));
          }), (function (err) {
            Curry._1(setError, (function (param) {
                    return "An error occurred while finishing account setup";
                  }));
          }), undefined, undefined);
  };
  return {
          isMutating: match[1],
          newPasswd: newPasswd,
          setNewPasswd: setNewPasswd,
          confirmPasswd: match$2[0],
          setConfirmPasswd: setConfirmPasswd,
          setupSuccess: match$3[0],
          error: match$4[0],
          onSubmit: onSubmit
        };
}

exports.ClaimInvitationWithTokenMutation = ClaimInvitationWithTokenMutation;
exports.useMutation = useMutation;
/* react Not a pure module */
