// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var $$Option = require("@mui/joy/Option").default;
var Select = require("@mui/joy/Select").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var CustomScalars$Thick = require("../../utils/CustomScalars.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var ChangeCabinSegmentSubmit$Thick = require("./mutations/ChangeCabinSegmentSubmit.bs.js");
var ChangeCabinSegmentForm_query_graphql$Thick = require("../../__generated__/ChangeCabinSegmentForm_query_graphql.bs.js");

var convertFragment = ChangeCabinSegmentForm_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(ChangeCabinSegmentForm_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, ChangeCabinSegmentForm_query_graphql$Thick.node, convertFragment);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function ChangeCabinSegmentForm(props) {
  var selectedCabins = props.selectedCabins;
  var query = RescriptRelay_Fragment.useFragment(ChangeCabinSegmentForm_query_graphql$Thick.node, convertFragment, props.fragmentRefs);
  var match = query.segments;
  var segOptions = match !== undefined ? Belt_Array.map(match.nodes, (function (node) {
            return node.voyageSegment;
          })) : [];
  var numCabins = Belt_Set.size(selectedCabins);
  var match$1 = ChangeCabinSegmentSubmit$Thick.useMutation(CustomScalars$Thick.BrandFamilySlug.parse(props.brandFamily), CustomScalars$Thick.VoyageSlug.parse(props.voyage), Belt_Array.map(Belt_Set.toArray(selectedCabins), (function (cabin) {
              return Caml_option.some(cabin);
            })), props.refetch, props.setSelectedCabins, props.onClose, props.setError);
  var setNewSegment = match$1.setNewSegment;
  var newSegment = match$1.newSegment;
  return JsxRuntime.jsx("form", {
              children: JsxRuntime.jsxs(Stack, {
                    direction: "column",
                    spacing: 2,
                    children: [
                      JsxRuntime.jsx(Typography, {
                            textColor: "text.tertiary",
                            children: Caml_option.some("Specify new segment for " + String(numCabins) + " selected cabin" + (
                                  numCabins > 1 ? "s" : ""
                                ) + ".")
                          }),
                      JsxRuntime.jsx(Select, {
                            children: Caml_option.some(Belt_Array.map(segOptions, (function (segment) {
                                        return JsxRuntime.jsx($$Option, {
                                                    value: segment,
                                                    children: Caml_option.some(segment)
                                                  });
                                      }))),
                            onChange: (function (param, val) {
                                Curry._1(setNewSegment, (function (param) {
                                        return val;
                                      }));
                              }),
                            placeholder: "Select segment...",
                            size: "sm",
                            value: Caml_option.some(newSegment)
                          }),
                      JsxRuntime.jsx(Button, {
                            loading: match$1.isMutating,
                            type: "submit",
                            disabled: (newSegment == null),
                            children: Caml_option.some("Apply to " + String(numCabins) + " selected cabin" + (
                                  numCabins > 1 ? "s" : ""
                                ) + "")
                          })
                    ]
                  }),
              disabled: (newSegment == null),
              onSubmit: match$1.onSubmit
            });
}

var make = ChangeCabinSegmentForm;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* @mui/joy/Stack Not a pure module */
