// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Utils = require("./utils");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRelay = require("react-relay");
var RelayRuntime = require("relay-runtime");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");

function convertObj(prim0, prim1, prim2, prim3) {
  return Utils.traverser(prim0, prim1, prim2, prim3);
}

function optArrayOfNullableToOptArrayOfOpt(x) {
  if (x !== undefined) {
    return Belt_Array.map(x, (function (prim) {
                  if (prim == null) {
                    return ;
                  } else {
                    return Caml_option.some(prim);
                  }
                }));
  }
  
}

function getLinkedRecords(t, name, $$arguments, param) {
  return optArrayOfNullableToOptArrayOfOpt(Caml_option.nullable_to_opt(t.getLinkedRecords(name, $$arguments)));
}

var RecordProxy = {
  getLinkedRecords: getLinkedRecords
};

function getPluralRootField(t, fieldName) {
  return optArrayOfNullableToOptArrayOfOpt(Caml_option.nullable_to_opt(t.getPluralRootField(fieldName)));
}

var RecordSourceSelectorProxy = {
  getPluralRootField: getPluralRootField
};

var ReadOnlyRecordSourceProxy = {};

function unwrapNormalizationArgument(wrapped) {
  var match = wrapped.kind;
  if (match === "Literal") {
    return {
            TAG: /* Literal */1,
            _0: wrapped
          };
  } else if (match === "ListValue") {
    return {
            TAG: /* ListValue */0,
            _0: wrapped
          };
  } else if (match === "ObjectValue") {
    return {
            TAG: /* ObjectValue */2,
            _0: wrapped
          };
  } else {
    return {
            TAG: /* Variable */3,
            _0: wrapped
          };
  }
}

function makeScalarMissingFieldHandler(handle) {
  return {
          kind: "scalar",
          handle: handle
        };
}

function makeLinkedMissingFieldHandler(handle) {
  return {
          kind: "linked",
          handle: handle
        };
}

function makePluralLinkedMissingFieldHandler(handle) {
  return {
          kind: "pluralLinked",
          handle: handle
        };
}

var MissingFieldHandler = {
  unwrapNormalizationArgument: unwrapNormalizationArgument,
  makeScalarMissingFieldHandler: makeScalarMissingFieldHandler,
  makeLinkedMissingFieldHandler: makeLinkedMissingFieldHandler,
  makePluralLinkedMissingFieldHandler: makePluralLinkedMissingFieldHandler
};

var nodeInterfaceMissingFieldHandler = makeLinkedMissingFieldHandler(function (field, record, args, _store) {
      var match = field.name;
      var match$1 = args.id;
      if ((record == null) || !(match === "node" && record.getType() === RelayRuntime.ROOT_TYPE && !(match$1 == null))) {
        return ;
      } else {
        return Caml_option.some(match$1);
      }
    });

var ConnectionHandler = {};

var Disposable = {};

var Observable = {};

var Network = {};

var RecordSource = {};

function make(source, gcReleaseBufferSize, queryCacheExpirationTime, param) {
  return new RelayRuntime.Store(source, {
              gcReleaseBufferSize: gcReleaseBufferSize,
              queryCacheExpirationTime: queryCacheExpirationTime
            });
}

var Store = {
  make: make
};

function toJs(f, arg) {
  Curry._3(f, arg.kind, arg.owner, arg.fieldPath);
}

function make$1(network, store, getDataID, treatMissingFieldsAsNull, missingFieldHandlers, requiredFieldLogger, isServer, param) {
  var tmp = {
    network: network,
    store: store,
    missingFieldHandlers: missingFieldHandlers !== undefined ? Belt_Array.concat(missingFieldHandlers, [nodeInterfaceMissingFieldHandler]) : [nodeInterfaceMissingFieldHandler]
  };
  if (getDataID !== undefined) {
    tmp.getDataID = Caml_option.valFromOption(getDataID);
  }
  if (treatMissingFieldsAsNull !== undefined) {
    tmp.treatMissingFieldsAsNull = Caml_option.valFromOption(treatMissingFieldsAsNull);
  }
  var tmp$1 = Belt_Option.map(requiredFieldLogger, toJs);
  if (tmp$1 !== undefined) {
    tmp.requiredFieldLogger = Caml_option.valFromOption(tmp$1);
  }
  if (isServer !== undefined) {
    tmp.isServer = Caml_option.valFromOption(isServer);
  }
  return new RelayRuntime.Environment(tmp);
}

var Environment = {
  make: make$1
};

function RescriptRelay$Context$Provider(props) {
  var provider = ReactRelay.ReactRelayContext.Provider;
  return React.createElement(provider, {
              value: {
                environment: props.environment
              },
              children: props.children
            });
}

var Provider = {
  make: RescriptRelay$Context$Provider
};

var Context = {
  Provider: Provider
};

var EnvironmentNotFoundInContext = /* @__PURE__ */Caml_exceptions.create("RescriptRelay.EnvironmentNotFoundInContext");

function useEnvironmentFromContext(param) {
  var context = React.useContext(ReactRelay.ReactRelayContext);
  if (context !== undefined) {
    return Caml_option.valFromOption(context).environment;
  }
  throw {
        RE_EXN_ID: EnvironmentNotFoundInContext,
        Error: new Error()
      };
}

function mapFetchPolicy(x) {
  if (x === undefined) {
    return ;
  }
  switch (x) {
    case /* StoreOnly */0 :
        return "store-only";
    case /* StoreOrNetwork */1 :
        return "store-or-network";
    case /* StoreAndNetwork */2 :
        return "store-and-network";
    case /* NetworkOnly */3 :
        return "network-only";
    
  }
}

function mapFetchQueryFetchPolicy(x) {
  if (x !== undefined) {
    if (x) {
      return "store-or-network";
    } else {
      return "network-only";
    }
  }
  
}

function MakeLoadQuery(C) {
  var load = function (environment, variables, fetchPolicy, fetchKey, networkCacheConfig, param) {
    return ReactRelay.loadQuery(environment, C.query, Curry._1(C.convertVariables, variables), {
                fetchKey: fetchKey,
                fetchPolicy: mapFetchPolicy(fetchPolicy),
                networkCacheConfig: networkCacheConfig
              });
  };
  var queryRefToObservable = function (token) {
    return Caml_option.nullable_to_opt(token.source);
  };
  var queryRefToPromise = function (token) {
    return new Promise((function (resolve, param) {
                  var o = queryRefToObservable(token);
                  if (o !== undefined) {
                    Caml_option.valFromOption(o).subscribe({
                          complete: (function (param) {
                              resolve({
                                    TAG: /* Ok */0,
                                    _0: undefined
                                  });
                            })
                        });
                    return ;
                  } else {
                    return resolve({
                                TAG: /* Error */1,
                                _0: undefined
                              });
                  }
                }));
  };
  return {
          load: load,
          queryRefToObservable: queryRefToObservable,
          queryRefToPromise: queryRefToPromise
        };
}

var Mutation_failed = /* @__PURE__ */Caml_exceptions.create("RescriptRelay.Mutation_failed");

var RequiredFieldLogger = {};

exports.convertObj = convertObj;
exports.RecordProxy = RecordProxy;
exports.RecordSourceSelectorProxy = RecordSourceSelectorProxy;
exports.ReadOnlyRecordSourceProxy = ReadOnlyRecordSourceProxy;
exports.MissingFieldHandler = MissingFieldHandler;
exports.ConnectionHandler = ConnectionHandler;
exports.Observable = Observable;
exports.Network = Network;
exports.RecordSource = RecordSource;
exports.Store = Store;
exports.Disposable = Disposable;
exports.RequiredFieldLogger = RequiredFieldLogger;
exports.Environment = Environment;
exports.mapFetchPolicy = mapFetchPolicy;
exports.mapFetchQueryFetchPolicy = mapFetchQueryFetchPolicy;
exports.Context = Context;
exports.EnvironmentNotFoundInContext = EnvironmentNotFoundInContext;
exports.useEnvironmentFromContext = useEnvironmentFromContext;
exports.Mutation_failed = Mutation_failed;
exports.MakeLoadQuery = MakeLoadQuery;
/* nodeInterfaceMissingFieldHandler Not a pure module */
