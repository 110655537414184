// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../utils/Common.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Table = require("@mui/joy/Table").default;
var Tooltip = require("@mui/joy/Tooltip").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var BookingsTableToolbar$Thick = require("../components/bookings/BookingsTableToolbar.bs.js");
var Settings = require("@mui/icons-material/Settings").default;
var BookingsPageQuery_graphql$Thick = require("../__generated__/BookingsPageQuery_graphql.bs.js");
var BookingsPage_query_graphql$Thick = require("../__generated__/BookingsPage_query_graphql.bs.js");
var BookingsPage_booking_graphql$Thick = require("../__generated__/BookingsPage_booking_graphql.bs.js");

var convertFragment = BookingsPage_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BookingsPage_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BookingsPage_query_graphql$Thick.node, convertFragment);
}

var makeRefetchVariables = BookingsPageQuery_graphql$Thick.Types.makeRefetchVariables;

var convertRefetchVariables = BookingsPageQuery_graphql$Thick.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(BookingsPage_query_graphql$Thick.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

var convertFragment$1 = BookingsPage_booking_graphql$Thick.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(BookingsPage_booking_graphql$Thick.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BookingsPage_booking_graphql$Thick.node, convertFragment$1);
}

var BookingFragment_lifecycleStatus_decode = BookingsPage_booking_graphql$Thick.Utils.lifecycleStatus_decode;

var BookingFragment_lifecycleStatus_fromString = BookingsPage_booking_graphql$Thick.Utils.lifecycleStatus_fromString;

var BookingFragment = {
  lifecycleStatus_decode: BookingFragment_lifecycleStatus_decode,
  lifecycleStatus_fromString: BookingFragment_lifecycleStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1
};

function BookingsPage$TableRow(props) {
  var data = RescriptRelay_Fragment.useFragment(BookingsPage_booking_graphql$Thick.node, convertFragment$1, props.fragmentRefs);
  var match = React.useState(function () {
        return false;
      });
  var setCopied = match[1];
  var bookingVersion = Belt_Array.get(data.bookingVersion.nodes, 0);
  var bookingVersion$1 = bookingVersion !== undefined ? bookingVersion.bookingVersionNumber : 1;
  var billingContact = data.billingContact;
  var match$1 = billingContact !== undefined ? [
      true,
      CustomScalars$Thick.NonemptyString.serialize(billingContact.emailString)
    ] : [
      false,
      "(None)"
    ];
  var bcEmail = match$1[1];
  var hasBcEmail = match$1[0];
  var billingContact$1 = data.billingContact;
  var match$2 = billingContact$1 !== undefined ? [
      true,
      billingContact$1.fullName
    ] : [
      false,
      "(None)"
    ];
  var url = "" + props.baseUrl + "/" + CustomScalars$Thick.BookingId.serialize(data.bookingId) + "/" + String(bookingVersion$1) + "";
  return JsxRuntime.jsxs("tr", {
              children: [
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "body-sm",
                            noWrap: true,
                            children: Caml_option.some(CustomScalars$Thick.BookingId.serialize(data.bookingId))
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "body-sm",
                            noWrap: true,
                            children: Caml_option.some(CustomScalars$Thick.CabinNumber.serialize(data.cabinNumber))
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "body-sm",
                            noWrap: true,
                            children: Caml_option.some(Common$Thick.Lifecycle.toString(data.lifecycleStatus))
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "body-sm",
                            noWrap: true,
                            children: Caml_option.some(String(data.numBerths))
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "body-sm",
                            noWrap: true,
                            sx: {
                              color: match$2[0] ? "" : "neutral.400"
                            },
                            children: Caml_option.some(match$2[1])
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "body-sm",
                            noWrap: true,
                            sx: {
                              color: hasBcEmail ? "" : "neutral.400"
                            },
                            children: Caml_option.some(hasBcEmail ? JsxRuntime.jsx(Tooltip, {
                                        children: JsxRuntime.jsx(Typography, {
                                              level: "body-sm",
                                              noWrap: true,
                                              children: Caml_option.some(bcEmail)
                                            }),
                                        arrow: true,
                                        size: "sm",
                                        sx: {
                                          cursor: "pointer"
                                        },
                                        title: match[0] ? "Copied to clipboard!" : bcEmail,
                                        onClick: (function (param) {
                                            return Common$Thick.copyToClipboard(bcEmail, setCopied);
                                          })
                                      }) : bcEmail)
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Stack, {
                            direction: "row",
                            justifyContent: "flex-end",
                            sx: {
                              width: "100%"
                            },
                            children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                      children: JsxRuntime.jsx(IconButton, {
                                            onClick: (function (param) {
                                                RescriptReactRouter.push(url);
                                              }),
                                            children: Caml_option.some(JsxRuntime.jsx(Settings, {}))
                                          }),
                                      arrow: true,
                                      size: "sm",
                                      title: "View and Manage Booking"
                                    }))
                          })
                    })
              ]
            });
}

var TableRow = {
  make: BookingsPage$TableRow
};

function BookingsPage(props) {
  var voyage = props.voyage;
  var brandFamily = props.brandFamily;
  var match = useRefetchable(props.fragmentRefs);
  var refetch = match[1];
  var data = match[0];
  var baseUrl = "/" + CustomScalars$Thick.BrandFamilySlug.serialize(brandFamily) + "/" + CustomScalars$Thick.VoyageSlug.serialize(voyage) + "/bookings";
  var primaryFilter_brandFamilySlug = {
    equalTo: CustomScalars$Thick.BrandFamilySlug.serialize(brandFamily)
  };
  var primaryFilter_voyageSlug = {
    equalTo: CustomScalars$Thick.VoyageSlug.serialize(voyage)
  };
  var primaryFilter_bookingId = {
    notIn: []
  };
  var primaryFilter = {
    orgSlug: undefined,
    brandFamilySlug: primaryFilter_brandFamilySlug,
    voyageSlug: primaryFilter_voyageSlug,
    bookingId: primaryFilter_bookingId,
    lifecycleStatus: undefined,
    cabinNumber: undefined,
    occRange: undefined,
    numBerths: undefined,
    fareClassSlug: undefined,
    billingContactAccountId: undefined,
    bookingIdString: undefined,
    cabinNumberString: undefined,
    bookingVersion: undefined,
    bookingVersionExist: undefined,
    cabin: undefined,
    billingContact: undefined,
    fare_class: undefined,
    and: undefined,
    or: undefined,
    not: undefined
  };
  var match$1 = React.useState(function () {
        return primaryFilter;
      });
  var setFilter = match$1[1];
  var filter = match$1[0];
  var match$2 = React.useState(function () {
        return 0;
      });
  var setOffset = match$2[1];
  var offset = match$2[0];
  var match$3 = data.bookings;
  var totalCount = match$3 !== undefined ? match$3.totalCount : 0;
  var refetchPage = function (newOffset) {
    Curry._4(refetch, Curry._3(makeRefetchVariables, undefined, Caml_option.some(newOffset), undefined), /* StoreAndNetwork */2, undefined, undefined);
  };
  var stringFromOffset = function (o, t) {
    var start = o + 1 | 0;
    var end = o + 50 | 0;
    var end$1 = end > t ? t : end;
    if (t !== 0) {
      return "" + String(start) + " - " + String(end$1) + " / " + String(t) + "";
    } else {
      return "0";
    }
  };
  var onLoadNext = function (param) {
    var newOffset = offset + 50 | 0;
    Curry._1(setOffset, (function (param) {
            return newOffset;
          }));
    refetchPage(newOffset);
  };
  var onLoadPrev = function (param) {
    var newOffset = offset + 50 | 0;
    Curry._1(setOffset, (function (param) {
            return newOffset;
          }));
    refetchPage(newOffset);
  };
  var search = function (t) {
    var newrecord = Caml_obj.obj_dup(filter);
    newrecord.or = [
      {
        billingContact: {
          or: [
            {
              fullName: {
                includesInsensitive: t
              }
            },
            {
              emailString: {
                includesInsensitive: CustomScalars$Thick.NonemptyString.parse(t)
              }
            }
          ]
        }
      },
      {
        bookingIdString: {
          includesInsensitive: CustomScalars$Thick.NonemptyString.parse(t)
        }
      },
      {
        cabinNumberString: {
          includesInsensitive: CustomScalars$Thick.NonemptyString.parse(t)
        }
      }
    ];
    Curry._1(setFilter, (function (param) {
            return newrecord;
          }));
  };
  var clearSearch = function (param) {
    var newrecord = Caml_obj.obj_dup(filter);
    newrecord.or = undefined;
    Curry._1(setFilter, (function (param) {
            return newrecord;
          }));
  };
  React.useEffect((function () {
          Curry._4(refetch, Curry._3(makeRefetchVariables, Caml_option.some(filter), undefined, undefined), /* StoreAndNetwork */2, undefined, undefined);
        }), [filter]);
  var match$4 = data.bookings;
  var tmp;
  var exit = 0;
  if (match$4 !== undefined) {
    var nodes = match$4.nodes;
    if (nodes.length !== 0) {
      tmp = JsxRuntime.jsxs(Table, {
            stripe: "odd",
            hoverRow: true,
            sx: {
              captionSide: "top",
              "& tbody": {
                bgcolor: "background.surface"
              }
            },
            children: [
              JsxRuntime.jsx("thead", {
                    children: JsxRuntime.jsxs("tr", {
                          children: [
                            JsxRuntime.jsx("th", {
                                  children: "Booking Id"
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Cabin Number"
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Lifecycle Status"
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "# of Berths"
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Billing Contact"
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Billing Contact Email"
                                }),
                            JsxRuntime.jsx("th", {})
                          ]
                        })
                  }),
              JsxRuntime.jsx("tbody", {
                    children: Belt_Array.map(nodes, (function (node) {
                            return JsxRuntime.jsx(BookingsPage$TableRow, {
                                        fragmentRefs: node.fragmentRefs,
                                        baseUrl: baseUrl
                                      }, node.__id);
                          }))
                  })
            ]
          });
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(Stack, {
          justifyContent: "center",
          alignItems: "center",
          sx: {
            height: "15rem",
            width: "100%"
          },
          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                    level: "title-lg",
                    sx: {
                      color: "neutral.500"
                    },
                    children: "No bookings found"
                  }))
        });
  }
  return JsxRuntime.jsxs(Stack, {
              spacing: 1,
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "h3",
                      component: "h1",
                      children: Caml_option.some("" + CustomScalars$Thick.VoyageSlug.serialize(voyage) + " Bookings")
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "row",
                      spacing: 1,
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                direction: "column",
                                alignItems: "flex-start",
                                spacing: 1,
                                sx: {
                                  width: "100%"
                                },
                                children: Caml_option.some(JsxRuntime.jsxs(Sheet, {
                                          variant: "outlined",
                                          sx: {
                                            pt: 1,
                                            borderRadius: "sm",
                                            width: "100%"
                                          },
                                          children: [
                                            JsxRuntime.jsx(BookingsTableToolbar$Thick.make, {
                                                  totalCount: totalCount,
                                                  offset: offset,
                                                  onLoadNext: onLoadNext,
                                                  onLoadPrev: onLoadPrev,
                                                  stringFromOffset: stringFromOffset,
                                                  searchBar: true,
                                                  search: search,
                                                  clearSearch: clearSearch
                                                }),
                                            tmp,
                                            JsxRuntime.jsx(BookingsTableToolbar$Thick.make, {
                                                  totalCount: totalCount,
                                                  offset: offset,
                                                  onLoadNext: onLoadNext,
                                                  onLoadPrev: onLoadPrev,
                                                  stringFromOffset: stringFromOffset,
                                                  search: search,
                                                  clearSearch: clearSearch
                                                })
                                          ]
                                        }))
                              }))
                    })
              ]
            });
}

var make = BookingsPage;

exports.QueryFragment = QueryFragment;
exports.BookingFragment = BookingFragment;
exports.TableRow = TableRow;
exports.make = make;
/* react Not a pure module */
