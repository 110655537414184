// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Grid = require("@mui/joy/Grid").default;
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var BDInvoiceDetails$Thick = require("../billing-payments/BDInvoiceDetails.bs.js");

function DiffComparison(props) {
  var billPayCurrent = props.billPayCurrent;
  var billPayNew = props.billPayNew;
  return JsxRuntime.jsxs(Grid, {
              children: [
                JsxRuntime.jsx(Grid, {
                      children: Caml_option.some(billPayCurrent !== undefined ? JsxRuntime.jsxs(Stack, {
                                  direction: "column",
                                  spacing: 1,
                                  sx: {
                                    border: "2px solid",
                                    borderColor: "neutral.300",
                                    borderRadius: "4px",
                                    p: 1
                                  },
                                  children: [
                                    JsxRuntime.jsx(Typography, {
                                          level: "h3",
                                          component: "h3",
                                          textColor: "neutral.500",
                                          children: "Current invoice details:"
                                        }),
                                    JsxRuntime.jsx(BDInvoiceDetails$Thick.make, {
                                          isActive: false,
                                          snapshot: false,
                                          canManage: false,
                                          billPayRec: billPayCurrent
                                        })
                                  ]
                                }) : JsxRuntime.jsx(Stack, {
                                  direction: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  sx: {
                                    height: "7rem"
                                  },
                                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                            level: "title-lg",
                                            component: "p",
                                            textColor: "neutral.500",
                                            children: "No current information available."
                                          }))
                                })),
                      xs: 6
                    }),
                JsxRuntime.jsx(Grid, {
                      children: Caml_option.some(billPayNew !== undefined ? JsxRuntime.jsxs(Stack, {
                                  direction: "column",
                                  spacing: 1,
                                  sx: {
                                    border: "2px solid",
                                    borderColor: "primary.400",
                                    borderRadius: "4px",
                                    p: 1
                                  },
                                  children: [
                                    JsxRuntime.jsx(Typography, {
                                          level: "h3",
                                          component: "h3",
                                          textColor: "neutral.500",
                                          children: "Preview invoice details:"
                                        }),
                                    JsxRuntime.jsx(BDInvoiceDetails$Thick.make, {
                                          isActive: true,
                                          snapshot: false,
                                          canManage: false,
                                          billPayRec: billPayNew
                                        })
                                  ]
                                }) : JsxRuntime.jsx(Stack, {
                                  direction: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  sx: {
                                    height: "7rem"
                                  },
                                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                            level: "title-lg",
                                            component: "p",
                                            textColor: "neutral.500",
                                            children: "No preview information available."
                                          }))
                                })),
                      xs: 6
                    })
              ],
              container: true,
              spacing: 1,
              sx: {
                width: "100%"
              }
            });
}

var make = DiffComparison;

exports.make = make;
/* @mui/joy/Grid Not a pure module */
