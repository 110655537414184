// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var List = require("@mui/joy/List").default;
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var RelayEnv$Thick = require("../../../RelayEnv.bs.js");
var ListItem = require("@mui/joy/ListItem").default;
var JsxRuntime = require("react/jsx-runtime");
var Belt_HashMapString = require("rescript/lib/js/belt_HashMapString.js");
var Typography = require("@mui/joy/Typography").default;
var CustomScalars$Thick = require("../../../utils/CustomScalars.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var DiffListQuery_graphql$Thick = require("../../../__generated__/DiffListQuery_graphql.bs.js");

var convertVariables = DiffListQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = DiffListQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = DiffListQuery_graphql$Thick.Internal.convertWrapRawResponse;

function use(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.useQuery(convertVariables, DiffListQuery_graphql$Thick.node, convertResponse, param, param$1, param$2, param$3, param$4);
}

function useLoader(param) {
  return RescriptRelay_Query.useLoader(convertVariables, DiffListQuery_graphql$Thick.node, (function (prim) {
                return prim;
              }), param);
}

function usePreloaded(param) {
  return RescriptRelay_Query.usePreloaded(DiffListQuery_graphql$Thick.node, convertResponse, (function (prim) {
                return prim;
              }), param);
}

function $$fetch(param, param$1, param$2, param$3, param$4, param$5) {
  return RescriptRelay_Query.$$fetch(DiffListQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4, param$5);
}

function fetchPromised(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.fetchPromised(DiffListQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4);
}

function retain(param, param$1) {
  return RescriptRelay_Query.retain(DiffListQuery_graphql$Thick.node, convertVariables, param, param$1);
}

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function DiffList(props) {
  var size = props.size;
  var diffStruct = props.diffStruct;
  var size$1 = size !== undefined ? size : "sm";
  var match = React.useState(function () {
        
      });
  var setReplacementPaxes = match[1];
  var replacementPaxes = match[0];
  var berths = diffStruct.addBerths;
  var addBerthsInitial = berths !== undefined ? "Add " + String(berths) + " berth(s)" : undefined;
  var addBerths = React.useDeferredValue(addBerthsInitial);
  var paxIdxAndName = Belt_HashMapString.fromArray(Belt_Array.keepMap(props.paxes.nodes, (function (p) {
              var user = p.bfcustUser;
              if (user !== undefined) {
                return [
                        String(p.idx),
                        user.fullName
                      ];
              }
              
            })));
  var berths$1 = diffStruct.dropBerths;
  var dropBerthsInitial;
  if (berths$1 !== undefined) {
    var removedPaxes = Belt_Array.map(berths$1, (function (idx) {
            var str = String(idx);
            var name = Belt_HashMapString.get(paxIdxAndName, str);
            if (name !== undefined) {
              return name;
            } else {
              return "Passenger " + String(idx) + "";
            }
          }));
    dropBerthsInitial = "Remove berths occupied by: " + removedPaxes.join(", ") + ".";
  } else {
    dropBerthsInitial = undefined;
  }
  var dropBerths = React.useDeferredValue(dropBerthsInitial);
  var berths$2 = diffStruct.detachPax;
  var detachPaxInitial;
  if (berths$2 !== undefined) {
    var detachedPaxes = Belt_Array.map(berths$2, (function (idx) {
            var str = String(idx);
            var name = Belt_HashMapString.get(paxIdxAndName, str);
            if (name !== undefined) {
              return name;
            } else {
              return "Passenger " + String(idx) + "";
            }
          }));
    detachPaxInitial = "Detach the following passengers from their berth(s): " + detachedPaxes.join(", ") + ".";
  } else {
    detachPaxInitial = undefined;
  }
  var detachPax = React.useDeferredValue(detachPaxInitial);
  var acctId = diffStruct.changeBillingContact;
  var bc;
  if (acctId !== undefined) {
    var str = CustomScalars$Thick.AccountId.serialize(Caml_option.valFromOption(acctId));
    var pax = Belt_MapString.get(replacementPaxes, str);
    bc = pax !== undefined ? "Change Billing Contact to " + pax.fullName + "." : "Change Billing Contact to " + str + ".";
  } else {
    bc = undefined;
  }
  var bc$1 = React.useDeferredValue(bc);
  var map = diffStruct.attachExistingPax;
  var attachExistingPax;
  if (map !== undefined) {
    var paxes = Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(map)), (function (param) {
            var str = CustomScalars$Thick.AccountId.serialize(param[1]);
            var pax = Belt_MapString.get(replacementPaxes, str);
            if (pax !== undefined) {
              return pax.fullName;
            } else {
              return str;
            }
          }));
    attachExistingPax = "Attach the following existing passenger(s): " + paxes.join(", ") + ".";
  } else {
    attachExistingPax = undefined;
  }
  var attachExistingPax$1 = React.useDeferredValue(attachExistingPax);
  var map$1 = diffStruct.replacePaxExisting;
  var replacePaxExisting;
  if (map$1 !== undefined) {
    var paxes$1 = Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(map$1)), (function (param) {
            var idx = param[0];
            var str = CustomScalars$Thick.AccountId.serialize(param[1]);
            var pax = Belt_MapString.get(replacementPaxes, str);
            if (pax === undefined) {
              return str;
            }
            var name = Belt_HashMapString.get(paxIdxAndName, String(idx));
            if (name !== undefined) {
              return "Replace " + name + " with " + pax.fullName + "";
            } else {
              return "Replace passenger " + String(idx) + " with " + pax.fullName + "";
            }
          }));
    replacePaxExisting = "Make the following passenger substitutions: " + paxes$1.join("; ") + ".";
  } else {
    replacePaxExisting = undefined;
  }
  var replacePaxExisting$1 = React.useDeferredValue(replacePaxExisting);
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  React.useEffect((function () {
          var match = diffStruct.changeBillingContact;
          var match$1 = diffStruct.attachExistingPax;
          var match$2 = diffStruct.replacePaxExisting;
          var exit = 0;
          if (match !== undefined || match$1 !== undefined || match$2 !== undefined) {
            exit = 1;
          } else {
            Curry._1(setReplacementPaxes, (function (param) {
                    
                  }));
          }
          if (exit === 1) {
            var bc = match !== undefined ? [Caml_option.valFromOption(match)] : [];
            var attach = match$1 !== undefined ? Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(match$1)), (function (param) {
                      return param[1];
                    })) : [];
            var replace = match$2 !== undefined ? Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(match$2)), (function (param) {
                      return param[1];
                    })) : [];
            var acctIds = Belt_Array.concat(Belt_Array.concat(bc, attach), replace);
            $$fetch(RelayEnv$Thick.environment, {
                  acctIds: acctIds
                }, (function (res) {
                    if (res.TAG === /* Ok */0) {
                      var match = res._0.bfcustUsers;
                      if (match !== undefined) {
                        var arr = Belt_MapString.fromArray(Belt_Array.map(match.nodes, (function (acct) {
                                    return [
                                            CustomScalars$Thick.AccountId.serialize(acct.accountId),
                                            {
                                              accountId: CustomScalars$Thick.AccountId.serialize(acct.accountId),
                                              fullName: acct.fullName
                                            }
                                          ];
                                  })));
                        Curry._1(setReplacementPaxes, (function (param) {
                                return arr;
                              }));
                      } else {
                        console.log("nothing doing");
                      }
                      return ;
                    }
                    console.error(res._0);
                  }), undefined, undefined, undefined);
          }
          
        }), [url.search]);
  return JsxRuntime.jsxs(List, {
              children: [
                addBerths !== undefined ? JsxRuntime.jsx(ListItem, {
                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                  level: size$1 === "sm" ? "body-sm" : "body-md",
                                  children: Caml_option.some(addBerths)
                                }))
                      }) : null,
                dropBerths !== undefined ? JsxRuntime.jsx(ListItem, {
                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                  level: size$1 === "sm" ? "body-sm" : "body-md",
                                  children: Caml_option.some(dropBerths)
                                }))
                      }) : null,
                bc$1 !== undefined ? JsxRuntime.jsx(ListItem, {
                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                  level: size$1 === "sm" ? "body-sm" : "body-md",
                                  children: Caml_option.some(bc$1)
                                }))
                      }) : null,
                detachPax !== undefined ? JsxRuntime.jsx(ListItem, {
                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                  level: size$1 === "sm" ? "body-sm" : "body-md",
                                  children: Caml_option.some(detachPax)
                                }))
                      }) : null,
                attachExistingPax$1 !== undefined ? JsxRuntime.jsx(ListItem, {
                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                  level: size$1 === "sm" ? "body-sm" : "body-md",
                                  children: Caml_option.some(attachExistingPax$1)
                                }))
                      }) : null,
                replacePaxExisting$1 !== undefined ? JsxRuntime.jsx(ListItem, {
                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                  level: size$1 === "sm" ? "body-sm" : "body-md",
                                  children: Caml_option.some(replacePaxExisting$1)
                                }))
                      }) : null
              ]
            });
}

var make = DiffList;

exports.Query = Query;
exports.make = make;
/* react Not a pure module */
