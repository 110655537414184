// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var Alert = require("@mui/joy/Alert").default;
var Stack = require("@mui/joy/Stack").default;
var DiffList$Thick = require("./DiffList.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var ChevronRight = require("@mui/icons-material/ChevronRight").default;
var InfoOutlined = require("@mui/icons-material/InfoOutlined").default;

function DraftAlert(props) {
  var match = React.useState(function () {
        return false;
      });
  var setIsOpen = match[1];
  var open_ = match[0];
  return JsxRuntime.jsx(Alert, {
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "column",
                        sx: {
                          width: "100%"
                        },
                        children: [
                          JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                sx: {
                                  width: "100%",
                                  cursor: "pointer"
                                },
                                onClick: (function (param) {
                                    Curry._1(setIsOpen, (function (param) {
                                            return !open_;
                                          }));
                                  }),
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        alignItems: "center",
                                        spacing: 2,
                                        children: [
                                          JsxRuntime.jsx(InfoOutlined, {
                                                sx: {
                                                  color: Common$Thick.Lifecycle.getPrimaryColor("DRAFT")
                                                }
                                              }),
                                          JsxRuntime.jsx(Typography, {
                                                level: "body-md",
                                                sx: {
                                                  color: Common$Thick.Lifecycle.getPrimaryColor("DRAFT")
                                                },
                                                children: "Draft"
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsx(ChevronRight, {
                                        sx: {
                                          transform: open_ ? "rotate(-90deg)" : "rotate(90deg)",
                                          color: Common$Thick.Lifecycle.getPrimaryColor("DRAFT")
                                        }
                                      })
                                ]
                              }),
                          JsxRuntime.jsx(Stack, {
                                sx: {
                                  display: open_ ? "block" : "none"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(DiffList$Thick.make, {
                                          paxes: props.paxes,
                                          diffStruct: props.diffStruct
                                        }))
                              })
                        ]
                      })),
              color: "neutral",
              sx: {
                position: "fixed",
                bottom: "2rem",
                right: "2rem",
                width: "20rem",
                borderColor: Common$Thick.Lifecycle.getPrimaryColor("DRAFT"),
                backgroundColor: Common$Thick.Lifecycle.getBackgroundColor("DRAFT")
              },
              variant: "outlined"
            });
}

var make = DraftAlert;

exports.make = make;
/* react Not a pure module */
