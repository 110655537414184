// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../utils/Common.bs.js");
var Grid = require("@mui/joy/Grid").default;
var Stack = require("@mui/joy/Stack").default;
var BDButton$Thick = require("./atoms/BDButton.bs.js");
var RelayEnv$Thick = require("../../RelayEnv.bs.js");
var Tooltip = require("@mui/joy/Tooltip").default;
var BookingDiff$Thick = require("../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var ChangeBCModal$Thick = require("./drafts/ChangeBCModal.bs.js");
var CustomScalars$Thick = require("../../utils/CustomScalars.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var BDContentBlock$Thick = require("./molecules/BDContentBlock.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var BDBillingContactQuery_graphql$Thick = require("../../__generated__/BDBillingContactQuery_graphql.bs.js");
var BDBillingContact_version_graphql$Thick = require("../../__generated__/BDBillingContact_version_graphql.bs.js");
var ContentCopyOutlined = require("@mui/icons-material/ContentCopyOutlined").default;
var PublishedWithChanges = require("@mui/icons-material/PublishedWithChanges").default;

var convertFragment = BDBillingContact_version_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BDBillingContact_version_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDBillingContact_version_graphql$Thick.node, convertFragment);
}

var VersionFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

var convertVariables = BDBillingContactQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = BDBillingContactQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = BDBillingContactQuery_graphql$Thick.Internal.convertWrapRawResponse;

function use$1(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.useQuery(convertVariables, BDBillingContactQuery_graphql$Thick.node, convertResponse, param, param$1, param$2, param$3, param$4);
}

function useLoader(param) {
  return RescriptRelay_Query.useLoader(convertVariables, BDBillingContactQuery_graphql$Thick.node, (function (prim) {
                return prim;
              }), param);
}

function usePreloaded(param) {
  return RescriptRelay_Query.usePreloaded(BDBillingContactQuery_graphql$Thick.node, convertResponse, (function (prim) {
                return prim;
              }), param);
}

function $$fetch(param, param$1, param$2, param$3, param$4, param$5) {
  return RescriptRelay_Query.$$fetch(BDBillingContactQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4, param$5);
}

function fetchPromised(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.fetchPromised(BDBillingContactQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4);
}

function retain(param, param$1) {
  return RescriptRelay_Query.retain(BDBillingContactQuery_graphql$Thick.node, convertVariables, param, param$1);
}

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use$1,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function BDBillingContact$BillingContact$DataStack(props) {
  var value = props.value;
  var label = props.label;
  var match = React.useState(function () {
        return false;
      });
  var setCopied = match[1];
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "body-sm",
                      noWrap: true,
                      sx: {
                        color: "neutral.500"
                      },
                      fontWeight: "normal",
                      children: Caml_option.some(label)
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      alignItems: "center",
                      spacing: 1,
                      children: [
                        JsxRuntime.jsx(Typography, {
                              level: "body-sm",
                              noWrap: true,
                              sx: {
                                color: value !== undefined ? "text.primary" : "neutral.plainDisabledColor"
                              },
                              fontWeight: "normal",
                              children: Caml_option.some(value !== undefined ? value : "No " + label.toLowerCase() + " provided")
                            }),
                        JsxRuntime.jsx(Tooltip, {
                              children: JsxRuntime.jsx(ContentCopyOutlined, {
                                    fontSize: "sm",
                                    onClick: (function (param) {
                                        if (value !== undefined) {
                                          Common$Thick.copyToClipboard(value, setCopied);
                                          return ;
                                        }
                                        
                                      })
                                  }),
                              arrow: true,
                              size: "sm",
                              sx: {
                                cursor: "pointer"
                              },
                              title: match[0] ? "Copied!" : "Copy to clipboard"
                            })
                      ]
                    })
              ]
            });
}

var DataStack = {
  make: BDBillingContact$BillingContact$DataStack
};

function BDBillingContact$BillingContact(props) {
  var content = props.content;
  var match = React.useState(function () {
        return false;
      });
  var setModalOpen = match[1];
  var actions = props.isActive && !(props.snapshot || !props.canManage) ? JsxRuntime.jsx(BDButton$Thick.make, {
          endDecorator: Caml_option.some(JsxRuntime.jsx(PublishedWithChanges, {
                    fontSize: "sm",
                    sx: {
                      color: "text.secondary"
                    }
                  })),
          onClick: (function (param) {
              Curry._1(setModalOpen, (function (param) {
                      return true;
                    }));
            }),
          text: "Change"
        }) : null;
  return JsxRuntime.jsxs(BDContentBlock$Thick.make, {
              title: "Billing Contact",
              actions: Caml_option.some(actions),
              children: [
                JsxRuntime.jsxs(Grid, {
                      children: [
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(BDBillingContact$BillingContact$DataStack, {
                                        label: "Contact Name",
                                        value: content.fullName
                                      })),
                              xs: 4
                            }),
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(BDBillingContact$BillingContact$DataStack, {
                                        label: "Account ID",
                                        value: content.accountId
                                      })),
                              xs: 3
                            }),
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(BDBillingContact$BillingContact$DataStack, {
                                        label: "Email",
                                        value: content.email
                                      })),
                              xs: 5
                            })
                      ],
                      container: true
                    }),
                JsxRuntime.jsx(ChangeBCModal$Thick.make, {
                      open_: match[0],
                      onClose: (function (param) {
                          Curry._1(setModalOpen, (function (param) {
                                  return false;
                                }));
                        }),
                      billPayCurrent: props.billPayRec,
                      versionUrl: props.versionUrl,
                      currentAcctId: content.accountId,
                      brandFamily: props.brandFamily,
                      generatePreviewFromDiff: props.generatePreviewFromDiff
                    })
              ]
            });
}

var BillingContact = {
  DataStack: DataStack,
  make: BDBillingContact$BillingContact
};

function BDBillingContact(props) {
  var brandFamily = props.brandFamily;
  var data = RescriptRelay_Fragment.useFragment(BDBillingContact_version_graphql$Thick.node, convertFragment, props.fragmentRefs);
  var billingContact = data.billingContact;
  var billingContact$1 = data.billingContact;
  var match = data.billingContact;
  var tmp;
  if (match !== undefined) {
    var email = match.email;
    tmp = email !== undefined ? CustomScalars$Thick.Email.serialize(Caml_option.valFromOption(email)) : undefined;
  } else {
    tmp = undefined;
  }
  var account_accountId = billingContact !== undefined ? CustomScalars$Thick.AccountId.serialize(billingContact.accountId) : undefined;
  var account_fullName = billingContact$1 !== undefined ? billingContact$1.fullName : undefined;
  var account = {
    accountId: account_accountId,
    fullName: account_fullName,
    email: tmp
  };
  var match$1 = React.useState(function () {
        return account;
      });
  var setBc = match$1[1];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  React.useEffect((function () {
          var acctId = diffStruct.changeBillingContact;
          if (acctId !== undefined) {
            $$fetch(RelayEnv$Thick.environment, {
                  accountId: Caml_option.valFromOption(acctId),
                  brandFamily: brandFamily
                }, (function (res) {
                    if (res.TAG === /* Ok */0) {
                      var match = res._0.bfcustUsers;
                      if (match !== undefined) {
                        var match$1 = match.nodes;
                        if (match$1.length !== 1) {
                          console.log("nothing doing");
                        } else {
                          var account = match$1[0];
                          var email = account.email;
                          var acct_accountId = CustomScalars$Thick.AccountId.serialize(account.accountId);
                          var acct_fullName = account.fullName;
                          var acct_email = email !== undefined ? CustomScalars$Thick.Email.serialize(Caml_option.valFromOption(email)) : undefined;
                          var acct = {
                            accountId: acct_accountId,
                            fullName: acct_fullName,
                            email: acct_email
                          };
                          Curry._1(setBc, (function (param) {
                                  return acct;
                                }));
                        }
                      } else {
                        console.log("nothing doing");
                      }
                      return ;
                    }
                    console.error(res._0);
                  }), undefined, undefined, undefined);
          } else {
            Curry._1(setBc, (function (param) {
                    return account;
                  }));
          }
        }), [url.search]);
  return JsxRuntime.jsx(BDBillingContact$BillingContact, {
              content: match$1[0],
              snapshot: props.snapshot,
              isActive: props.isActive,
              canManage: props.canManage,
              versionUrl: props.versionUrl,
              billPayRec: props.billPayRec,
              brandFamily: brandFamily,
              generatePreviewFromDiff: props.generatePreviewFromDiff
            });
}

var make = BDBillingContact;

exports.VersionFragment = VersionFragment;
exports.Query = Query;
exports.BillingContact = BillingContact;
exports.make = make;
/* react Not a pure module */
