// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Drawer$Thick = require("../../common/molecules/Drawer.bs.js");
var Grid = require("@mui/joy/Grid").default;
var Stack = require("@mui/joy/Stack").default;
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var BDInvoiceDetails$Thick = require("./BDInvoiceDetails.bs.js");
var BDDepositScheduleDetails$Thick = require("./BDDepositScheduleDetails.bs.js");

function BDBillPayDrawer(props) {
  var canManage = props.canManage;
  var snapshot = props.snapshot;
  var isActive = props.isActive;
  var match = React.useState(function () {
        return "invoice";
      });
  var setTab = match[1];
  var tab = match[0];
  return JsxRuntime.jsxs(Drawer$Thick.make, {
              open_: props.open_,
              title: "Billing & Payment Details",
              onClose: props.onClose,
              children: [
                JsxRuntime.jsxs(Grid, {
                      children: [
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                        direction: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        onClick: (function (param) {
                                            Curry._1(setTab, (function (param) {
                                                    return "invoice";
                                                  }));
                                          }),
                                        children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                  sx: {
                                                    width: "fit-content",
                                                    height: "100"
                                                  },
                                                  children: [
                                                    JsxRuntime.jsx(Typography, {
                                                          level: "body-sm",
                                                          sx: {
                                                            my: 2,
                                                            color: tab === "invoice" ? "text.primary" : "neutral.500"
                                                          },
                                                          children: "Invoice"
                                                        }),
                                                    JsxRuntime.jsx(Stack, {
                                                          sx: {
                                                            height: "3px",
                                                            width: "100%",
                                                            backgroundColor: tab === "invoice" ? "text.secondary" : "transparent",
                                                            borderTopRightRadius: "100px",
                                                            borderTopLeftRadius: "100px"
                                                          }
                                                        })
                                                  ]
                                                }))
                                      })),
                              sx: {
                                "&:hover": {
                                  backgroundColor: "background.level2",
                                  cursor: "pointer"
                                }
                              },
                              xs: 6
                            }),
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                        direction: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        onClick: (function (param) {
                                            Curry._1(setTab, (function (param) {
                                                    return "deposit";
                                                  }));
                                          }),
                                        children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                  sx: {
                                                    width: "fit-content",
                                                    height: "100"
                                                  },
                                                  children: [
                                                    JsxRuntime.jsx(Typography, {
                                                          level: "body-sm",
                                                          sx: {
                                                            my: 2,
                                                            color: tab === "deposit" ? "text.primary" : "neutral.500"
                                                          },
                                                          children: "Deposit Schedule"
                                                        }),
                                                    JsxRuntime.jsx(Stack, {
                                                          sx: {
                                                            height: "3px",
                                                            width: "100%",
                                                            backgroundColor: tab === "deposit" ? "text.secondary" : "transparent",
                                                            borderTopRightRadius: "100px",
                                                            borderTopLeftRadius: "100px"
                                                          }
                                                        })
                                                  ]
                                                }))
                                      })),
                              sx: {
                                "&:hover": {
                                  backgroundColor: "background.level2",
                                  cursor: "pointer"
                                }
                              },
                              xs: 6
                            })
                      ],
                      container: true,
                      sx: {
                        backgroundColor: "background.level1"
                      }
                    }),
                JsxRuntime.jsx(Divider, {}),
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                direction: "column",
                                spacing: 2,
                                sx: {
                                  px: 2,
                                  py: 3
                                },
                                children: Caml_option.some(tab === "deposit" ? JsxRuntime.jsx(BDDepositScheduleDetails$Thick.make, {
                                            isActive: isActive,
                                            snapshot: snapshot,
                                            canManage: canManage
                                          }) : JsxRuntime.jsx(BDInvoiceDetails$Thick.make, {
                                            isActive: isActive,
                                            snapshot: snapshot,
                                            canManage: canManage,
                                            billPayRec: props.billPayRec
                                          }))
                              }))
                    })
              ]
            });
}

var make = BDBillPayDrawer;

exports.make = make;
/* react Not a pure module */
