// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ChangeCabinCapacitySubmit_ChangeCabinCapacityMutation_graphql$Thick = require("../../../__generated__/ChangeCabinCapacitySubmit_ChangeCabinCapacityMutation_graphql.bs.js");

var convertVariables = ChangeCabinCapacitySubmit_ChangeCabinCapacityMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = ChangeCabinCapacitySubmit_ChangeCabinCapacityMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = ChangeCabinCapacitySubmit_ChangeCabinCapacityMutation_graphql$Thick.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables, ChangeCabinCapacitySubmit_ChangeCabinCapacityMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables, ChangeCabinCapacitySubmit_ChangeCabinCapacityMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param);
}

var ChangeCabinCapacityMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(onBrandFamily, onVoyage, forCabinNumbers, refetch, setSelectedCabins, onClose, setError) {
  var match = use(undefined);
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var match$2 = React.useState(function () {
        return false;
      });
  var match$3 = React.useState(function () {
        return null;
      });
  var capacityMin = match$3[0];
  var match$4 = React.useState(function () {
        return null;
      });
  var capacityMax = match$4[0];
  var onSubmit = function (e) {
    e.preventDefault();
    Curry._1(setError, (function (param) {
            
          }));
    var inputPayload = (capacityMax == null) ? (
        (capacityMin == null) ? ({
              onBrandFamily: onBrandFamily,
              onVoyage: onVoyage,
              forCabinNumbers: forCabinNumbers
            }) : ({
              onBrandFamily: onBrandFamily,
              onVoyage: onVoyage,
              forCabinNumbers: forCabinNumbers,
              maybeNewSalesMin: Belt_Option.getExn(Belt_Int.fromString(capacityMin))
            })
      ) : (
        (capacityMin == null) ? ({
              onBrandFamily: onBrandFamily,
              onVoyage: onVoyage,
              forCabinNumbers: forCabinNumbers,
              maybeNewSalesMax: Belt_Option.getExn(Belt_Int.fromString(capacityMax))
            }) : ({
              onBrandFamily: onBrandFamily,
              onVoyage: onVoyage,
              forCabinNumbers: forCabinNumbers,
              maybeNewSalesMin: Belt_Option.getExn(Belt_Int.fromString(capacityMin)),
              maybeNewSalesMax: Belt_Option.getExn(Belt_Int.fromString(capacityMax))
            })
      );
    Curry._8(mutate, {
          input: inputPayload
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err === undefined) {
              var match = resp.changeCabinCapacity;
              if (match !== undefined) {
                var match$1 = match.boolean;
                if (match$1 !== undefined && match$1) {
                  console.log("Successfully changed cabin capacity");
                  Curry._1(refetch, undefined);
                  Curry._1(onClose, undefined);
                  Curry._1(setSelectedCabins, (function (param) {
                          return Belt_Set.make(Common$Thick.CabinCmp);
                        }));
                  Curry._1(setError, (function (param) {
                          
                        }));
                  return ;
                }
                
              }
              console.log("Failed to change cabin capacity");
              Curry._1(setError, (function (param) {
                      return "Failed to change cabin capacity";
                    }));
              return ;
            }
            if (err !== undefined) {
              var error = Belt_Array.reduce(err, "", (function (carry, obj) {
                      if (carry === "") {
                        return obj.message;
                      } else {
                        return carry + ", " + obj.message;
                      }
                    }));
              console.log(error);
              Curry._1(setError, (function (param) {
                      return "Error changing cabin capacity";
                    }));
              return ;
            }
            console.log("Unknown error changing cabin capacity");
          }), (function (err) {
            console.log(err);
            Curry._1(setError, (function (param) {
                    return "Error changing cabin capacity";
                  }));
          }), undefined, undefined);
  };
  return {
          isMutating: match[1],
          areChangingMin: match$1[0],
          setAreChangingMin: match$1[1],
          areChangingMax: match$2[0],
          setAreChangingMax: match$2[1],
          capacityMin: capacityMin,
          setCapacityMin: match$3[1],
          capacityMax: capacityMax,
          setCapacityMax: match$4[1],
          onSubmit: onSubmit
        };
}

function ChangeCabinCapacitySubmit(props) {
  var size = props.size;
  var color = props.color;
  var variant = props.variant;
  var onError = props.onError;
  var onCompleted = props.onCompleted;
  var maybeNewSalesMax = props.maybeNewSalesMax;
  var maybeNewSalesMin = props.maybeNewSalesMin;
  var forCabinNumbers = props.forCabinNumbers;
  var onVoyage = props.onVoyage;
  var onBrandFamily = props.onBrandFamily;
  var variant$1 = variant !== undefined ? variant : "solid";
  var color$1 = color !== undefined ? color : "primary";
  var size$1 = size !== undefined ? size : "md";
  var match = use(undefined);
  var mutate = match[0];
  var inputPayload = (maybeNewSalesMax == null) ? (
      (maybeNewSalesMin == null) ? ({
            onBrandFamily: onBrandFamily,
            onVoyage: onVoyage,
            forCabinNumbers: forCabinNumbers
          }) : ({
            onBrandFamily: onBrandFamily,
            onVoyage: onVoyage,
            forCabinNumbers: forCabinNumbers,
            maybeNewSalesMin: Belt_Option.getExn(Belt_Int.fromString(maybeNewSalesMin))
          })
    ) : (
      (maybeNewSalesMin == null) ? ({
            onBrandFamily: onBrandFamily,
            onVoyage: onVoyage,
            forCabinNumbers: forCabinNumbers,
            maybeNewSalesMax: Belt_Option.getExn(Belt_Int.fromString(maybeNewSalesMax))
          }) : ({
            onBrandFamily: onBrandFamily,
            onVoyage: onVoyage,
            forCabinNumbers: forCabinNumbers,
            maybeNewSalesMin: Belt_Option.getExn(Belt_Int.fromString(maybeNewSalesMin)),
            maybeNewSalesMax: Belt_Option.getExn(Belt_Int.fromString(maybeNewSalesMax))
          })
    );
  var onClick = function (e) {
    e.preventDefault();
    return Curry._8(mutate, {
                input: inputPayload
              }, undefined, undefined, undefined, onCompleted, onError, undefined, undefined);
  };
  return JsxRuntime.jsx(Button, {
              loading: match[1],
              type: "submit",
              onClick: onClick,
              variant: variant$1,
              color: color$1,
              size: size$1,
              disabled: props.disabled,
              children: Caml_option.some(props.buttonText)
            });
}

var make = ChangeCabinCapacitySubmit;

exports.ChangeCabinCapacityMutation = ChangeCabinCapacityMutation;
exports.useMutation = useMutation;
exports.make = make;
/* react Not a pure module */
