// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CustomScalars$Thick = require("../../../utils/CustomScalars.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var InviteUserSubmit_InviteToBrandFamilyMutation_graphql$Thick = require("../../../__generated__/InviteUserSubmit_InviteToBrandFamilyMutation_graphql.bs.js");

var convertVariables = InviteUserSubmit_InviteToBrandFamilyMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = InviteUserSubmit_InviteToBrandFamilyMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = InviteUserSubmit_InviteToBrandFamilyMutation_graphql$Thick.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables, InviteUserSubmit_InviteToBrandFamilyMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables, InviteUserSubmit_InviteToBrandFamilyMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param);
}

var InviteToBrandFamilyMutation_brandfamRole_decode = InviteUserSubmit_InviteToBrandFamilyMutation_graphql$Thick.Utils.brandfamRole_decode;

var InviteToBrandFamilyMutation_brandfamRole_fromString = InviteUserSubmit_InviteToBrandFamilyMutation_graphql$Thick.Utils.brandfamRole_fromString;

var InviteToBrandFamilyMutation = {
  brandfamRole_decode: InviteToBrandFamilyMutation_brandfamRole_decode,
  brandfamRole_fromString: InviteToBrandFamilyMutation_brandfamRole_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(brandFamily, refreshTeam, initialRoles, initialVoyages, onClose) {
  var match = use(undefined);
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setUserEmail = match$1[1];
  var userEmail = match$1[0];
  var match$2 = React.useState(function () {
        return initialRoles;
      });
  var setUserRoles = match$2[1];
  var userRoles = match$2[0];
  var match$3 = React.useState(function () {
        return initialVoyages;
      });
  var setUserVoyages = match$3[1];
  var userVoyages = match$3[0];
  var match$4 = React.useState(function () {
        
      });
  var setError = match$4[1];
  var onSubmit = function (e) {
    e.preventDefault();
    Curry._1(setError, (function (param) {
            
          }));
    var roleRec = Belt_Option.getWithDefault(Belt_Array.get(userRoles, 0), {
          brandfamRole: "INVENTORY_MANAGER",
          description: ""
        });
    var inviteeRole = roleRec.brandfamRole;
    var additionalInviteeRoles = Belt_Array.map(userRoles, (function (role) {
            return role.brandfamRole;
          }));
    var toVoyages = Belt_Array.map(userVoyages, (function (voyage) {
            return Caml_option.some(voyage.voyageSlug);
          }));
    Curry._8(mutate, {
          input: {
            toBrandFamilySlug: brandFamily,
            toVoyages: toVoyages,
            inviteeEmail: CustomScalars$Thick.Email.parse(userEmail),
            inviteeRole: inviteeRole,
            additionalInviteeRoles: additionalInviteeRoles
          }
        }, undefined, undefined, undefined, (function (param, err) {
            if (err === undefined) {
              console.log("Successfully invited user");
              Curry._1(refreshTeam, undefined);
              Curry._1(onClose, undefined);
              Curry._1(setUserEmail, (function (param) {
                      return "";
                    }));
              Curry._1(setUserRoles, (function (param) {
                      return initialRoles;
                    }));
              return Curry._1(setUserVoyages, (function (param) {
                            return initialVoyages;
                          }));
            } else {
              console.log("Failed to invite user");
              Curry._1(setError, (function (param) {
                      return "Failed to invite user";
                    }));
              return ;
            }
          }), (function (param) {
            console.log("Error inviting user");
            Curry._1(setError, (function (param) {
                    return "Error inviting user";
                  }));
          }), undefined, undefined);
  };
  return {
          isMutating: match[1],
          userEmail: userEmail,
          setUserEmail: setUserEmail,
          userRoles: userRoles,
          setUserRoles: setUserRoles,
          userVoyages: userVoyages,
          setUserVoyages: setUserVoyages,
          error: match$4[0],
          setError: setError,
          onSubmit: onSubmit
        };
}

exports.InviteToBrandFamilyMutation = InviteToBrandFamilyMutation;
exports.useMutation = useMutation;
/* react Not a pure module */
