// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var DateFns = require("date-fns");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var CustomScalars$Thick = require("../../../utils/CustomScalars.bs.js");

function parse(v) {
  switch (v.TAG | 0) {
    case /* Diff */0 :
        var diff = v._0;
        var lines = diff.quoteLines;
        var billingLines = lines !== undefined ? Belt_Array.map(lines, (function (node) {
                  var tmp;
                  if (node !== undefined) {
                    var description = node.billingLineDescription;
                    tmp = description !== undefined ? CustomScalars$Thick.NonemptyString.serialize(Caml_option.valFromOption(description)) : "N/A";
                  } else {
                    tmp = "N/A";
                  }
                  var tmp$1;
                  if (node !== undefined) {
                    var amount = node.lineAmount;
                    tmp$1 = amount !== undefined ? amount : 0.0;
                  } else {
                    tmp$1 = 0.0;
                  }
                  return {
                          billingLineDescription: tmp,
                          lineAmount: tmp$1
                        };
                })) : [];
        var payments = diff.quotePayments;
        var paymentLines = payments !== undefined ? Belt_Array.map(Belt_Array.keepMap(payments, (function (n) {
                      if (n !== undefined) {
                        return n;
                      }
                      
                    })), (function (node) {
                  var url = node.processorPmtImageUrl;
                  return {
                          date: DateFns.format(DateFns.parseISO(node.createdAt), "MM/dd/yyyy"),
                          payer: CustomScalars$Thick.AccountId.serialize(node.payer),
                          lifecycleStatus: Common$Thick.PmtLifecycle.toString(node.lifecycleStatus),
                          shouldRenderStatus: Common$Thick.PmtLifecycle.shouldRenderStatus(node.lifecycleStatus),
                          paymentMethod: CustomScalars$Thick.NonemptyString.serialize(node.processorDescription),
                          paymentMethodImage: url !== undefined ? CustomScalars$Thick.NonemptyString.serialize(Caml_option.valFromOption(url)) : undefined,
                          amount: node.netAmount
                        };
                })) : [];
        var match = diff.quoteTotals;
        var tmp;
        if (match !== undefined) {
          var amount = match.quoteLineSubtotal;
          tmp = amount !== undefined ? amount : 0.0;
        } else {
          tmp = 0.0;
        }
        var match$1 = diff.quoteTotals;
        var tmp$1;
        if (match$1 !== undefined) {
          var amount$1 = match$1.paymentsSubtotal;
          tmp$1 = amount$1 !== undefined ? amount$1 : 0.0;
        } else {
          tmp$1 = 0.0;
        }
        var match$2 = diff.quoteTotals;
        var tmp$2;
        if (match$2 !== undefined) {
          var amount$2 = match$2.paymentsPendingSubtotal;
          tmp$2 = amount$2 !== undefined ? amount$2 : 0.0;
        } else {
          tmp$2 = 0.0;
        }
        var match$3 = diff.quoteTotals;
        var tmp$3;
        if (match$3 !== undefined) {
          var amount$3 = match$3.balanceWithPendingAmount;
          tmp$3 = amount$3 !== undefined ? amount$3 : 0.0;
        } else {
          tmp$3 = 0.0;
        }
        var match$4 = diff.quoteTotals;
        var tmp$4;
        if (match$4 !== undefined) {
          var pendingTotal = match$4.paymentsPendingSubtotal;
          tmp$4 = pendingTotal !== undefined ? pendingTotal > 0.0 : false;
        } else {
          tmp$4 = false;
        }
        var match$5 = diff.quoteTotals;
        var tmp$5;
        if (match$5 !== undefined) {
          var amount$4 = match$5.balanceAmount;
          tmp$5 = amount$4 !== undefined ? amount$4 : 0.0;
        } else {
          tmp$5 = 0.0;
        }
        return {
                billing: {
                  billingLines: billingLines,
                  billingLineSubtotal: tmp
                },
                payment: {
                  completedPaymentSubtotal: tmp$1,
                  pendingPaymentSubtotal: tmp$2,
                  paymentLines: paymentLines
                },
                totalAmountIncludingPending: tmp$3,
                arePendingPayments: tmp$4,
                totalAmount: tmp$5
              };
    case /* Current */1 :
        var data = v._0;
        var match$6 = data.billingSummary;
        var completedPaymentSubtotal = match$6 !== undefined ? match$6.completedPaymentSubtotal : 0.0;
        var match$7 = data.billingSummary;
        var pendingPaymentSubtotal = match$7 !== undefined ? match$7.pendingPaymentSubtotal : 0.0;
        var match$8 = data.billingSummary;
        var totalAmountIncludingPending = match$8 !== undefined ? match$8.totalAmountIncludingPending : 0.0;
        var match$9 = data.billingSummary;
        var billingLineSubtotal = match$9 !== undefined ? match$9.billingLineSubtotal : 0.0;
        var match$10 = data.billingSummary;
        var totalAmount = match$10 !== undefined ? match$10.totalAmount : 0.0;
        var billingLines$1 = Belt_Array.map(data.billingLine.nodes, (function (node) {
                return {
                        billingLineDescription: CustomScalars$Thick.NonemptyString.serialize(node.billingLineDescription),
                        lineAmount: node.lineAmount
                      };
              }));
        var paymentLines$1 = Belt_Array.map(data.payment.nodes, (function (node) {
                var match = node.payer_user;
                var url = node.processorPmtImageUrl;
                return {
                        date: DateFns.format(DateFns.parseISO(node.createdAt), "MM/dd/yyyy"),
                        payer: match !== undefined ? match.fullName : CustomScalars$Thick.AccountId.serialize(node.payer),
                        lifecycleStatus: Common$Thick.PmtLifecycle.toString(node.lifecycleStatus),
                        shouldRenderStatus: Common$Thick.PmtLifecycle.shouldRenderStatus(node.lifecycleStatus),
                        paymentMethod: CustomScalars$Thick.NonemptyString.serialize(node.processorDescription),
                        paymentMethodImage: url !== undefined ? CustomScalars$Thick.NonemptyString.serialize(Caml_option.valFromOption(url)) : undefined,
                        amount: node.netAmount
                      };
              }));
        return {
                billing: {
                  billingLines: billingLines$1,
                  billingLineSubtotal: billingLineSubtotal
                },
                payment: {
                  completedPaymentSubtotal: completedPaymentSubtotal,
                  pendingPaymentSubtotal: pendingPaymentSubtotal,
                  paymentLines: paymentLines$1
                },
                totalAmountIncludingPending: totalAmountIncludingPending,
                arePendingPayments: pendingPaymentSubtotal > 0.0,
                totalAmount: totalAmount
              };
    case /* Proposed */2 :
        var diff$1 = v._0;
        var lines$1 = diff$1.quoteLines;
        var billingLines$2 = lines$1 !== undefined ? Belt_Array.map(lines$1, (function (node) {
                  var tmp;
                  if (node !== undefined) {
                    var description = node.billingLineDescription;
                    tmp = description !== undefined ? CustomScalars$Thick.NonemptyString.serialize(Caml_option.valFromOption(description)) : "N/A";
                  } else {
                    tmp = "N/A";
                  }
                  var tmp$1;
                  if (node !== undefined) {
                    var amount = node.lineAmount;
                    tmp$1 = amount !== undefined ? amount : 0.0;
                  } else {
                    tmp$1 = 0.0;
                  }
                  return {
                          billingLineDescription: tmp,
                          lineAmount: tmp$1
                        };
                })) : [];
        var payments$1 = diff$1.quotePayments;
        var paymentLines$2 = payments$1 !== undefined ? Belt_Array.map(Belt_Array.keepMap(payments$1, (function (n) {
                      if (n !== undefined) {
                        return n;
                      }
                      
                    })), (function (node) {
                  var url = node.processorPmtImageUrl;
                  var amount = node.grossAmount;
                  return {
                          date: DateFns.format(DateFns.parseISO(node.createdAt), "MM/dd/yyyy"),
                          payer: CustomScalars$Thick.AccountId.serialize(node.payer),
                          lifecycleStatus: Common$Thick.PmtLifecycle.toString(node.lifecycleStatus),
                          shouldRenderStatus: Common$Thick.PmtLifecycle.shouldRenderStatus(node.lifecycleStatus),
                          paymentMethod: CustomScalars$Thick.NonemptyString.serialize(node.processorDescription),
                          paymentMethodImage: url !== undefined ? CustomScalars$Thick.NonemptyString.serialize(Caml_option.valFromOption(url)) : undefined,
                          amount: amount !== undefined ? amount : 0.0
                        };
                })) : [];
        var match$11 = diff$1.quoteTotals;
        var tmp$6;
        if (match$11 !== undefined) {
          var amount$5 = match$11.quoteLineSubtotal;
          tmp$6 = amount$5 !== undefined ? amount$5 : 0.0;
        } else {
          tmp$6 = 0.0;
        }
        var match$12 = diff$1.quoteTotals;
        var tmp$7;
        if (match$12 !== undefined) {
          var amount$6 = match$12.paymentsSubtotal;
          tmp$7 = amount$6 !== undefined ? amount$6 : 0.0;
        } else {
          tmp$7 = 0.0;
        }
        var match$13 = diff$1.quoteTotals;
        var tmp$8;
        if (match$13 !== undefined) {
          var amount$7 = match$13.paymentsPendingSubtotal;
          tmp$8 = amount$7 !== undefined ? amount$7 : 0.0;
        } else {
          tmp$8 = 0.0;
        }
        var match$14 = diff$1.quoteTotals;
        var tmp$9;
        if (match$14 !== undefined) {
          var amount$8 = match$14.balanceWithPendingAmount;
          tmp$9 = amount$8 !== undefined ? amount$8 : 0.0;
        } else {
          tmp$9 = 0.0;
        }
        var match$15 = diff$1.quoteTotals;
        var tmp$10;
        if (match$15 !== undefined) {
          var pendingTotal$1 = match$15.paymentsPendingSubtotal;
          tmp$10 = pendingTotal$1 !== undefined ? pendingTotal$1 > 0.0 : false;
        } else {
          tmp$10 = false;
        }
        var match$16 = diff$1.quoteTotals;
        var tmp$11;
        if (match$16 !== undefined) {
          var amount$9 = match$16.balanceAmount;
          tmp$11 = amount$9 !== undefined ? amount$9 : 0.0;
        } else {
          tmp$11 = 0.0;
        }
        return {
                billing: {
                  billingLines: billingLines$2,
                  billingLineSubtotal: tmp$6
                },
                payment: {
                  completedPaymentSubtotal: tmp$7,
                  pendingPaymentSubtotal: tmp$8,
                  paymentLines: paymentLines$2
                },
                totalAmountIncludingPending: tmp$9,
                arePendingPayments: tmp$10,
                totalAmount: tmp$11
              };
    
  }
}

exports.parse = parse;
/* date-fns Not a pure module */
