// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Float = require("rescript/lib/js/belt_Float.js");
var Link$Thick = require("../components/common/atoms/Link.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../utils/Common.bs.js");
var Link = require("@mui/joy/Link").default;
var Input = require("@mui/joy/Input").default;
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var PasswordInput$Thick = require("../components/common/molecules/PasswordInput.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var SendResetToken$Thick = require("../components/forgot-password/mutations/SendResetToken.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var FormHelperText = require("@mui/joy/FormHelperText").default;
var ResetPasswordSubmit$Thick = require("../components/forgot-password/mutations/ResetPasswordSubmit.bs.js");
var ForgotPasswordPage_query_graphql$Thick = require("../__generated__/ForgotPasswordPage_query_graphql.bs.js");

var convertFragment = ForgotPasswordPage_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(ForgotPasswordPage_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, ForgotPasswordPage_query_graphql$Thick.node, convertFragment);
}

var QueryFragment_signonStatus_decode = ForgotPasswordPage_query_graphql$Thick.Utils.signonStatus_decode;

var QueryFragment_signonStatus_fromString = ForgotPasswordPage_query_graphql$Thick.Utils.signonStatus_fromString;

var QueryFragment = {
  signonStatus_decode: QueryFragment_signonStatus_decode,
  signonStatus_fromString: QueryFragment_signonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function ForgotPasswordPage(props) {
  var data = RescriptRelay_Fragment.useFragment(ForgotPasswordPage_query_graphql$Thick.node, convertFragment, props.fragmentRefs);
  var brand = Common$Thick.LS.getSelectedBrand(undefined);
  var redirect = brand !== undefined ? "/" + brand + "" : "/dashboard";
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var searchParams = new URLSearchParams(url.search);
  var token = searchParams.get("token");
  var expiry = searchParams.get("expiry");
  var expired;
  if (expiry == null) {
    expired = true;
  } else {
    var expiry$1 = Belt_Float.fromString(expiry);
    if (expiry$1 !== undefined) {
      var now = Date.now();
      var expiry$2 = expiry$1 * 1000.0;
      expired = now >= expiry$2;
    } else {
      expired = true;
    }
  }
  var match = SendResetToken$Thick.useMutation(undefined);
  var requestError = match.error;
  var setEmail = match.setEmail;
  var email = match.email;
  var match$1 = ResetPasswordSubmit$Thick.useMutation(undefined);
  var resetPasswordSubmit = match$1.onSubmit;
  var passUpdateSuccess = match$1.passUpdateSuccess;
  var resetError = match$1.error;
  var setConfirmPasswd = match$1.setConfirmPasswd;
  var confirmPasswd = match$1.confirmPasswd;
  var setNewPasswd = match$1.setNewPasswd;
  var newPasswd = match$1.newPasswd;
  React.useEffect((function () {
          var match = data.currentUserSignonStatus;
          if (match === "SIGNED_ON") {
            RescriptReactRouter.push(redirect);
          }
          
        }), [data.currentUserSignonStatus]);
  var tmp;
  var exit = 0;
  var exit$1 = 0;
  if ((token == null) || !(!expired && !passUpdateSuccess)) {
    exit$1 = 2;
  } else {
    tmp = JsxRuntime.jsxs(Stack, {
          component: "form",
          spacing: 2,
          onSubmit: (function (e) {
              Curry._2(resetPasswordSubmit, e, token);
            }),
          children: [
            JsxRuntime.jsx(Typography, {
                  level: "body-sm",
                  component: "p",
                  children: "Enter your new password."
                }),
            JsxRuntime.jsx(FormControl, {
                  children: Caml_option.some(JsxRuntime.jsx(PasswordInput$Thick.make, {
                            placeholder: "New password",
                            value: newPasswd,
                            onChange: (function (e) {
                                var val = e.currentTarget.value;
                                Curry._1(setNewPasswd, (function (param) {
                                        return val;
                                      }));
                              })
                          }))
                }),
            JsxRuntime.jsxs(FormControl, {
                  error: confirmPasswd !== newPasswd,
                  children: [
                    JsxRuntime.jsx(PasswordInput$Thick.make, {
                          placeholder: "Confirm password",
                          value: confirmPasswd,
                          onChange: (function (e) {
                              var val = e.currentTarget.value;
                              Curry._1(setConfirmPasswd, (function (param) {
                                      return val;
                                    }));
                            })
                        }),
                    JsxRuntime.jsx(FormHelperText, {
                          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                    color: "danger",
                                    level: "body-xs",
                                    component: "p",
                                    children: Caml_option.some(confirmPasswd !== newPasswd ? "Passwords do not match" : "")
                                  }))
                        })
                  ]
                }),
            JsxRuntime.jsx(Button, {
                  loading: match$1.isMutating,
                  type: "submit",
                  disabled: newPasswd === "" || newPasswd !== confirmPasswd,
                  children: "Reset Password"
                })
          ]
        });
  }
  if (exit$1 === 2) {
    if (passUpdateSuccess) {
      tmp = JsxRuntime.jsx(JsxRuntime.Fragment, {
            children: Caml_option.some(JsxRuntime.jsxs(Typography, {
                      level: "body-md",
                      component: "p",
                      children: [
                        "Success! Please ",
                        JsxRuntime.jsx(Link$Thick.make, {
                              to: "/login",
                              children: "log in"
                            }),
                        " with your new password."
                      ]
                    }))
          });
    } else if ((token == null) || !expired) {
      exit = 1;
    } else {
      tmp = JsxRuntime.jsx(Typography, {
            level: "body-md",
            component: "p",
            children: "This link has expired. Please try again."
          });
    }
  }
  if (exit === 1) {
    tmp = match.tokenSent ? JsxRuntime.jsxs(Stack, {
            component: "form",
            spacing: 1,
            children: [
              JsxRuntime.jsx(Typography, {
                    level: "body-md",
                    component: "p",
                    children: "Please check your email for a reset link."
                  }),
              JsxRuntime.jsxs(Typography, {
                    level: "body-xs",
                    children: [
                      "If an email hasn't arrived in your inbox within a few minutes, you can ",
                      JsxRuntime.jsx(Link, {
                            children: "resend it",
                            color: "primary",
                            onClick: match.onClick
                          }),
                      "."
                    ]
                  })
            ]
          }) : JsxRuntime.jsxs(Stack, {
            component: "form",
            spacing: 1,
            onSubmit: match.onSubmit,
            children: [
              JsxRuntime.jsx(Typography, {
                    level: "body-sm",
                    component: "p",
                    children: "Please enter the email address associated with your account."
                  }),
              JsxRuntime.jsxs(FormControl, {
                    error: email === "" || !Common$Thick.Email.isValid(email),
                    children: [
                      JsxRuntime.jsx(FormLabel, {
                            children: "Email"
                          }),
                      JsxRuntime.jsx(Input, {
                            required: true,
                            type: "email",
                            value: email,
                            onChange: (function (e) {
                                var value = e.currentTarget.value;
                                Curry._1(setEmail, (function (param) {
                                        return value;
                                      }));
                              })
                          }),
                      JsxRuntime.jsx(FormHelperText, {
                            children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                      color: "danger",
                                      level: "body-xs",
                                      component: "p",
                                      children: Caml_option.some(email === "" || !Common$Thick.Email.isValid(email) ? "Please enter a valid email address" : " ")
                                    }))
                          })
                    ]
                  }),
              JsxRuntime.jsx(Button, {
                    loading: match.isMutating,
                    type: "submit",
                    disabled: email === "" || !Common$Thick.Email.isValid(email),
                    children: "Send Reset Link"
                  })
            ]
          });
  }
  return JsxRuntime.jsxs(Sheet, {
              variant: "outlined",
              sx: {
                width: 300,
                mx: "auto",
                my: 4,
                py: 3,
                px: 2,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                borderRadius: "sm",
                boxShadow: "md",
                backgroundColor: "background.level1"
              },
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "h4",
                      component: "h1",
                      children: "Reset your password"
                    }),
                JsxRuntime.jsx(Stack, {
                      spacing: 2,
                      children: Caml_option.some(tmp)
                    }),
                requestError !== undefined ? JsxRuntime.jsx(Typography, {
                        color: "danger",
                        level: "body-sm",
                        children: Caml_option.some(requestError)
                      }) : JsxRuntime.jsx(JsxRuntime.Fragment, {}),
                resetError !== undefined ? JsxRuntime.jsx(Typography, {
                        color: "danger",
                        level: "body-sm",
                        children: Caml_option.some(resetError)
                      }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
              ]
            });
}

var make = ForgotPasswordPage;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
