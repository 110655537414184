// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../utils/Common.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Table = require("@mui/joy/Table").default;
var Tooltip = require("@mui/joy/Tooltip").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var CustomScalars$Thick = require("../../utils/CustomScalars.bs.js");
var CustTableToolbar$Thick = require("./CustTableToolbar.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CreateNewAccountForm$Thick = require("./CreateNewAccountForm.bs.js");
var CreateLinkedAccountForm$Thick = require("./CreateLinkedAccountForm.bs.js");
var CustomerTableQuery_graphql$Thick = require("../../__generated__/CustomerTableQuery_graphql.bs.js");
var CustomerTable_user_graphql$Thick = require("../../__generated__/CustomerTable_user_graphql.bs.js");
var CustomerTable_query_graphql$Thick = require("../../__generated__/CustomerTable_query_graphql.bs.js");
var SupervisorAccountOutlined = require("@mui/icons-material/SupervisorAccountOutlined").default;

var convertFragment = CustomerTable_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(CustomerTable_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, CustomerTable_query_graphql$Thick.node, convertFragment);
}

var makeRefetchVariables = CustomerTableQuery_graphql$Thick.Types.makeRefetchVariables;

var convertRefetchVariables = CustomerTableQuery_graphql$Thick.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(CustomerTable_query_graphql$Thick.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

var convertFragment$1 = CustomerTable_user_graphql$Thick.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(CustomerTable_user_graphql$Thick.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, CustomerTable_user_graphql$Thick.node, convertFragment$1);
}

var UserFragment_accountStatus_decode = CustomerTable_user_graphql$Thick.Utils.accountStatus_decode;

var UserFragment_accountStatus_fromString = CustomerTable_user_graphql$Thick.Utils.accountStatus_fromString;

var UserFragment = {
  accountStatus_decode: UserFragment_accountStatus_decode,
  accountStatus_fromString: UserFragment_accountStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1
};

function CustomerTable$TableRow(props) {
  var user = RescriptRelay_Fragment.useFragment(CustomerTable_user_graphql$Thick.node, convertFragment$1, props.fragmentRefs);
  var match = React.useState(function () {
        return false;
      });
  var setDialogOpen = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setCopied = match$1[1];
  var email = user.email;
  var tmp;
  if (email !== undefined) {
    var email$1 = Caml_option.valFromOption(email);
    tmp = JsxRuntime.jsx(Tooltip, {
          children: JsxRuntime.jsx(Typography, {
                level: "body-sm",
                noWrap: true,
                children: Caml_option.some(CustomScalars$Thick.Email.serialize(email$1))
              }),
          arrow: true,
          size: "sm",
          sx: {
            cursor: "pointer"
          },
          title: match$1[0] ? "Copied to clipboard!" : CustomScalars$Thick.Email.serialize(email$1),
          onClick: (function (param) {
              return Common$Thick.copyToClipboard(CustomScalars$Thick.Email.serialize(email$1), setCopied);
            })
        });
  } else {
    tmp = "(None)";
  }
  var custodianAccountId = user.custodianAccountId;
  var match$2 = user.accountStatus;
  return JsxRuntime.jsxs("tr", {
              children: [
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "body-sm",
                            noWrap: true,
                            children: Caml_option.some(tmp)
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "body-sm",
                            noWrap: true,
                            children: Caml_option.some(user.fullName)
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "body-sm",
                            noWrap: true,
                            children: Caml_option.some(Common$Thick.AccountStatus.toString(user.accountStatus))
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "body-sm",
                            noWrap: true,
                            children: Caml_option.some(CustomScalars$Thick.AccountId.serialize(user.accountId))
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "body-sm",
                            noWrap: true,
                            children: Caml_option.some(custodianAccountId !== undefined ? CustomScalars$Thick.AccountId.serialize(Caml_option.valFromOption(custodianAccountId)) : "(None)")
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "body-sm",
                            noWrap: true,
                            children: Caml_option.some(match$2 === "UNCLAIMED" ? null : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                        children: [
                                          JsxRuntime.jsx(Stack, {
                                                direction: "row",
                                                justifyContent: "flex-end",
                                                sx: {
                                                  width: "100%"
                                                },
                                                children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                                          children: JsxRuntime.jsx(IconButton, {
                                                                onClick: (function (param) {
                                                                    Curry._1(setDialogOpen, (function (param) {
                                                                            return true;
                                                                          }));
                                                                  }),
                                                                children: Caml_option.some(JsxRuntime.jsx(SupervisorAccountOutlined, {}))
                                                              }),
                                                          arrow: true,
                                                          size: "sm",
                                                          title: "Create Linked Account"
                                                        }))
                                              }),
                                          JsxRuntime.jsx(CreateLinkedAccountForm$Thick.make, {
                                                open_: match[0],
                                                onClose: (function (param) {
                                                    Curry._1(setDialogOpen, (function (param) {
                                                            return false;
                                                          }));
                                                  }),
                                                brandFamily: props.brandFamily,
                                                refresh: props.refresh,
                                                custodian: user.fullName,
                                                custodianId: user.accountId
                                              })
                                        ]
                                      }))
                          })
                    })
              ]
            });
}

var TableRow = {
  make: CustomerTable$TableRow
};

function CustomerTable(props) {
  var brandFamily = props.brandFamily;
  var match = useRefetchable(props.fragmentRefs);
  var refetch = match[1];
  var data = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setDialogOpen = match$1[1];
  var match$2 = React.useState(function () {
        return "all";
      });
  var setAccountSelect = match$2[1];
  var accountSelect = match$2[0];
  var primaryFilter_brandFamilySlug = {
    equalTo: CustomScalars$Thick.BrandFamilySlug.serialize(brandFamily)
  };
  var primaryFilter_email = {
    notIn: []
  };
  var primaryFilter_accountStatus = {
    in: [
      "UNCLAIMED",
      "CLAIMED",
      "INVITED"
    ]
  };
  var primaryFilter = {
    orgSlug: undefined,
    brandFamilySlug: primaryFilter_brandFamilySlug,
    accountId: undefined,
    email: primaryFilter_email,
    signonStatus: undefined,
    firstName: undefined,
    lastName: undefined,
    fullName: undefined,
    accountStatus: primaryFilter_accountStatus,
    custodianAccountId: undefined,
    emailString: undefined,
    legalDocumentFirstName: undefined,
    legalDocumentLastName: undefined,
    legalDocumentGender: undefined,
    legalDocumentBirthdate: undefined,
    legalDocumentCitizenship: undefined,
    billingContactForBooking: undefined,
    billingContactForBookingExist: undefined,
    payment: undefined,
    paymentExist: undefined,
    bookingVersions: undefined,
    bookingVersionsExist: undefined,
    passenger_record: undefined,
    passenger_recordExist: undefined,
    doc_citizenshipCountry: undefined,
    doc_citizenshipCountryExists: undefined,
    and: undefined,
    or: undefined,
    not: undefined
  };
  var match$3 = React.useState(function () {
        return primaryFilter;
      });
  var setFilter = match$3[1];
  var filter = match$3[0];
  var match$4 = React.useState(function () {
        return 0;
      });
  var setOffset = match$4[1];
  var offset = match$4[0];
  var refetchPage = function (newOffset) {
    Curry._4(refetch, Curry._4(makeRefetchVariables, undefined, undefined, Caml_option.some(newOffset), undefined), /* StoreAndNetwork */2, undefined, undefined);
  };
  var match$5 = data.bfcustUsers;
  var totalCount = match$5 !== undefined ? match$5.totalCount : 0;
  var stringFromOffset = function (o, t) {
    var start = o + 1 | 0;
    var end = o + 50 | 0;
    var end$1 = end > t ? t : end;
    if (t !== 0) {
      return "" + String(start) + " - " + String(end$1) + " / " + String(t) + "";
    } else {
      return "0";
    }
  };
  var onLoadNext = function (param) {
    var newOffset = offset + 50 | 0;
    Curry._1(setOffset, (function (param) {
            return newOffset;
          }));
    refetchPage(newOffset);
  };
  var onLoadPrev = function (param) {
    var newOffset = offset + 50 | 0;
    Curry._1(setOffset, (function (param) {
            return newOffset;
          }));
    refetchPage(newOffset);
  };
  var search = function (t) {
    var newrecord = Caml_obj.obj_dup(filter);
    newrecord.or = [
      {
        fullName: {
          includesInsensitive: t
        }
      },
      {
        emailString: {
          includesInsensitive: CustomScalars$Thick.NonemptyString.parse(t)
        }
      }
    ];
    Curry._1(setFilter, (function (param) {
            return newrecord;
          }));
  };
  var clearSearch = function (param) {
    var newrecord = Caml_obj.obj_dup(filter);
    newrecord.or = undefined;
    Curry._1(setFilter, (function (param) {
            return newrecord;
          }));
  };
  var handleAccountSelect = function (s) {
    var arr;
    switch (s) {
      case "claimed" :
          arr = ["CLAIMED"];
          break;
      case "invited" :
          arr = ["INVITED"];
          break;
      case "unclaimed" :
          arr = ["UNCLAIMED"];
          break;
      default:
        arr = [
          "UNCLAIMED",
          "CLAIMED",
          "INVITED"
        ];
    }
    var newrecord = Caml_obj.obj_dup(filter);
    newrecord.accountStatus = {
      in: arr
    };
    Curry._1(setFilter, (function (param) {
            return newrecord;
          }));
  };
  React.useEffect((function () {
          Curry._4(refetch, Curry._4(makeRefetchVariables, undefined, Caml_option.some(filter), undefined, undefined), /* StoreAndNetwork */2, undefined, undefined);
        }), [filter]);
  var match$6 = data.bfcustUsers;
  var tmp;
  if (match$6 !== undefined) {
    var edges = match$6.edges;
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(CustTableToolbar$Thick.make, {
                  totalCount: totalCount,
                  offset: offset,
                  onLoadNext: onLoadNext,
                  onLoadPrev: onLoadPrev,
                  stringFromOffset: stringFromOffset,
                  searchBar: true,
                  search: search,
                  clearSearch: clearSearch,
                  handleAccountSelect: handleAccountSelect,
                  accountSelect: accountSelect,
                  setAccountSelect: setAccountSelect,
                  setDialogOpen: setDialogOpen
                }),
            edges.length !== 0 ? JsxRuntime.jsxs(Table, {
                    stripe: "odd",
                    hoverRow: true,
                    sx: {
                      captionSide: "top",
                      "& tbody": {
                        bgcolor: "background.surface"
                      }
                    },
                    children: [
                      JsxRuntime.jsx("thead", {
                            children: JsxRuntime.jsxs("tr", {
                                  children: [
                                    JsxRuntime.jsx("th", {
                                          children: "Email"
                                        }),
                                    JsxRuntime.jsx("th", {
                                          children: "Name"
                                        }),
                                    JsxRuntime.jsx("th", {
                                          children: "Account Status"
                                        }),
                                    JsxRuntime.jsx("th", {
                                          children: "Account Id"
                                        }),
                                    JsxRuntime.jsx("th", {
                                          children: "Managed by Account Id"
                                        }),
                                    JsxRuntime.jsx("th", {
                                          style: {
                                            width: "40px"
                                          }
                                        })
                                  ]
                                })
                          }),
                      JsxRuntime.jsx("tbody", {
                            children: Belt_Array.map(edges, (function (edge) {
                                    return JsxRuntime.jsx(CustomerTable$TableRow, {
                                                fragmentRefs: edge.node.fragmentRefs,
                                                refresh: clearSearch,
                                                brandFamily: brandFamily
                                              }, edge.__id);
                                  }))
                          })
                    ]
                  }) : JsxRuntime.jsx(Stack, {
                    justifyContent: "center",
                    alignItems: "center",
                    sx: {
                      height: "15rem",
                      width: "100%"
                    },
                    children: Caml_option.some(JsxRuntime.jsx(Typography, {
                              level: "title-lg",
                              sx: {
                                color: "neutral.500"
                              },
                              children: "No customers found"
                            }))
                  }),
            JsxRuntime.jsx(CustTableToolbar$Thick.make, {
                  totalCount: totalCount,
                  offset: offset,
                  onLoadNext: onLoadNext,
                  onLoadPrev: onLoadPrev,
                  stringFromOffset: stringFromOffset,
                  search: search,
                  clearSearch: clearSearch,
                  handleAccountSelect: handleAccountSelect,
                  accountSelect: accountSelect,
                  setAccountSelect: setAccountSelect,
                  setDialogOpen: setDialogOpen
                })
          ]
        });
  } else {
    tmp = null;
  }
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                        spacing: 1,
                        sx: {
                          width: "100%"
                        },
                        children: Caml_option.some(JsxRuntime.jsxs(Sheet, {
                                  variant: "outlined",
                                  sx: {
                                    pt: 1,
                                    borderRadius: "sm",
                                    width: "100%"
                                  },
                                  children: [
                                    tmp,
                                    JsxRuntime.jsx(CreateNewAccountForm$Thick.make, {
                                          open_: match$1[0],
                                          onClose: (function (param) {
                                              Curry._1(setDialogOpen, (function (param) {
                                                      return false;
                                                    }));
                                            }),
                                          brandFamily: brandFamily,
                                          refresh: clearSearch
                                        })
                                  ]
                                }))
                      }))
            });
}

var make = CustomerTable;

exports.QueryFragment = QueryFragment;
exports.UserFragment = UserFragment;
exports.TableRow = TableRow;
exports.make = make;
/* react Not a pure module */
