// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Chip = require("@mui/joy/Chip").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var CustomScalars$Thick = require("../../utils/CustomScalars.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var Autocomplete = require("@mui/joy/Autocomplete").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var FormHelperText = require("@mui/joy/FormHelperText").default;
var VoyageGrantsView_voyagegrant_graphql$Thick = require("../../__generated__/VoyageGrantsView_voyagegrant_graphql.bs.js");

var convertFragment = VoyageGrantsView_voyagegrant_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(VoyageGrantsView_voyagegrant_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, VoyageGrantsView_voyagegrant_graphql$Thick.node, convertFragment);
}

var VoyageGrantFrag = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function VoyageGrantsView$Chips(props) {
  var vgf = RescriptRelay_Fragment.useFragment(VoyageGrantsView_voyagegrant_graphql$Thick.node, convertFragment, props.fragment);
  return Belt_Array.map(Belt_Array.keep(vgf.nodes, (function (node) {
                    return node.hasVoyage;
                  })), (function (node) {
                return JsxRuntime.jsx(Chip, {
                            children: Caml_option.some(CustomScalars$Thick.VoyageSlug.serialize(node.voyageSlug)),
                            color: "primary",
                            size: "sm",
                            variant: "outlined"
                          }, CustomScalars$Thick.VoyageSlug.serialize(node.voyageSlug));
              }));
}

var Chips = {
  make: VoyageGrantsView$Chips
};

function VoyageGrantsView$Autocomplete(props) {
  var setSelectedVoyages = props.setSelectedVoyages;
  var vgf = RescriptRelay_Fragment.useFragment(VoyageGrantsView_voyagegrant_graphql$Thick.node, convertFragment, props.fragment);
  var voyageList = Belt_Array.map(vgf.nodes, (function (node) {
          return {
                  voyageSlug: node.voyageSlug
                };
        }));
  React.useEffect((function () {
          var currentVoyages = Belt_Array.keepMap(vgf.nodes, (function (node) {
                  var match = node.hasVoyage;
                  if (match) {
                    return {
                            voyageSlug: node.voyageSlug
                          };
                  }
                  
                }));
          Curry._1(setSelectedVoyages, (function (param) {
                  return currentVoyages;
                }));
        }), []);
  return JsxRuntime.jsxs(FormControl, {
              children: [
                JsxRuntime.jsx(FormLabel, {
                      children: "Voyages"
                    }),
                JsxRuntime.jsx(Autocomplete, {
                      options: voyageList,
                      disabled: props.disabled,
                      getOptionLabel: (function (node) {
                          return CustomScalars$Thick.VoyageSlug.serialize(node.voyageSlug);
                        }),
                      id: "voyages",
                      isOptionEqualToValue: (function (option, value) {
                          return CustomScalars$Thick.VoyageSlug.serialize(option.voyageSlug) === CustomScalars$Thick.VoyageSlug.serialize(value.voyageSlug);
                        }),
                      multiple: true,
                      onChange: (function (param, value) {
                          Curry._1(setSelectedVoyages, (function (param) {
                                  return value;
                                }));
                        }),
                      value: props.selectedVoyages
                    }),
                JsxRuntime.jsx(FormHelperText, {
                      children: "User voyage access"
                    })
              ]
            });
}

var Autocomplete$1 = {
  make: VoyageGrantsView$Autocomplete
};

exports.VoyageGrantFrag = VoyageGrantFrag;
exports.Chips = Chips;
exports.Autocomplete = Autocomplete$1;
/* react Not a pure module */
