// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../components/common/molecules/Dialog.bs.js");
var Input = require("@mui/joy/Input").default;
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");
var PasswordInput$Thick = require("../components/common/molecules/PasswordInput.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var FormHelperText = require("@mui/joy/FormHelperText").default;
var ChangeEmailSubmit$Thick = require("../components/account/mutations/ChangeEmailSubmit.bs.js");
var AccountAccessSection$Thick = require("../components/account/AccountAccessSection.bs.js");
var ChangePasswordSubmit$Thick = require("../components/account/mutations/ChangePasswordSubmit.bs.js");
var BrandFamilyAccessSection$Thick = require("../components/account/BrandFamilyAccessSection.bs.js");
var AccountPage_query_graphql$Thick = require("../__generated__/AccountPage_query_graphql.bs.js");

var convertFragment = AccountPage_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(AccountPage_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AccountPage_query_graphql$Thick.node, convertFragment);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function AccountPage(props) {
  var data = RescriptRelay_Fragment.useFragment(AccountPage_query_graphql$Thick.node, convertFragment, props.fragmentRefs);
  var match = React.useState(function () {
        return false;
      });
  var setModalOpen = match[1];
  var match$1 = React.useState(function () {
        return "";
      });
  var setModalType = match$1[1];
  var modalType = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setCurrentPass = match$2[1];
  var currentPass = match$2[0];
  var clearAll = function (param) {
    Curry._1(setCurrentPass, (function (param) {
            return "";
          }));
    Curry._1(setModalType, (function (param) {
            return "";
          }));
    Curry._1(setModalOpen, (function (param) {
            return false;
          }));
  };
  var emailString = Belt_Option.getExn(data.currentUserEmail);
  var email = CustomScalars$Thick.Email.parse(emailString);
  var match$3 = ChangeEmailSubmit$Thick.useMutation(setModalType);
  var changeEmailSubmit = match$3.onSubmit;
  var changeEmailError = match$3.error;
  var setEmailConfirm = match$3.setEmailConfirm;
  var emailConfirm = match$3.emailConfirm;
  var setNewEmail = match$3.setNewEmail;
  var newEmail = match$3.newEmail;
  var match$4 = ChangePasswordSubmit$Thick.useMutation(setModalType);
  var changePassSubmit = match$4.onSubmit;
  var changePassError = match$4.error;
  var setConfirmPass = match$4.setConfirmPass;
  var confirmPass = match$4.confirmPass;
  var setNewPass = match$4.setNewPass;
  var newPass = match$4.newPass;
  return JsxRuntime.jsx(Sheet, {
              sx: {
                backgroundColor: "background.surface",
                borderRadius: "sm",
                width: "100%",
                border: 1,
                borderColor: "neutral.200"
              },
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "column",
                        sx: {
                          py: 2,
                          px: 3
                        },
                        children: [
                          JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                sx: {
                                  mb: 2
                                },
                                children: [
                                  JsxRuntime.jsx(Typography, {
                                        level: "h3",
                                        component: "h1",
                                        children: "My account"
                                      }),
                                  JsxRuntime.jsx(Typography, {
                                        level: "body-sm",
                                        component: "p",
                                        textColor: "text.tertiary",
                                        children: "View and edit your Cruise Control account details below."
                                      })
                                ]
                              }),
                          JsxRuntime.jsx(Divider, {}),
                          JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                alignItems: "flex-start",
                                spacing: 1,
                                children: [
                                  JsxRuntime.jsx(AccountAccessSection$Thick.make, {
                                        currentUserEmail: emailString,
                                        openModal: (function (s) {
                                            Curry._1(setModalType, (function (param) {
                                                    return s;
                                                  }));
                                            Curry._1(setModalOpen, (function (param) {
                                                    return true;
                                                  }));
                                          })
                                      }),
                                  JsxRuntime.jsx(Divider, {}),
                                  JsxRuntime.jsx(BrandFamilyAccessSection$Thick.make, {
                                        fragmentRefs: data.fragmentRefs,
                                        currentUserEmail: email
                                      }),
                                  JsxRuntime.jsxs(Dialog$Thick.make, {
                                        open_: match[0],
                                        onClose: clearAll,
                                        title: "Change " + modalType + "",
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                textColor: "text.tertiary",
                                                children: Caml_option.some("You are updating the " + modalType + " associated with your account. Please type your " + (
                                                      modalType === "password" ? "current " : ""
                                                    ) + "password followed by your new " + modalType + ". Then, confirm your changes.")
                                              }),
                                          JsxRuntime.jsxs(Stack, {
                                                component: "form",
                                                spacing: 2,
                                                onSubmit: (function (e) {
                                                    e.preventDefault();
                                                    switch (modalType) {
                                                      case "email" :
                                                          return Curry._2(changeEmailSubmit, e, currentPass);
                                                      case "password" :
                                                          return Curry._2(changePassSubmit, e, currentPass);
                                                      default:
                                                        return ;
                                                    }
                                                  }),
                                                children: [
                                                  JsxRuntime.jsx(FormControl, {
                                                        children: Caml_option.some(JsxRuntime.jsx(PasswordInput$Thick.make, {
                                                                  placeholder: "Confirm your " + (
                                                                    modalType === "password" ? "old " : ""
                                                                  ) + "password",
                                                                  value: currentPass,
                                                                  onChange: (function (e) {
                                                                      var val = e.currentTarget.value;
                                                                      Curry._1(setCurrentPass, (function (param) {
                                                                              return val;
                                                                            }));
                                                                    })
                                                                }))
                                                      }),
                                                  modalType === "password" ? JsxRuntime.jsx(FormControl, {
                                                          children: Caml_option.some(JsxRuntime.jsx(PasswordInput$Thick.make, {
                                                                    placeholder: "Enter your new password",
                                                                    value: newPass,
                                                                    onChange: (function (e) {
                                                                        var val = e.currentTarget.value;
                                                                        Curry._1(setNewPass, (function (param) {
                                                                                return val;
                                                                              }));
                                                                      })
                                                                  }))
                                                        }) : JsxRuntime.jsx(FormControl, {
                                                          children: Caml_option.some(JsxRuntime.jsx(Input, {
                                                                    placeholder: "Enter your new email",
                                                                    value: newEmail,
                                                                    onChange: (function (e) {
                                                                        var val = e.currentTarget.value;
                                                                        Curry._1(setNewEmail, (function (param) {
                                                                                return val;
                                                                              }));
                                                                      })
                                                                  }))
                                                        }),
                                                  modalType === "password" ? JsxRuntime.jsxs(FormControl, {
                                                          error: confirmPass !== newPass,
                                                          children: [
                                                            JsxRuntime.jsx(PasswordInput$Thick.make, {
                                                                  placeholder: "Confirm your new password",
                                                                  value: confirmPass,
                                                                  onChange: (function (e) {
                                                                      var val = e.currentTarget.value;
                                                                      Curry._1(setConfirmPass, (function (param) {
                                                                              return val;
                                                                            }));
                                                                    })
                                                                }),
                                                            JsxRuntime.jsx(FormHelperText, {
                                                                  children: Caml_option.some(newPass !== confirmPass ? "Passwords do not match" : "")
                                                                })
                                                          ]
                                                        }) : JsxRuntime.jsxs(FormControl, {
                                                          error: emailConfirm !== newEmail,
                                                          children: [
                                                            JsxRuntime.jsx(Input, {
                                                                  placeholder: "Confirm your new email",
                                                                  value: emailConfirm,
                                                                  onChange: (function (e) {
                                                                      var val = e.currentTarget.value;
                                                                      Curry._1(setEmailConfirm, (function (param) {
                                                                              return val;
                                                                            }));
                                                                    })
                                                                }),
                                                            JsxRuntime.jsx(FormHelperText, {
                                                                  children: Caml_option.some(emailConfirm !== newEmail ? "Emails do not match" : "")
                                                                })
                                                          ]
                                                        }),
                                                  modalType === "password" ? JsxRuntime.jsx("div", {
                                                          children: JsxRuntime.jsx(Button, {
                                                                loading: match$4.isMutating,
                                                                type: "submit",
                                                                disabled: confirmPass !== newPass || confirmPass === "" || currentPass === "" || newPass === "",
                                                                children: "Submit"
                                                              })
                                                        }) : JsxRuntime.jsx("div", {
                                                          children: JsxRuntime.jsx(Button, {
                                                                loading: match$3.isMutating,
                                                                type: "submit",
                                                                disabled: emailConfirm !== newEmail || emailConfirm === "" || currentPass === "" || newEmail === "",
                                                                children: "Submit"
                                                              })
                                                        })
                                                ]
                                              }),
                                          changeEmailError !== undefined ? JsxRuntime.jsx(Typography, {
                                                  color: "danger",
                                                  children: Caml_option.some(changeEmailError)
                                                }) : JsxRuntime.jsx(JsxRuntime.Fragment, {}),
                                          changePassError !== undefined ? JsxRuntime.jsx(Typography, {
                                                  color: "danger",
                                                  children: Caml_option.some(changePassError)
                                                }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                                        ]
                                      })
                                ]
                              })
                        ]
                      }))
            });
}

var make = AccountPage;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
