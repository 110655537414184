// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");
var CabinTableQueryRefetchQuery_graphql$Thick = require("./CabinTableQueryRefetchQuery_graphql.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"cabins_totalCount":{"b":""},"cabins_pageInfo_startCursor":{"c":"CustomScalars.Cursor"},"cabins_pageInfo_endCursor":{"c":"CustomScalars.Cursor"},"cabins_edges_node":{"f":""}}};

var fragmentConverterMap = {
  "CustomScalars.Cursor": CustomScalars$Thick.Cursor.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

function makeNode(rescript_graphql_node_CabinTableQueryRefetchQuery) {
  return ({
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "brandFamily"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "offset"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "voyage"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": rescript_graphql_node_CabinTableQueryRefetchQuery
    }
  },
  "name": "CabinTableQuery_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "brandFamilySlug",
              "variableName": "brandFamily"
            },
            {
              "kind": "Variable",
              "name": "voyageSlug",
              "variableName": "voyage"
            }
          ],
          "kind": "ObjectValue",
          "name": "condition"
        },
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 50
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        }
      ],
      "concreteType": "CabinsConnection",
      "kind": "LinkedField",
      "name": "cabins",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CabinsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Cabin",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CabinTable_cabin"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
});
}

var node = makeNode(CabinTableQueryRefetchQuery_graphql$Thick.node);

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
