// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../utils/Common.bs.js");
var Chip = require("@mui/joy/Chip").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var FormControl = require("@mui/joy/FormControl").default;
var Autocomplete = require("@mui/joy/Autocomplete").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var FormHelperText = require("@mui/joy/FormHelperText").default;
var RoleGrantsView_rolegrant_graphql$Thick = require("../../__generated__/RoleGrantsView_rolegrant_graphql.bs.js");

var convertFragment = RoleGrantsView_rolegrant_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(RoleGrantsView_rolegrant_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, RoleGrantsView_rolegrant_graphql$Thick.node, convertFragment);
}

var RoleGrantFrag_brandfamRole_decode = RoleGrantsView_rolegrant_graphql$Thick.Utils.brandfamRole_decode;

var RoleGrantFrag_brandfamRole_fromString = RoleGrantsView_rolegrant_graphql$Thick.Utils.brandfamRole_fromString;

var RoleGrantFrag = {
  brandfamRole_decode: RoleGrantFrag_brandfamRole_decode,
  brandfamRole_fromString: RoleGrantFrag_brandfamRole_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function RoleGrantsView$Chips(props) {
  var rgf = RescriptRelay_Fragment.useFragment(RoleGrantsView_rolegrant_graphql$Thick.node, convertFragment, props.fragment);
  return Belt_Array.map(Belt_Array.keep(rgf.nodes, (function (node) {
                    return node.hasRole;
                  })), (function (node) {
                return JsxRuntime.jsx(Chip, {
                            children: Caml_option.some(Common$Thick.BrandfamRole.toString(Common$Thick.BrandfamRole.toInput(node.brandfamRole))),
                            color: Common$Thick.BrandfamRole.toColor(Common$Thick.BrandfamRole.toInput(node.brandfamRole)),
                            size: "sm",
                            variant: "outlined"
                          }, Common$Thick.BrandfamRole.toString(Common$Thick.BrandfamRole.toInput(node.brandfamRole)));
              }));
}

var Chips = {
  make: RoleGrantsView$Chips
};

function RoleGrantsView$Autocomplete(props) {
  var selectedRoles = props.selectedRoles;
  var setSelectedRoles = props.setSelectedRoles;
  var rgf = RescriptRelay_Fragment.useFragment(RoleGrantsView_rolegrant_graphql$Thick.node, convertFragment, props.fragment);
  var rolesList = Belt_Array.map(rgf.nodes, (function (node) {
          return {
                  brandfamRole: Common$Thick.BrandfamRole.toInput(node.brandfamRole),
                  description: ""
                };
        }));
  React.useEffect((function () {
          var currentRoles = Belt_Array.keepMap(rgf.nodes, (function (node) {
                  var match = node.hasRole;
                  if (match) {
                    return {
                            brandfamRole: Common$Thick.BrandfamRole.toInput(node.brandfamRole),
                            description: ""
                          };
                  }
                  
                }));
          Curry._1(setSelectedRoles, (function (param) {
                  return currentRoles;
                }));
        }), []);
  var match = selectedRoles.length;
  var match$1 = selectedRoles.length;
  return JsxRuntime.jsxs(FormControl, {
              error: match === 0,
              children: [
                JsxRuntime.jsx(FormLabel, {
                      children: "Roles"
                    }),
                JsxRuntime.jsx(Autocomplete, {
                      options: rolesList,
                      disabled: props.disabled,
                      getOptionLabel: (function (node) {
                          return Common$Thick.BrandfamRole.toString(node.brandfamRole);
                        }),
                      id: "brandfam-roles",
                      isOptionEqualToValue: (function (option, value) {
                          return Common$Thick.BrandfamRole.toString(option.brandfamRole) === Common$Thick.BrandfamRole.toString(value.brandfamRole);
                        }),
                      multiple: true,
                      onChange: (function (param, value) {
                          Curry._1(setSelectedRoles, (function (param) {
                                  return value;
                                }));
                        }),
                      value: selectedRoles
                    }),
                JsxRuntime.jsx(FormHelperText, {
                      children: Caml_option.some(match$1 !== 0 ? "User roles" : "User must have at least one role!")
                    })
              ]
            });
}

var Autocomplete$1 = {
  make: RoleGrantsView$Autocomplete
};

exports.RoleGrantFrag = RoleGrantFrag;
exports.Chips = Chips;
exports.Autocomplete = Autocomplete$1;
/* react Not a pure module */
