// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Modal = require("@mui/joy/Modal").default;
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var Divider = require("@mui/joy/Divider").default;
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var ModalClose = require("@mui/joy/ModalClose").default;
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var DialogTitle = require("@mui/joy/DialogTitle").default;
var ModalDialog = require("@mui/joy/ModalDialog").default;
var DiffComparison$Thick = require("./DiffComparison.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var Add = require("@mui/icons-material/Add").default;

function PreviewModal(props) {
  var error = props.error;
  var versionUrl = props.versionUrl;
  var onClose = props.onClose;
  var applyDiff = props.applyDiff;
  var billPayNew = props.billPayNew;
  var onApply = function (e) {
    e.preventDefault();
    var searchStr = BookingDiff$Thick.serialize(applyDiff);
    var url = searchStr === "" ? versionUrl : versionUrl + "?" + searchStr;
    RescriptReactRouter.push(url);
    Curry._1(onClose, undefined);
  };
  var tmp;
  var exit = 0;
  if (billPayNew !== undefined && error === undefined) {
    tmp = JsxRuntime.jsx(DiffComparison$Thick.make, {
          billPayNew: billPayNew,
          billPayCurrent: props.billPayCurrent
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(Stack, {
          direction: "column",
          justifyContent: "center",
          alignItems: "center",
          sx: {
            height: "7rem"
          },
          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                    level: "title-lg",
                    component: "p",
                    textColor: "neutral.500",
                    children: "Your proposed change cannot be applied."
                  }))
        });
  }
  return JsxRuntime.jsx(Modal, {
              open: props.open_,
              onClose: onClose,
              slotProps: {
                backdrop: {
                  sx: {
                    backdropFilter: "none",
                    backgroundColor: "rgba(16, 13, 12, 0.7)"
                  }
                }
              },
              children: Caml_option.some(JsxRuntime.jsxs(ModalDialog, {
                        variant: "outlined",
                        layout: "center",
                        sx: {
                          minWidth: billPayNew !== undefined && error === undefined ? "75vw" : "40rem",
                          borderRadius: "4px",
                          boxShadow: "lg"
                        },
                        children: [
                          JsxRuntime.jsx(DialogTitle, {
                                children: Caml_option.some(props.title)
                              }),
                          JsxRuntime.jsx(ModalClose, {
                                sx: {
                                  top: "0.8rem",
                                  right: "0.8rem"
                                }
                              }),
                          JsxRuntime.jsx(Divider, {}),
                          JsxRuntime.jsxs(DialogContent, {
                                children: [
                                  JsxRuntime.jsx(Typography, {
                                        level: "title-md",
                                        sx: {
                                          marginBottom: "1rem",
                                          color: "primary.400"
                                        },
                                        children: Caml_option.some(props.description)
                                      }),
                                  tmp
                                ]
                              }),
                          JsxRuntime.jsx("form", {
                                children: JsxRuntime.jsxs(DialogActions, {
                                      children: [
                                        billPayNew !== undefined && error === undefined ? JsxRuntime.jsx(Button, {
                                                type: "submit",
                                                endDecorator: Caml_option.some(JsxRuntime.jsx(Add, {})),
                                                color: "primary",
                                                children: Caml_option.some(applyDiff.hasDiff ? "Apply to Draft" : "Create New Draft")
                                              }) : null,
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(Button, {
                                                    onClick: (function (param) {
                                                        Curry._1(onClose, undefined);
                                                      }),
                                                    variant: "outlined",
                                                    children: "Cancel"
                                                  })
                                            })
                                      ]
                                    }),
                                onSubmit: onApply
                              })
                        ]
                      }))
            });
}

var make = PreviewModal;

exports.make = make;
/* @mui/joy/Modal Not a pure module */
