// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var $$Option = require("@mui/joy/Option").default;
var Select = require("@mui/joy/Select").default;
var SearchBar$Thick = require("../common/molecules/SearchBar.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var Add = require("@mui/icons-material/Add").default;
var ChevronLeft = require("@mui/icons-material/ChevronLeft").default;
var ChevronRight = require("@mui/icons-material/ChevronRight").default;

function CustTableToolbar(props) {
  var setDialogOpen = props.setDialogOpen;
  var setAccountSelect = props.setAccountSelect;
  var handleAccountSelect = props.handleAccountSelect;
  var clearSearch = props.clearSearch;
  var search = props.search;
  var searchBar = props.searchBar;
  var onLoadPrev = props.onLoadPrev;
  var onLoadNext = props.onLoadNext;
  var offset = props.offset;
  var totalCount = props.totalCount;
  var searchBar$1 = searchBar !== undefined ? searchBar : false;
  var match = React.useState(function () {
        return "";
      });
  var setSearchVal = match[1];
  var searchVal = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setFilterApplied = match$1[1];
  var onSubmit = function (e) {
    e.preventDefault();
    Curry._1(search, searchVal);
    Curry._1(setFilterApplied, (function (param) {
            return true;
          }));
  };
  return JsxRuntime.jsx(Sheet, {
              sx: {
                p: 1
              },
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        children: [
                          searchBar$1 ? JsxRuntime.jsxs(Stack, {
                                  direction: "row",
                                  component: "form",
                                  spacing: 1,
                                  onSubmit: onSubmit,
                                  children: [
                                    JsxRuntime.jsx(SearchBar$Thick.make, {
                                          value: searchVal,
                                          onChange: (function (e) {
                                              var val = e.currentTarget.value;
                                              Curry._1(setSearchVal, (function (param) {
                                                      return val;
                                                    }));
                                              if (val === "") {
                                                Curry._1(setFilterApplied, (function (param) {
                                                        return false;
                                                      }));
                                                return Curry._1(clearSearch, undefined);
                                              }
                                              
                                            }),
                                          placeholder: "Search users",
                                          onClear: (function (param) {
                                              Curry._1(setSearchVal, (function (param) {
                                                      return "";
                                                    }));
                                              Curry._1(setFilterApplied, (function (param) {
                                                      return false;
                                                    }));
                                              Curry._1(clearSearch, undefined);
                                            }),
                                          showClear: searchVal !== "" || match$1[0]
                                        }),
                                    JsxRuntime.jsx(Button, {
                                          type: "submit",
                                          variant: "outlined",
                                          disabled: searchVal === "",
                                          children: "Search"
                                        })
                                  ]
                                }) : JsxRuntime.jsx(Button, {
                                  onClick: (function (param) {
                                      Curry._1(setDialogOpen, (function (param) {
                                              return true;
                                            }));
                                    }),
                                  startDecorator: Caml_option.some(JsxRuntime.jsx(Add, {})),
                                  "aria-label": "Create New Account",
                                  variant: "outlined",
                                  size: "sm",
                                  children: "Create New Account"
                                }),
                          JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                spacing: 1,
                                children: [
                                  searchBar$1 ? JsxRuntime.jsxs(Select, {
                                          children: [
                                            JsxRuntime.jsx($$Option, {
                                                  value: "all",
                                                  children: "All Accounts"
                                                }),
                                            JsxRuntime.jsx($$Option, {
                                                  value: "claimed",
                                                  children: "Claimed"
                                                }),
                                            JsxRuntime.jsx($$Option, {
                                                  value: "invited",
                                                  children: "Invited"
                                                }),
                                            JsxRuntime.jsx($$Option, {
                                                  value: "unclaimed",
                                                  children: "Unclaimed"
                                                })
                                          ],
                                          onChange: (function (param, val) {
                                              Curry._1(setAccountSelect, (function (param) {
                                                      return val;
                                                    }));
                                              Curry._1(handleAccountSelect, val);
                                            }),
                                          sx: {
                                            width: "10rem"
                                          },
                                          value: props.accountSelect
                                        }) : JsxRuntime.jsx("div", {}),
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        children: [
                                          JsxRuntime.jsx(IconButton, {
                                                onClick: (function (param) {
                                                    Curry._1(onLoadPrev, undefined);
                                                  }),
                                                variant: "plain",
                                                disabled: offset === 0,
                                                children: Caml_option.some(JsxRuntime.jsx(ChevronLeft, {}))
                                              }),
                                          JsxRuntime.jsx(Typography, {
                                                level: "body-xs",
                                                children: Caml_option.some("" + Curry._2(props.stringFromOffset, offset, totalCount) + "")
                                              }),
                                          JsxRuntime.jsx(IconButton, {
                                                onClick: (function (param) {
                                                    Curry._1(onLoadNext, undefined);
                                                  }),
                                                variant: "plain",
                                                disabled: (offset + 50 | 0) >= totalCount,
                                                children: Caml_option.some(JsxRuntime.jsx(ChevronRight, {}))
                                              })
                                        ]
                                      })
                                ]
                              })
                        ]
                      }))
            });
}

var make = CustTableToolbar;

exports.make = make;
/* react Not a pure module */
