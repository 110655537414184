// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function previewCallback(bookingId, bookingVersionNumber, voyage, diff) {
  var berths = diff.dropBerths;
  var berths$1 = diff.addBerths;
  var paxes = diff.detachPax;
  var paxes$1 = diff.attachExistingPax;
  var paxes$2 = diff.replacePaxExisting;
  var bc = diff.changeBillingContact;
  return {
          bookingDiff: {
            dropBerths: berths !== undefined ? Belt_Array.map(berths, (function (b) {
                      return b;
                    })) : undefined,
            addBerths: berths$1 !== undefined ? berths$1 : undefined,
            detachPax: paxes !== undefined ? Belt_Array.map(paxes, (function (p) {
                      return p;
                    })) : undefined,
            attachExistingPax: paxes$1 !== undefined ? Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(paxes$1)), (function (p) {
                      return {
                              idx: p[0],
                              accountId: p[1]
                            };
                    })) : undefined,
            replacePaxExisting: paxes$2 !== undefined ? Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(paxes$2)), (function (p) {
                      return {
                              idx: p[0],
                              accountId: p[1]
                            };
                    })) : undefined,
            changeBillingContact: bc !== undefined ? Caml_option.some(Caml_option.valFromOption(bc)) : undefined
          },
          bookingId: bookingId,
          bookingVersionNumber: bookingVersionNumber,
          voyageSlug: voyage
        };
}

exports.previewCallback = previewCallback;
/* No side effect */
