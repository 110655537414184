// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Link$Thick = require("../atoms/Link.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var RelayEnv$Thick = require("../../../RelayEnv.bs.js");
var AccountMenu$Thick = require("./AccountMenu.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var NavBarQuery_graphql$Thick = require("../../../__generated__/NavBarQuery_graphql.bs.js");
var NavBar_profile_graphql$Thick = require("../../../__generated__/NavBar_profile_graphql.bs.js");

var convertFragment = NavBar_profile_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(NavBar_profile_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NavBar_profile_graphql$Thick.node, convertFragment);
}

var ProfileFragment_signonStatus_decode = NavBar_profile_graphql$Thick.Utils.signonStatus_decode;

var ProfileFragment_signonStatus_fromString = NavBar_profile_graphql$Thick.Utils.signonStatus_fromString;

var ProfileFragment = {
  signonStatus_decode: ProfileFragment_signonStatus_decode,
  signonStatus_fromString: ProfileFragment_signonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

var convertVariables = NavBarQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = NavBarQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = NavBarQuery_graphql$Thick.Internal.convertWrapRawResponse;

function use$1(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.useQuery(convertVariables, NavBarQuery_graphql$Thick.node, convertResponse, param, param$1, param$2, param$3, param$4);
}

function useLoader(param) {
  return RescriptRelay_Query.useLoader(convertVariables, NavBarQuery_graphql$Thick.node, (function (prim) {
                return prim;
              }), param);
}

function usePreloaded(param) {
  return RescriptRelay_Query.usePreloaded(NavBarQuery_graphql$Thick.node, convertResponse, (function (prim) {
                return prim;
              }), param);
}

function $$fetch(param, param$1, param$2, param$3, param$4, param$5) {
  return RescriptRelay_Query.$$fetch(NavBarQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4, param$5);
}

function fetchPromised(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.fetchPromised(NavBarQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4);
}

function retain(param, param$1) {
  return RescriptRelay_Query.retain(NavBarQuery_graphql$Thick.node, convertVariables, param, param$1);
}

var CanQuery_brandfamPermission_decode = NavBarQuery_graphql$Thick.Utils.brandfamPermission_decode;

var CanQuery_brandfamPermission_fromString = NavBarQuery_graphql$Thick.Utils.brandfamPermission_fromString;

var CanQuery = {
  brandfamPermission_decode: CanQuery_brandfamPermission_decode,
  brandfamPermission_fromString: CanQuery_brandfamPermission_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use$1,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function NavBar(props) {
  var brandFamily = props.brandFamily;
  var profile = RescriptRelay_Fragment.useFragment(NavBar_profile_graphql$Thick.node, convertFragment, props.profile);
  var match = React.useState(function () {
        
      });
  var setBfQuery = match[1];
  React.useEffect((function () {
          if (brandFamily !== undefined) {
            $$fetch(RelayEnv$Thick.environment, {
                  brandFamily: Caml_option.valFromOption(brandFamily)
                }, (function (res) {
                    if (res.TAG !== /* Ok */0) {
                      return Curry._1(setBfQuery, (function (param) {
                                    
                                  }));
                    }
                    var res$1 = res._0;
                    Curry._1(setBfQuery, (function (param) {
                            return res$1;
                          }));
                  }), undefined, undefined, undefined);
          }
          
        }), []);
  var match$1 = profile.currentUserSignonStatus;
  var match$2 = profile.currentUserEmail;
  var tmp;
  var exit = 0;
  if (match$1 === "SIGNED_ON" && match$2 !== undefined) {
    tmp = JsxRuntime.jsx(AccountMenu$Thick.make, {
          query: profile.fragmentRefs,
          brandFamily: brandFamily,
          bfQuery: match[0]
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(Box, {
          children: Caml_option.some(JsxRuntime.jsx(Link$Thick.make, {
                    to: "/login",
                    children: JsxRuntime.jsx(Typography, {
                          children: "Login"
                        })
                  }))
        });
  }
  return JsxRuntime.jsx(Box, {
              children: Caml_option.some(JsxRuntime.jsxs(Box, {
                        children: [
                          JsxRuntime.jsx(Box, {}),
                          JsxRuntime.jsxs(Box, {
                                children: [
                                  tmp,
                                  JsxRuntime.jsx(Box, {
                                        sx: {
                                          mx: 2
                                        }
                                      })
                                ],
                                sx: {
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center"
                                }
                              })
                        ],
                        sx: {
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          p: 1
                        }
                      })),
              sx: {
                width: "100%",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "background.body",
                borderBottom: 1,
                borderColor: "neutral.300"
              }
            });
}

var make = NavBar;

exports.ProfileFragment = ProfileFragment;
exports.CanQuery = CanQuery;
exports.make = make;
/* react Not a pure module */
