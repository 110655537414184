// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../utils/Common.bs.js");
var QueryVar$Thick = require("../utils/QueryVar.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var DashboardLayout$Thick = require("../layouts/DashboardLayout.bs.js");
var OrgProtectedRoute$Thick = require("../components/wrappers/OrgProtectedRoute.bs.js");
var BrandPermProtected$Thick = require("../components/wrappers/BrandPermProtected.bs.js");
var CabinInventoryPage$Thick = require("../pages/CabinInventoryPage.bs.js");
var VoyageProtectedRoute$Thick = require("../components/wrappers/VoyageProtectedRoute.bs.js");
var CabinInventoryRouteQuery_graphql$Thick = require("../__generated__/CabinInventoryRouteQuery_graphql.bs.js");

var convertVariables = CabinInventoryRouteQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = CabinInventoryRouteQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = CabinInventoryRouteQuery_graphql$Thick.Internal.convertWrapRawResponse;

function use(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.useQuery(convertVariables, CabinInventoryRouteQuery_graphql$Thick.node, convertResponse, param, param$1, param$2, param$3, param$4);
}

function useLoader(param) {
  return RescriptRelay_Query.useLoader(convertVariables, CabinInventoryRouteQuery_graphql$Thick.node, (function (prim) {
                return prim;
              }), param);
}

function usePreloaded(param) {
  return RescriptRelay_Query.usePreloaded(CabinInventoryRouteQuery_graphql$Thick.node, convertResponse, (function (prim) {
                return prim;
              }), param);
}

function $$fetch(param, param$1, param$2, param$3, param$4, param$5) {
  return RescriptRelay_Query.$$fetch(CabinInventoryRouteQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4, param$5);
}

function fetchPromised(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.fetchPromised(CabinInventoryRouteQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4);
}

function retain(param, param$1) {
  return RescriptRelay_Query.retain(CabinInventoryRouteQuery_graphql$Thick.node, convertVariables, param, param$1);
}

var Query_accountStatus_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.accountStatus_decode;

var Query_accountStatus_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.accountStatus_fromString;

var Query_custSignonStatus_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.custSignonStatus_decode;

var Query_custSignonStatus_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.custSignonStatus_fromString;

var Query_docGender_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.docGender_decode;

var Query_docGender_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.docGender_fromString;

var Query_lifecycleStatus_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.lifecycleStatus_decode;

var Query_lifecycleStatus_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.lifecycleStatus_fromString;

var Query_paxDataStatus_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.paxDataStatus_decode;

var Query_paxDataStatus_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.paxDataStatus_fromString;

var Query_pmtDirection_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.pmtDirection_decode;

var Query_pmtDirection_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.pmtDirection_fromString;

var Query_pmtLifecycleStatus_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var Query_pmtLifecycleStatus_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var Query_pmtProcessor_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.pmtProcessor_decode;

var Query_pmtProcessor_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.pmtProcessor_fromString;

var Query_subtotalCategory_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.subtotalCategory_decode;

var Query_subtotalCategory_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.subtotalCategory_fromString;

var Query = {
  accountStatus_decode: Query_accountStatus_decode,
  accountStatus_fromString: Query_accountStatus_fromString,
  custSignonStatus_decode: Query_custSignonStatus_decode,
  custSignonStatus_fromString: Query_custSignonStatus_fromString,
  docGender_decode: Query_docGender_decode,
  docGender_fromString: Query_docGender_fromString,
  lifecycleStatus_decode: Query_lifecycleStatus_decode,
  lifecycleStatus_fromString: Query_lifecycleStatus_fromString,
  paxDataStatus_decode: Query_paxDataStatus_decode,
  paxDataStatus_fromString: Query_paxDataStatus_fromString,
  pmtDirection_decode: Query_pmtDirection_decode,
  pmtDirection_fromString: Query_pmtDirection_fromString,
  pmtLifecycleStatus_decode: Query_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: Query_pmtLifecycleStatus_fromString,
  pmtProcessor_decode: Query_pmtProcessor_decode,
  pmtProcessor_fromString: Query_pmtProcessor_fromString,
  subtotalCategory_decode: Query_subtotalCategory_decode,
  subtotalCategory_fromString: Query_subtotalCategory_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function CabinInventoryRoute(props) {
  var voyage = props.voyage;
  var brandFamily = props.brandFamily;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var qStructInitial = QueryVar$Thick.parse(url.search);
  var qStruct = React.useDeferredValue(qStructInitial);
  var filterConfig_voyageClassSlug = Belt_Option.flatMap(qStruct.cabinFilters.class, (function (a) {
          return Curry._1(QueryVar$Thick.StringArg.Filter.input_StringFilter, a);
        }));
  var filterConfig_voyageSubclassSlug = Belt_Option.flatMap(qStruct.cabinFilters.subclass, (function (a) {
          return Curry._1(QueryVar$Thick.StringArg.Filter.input_StringFilter, a);
        }));
  var filterConfig_voyageCategorySlug = Belt_Option.flatMap(qStruct.cabinFilters.category, (function (a) {
          return Curry._1(QueryVar$Thick.StringArg.Filter.input_StringFilter, a);
        }));
  var filterConfig_voyageSegment = Belt_Option.flatMap(qStruct.cabinFilters.segment, (function (a) {
          return Curry._1(QueryVar$Thick.StringArg.Filter.input_StringFilter, a);
        }));
  var filterConfig_cabinNumber = Belt_Option.flatMap(qStruct.cabinFilters.cabin, (function (a) {
          return Curry._1(QueryVar$Thick.StringArg.Filter.input_StringFilter, a);
        }));
  var filterConfig_totBerths = Belt_Option.flatMap(qStruct.cabinFilters.totBerths, (function (a) {
          return Curry._1(QueryVar$Thick.IntArg.Filter.input_OccupancyFilter, a);
        }));
  var filterConfig_occRange = Belt_Option.flatMap(qStruct.cabinFilters.occRange, (function (a) {
          return Curry._1(QueryVar$Thick.IntRange.Filter.input_IntRangeFilter, a);
        }));
  var filterConfig_privateNoteMd = Belt_Option.flatMap(qStruct.cabinFilters.privateNote, (function (a) {
          return Curry._1(QueryVar$Thick.StringArg.Filter.input_NonemptyStringFilter, a);
        }));
  var filterConfig_publicNoteMd = Belt_Option.flatMap(qStruct.cabinFilters.publicNote, (function (a) {
          return Curry._1(QueryVar$Thick.StringArg.Filter.input_NonemptyStringFilter, a);
        }));
  var filterConfig_bookingExist = Belt_Option.flatMap(qStruct.cabinFilters.bookingExist, (function (a) {
          return a;
        }));
  var filterConfig = {
    orgSlug: undefined,
    brandFamilySlug: undefined,
    voyageSlug: undefined,
    vesselConfigurationSlug: undefined,
    voyageClassSlug: filterConfig_voyageClassSlug,
    voyageSubclassSlug: filterConfig_voyageSubclassSlug,
    voyageCategorySlug: filterConfig_voyageCategorySlug,
    voyageSegment: filterConfig_voyageSegment,
    cabinNumber: filterConfig_cabinNumber,
    totBerths: filterConfig_totBerths,
    salesMinBerth: undefined,
    salesMaxBerth: undefined,
    occRange: filterConfig_occRange,
    deckNumber: undefined,
    deckName: undefined,
    privateNoteMd: filterConfig_privateNoteMd,
    publicNoteMd: filterConfig_publicNoteMd,
    booking: undefined,
    bookingExist: filterConfig_bookingExist,
    bookingVersion: undefined,
    bookingVersionExist: undefined,
    segment: undefined,
    segmentExists: undefined,
    and: undefined,
    or: undefined,
    not: undefined
  };
  var filter;
  if (qStruct.cabinFilters.hasCabinFiltrationEnabled) {
    filter = filterConfig;
  } else {
    var newrecord = Caml_obj.obj_dup(filterConfig);
    newrecord.voyageSlug = {
      equalTo: CustomScalars$Thick.VoyageSlug.serialize(voyage)
    };
    filter = newrecord;
  }
  var offsetFromPage = function (page) {
    return Math.imul(page - 1 | 0, 50);
  };
  var page = qStruct.page;
  var page$1 = page !== undefined ? Belt_Option.getWithDefault(Curry._1(QueryVar$Thick.IntArg.Filter.parse_Int, page), 1) : 1;
  var data = use({
        brandFamily: brandFamily,
        filter: filter,
        offset: offsetFromPage(page$1),
        voyage: voyage
      }, undefined, undefined, undefined, undefined);
  var fragmentRefs = data.fragmentRefs;
  var match = data.allCabins;
  var allCabins = match !== undefined ? Belt_Set.fromArray(Belt_Array.map(match.nodes, (function (c) {
                return c.cabinNumber;
              })), Common$Thick.CabinCmp) : Belt_Set.make(Common$Thick.CabinCmp);
  var match$1 = React.useState(function () {
        return Belt_Set.make(Common$Thick.CabinCmp);
      });
  var setSelectedCabins = match$1[1];
  var selectedCabins = match$1[0];
  var handleCabinToggle = function (c) {
    if (Belt_Set.has(selectedCabins, c)) {
      return Curry._1(setSelectedCabins, (function (param) {
                    return Belt_Set.remove(selectedCabins, c);
                  }));
    } else {
      return Curry._1(setSelectedCabins, (function (param) {
                    return Belt_Set.add(selectedCabins, c);
                  }));
    }
  };
  return JsxRuntime.jsx(DashboardLayout$Thick.make, {
              fragmentRefs: fragmentRefs,
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(OrgProtectedRoute$Thick.make, {
                              fragmentRefs: fragmentRefs,
                              children: JsxRuntime.jsx(BrandPermProtected$Thick.make, {
                                    fragmentRefs: fragmentRefs,
                                    neededPerm: "VIEW_CABIN_INVENTORY",
                                    children: JsxRuntime.jsx(VoyageProtectedRoute$Thick.make, {
                                          fragmentRefs: fragmentRefs,
                                          brandFamily: brandFamily,
                                          voyage: voyage,
                                          children: JsxRuntime.jsx(CabinInventoryPage$Thick.make, {
                                                fragmentRefs: fragmentRefs,
                                                brandFamily: CustomScalars$Thick.BrandFamilySlug.serialize(brandFamily),
                                                voyage: CustomScalars$Thick.VoyageSlug.serialize(voyage),
                                                allCabins: allCabins,
                                                selectedCabins: selectedCabins,
                                                setSelectedCabins: setSelectedCabins,
                                                handleCabinToggle: handleCabinToggle
                                              })
                                        }),
                                    isRoute: true,
                                    redirect: "/" + CustomScalars$Thick.BrandFamilySlug.serialize(brandFamily) + ""
                                  })
                            }))
                  }),
              bg: "primary.50",
              voyage: Caml_option.some(voyage),
              cabinDash: true,
              clearCabins: (function (param) {
                  Curry._1(setSelectedCabins, (function (param) {
                          return Belt_Set.make(Common$Thick.CabinCmp);
                        }));
                })
            });
}

var make = CabinInventoryRoute;

exports.Query = Query;
exports.make = make;
/* react Not a pure module */
