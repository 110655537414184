// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../utils/Common.bs.js");
var Dialog$Thick = require("../common/molecules/Dialog.bs.js");
var Input = require("@mui/joy/Input").default;
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var FormControl = require("@mui/joy/FormControl").default;
var Autocomplete = require("@mui/joy/Autocomplete").default;
var InviteUserSubmit$Thick = require("./mutations/InviteUserSubmit.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var FormHelperText = require("@mui/joy/FormHelperText").default;
var BrandPermProtected$Thick = require("../wrappers/BrandPermProtected.bs.js");
var InviteUserForm_query_graphql$Thick = require("../../__generated__/InviteUserForm_query_graphql.bs.js");

var convertFragment = InviteUserForm_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(InviteUserForm_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, InviteUserForm_query_graphql$Thick.node, convertFragment);
}

var QueryFragment_brandfamRole_decode = InviteUserForm_query_graphql$Thick.Utils.brandfamRole_decode;

var QueryFragment_brandfamRole_fromString = InviteUserForm_query_graphql$Thick.Utils.brandfamRole_fromString;

var QueryFragment = {
  brandfamRole_decode: QueryFragment_brandfamRole_decode,
  brandfamRole_fromString: QueryFragment_brandfamRole_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function InviteUserForm(props) {
  var onClose = props.onClose;
  var fragment = RescriptRelay_Fragment.useFragment(InviteUserForm_query_graphql$Thick.node, convertFragment, props.fragment);
  var match = fragment.roles;
  var rolesList = match !== undefined ? Belt_List.toArray(Belt_Array.reduce(match.nodes, /* [] */0, (function (acc, node) {
                return Belt_List.add(acc, {
                            brandfamRole: Common$Thick.BrandfamRole.toInput(node.brandfamRole),
                            description: node.description
                          });
              }))) : [];
  var match$1 = fragment.voyages;
  var voyagesList = match$1 !== undefined ? Belt_List.toArray(Belt_Array.reduce(match$1.nodes, /* [] */0, (function (acc, node) {
                return Belt_List.add(acc, {
                            voyageSlug: node.voyageSlug
                          });
              }))) : [];
  var match$2 = InviteUserSubmit$Thick.useMutation(props.brandFamily, props.refreshTeam, Belt_Array.slice(rolesList, 0, 1), [], onClose);
  var setError = match$2.setError;
  var error = match$2.error;
  var setUserVoyages = match$2.setUserVoyages;
  var setUserRoles = match$2.setUserRoles;
  var userRoles = match$2.userRoles;
  var setUserEmail = match$2.setUserEmail;
  var userEmail = match$2.userEmail;
  var clearAll = function (param) {
    Curry._1(setUserEmail, (function (param) {
            return "";
          }));
    Curry._1(setUserRoles, (function (param) {
            return Belt_Array.slice(rolesList, 0, 1);
          }));
    Curry._1(setUserVoyages, (function (param) {
            return [];
          }));
  };
  var match$3 = userRoles.length;
  var match$4 = userRoles.length;
  var tmp = userEmail === "" || error !== undefined ? true : userRoles.length === 0;
  return JsxRuntime.jsxs(Dialog$Thick.make, {
              open_: props.open_,
              onClose: (function (param) {
                  Curry._1(onClose, undefined);
                  clearAll(undefined);
                  Curry._1(setError, (function (param) {
                          
                        }));
                }),
              title: "Invite User",
              children: [
                JsxRuntime.jsx(Typography, {
                      textColor: "text.tertiary",
                      children: "Invite a user to your brand family. They will receive an email with a link to create an account."
                    }),
                JsxRuntime.jsx(Typography, {
                      component: "h3",
                      children: "User Information"
                    }),
                JsxRuntime.jsxs(Stack, {
                      component: "form",
                      spacing: 2,
                      onSubmit: match$2.onSubmit,
                      children: [
                        JsxRuntime.jsxs(FormControl, {
                              error: userEmail === "" || !Common$Thick.Email.isValid(userEmail),
                              children: [
                                JsxRuntime.jsx(FormLabel, {
                                      children: "Email"
                                    }),
                                JsxRuntime.jsx(Input, {
                                      required: true,
                                      type: "email",
                                      value: userEmail,
                                      onChange: (function (e) {
                                          var val = e.currentTarget.value;
                                          Curry._1(setUserEmail, (function (param) {
                                                  return val;
                                                }));
                                        })
                                    }),
                                JsxRuntime.jsx(FormHelperText, {
                                      children: Caml_option.some(userEmail === "" || !Common$Thick.Email.isValid(userEmail) ? "Please enter a valid email address" : " ")
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(BrandPermProtected$Thick.make, {
                              fragmentRefs: props.permFrag,
                              neededPerm: "MANAGE_USER",
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      component: "h3",
                                      children: "Permissions"
                                    }),
                                JsxRuntime.jsxs(FormControl, {
                                      error: match$3 === 0,
                                      children: [
                                        JsxRuntime.jsx(FormLabel, {
                                              children: "Roles"
                                            }),
                                        JsxRuntime.jsx(Autocomplete, {
                                              options: rolesList,
                                              getOptionLabel: (function (node) {
                                                  return Common$Thick.BrandfamRole.toString(node.brandfamRole);
                                                }),
                                              id: "brandfam-roles",
                                              isOptionEqualToValue: (function (option, value) {
                                                  return Common$Thick.BrandfamRole.toString(option.brandfamRole) === Common$Thick.BrandfamRole.toString(value.brandfamRole);
                                                }),
                                              multiple: true,
                                              onChange: (function (param, value) {
                                                  Curry._1(setUserRoles, (function (param) {
                                                          return value;
                                                        }));
                                                }),
                                              value: userRoles
                                            }),
                                        JsxRuntime.jsx(FormHelperText, {
                                              children: Caml_option.some(match$4 !== 0 ? " " : "User must be invited with at least one role!")
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsxs(FormControl, {
                                      children: [
                                        JsxRuntime.jsx(FormLabel, {
                                              children: "Voyages"
                                            }),
                                        JsxRuntime.jsx(Autocomplete, {
                                              options: voyagesList,
                                              getOptionLabel: (function (node) {
                                                  return node.voyageSlug;
                                                }),
                                              id: "voyages",
                                              isOptionEqualToValue: (function (option, value) {
                                                  return Caml_obj.equal(option.voyageSlug, value.voyageSlug);
                                                }),
                                              multiple: true,
                                              onChange: (function (param, value) {
                                                  Curry._1(setUserVoyages, (function (param) {
                                                          return value;
                                                        }));
                                                }),
                                              value: match$2.userVoyages
                                            })
                                      ]
                                    })
                              ]
                            }),
                        JsxRuntime.jsx(FormControl, {
                              children: Caml_option.some(JsxRuntime.jsx(Button, {
                                        loading: match$2.isMutating,
                                        type: "submit",
                                        disabled: tmp,
                                        children: "Submit"
                                      }))
                            })
                      ]
                    }),
                error !== undefined ? JsxRuntime.jsx(Typography, {
                        color: "danger",
                        children: Caml_option.some(error)
                      }) : null
              ]
            });
}

var make = InviteUserForm;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* Common-Thick Not a pure module */
