// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Modal = require("@mui/joy/Modal").default;
var Stack = require("@mui/joy/Stack").default;
var RelayEnv$Thick = require("../../../RelayEnv.bs.js");
var Button = require("@mui/joy/Button").default;
var Divider = require("@mui/joy/Divider").default;
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Belt_HashMapString = require("rescript/lib/js/belt_HashMapString.js");
var PreviewModal$Thick = require("./PreviewModal.bs.js");
var ModalClose = require("@mui/joy/ModalClose").default;
var Typography = require("@mui/joy/Typography").default;
var CustomScalars$Thick = require("../../../utils/CustomScalars.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var DialogTitle = require("@mui/joy/DialogTitle").default;
var ModalDialog = require("@mui/joy/ModalDialog").default;
var PreviewChanges$Thick = require("./PreviewChanges.bs.js");
var Autocomplete = require("@mui/joy/Autocomplete").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var ChangeBCModalQuery_graphql$Thick = require("../../../__generated__/ChangeBCModalQuery_graphql.bs.js");

var convertVariables = ChangeBCModalQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = ChangeBCModalQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = ChangeBCModalQuery_graphql$Thick.Internal.convertWrapRawResponse;

function use(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.useQuery(convertVariables, ChangeBCModalQuery_graphql$Thick.node, convertResponse, param, param$1, param$2, param$3, param$4);
}

function useLoader(param) {
  return RescriptRelay_Query.useLoader(convertVariables, ChangeBCModalQuery_graphql$Thick.node, (function (prim) {
                return prim;
              }), param);
}

function usePreloaded(param) {
  return RescriptRelay_Query.usePreloaded(ChangeBCModalQuery_graphql$Thick.node, convertResponse, (function (prim) {
                return prim;
              }), param);
}

function $$fetch(param, param$1, param$2, param$3, param$4, param$5) {
  return RescriptRelay_Query.$$fetch(ChangeBCModalQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4, param$5);
}

function fetchPromised(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.fetchPromised(ChangeBCModalQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4);
}

function retain(param, param$1) {
  return RescriptRelay_Query.retain(ChangeBCModalQuery_graphql$Thick.node, convertVariables, param, param$1);
}

var Query_accountStatus_decode = ChangeBCModalQuery_graphql$Thick.Utils.accountStatus_decode;

var Query_accountStatus_fromString = ChangeBCModalQuery_graphql$Thick.Utils.accountStatus_fromString;

var Query_custSignonStatus_decode = ChangeBCModalQuery_graphql$Thick.Utils.custSignonStatus_decode;

var Query_custSignonStatus_fromString = ChangeBCModalQuery_graphql$Thick.Utils.custSignonStatus_fromString;

var Query_docGender_decode = ChangeBCModalQuery_graphql$Thick.Utils.docGender_decode;

var Query_docGender_fromString = ChangeBCModalQuery_graphql$Thick.Utils.docGender_fromString;

var Query_lifecycleStatus_decode = ChangeBCModalQuery_graphql$Thick.Utils.lifecycleStatus_decode;

var Query_lifecycleStatus_fromString = ChangeBCModalQuery_graphql$Thick.Utils.lifecycleStatus_fromString;

var Query_paxDataStatus_decode = ChangeBCModalQuery_graphql$Thick.Utils.paxDataStatus_decode;

var Query_paxDataStatus_fromString = ChangeBCModalQuery_graphql$Thick.Utils.paxDataStatus_fromString;

var Query_pmtDirection_decode = ChangeBCModalQuery_graphql$Thick.Utils.pmtDirection_decode;

var Query_pmtDirection_fromString = ChangeBCModalQuery_graphql$Thick.Utils.pmtDirection_fromString;

var Query_pmtLifecycleStatus_decode = ChangeBCModalQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var Query_pmtLifecycleStatus_fromString = ChangeBCModalQuery_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var Query_pmtProcessor_decode = ChangeBCModalQuery_graphql$Thick.Utils.pmtProcessor_decode;

var Query_pmtProcessor_fromString = ChangeBCModalQuery_graphql$Thick.Utils.pmtProcessor_fromString;

var Query_subtotalCategory_decode = ChangeBCModalQuery_graphql$Thick.Utils.subtotalCategory_decode;

var Query_subtotalCategory_fromString = ChangeBCModalQuery_graphql$Thick.Utils.subtotalCategory_fromString;

var Query = {
  accountStatus_decode: Query_accountStatus_decode,
  accountStatus_fromString: Query_accountStatus_fromString,
  custSignonStatus_decode: Query_custSignonStatus_decode,
  custSignonStatus_fromString: Query_custSignonStatus_fromString,
  docGender_decode: Query_docGender_decode,
  docGender_fromString: Query_docGender_fromString,
  lifecycleStatus_decode: Query_lifecycleStatus_decode,
  lifecycleStatus_fromString: Query_lifecycleStatus_fromString,
  paxDataStatus_decode: Query_paxDataStatus_decode,
  paxDataStatus_fromString: Query_paxDataStatus_fromString,
  pmtDirection_decode: Query_pmtDirection_decode,
  pmtDirection_fromString: Query_pmtDirection_fromString,
  pmtLifecycleStatus_decode: Query_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: Query_pmtLifecycleStatus_fromString,
  pmtProcessor_decode: Query_pmtProcessor_decode,
  pmtProcessor_fromString: Query_pmtProcessor_fromString,
  subtotalCategory_decode: Query_subtotalCategory_decode,
  subtotalCategory_fromString: Query_subtotalCategory_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function ChangeBCModal(props) {
  var brandFamily = props.brandFamily;
  var currentAcctId = props.currentAcctId;
  var onClose = props.onClose;
  var match = React.useState(function () {
        return [];
      });
  var setSelectOptions = match[1];
  var selectOptions = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setAutocompleteOpen = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setLoading = match$2[1];
  var loading = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setError = match$3[1];
  var match$4 = React.useState(function () {
        return false;
      });
  var setPreviewModalOpen = match$4[1];
  var match$5 = React.useState(function () {
        
      });
  var setBillPayNew = match$5[1];
  var match$6 = React.useState(function () {
        return null;
      });
  var setBcAcct = match$6[1];
  var bcAcct = match$6[0];
  var onError = function (s) {
    Curry._1(setError, (function (param) {
            return s;
          }));
  };
  var onSuccess = function (b) {
    Curry._1(setBillPayNew, (function (param) {
            return b;
          }));
    Curry._1(onClose, undefined);
    Curry._1(setPreviewModalOpen, (function (param) {
            return true;
          }));
  };
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var applyDiff = React.useMemo((function () {
          return BookingDiff$Thick.Alter.changeBillingContact(BookingDiff$Thick.parse(url.search), Belt_Option.map((bcAcct == null) ? undefined : Caml_option.some(bcAcct), (function (a) {
                            return CustomScalars$Thick.AccountId.parse(a);
                          })));
        }), [
        bcAcct,
        url.search
      ]);
  var selectMap = React.useMemo((function () {
          return Belt_HashMapString.fromArray(Belt_Array.map(selectOptions, (function (val) {
                            return [
                                    val.accountId,
                                    val.fullName
                                  ];
                          })));
        }), [selectOptions]);
  var toBc = React.useMemo((function () {
          if (bcAcct == null) {
            return "";
          }
          var bcName = Belt_HashMapString.get(selectMap, bcAcct);
          if (bcName !== undefined) {
            return " to " + bcName + "";
          } else {
            return "";
          }
        }), [bcAcct]);
  var createFilter = function (s) {
    return {
            fullName: {
              includesInsensitive: s
            }
          };
  };
  var search = function (val) {
    Curry._1(setLoading, (function (param) {
            return true;
          }));
    Curry._1(setAutocompleteOpen, (function (param) {
            return false;
          }));
    Curry._1(setError, (function (param) {
            
          }));
    $$fetch(RelayEnv$Thick.environment, {
          brandFamily: brandFamily,
          filter: createFilter(val)
        }, (function (res) {
            if (res.TAG === /* Ok */0) {
              var match = res._0.bfcustUsers;
              if (match !== undefined) {
                var results = Belt_Array.keepMap(match.nodes, (function (node) {
                        var accountId = CustomScalars$Thick.AccountId.serialize(node.accountId);
                        var fullName = node.fullName;
                        var n = {
                          accountId: accountId,
                          fullName: fullName
                        };
                        if (currentAcctId !== undefined && currentAcctId === CustomScalars$Thick.AccountId.serialize(node.accountId)) {
                          return ;
                        } else {
                          return n;
                        }
                      }));
                Curry._1(setSelectOptions, (function (param) {
                        return results;
                      }));
                Curry._1(setAutocompleteOpen, (function (param) {
                        return true;
                      }));
                Curry._1(setLoading, (function (param) {
                        return false;
                      }));
                return ;
              }
              Curry._1(setError, (function (param) {
                      return "No users found.";
                    }));
              Curry._1(setLoading, (function (param) {
                      return false;
                    }));
              return ;
            }
            console.error(res._0);
            Curry._1(setError, (function (param) {
                    return "Something went wrong. Please try again.";
                  }));
            Curry._1(setLoading, (function (param) {
                    return false;
                  }));
          }), undefined, undefined, undefined);
  };
  var match$7 = PreviewChanges$Thick.useQuery(props.generatePreviewFromDiff, applyDiff, setLoading, onSuccess, onError);
  var onClick = match$7.onClick;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Modal, {
                      open: props.open_,
                      onClose: onClose,
                      slotProps: {
                        backdrop: {
                          sx: {
                            backdropFilter: "none",
                            backgroundColor: "rgba(16, 13, 12, 0.7)"
                          }
                        }
                      },
                      children: Caml_option.some(JsxRuntime.jsxs(ModalDialog, {
                                variant: "outlined",
                                layout: "center",
                                sx: {
                                  minWidth: "30rem",
                                  borderRadius: "4px",
                                  boxShadow: "lg"
                                },
                                children: [
                                  JsxRuntime.jsx(DialogTitle, {
                                        children: "Change Billing Contact"
                                      }),
                                  JsxRuntime.jsx(ModalClose, {
                                        sx: {
                                          top: "0.8rem",
                                          right: "0.8rem"
                                        }
                                      }),
                                  JsxRuntime.jsx(Divider, {}),
                                  JsxRuntime.jsxs(DialogContent, {
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                level: "title-md",
                                                sx: {
                                                  marginBottom: "1rem"
                                                },
                                                children: "Search for a user to add as the billing contact on this booking."
                                              }),
                                          JsxRuntime.jsx(Stack, {
                                                direction: "column",
                                                spacing: 1,
                                                children: Caml_option.some(JsxRuntime.jsx(Autocomplete, {
                                                          options: selectOptions,
                                                          getOptionLabel: (function (option) {
                                                              return option.fullName;
                                                            }),
                                                          isOptionEqualToValue: (function (option, value) {
                                                              return option.accountId === value.accountId;
                                                            }),
                                                          loading: loading,
                                                          onChange: (function (param, value) {
                                                              Curry._1(setBcAcct, (function (param) {
                                                                      return value.accountId;
                                                                    }));
                                                            }),
                                                          onClose: (function (param) {
                                                              Curry._1(setAutocompleteOpen, (function (param) {
                                                                      return false;
                                                                    }));
                                                            }),
                                                          onInputChange: (function (param, v, param$1) {
                                                              if (v === "") {
                                                                Curry._1(setSelectOptions, (function (param) {
                                                                        return [];
                                                                      }));
                                                                Curry._1(setAutocompleteOpen, (function (param) {
                                                                        return false;
                                                                      }));
                                                                Curry._1(setError, (function (param) {
                                                                        
                                                                      }));
                                                                Curry._1(setLoading, (function (param) {
                                                                        return false;
                                                                      }));
                                                              } else {
                                                                search(v);
                                                              }
                                                            }),
                                                          onOpen: (function (param) {
                                                              Curry._1(setAutocompleteOpen, (function (param) {
                                                                      return true;
                                                                    }));
                                                            }),
                                                          open: match$1[0],
                                                          placeholder: "Search for a Billing Contact"
                                                        }))
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsx(Button, {
                                        loading: loading,
                                        onClick: (function (param) {
                                            Curry._1(onClick, undefined);
                                          }),
                                        disabled: loading,
                                        children: "Change Billing Contact"
                                      })
                                ]
                              }))
                    }),
                JsxRuntime.jsx(PreviewModal$Thick.make, {
                      billPayCurrent: props.billPayCurrent,
                      billPayNew: match$5[0],
                      applyDiff: applyDiff,
                      title: "Change Billing Contact",
                      description: "You are proposing to change the billing contact on this booking" + toBc + ".",
                      onClose: (function (param) {
                          Curry._1(setBillPayNew, (function (param) {
                                  
                                }));
                          Curry._1(setError, (function (param) {
                                  
                                }));
                          Curry._1(setPreviewModalOpen, (function (param) {
                                  return false;
                                }));
                          Curry._1(setBcAcct, (function (param) {
                                  return null;
                                }));
                        }),
                      open_: match$4[0],
                      versionUrl: props.versionUrl,
                      error: match$3[0]
                    })
              ]
            });
}

var make = ChangeBCModal;

exports.Query = Query;
exports.make = make;
/* react Not a pure module */
