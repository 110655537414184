// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_SetInt = require("rescript/lib/js/belt_SetInt.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var QueryString$Util = require("util/src/QueryString.bs.js");
var S$RescriptStruct = require("rescript-struct/src/S.bs.js");
var CustomScalars$Thick = require("./CustomScalars.bs.js");

var emptyDiff = {
  hasDiff: false,
  addBerths: undefined,
  dropBerths: undefined,
  changeBillingContact: undefined,
  detachPax: undefined,
  attachExistingPax: undefined,
  replacePaxExisting: undefined
};

function toUnnested(param) {
  return {
          addBerthsIfAny: Belt_Option.getWithDefault(param.addBerths, 0),
          dropBerthsIfAny: Belt_Option.mapWithDefault(param.dropBerths, undefined, Belt_SetInt.fromArray),
          changeBillingContactIfAny: param.changeBillingContact,
          detachPaxIfAny: Belt_Option.mapWithDefault(param.detachPax, undefined, Belt_SetInt.fromArray),
          attachExistingPaxIfAny: Belt_Option.getWithDefault(param.attachExistingPax, undefined),
          replacePaxExistingIfAny: Belt_Option.getWithDefault(param.replacePaxExisting, undefined)
        };
}

function toNested(param) {
  var replacePaxExistingIfAny = param.replacePaxExistingIfAny;
  var attachExistingPaxIfAny = param.attachExistingPaxIfAny;
  var detachPaxIfAny = param.detachPaxIfAny;
  var changeBillingContactIfAny = param.changeBillingContactIfAny;
  var dropBerthsIfAny = param.dropBerthsIfAny;
  var addBerthsIfAny = param.addBerthsIfAny;
  var theDiff_addBerths = addBerthsIfAny < 1 ? undefined : addBerthsIfAny;
  var theDiff_dropBerths = Belt_SetInt.isEmpty(dropBerthsIfAny) ? undefined : Belt_SetInt.toArray(dropBerthsIfAny);
  var theDiff_detachPax = Belt_SetInt.isEmpty(detachPaxIfAny) ? undefined : Belt_SetInt.toArray(detachPaxIfAny);
  var theDiff_attachExistingPax = Belt_MapInt.isEmpty(attachExistingPaxIfAny) ? undefined : Caml_option.some(attachExistingPaxIfAny);
  var theDiff_replacePaxExisting = Belt_MapInt.isEmpty(replacePaxExistingIfAny) ? undefined : Caml_option.some(replacePaxExistingIfAny);
  var theDiff = {
    hasDiff: false,
    addBerths: theDiff_addBerths,
    dropBerths: theDiff_dropBerths,
    changeBillingContact: changeBillingContactIfAny,
    detachPax: theDiff_detachPax,
    attachExistingPax: theDiff_attachExistingPax,
    replacePaxExisting: theDiff_replacePaxExisting
  };
  if (Caml_obj.equal(theDiff, emptyDiff)) {
    return emptyDiff;
  } else {
    return {
            hasDiff: true,
            addBerths: theDiff_addBerths,
            dropBerths: theDiff_dropBerths,
            changeBillingContact: changeBillingContactIfAny,
            detachPax: theDiff_detachPax,
            attachExistingPax: theDiff_attachExistingPax,
            replacePaxExisting: theDiff_replacePaxExisting
          };
  }
}

function changeBillingContact(nestedDiff, newBc) {
  return {
          hasDiff: nestedDiff.hasDiff,
          addBerths: nestedDiff.addBerths,
          dropBerths: nestedDiff.dropBerths,
          changeBillingContact: newBc,
          detachPax: nestedDiff.detachPax,
          attachExistingPax: nestedDiff.attachExistingPax,
          replacePaxExisting: nestedDiff.replacePaxExisting
        };
}

function attachAPax(nestedDiff, attachAtIndex, attachAccountWithId) {
  var theDiff = toUnnested(nestedDiff);
  var detachPaxIfAny = theDiff.detachPaxIfAny;
  return toNested(Belt_SetInt.has(detachPaxIfAny, attachAtIndex) ? ({
                  addBerthsIfAny: theDiff.addBerthsIfAny,
                  dropBerthsIfAny: theDiff.dropBerthsIfAny,
                  changeBillingContactIfAny: theDiff.changeBillingContactIfAny,
                  detachPaxIfAny: Belt_SetInt.remove(detachPaxIfAny, attachAtIndex),
                  attachExistingPaxIfAny: theDiff.attachExistingPaxIfAny,
                  replacePaxExistingIfAny: Belt_MapInt.set(theDiff.replacePaxExistingIfAny, attachAtIndex, attachAccountWithId)
                }) : ({
                  addBerthsIfAny: theDiff.addBerthsIfAny,
                  dropBerthsIfAny: theDiff.dropBerthsIfAny,
                  changeBillingContactIfAny: theDiff.changeBillingContactIfAny,
                  detachPaxIfAny: theDiff.detachPaxIfAny,
                  attachExistingPaxIfAny: Belt_MapInt.set(theDiff.attachExistingPaxIfAny, attachAtIndex, attachAccountWithId),
                  replacePaxExistingIfAny: theDiff.replacePaxExistingIfAny
                }));
}

function replaceAPax(nestedDiff, replaceAtIndex, replaceWithAccountWithId) {
  var theDiff = toUnnested(nestedDiff);
  var attachExistingPaxIfAny = theDiff.attachExistingPaxIfAny;
  return toNested(Belt_MapInt.has(attachExistingPaxIfAny, replaceAtIndex) ? ({
                  addBerthsIfAny: theDiff.addBerthsIfAny,
                  dropBerthsIfAny: theDiff.dropBerthsIfAny,
                  changeBillingContactIfAny: theDiff.changeBillingContactIfAny,
                  detachPaxIfAny: theDiff.detachPaxIfAny,
                  attachExistingPaxIfAny: Belt_MapInt.set(attachExistingPaxIfAny, replaceAtIndex, replaceWithAccountWithId),
                  replacePaxExistingIfAny: theDiff.replacePaxExistingIfAny
                }) : ({
                  addBerthsIfAny: theDiff.addBerthsIfAny,
                  dropBerthsIfAny: theDiff.dropBerthsIfAny,
                  changeBillingContactIfAny: theDiff.changeBillingContactIfAny,
                  detachPaxIfAny: theDiff.detachPaxIfAny,
                  attachExistingPaxIfAny: theDiff.attachExistingPaxIfAny,
                  replacePaxExistingIfAny: Belt_MapInt.set(theDiff.replacePaxExistingIfAny, replaceAtIndex, replaceWithAccountWithId)
                }));
}

function addSingleBerth(nestedDiff) {
  var theDiff = toUnnested(nestedDiff);
  var dropBerthsIfAny = theDiff.dropBerthsIfAny;
  var higestDroppingIdx = Belt_SetInt.maximum(dropBerthsIfAny);
  return toNested(higestDroppingIdx !== undefined ? ({
                  addBerthsIfAny: theDiff.addBerthsIfAny,
                  dropBerthsIfAny: Belt_SetInt.remove(dropBerthsIfAny, higestDroppingIdx),
                  changeBillingContactIfAny: theDiff.changeBillingContactIfAny,
                  detachPaxIfAny: Belt_SetInt.add(theDiff.detachPaxIfAny, higestDroppingIdx),
                  attachExistingPaxIfAny: theDiff.attachExistingPaxIfAny,
                  replacePaxExistingIfAny: theDiff.replacePaxExistingIfAny
                }) : ({
                  addBerthsIfAny: theDiff.addBerthsIfAny + 1 | 0,
                  dropBerthsIfAny: theDiff.dropBerthsIfAny,
                  changeBillingContactIfAny: theDiff.changeBillingContactIfAny,
                  detachPaxIfAny: theDiff.detachPaxIfAny,
                  attachExistingPaxIfAny: theDiff.attachExistingPaxIfAny,
                  replacePaxExistingIfAny: theDiff.replacePaxExistingIfAny
                }));
}

function dropSingleBerth(nestedDiff, newIdxToDrop) {
  var theDiff = toUnnested(nestedDiff);
  var addBerthsIfAny = theDiff.addBerthsIfAny;
  var proposedBerthsToDrop = Belt_SetInt.add(theDiff.dropBerthsIfAny, newIdxToDrop);
  return toNested(addBerthsIfAny > 0 ? ({
                  addBerthsIfAny: addBerthsIfAny - 1 | 0,
                  dropBerthsIfAny: theDiff.dropBerthsIfAny,
                  changeBillingContactIfAny: theDiff.changeBillingContactIfAny,
                  detachPaxIfAny: Belt_SetInt.remove(theDiff.detachPaxIfAny, newIdxToDrop),
                  attachExistingPaxIfAny: Belt_MapInt.remove(theDiff.attachExistingPaxIfAny, newIdxToDrop),
                  replacePaxExistingIfAny: theDiff.replacePaxExistingIfAny
                }) : ({
                  addBerthsIfAny: theDiff.addBerthsIfAny,
                  dropBerthsIfAny: proposedBerthsToDrop,
                  changeBillingContactIfAny: theDiff.changeBillingContactIfAny,
                  detachPaxIfAny: theDiff.detachPaxIfAny,
                  attachExistingPaxIfAny: theDiff.attachExistingPaxIfAny,
                  replacePaxExistingIfAny: Belt_MapInt.remove(theDiff.replacePaxExistingIfAny, newIdxToDrop)
                }));
}

function detachSingleBerth(nestedDiff, indexToDetach) {
  var theDiff = toUnnested(nestedDiff);
  var replacePaxExistingIfAny = theDiff.replacePaxExistingIfAny;
  var attachExistingPaxIfAny = theDiff.attachExistingPaxIfAny;
  return toNested(Belt_MapInt.has(attachExistingPaxIfAny, indexToDetach) || Belt_MapInt.has(replacePaxExistingIfAny, indexToDetach) ? ({
                  addBerthsIfAny: theDiff.addBerthsIfAny,
                  dropBerthsIfAny: theDiff.dropBerthsIfAny,
                  changeBillingContactIfAny: theDiff.changeBillingContactIfAny,
                  detachPaxIfAny: theDiff.detachPaxIfAny,
                  attachExistingPaxIfAny: Belt_MapInt.remove(attachExistingPaxIfAny, indexToDetach),
                  replacePaxExistingIfAny: Belt_MapInt.remove(replacePaxExistingIfAny, indexToDetach)
                }) : ({
                  addBerthsIfAny: theDiff.addBerthsIfAny,
                  dropBerthsIfAny: theDiff.dropBerthsIfAny,
                  changeBillingContactIfAny: theDiff.changeBillingContactIfAny,
                  detachPaxIfAny: Belt_SetInt.add(theDiff.detachPaxIfAny, indexToDetach),
                  attachExistingPaxIfAny: theDiff.attachExistingPaxIfAny,
                  replacePaxExistingIfAny: theDiff.replacePaxExistingIfAny
                }));
}

var Alter = {
  changeBillingContact: changeBillingContact,
  attachAPax: attachAPax,
  replaceAPax: replaceAPax,
  addSingleBerth: addSingleBerth,
  dropSingleBerth: dropSingleBerth,
  detachSingleBerth: detachSingleBerth
};

function fieldToString(diffField) {
  return diffField;
}

var acctId = S$RescriptStruct.transform(S$RescriptStruct.string(undefined), (function (v) {
        return CustomScalars$Thick.AccountId.parse(v);
      }), undefined, (function (v) {
        return CustomScalars$Thick.AccountId.serialize(v);
      }), undefined);

var bookingDiffStruct = QueryString$Util.Parser.make((function (dest) {
        if (Caml_obj.equal(dest, emptyDiff)) {
          return emptyDiff;
        } else {
          return {
                  hasDiff: true,
                  addBerths: dest.addBerths,
                  dropBerths: dest.dropBerths,
                  changeBillingContact: dest.changeBillingContact,
                  detachPax: dest.detachPax,
                  attachExistingPax: dest.attachExistingPax,
                  replacePaxExisting: dest.replacePaxExisting
                };
        }
      }), [
      Curry._6(QueryString$Util.Parameter.Scalar.$$Array.make, "dp.dropBerths", fieldToString, QueryString$Util.PositiveInt.struct, false, (function (diff, dropBerths) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: diff.addBerths,
                      dropBerths: dropBerths,
                      changeBillingContact: diff.changeBillingContact,
                      detachPax: diff.detachPax,
                      attachExistingPax: diff.attachExistingPax,
                      replacePaxExisting: diff.replacePaxExisting
                    };
            }), (function (diff) {
              return diff.dropBerths;
            })),
      Curry._6(QueryString$Util.Parameter.Scalar.make, "dp.addBerths", fieldToString, QueryString$Util.PositiveInt.struct, false, (function (diff, addBerths) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: addBerths,
                      dropBerths: diff.dropBerths,
                      changeBillingContact: diff.changeBillingContact,
                      detachPax: diff.detachPax,
                      attachExistingPax: diff.attachExistingPax,
                      replacePaxExisting: diff.replacePaxExisting
                    };
            }), (function (diff) {
              return diff.addBerths;
            })),
      Curry._6(QueryString$Util.Parameter.Scalar.make, "dp.changeBc", fieldToString, acctId, false, (function (diff, changeBillingContact) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: diff.addBerths,
                      dropBerths: diff.dropBerths,
                      changeBillingContact: changeBillingContact,
                      detachPax: diff.detachPax,
                      attachExistingPax: diff.attachExistingPax,
                      replacePaxExisting: diff.replacePaxExisting
                    };
            }), (function (diff) {
              return diff.changeBillingContact;
            })),
      Curry._6(QueryString$Util.Parameter.Scalar.$$Array.make, "dp.detachPax", fieldToString, QueryString$Util.PositiveInt.struct, false, (function (diff, detachPax) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: diff.addBerths,
                      dropBerths: diff.dropBerths,
                      changeBillingContact: diff.changeBillingContact,
                      detachPax: detachPax,
                      attachExistingPax: diff.attachExistingPax,
                      replacePaxExisting: diff.replacePaxExisting
                    };
            }), (function (diff) {
              return diff.detachPax;
            })),
      Curry._7(QueryString$Util.Parameter.Tuple.IntMap.make, "dp.attachExistingPax", fieldToString, S$RescriptStruct.tuple2(QueryString$Util.PositiveInt.struct, acctId), (function (diff, attachExistingPax) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: diff.addBerths,
                      dropBerths: diff.dropBerths,
                      changeBillingContact: diff.changeBillingContact,
                      detachPax: diff.detachPax,
                      attachExistingPax: attachExistingPax,
                      replacePaxExisting: diff.replacePaxExisting
                    };
            }), (function (diff) {
              return diff.attachExistingPax;
            }), (function (v) {
              return v;
            }), (function (v) {
              return v;
            })),
      Curry._7(QueryString$Util.Parameter.Tuple.IntMap.make, "dp.replacePaxExisting", fieldToString, S$RescriptStruct.tuple2(QueryString$Util.PositiveInt.struct, acctId), (function (diff, replacePaxExisting) {
              return {
                      hasDiff: diff.hasDiff,
                      addBerths: diff.addBerths,
                      dropBerths: diff.dropBerths,
                      changeBillingContact: diff.changeBillingContact,
                      detachPax: diff.detachPax,
                      attachExistingPax: diff.attachExistingPax,
                      replacePaxExisting: replacePaxExisting
                    };
            }), (function (diff) {
              return diff.replacePaxExisting;
            }), (function (v) {
              return v;
            }), (function (v) {
              return v;
            }))
    ], emptyDiff);

function parse(queryString) {
  return QueryString$Util.Parser.parse(bookingDiffStruct, queryString);
}

function serialize(queryString) {
  return QueryString$Util.Parser.serialize(bookingDiffStruct, queryString);
}

var $$String;

var Int;

var $$JSON;

exports.$$String = $$String;
exports.Int = Int;
exports.$$JSON = $$JSON;
exports.emptyDiff = emptyDiff;
exports.toUnnested = toUnnested;
exports.toNested = toNested;
exports.Alter = Alter;
exports.fieldToString = fieldToString;
exports.acctId = acctId;
exports.bookingDiffStruct = bookingDiffStruct;
exports.parse = parse;
exports.serialize = serialize;
/* acctId Not a pure module */
