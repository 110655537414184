// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../common/molecules/Dialog.bs.js");
var Input = require("@mui/joy/Input").default;
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var CustomScalars$Thick = require("../../utils/CustomScalars.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var RoleGrantsView$Thick = require("../dataview/RoleGrantsView.bs.js");
var AlterUserSubmit$Thick = require("./mutations/AlterUserSubmit.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var VoyageGrantsView$Thick = require("../dataview/VoyageGrantsView.bs.js");
var FormHelperText = require("@mui/joy/FormHelperText").default;
var AlterUserForm_user_graphql$Thick = require("../../__generated__/AlterUserForm_user_graphql.bs.js");

var convertFragment = AlterUserForm_user_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(AlterUserForm_user_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AlterUserForm_user_graphql$Thick.node, convertFragment);
}

var UserFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function AlterUserForm(props) {
  var onClose = props.onClose;
  var user = RescriptRelay_Fragment.useFragment(AlterUserForm_user_graphql$Thick.node, convertFragment, props.fragment);
  var match = AlterUserSubmit$Thick.useMutation(user.email, user.brandFamilySlug, [], [], props.refreshTeam, onClose);
  var setError = match.setError;
  var error = match.error;
  return JsxRuntime.jsxs(Dialog$Thick.make, {
              open_: props.isOpen,
              onClose: (function (param) {
                  Curry._1(onClose, undefined);
                  Curry._1(setError, (function (param) {
                          
                        }));
                }),
              title: "Manage User",
              children: [
                JsxRuntime.jsx(Typography, {
                      textColor: "text.tertiary",
                      children: "Manage a user's permissions."
                    }),
                JsxRuntime.jsx(Typography, {
                      component: "h3",
                      children: "User Information"
                    }),
                JsxRuntime.jsxs(FormControl, {
                      children: [
                        JsxRuntime.jsx(FormLabel, {
                              children: "Email"
                            }),
                        JsxRuntime.jsx(Input, {
                              disabled: true,
                              type: "email",
                              value: CustomScalars$Thick.Email.serialize(user.email)
                            }),
                        JsxRuntime.jsx(FormHelperText, {
                              children: "User to be updated"
                            })
                      ]
                    }),
                JsxRuntime.jsxs(Stack, {
                      component: "form",
                      spacing: 2,
                      onSubmit: match.onSubmit,
                      children: [
                        JsxRuntime.jsx(Typography, {
                              component: "h3",
                              children: "Permissions"
                            }),
                        JsxRuntime.jsx(RoleGrantsView$Thick.Autocomplete.make, {
                              fragment: user.userRoleGrants.fragmentRefs,
                              setSelectedRoles: match.setSelectedRoles,
                              selectedRoles: match.selectedRoles,
                              disabled: false
                            }),
                        JsxRuntime.jsx(VoyageGrantsView$Thick.Autocomplete.make, {
                              fragment: user.userVoyageGrants.fragmentRefs,
                              setSelectedVoyages: match.setSelectedVoyages,
                              selectedVoyages: match.selectedVoyages,
                              disabled: false
                            }),
                        JsxRuntime.jsx(FormControl, {
                              children: Caml_option.some(JsxRuntime.jsx(Button, {
                                        loading: match.isMutating,
                                        type: "submit",
                                        children: "Update User"
                                      }))
                            })
                      ]
                    }),
                error !== undefined ? JsxRuntime.jsx(Typography, {
                        color: "danger",
                        children: Caml_option.some(error)
                      }) : null
              ]
            });
}

var make = AlterUserForm;

exports.UserFragment = UserFragment;
exports.make = make;
/* Dialog-Thick Not a pure module */
