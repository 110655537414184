// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var BDContentBlock$Thick = require("./molecules/BDContentBlock.bs.js");

function BDWorkflows(props) {
  return JsxRuntime.jsx(BDContentBlock$Thick.make, {
              title: "Workflows",
              children: JsxRuntime.jsx(Typography, {
                    level: "body-sm",
                    children: "Workflows go here"
                  })
            });
}

var make = BDWorkflows;

exports.make = make;
/* react/jsx-runtime Not a pure module */
