// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var RelayEnv$Thick = require("./RelayEnv.bs.js");
var ThickApp$Thick = require("./ThickApp.bs.js");
var Client = require("react-dom/client");
var JsxRuntime = require("react/jsx-runtime");
var BasePathJs = require("@shoelace-style/shoelace/dist/utilities/base-path.js");

BasePathJs.setBasePath("https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.5.2/dist/");

var rootElement = document.querySelector("#root");

if (!(rootElement == null)) {
  var root = Client.createRoot(rootElement);
  root.render(JsxRuntime.jsx(RescriptRelay.Context.Provider.make, {
            environment: RelayEnv$Thick.environment,
            children: JsxRuntime.jsx(ThickApp$Thick.make, {})
          }));
}

/*  Not a pure module */
