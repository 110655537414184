// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"email":{"c":"CustomScalars.Email"},"custodianAccountId":{"c":"CustomScalars.AccountId"},"accountId":{"c":"CustomScalars.AccountId"}}};

var fragmentConverterMap = {
  "CustomScalars.AccountId": CustomScalars$Thick.AccountId.parse,
  "CustomScalars.Email": CustomScalars$Thick.Email.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function accountStatus_decode($$enum) {
  if ($$enum === "CLAIMED" || $$enum === "UNCLAIMED" || $$enum === "INVITED") {
    return $$enum;
  }
  
}

var accountStatus_fromString = accountStatus_decode;

var Utils = {
  accountStatus_decode: accountStatus_decode,
  accountStatus_fromString: accountStatus_fromString
};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerTable_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "custodianAccountId",
      "storageKey": null
    }
  ],
  "type": "BfcustUser",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* RescriptRelay Not a pure module */
