// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var TeamPageQuery_graphql$Thick = require("./TeamPageQuery_graphql.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"team_nodes":{"f":""},"pending_nodes":{"f":""},"":{"f":""}}};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, undefined, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: undefined,
  convertFragment: convertFragment
};

function brandfamRole_decode($$enum) {
  if ($$enum === "BRAND_FAMILY_ADMIN" || $$enum === "INVENTORY_MANAGER" || $$enum === "CUSTOMER_SERVICE") {
    return $$enum;
  }
  
}

var brandfamRole_fromString = brandfamRole_decode;

function signonStatus_decode($$enum) {
  if ($$enum === "PENDING_2FA" || $$enum === "ANONYMOUS" || $$enum === "CLEAR_JWT" || $$enum === "SIGNED_ON" || $$enum === "INVITED") {
    return $$enum;
  }
  
}

var signonStatus_fromString = signonStatus_decode;

var Utils = {
  brandfamRole_decode: brandfamRole_decode,
  brandfamRole_fromString: brandfamRole_fromString,
  signonStatus_decode: signonStatus_decode,
  signonStatus_fromString: signonStatus_fromString
};

function makeNode(rescript_graphql_node_TeamPageQuery) {
  return ((function(){
var v0 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "brandFamilySlug",
      "variableName": "brandFamily"
    }
  ],
  "kind": "ObjectValue",
  "name": "condition"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "TeamPage_user"
},
v3 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "AlterUserForm_user"
},
v4 = [
  {
    "kind": "Variable",
    "name": "brandFamily",
    "variableName": "brandFamily"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "brandFamily"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": rescript_graphql_node_TeamPageQuery
    }
  },
  "name": "TeamPage_query",
  "selections": [
    {
      "alias": "team",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "signonStatus": {
              "notEqualTo": "INVITED"
            }
          }
        }
      ],
      "concreteType": "BrandFamilyUsersConnection",
      "kind": "LinkedField",
      "name": "brandFamilyUsers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandFamilyUser",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "signonStatus",
              "storageKey": null
            },
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "pending",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "signonStatus": {
              "equalTo": "INVITED"
            }
          }
        }
      ],
      "concreteType": "BrandFamilyUsersConnection",
      "kind": "LinkedField",
      "name": "brandFamilyUsers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandFamilyUser",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        (v0/*: any*/)
      ],
      "concreteType": "CurrentUserProfilesConnection",
      "kind": "LinkedField",
      "name": "currentUserProfiles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CurrentUserProfile",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "brandFamilyRoles",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": (v4/*: any*/),
      "kind": "FragmentSpread",
      "name": "BrandPermProtected_query"
    },
    {
      "args": (v4/*: any*/),
      "kind": "FragmentSpread",
      "name": "BFGrantWorkflows_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})());
}

var node = makeNode(TeamPageQuery_graphql$Thick.node);

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
