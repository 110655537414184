// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Spinner$Thick = require("../components/common/atoms/Spinner.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var DashboardLayout$Thick = require("../layouts/DashboardLayout.bs.js");
var OrgProtectedRoute$Thick = require("../components/wrappers/OrgProtectedRoute.bs.js");
var BrandPermProtected$Thick = require("../components/wrappers/BrandPermProtected.bs.js");
var CustomerAccountsPage$Thick = require("../pages/CustomerAccountsPage.bs.js");
var CustomerAccountsRouteQuery_graphql$Thick = require("../__generated__/CustomerAccountsRouteQuery_graphql.bs.js");

var convertVariables = CustomerAccountsRouteQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = CustomerAccountsRouteQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = CustomerAccountsRouteQuery_graphql$Thick.Internal.convertWrapRawResponse;

function use(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.useQuery(convertVariables, CustomerAccountsRouteQuery_graphql$Thick.node, convertResponse, param, param$1, param$2, param$3, param$4);
}

function useLoader(param) {
  return RescriptRelay_Query.useLoader(convertVariables, CustomerAccountsRouteQuery_graphql$Thick.node, (function (prim) {
                return prim;
              }), param);
}

function usePreloaded(param) {
  return RescriptRelay_Query.usePreloaded(CustomerAccountsRouteQuery_graphql$Thick.node, convertResponse, (function (prim) {
                return prim;
              }), param);
}

function $$fetch(param, param$1, param$2, param$3, param$4, param$5) {
  return RescriptRelay_Query.$$fetch(CustomerAccountsRouteQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4, param$5);
}

function fetchPromised(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.fetchPromised(CustomerAccountsRouteQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4);
}

function retain(param, param$1) {
  return RescriptRelay_Query.retain(CustomerAccountsRouteQuery_graphql$Thick.node, convertVariables, param, param$1);
}

var Query_accountStatus_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.accountStatus_decode;

var Query_accountStatus_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.accountStatus_fromString;

var Query_custSignonStatus_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.custSignonStatus_decode;

var Query_custSignonStatus_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.custSignonStatus_fromString;

var Query_docGender_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.docGender_decode;

var Query_docGender_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.docGender_fromString;

var Query_lifecycleStatus_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.lifecycleStatus_decode;

var Query_lifecycleStatus_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.lifecycleStatus_fromString;

var Query_paxDataStatus_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.paxDataStatus_decode;

var Query_paxDataStatus_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.paxDataStatus_fromString;

var Query_pmtDirection_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.pmtDirection_decode;

var Query_pmtDirection_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.pmtDirection_fromString;

var Query_pmtLifecycleStatus_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var Query_pmtLifecycleStatus_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var Query_pmtProcessor_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.pmtProcessor_decode;

var Query_pmtProcessor_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.pmtProcessor_fromString;

var Query_subtotalCategory_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.subtotalCategory_decode;

var Query_subtotalCategory_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.subtotalCategory_fromString;

var Query = {
  accountStatus_decode: Query_accountStatus_decode,
  accountStatus_fromString: Query_accountStatus_fromString,
  custSignonStatus_decode: Query_custSignonStatus_decode,
  custSignonStatus_fromString: Query_custSignonStatus_fromString,
  docGender_decode: Query_docGender_decode,
  docGender_fromString: Query_docGender_fromString,
  lifecycleStatus_decode: Query_lifecycleStatus_decode,
  lifecycleStatus_fromString: Query_lifecycleStatus_fromString,
  paxDataStatus_decode: Query_paxDataStatus_decode,
  paxDataStatus_fromString: Query_paxDataStatus_fromString,
  pmtDirection_decode: Query_pmtDirection_decode,
  pmtDirection_fromString: Query_pmtDirection_fromString,
  pmtLifecycleStatus_decode: Query_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: Query_pmtLifecycleStatus_fromString,
  pmtProcessor_decode: Query_pmtProcessor_decode,
  pmtProcessor_fromString: Query_pmtProcessor_fromString,
  subtotalCategory_decode: Query_subtotalCategory_decode,
  subtotalCategory_fromString: Query_subtotalCategory_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function CustomerAccountsRoute(props) {
  var brandFamily = props.brandFamily;
  var filter_brandFamilySlug = {
    equalTo: CustomScalars$Thick.BrandFamilySlug.serialize(brandFamily)
  };
  var filter_email = {
    notIn: []
  };
  var filter = {
    orgSlug: undefined,
    brandFamilySlug: filter_brandFamilySlug,
    accountId: undefined,
    email: filter_email,
    signonStatus: undefined,
    firstName: undefined,
    lastName: undefined,
    fullName: undefined,
    accountStatus: undefined,
    custodianAccountId: undefined,
    emailString: undefined,
    legalDocumentFirstName: undefined,
    legalDocumentLastName: undefined,
    legalDocumentGender: undefined,
    legalDocumentBirthdate: undefined,
    legalDocumentCitizenship: undefined,
    billingContactForBooking: undefined,
    billingContactForBookingExist: undefined,
    payment: undefined,
    paymentExist: undefined,
    bookingVersions: undefined,
    bookingVersionsExist: undefined,
    passenger_record: undefined,
    passenger_recordExist: undefined,
    doc_citizenshipCountry: undefined,
    doc_citizenshipCountryExists: undefined,
    and: undefined,
    or: undefined,
    not: undefined
  };
  var data = use({
        brandFamily: brandFamily,
        filter: filter
      }, undefined, undefined, undefined, undefined);
  var fragmentRefs = data.fragmentRefs;
  return JsxRuntime.jsx(DashboardLayout$Thick.make, {
              fragmentRefs: fragmentRefs,
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(OrgProtectedRoute$Thick.make, {
                              fragmentRefs: fragmentRefs,
                              children: JsxRuntime.jsx(BrandPermProtected$Thick.make, {
                                    fragmentRefs: fragmentRefs,
                                    neededPerm: "VIEW_CUSTOMER_DETAILS",
                                    children: JsxRuntime.jsx(CustomerAccountsPage$Thick.make, {
                                          fragmentRefs: fragmentRefs,
                                          brandFamily: brandFamily
                                        }),
                                    isRoute: true,
                                    redirect: "/" + CustomScalars$Thick.BrandFamilySlug.serialize(brandFamily) + ""
                                  })
                            })),
                    fallback: Caml_option.some(JsxRuntime.jsx(Spinner$Thick.make, {}))
                  }),
              brandFamily: CustomScalars$Thick.BrandFamilySlug.serialize(brandFamily)
            });
}

var make = CustomerAccountsRoute;

exports.Query = Query;
exports.make = make;
/* react Not a pure module */
