// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");

var Types = {};

var variablesConverter = {"paxApplicationInput":{"idx":{"b":""},"accountId":{"c":"CustomScalars.AccountId"}},"bookingDiffInput":{"replacePaxExisting":{"r":"paxApplicationInput"},"dropBerths":{"b":"a"},"detachPax":{"b":"a"},"changeBillingContact":{"c":"CustomScalars.AccountId"},"attachExistingPax":{"r":"paxApplicationInput"},"addBerths":{"b":""}},"applyBookingChangesInput":{"_voyageSlug":{"c":"CustomScalars.VoyageSlug"},"_bookingVersionNumber":{"b":""},"_bookingId":{"c":"CustomScalars.BookingId"},"_bookingDiff":{"r":"bookingDiffInput"}},"__root":{"input":{"r":"applyBookingChangesInput"}}};

var variablesConverterMap = {
  "CustomScalars.BookingId": CustomScalars$Thick.BookingId.serialize,
  "CustomScalars.VoyageSlug": CustomScalars$Thick.VoyageSlug.serialize,
  "CustomScalars.AccountId": CustomScalars$Thick.AccountId.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"applyBookingChanges_applyDiffResult_bookingVersionNumber":{"b":""}}};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"applyBookingChanges_applyDiffResult_bookingVersionNumber":{"b":""}}};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ApplyBookingChangesPayload",
    "kind": "LinkedField",
    "name": "applyBookingChanges",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ApplyDiffResult",
        "kind": "LinkedField",
        "name": "applyDiffResult",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "success",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bookingVersionNumber",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ApplyDraft_ApplyBookingChangesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ApplyDraft_ApplyBookingChangesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "834b1a6554c62e77f7e9c173ddad787c",
    "id": null,
    "metadata": {},
    "name": "ApplyDraft_ApplyBookingChangesMutation",
    "operationKind": "mutation",
    "text": "mutation ApplyDraft_ApplyBookingChangesMutation(\n  $input: ApplyBookingChangesInput!\n) {\n  applyBookingChanges(input: $input) {\n    applyDiffResult {\n      success\n      bookingVersionNumber\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
