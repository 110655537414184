// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ApplyDraft_ApplyBookingChangesMutation_graphql$Thick = require("../../../../__generated__/ApplyDraft_ApplyBookingChangesMutation_graphql.bs.js");

var convertVariables = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables, ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables, ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param);
}

var ApplyBookingChangesMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(applyArgs, redirect) {
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var match$1 = use(undefined);
  var mutate = match$1[0];
  var onSubmit = function (e) {
    e.preventDefault();
    Curry._1(setError, (function (param) {
            
          }));
    Curry._8(mutate, {
          input: {
            _voyageSlug: applyArgs.voyageSlug,
            _bookingId: applyArgs.bookingId,
            _bookingVersionNumber: applyArgs.bookingVersionNumber,
            _bookingDiff: applyArgs.bookingDiff
          }
        }, undefined, undefined, (function (store, param) {
            store.invalidateStore();
          }), (function (resp, err) {
            if (err === undefined) {
              var match = resp.applyBookingChanges;
              if (match !== undefined) {
                var match$1 = match.applyDiffResult;
                if (match$1 !== undefined) {
                  var num = match$1.bookingVersionNumber;
                  if (num !== undefined) {
                    var match$2 = match$1.success;
                    if (match$2 !== undefined && match$2) {
                      var url = redirect + "/" + String(num);
                      RescriptReactRouter.push(url);
                      return ;
                    }
                    
                  }
                  
                }
                
              }
              Curry._1(setError, (function (param) {
                      return "Failed to apply changes";
                    }));
              return ;
            }
            Curry._1(setError, (function (param) {
                    return "Failed to apply changes";
                  }));
          }), (function (err) {
            Curry._1(setError, (function (param) {
                    return "Something went wrong, please try again.";
                  }));
          }), undefined, undefined);
  };
  return {
          isMutating: match$1[1],
          error: match[0],
          onSubmit: onSubmit
        };
}

exports.ApplyBookingChangesMutation = ApplyBookingChangesMutation;
exports.useMutation = useMutation;
/* react Not a pure module */
