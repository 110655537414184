// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var Menu = require("@mui/joy/Menu").default;
var Dropdown = require("@mui/joy/Dropdown").default;
var JsxRuntime = require("react/jsx-runtime");
var MenuButton = require("@mui/joy/MenuButton").default;
var Typography = require("@mui/joy/Typography").default;
var KeyboardArrowDown = require("@mui/icons-material/KeyboardArrowDown").default;

function BDMenu(props) {
  var disabled = props.disabled;
  var disabled$1 = disabled !== undefined ? disabled : false;
  var match = React.useState(function () {
        return false;
      });
  var setIsOpen = match[1];
  var isOpen = match[0];
  return JsxRuntime.jsxs(Dropdown, {
              children: [
                JsxRuntime.jsx(MenuButton, {
                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                level: "body-sm",
                                sx: {
                                  color: "text.primary"
                                },
                                fontWeight: "400",
                                children: Caml_option.some(props.text)
                              })),
                      endDecorator: Caml_option.some(JsxRuntime.jsx(Box, {
                                children: Caml_option.some(JsxRuntime.jsx(KeyboardArrowDown, {
                                          sx: {
                                            color: "text.secondary",
                                            transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)"
                                          }
                                        })),
                                sx: {
                                  color: "text.primary",
                                  position: "relative",
                                  top: "1px"
                                }
                              })),
                      sx: {
                        boxShadow: "1px 1px 2px 0px #E5E5E5",
                        border: "1px solid",
                        borderColor: "#100D0C4D",
                        borderRadius: "2px",
                        backgroundColor: disabled$1 === true ? "primary.solidDisabledBg" : "common.white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingInline: "0.5rem",
                        opacity: disabled$1 ? "0.5" : "1",
                        "&:hover": {
                          backgroundColor: disabled$1 === true ? "" : "#E5E5E5",
                          cursor: disabled$1 === true ? "inherit" : "pointer"
                        },
                        "&:active": {
                          backgroundColor: disabled$1 === true ? "primary.solidDisabledBg" : ""
                        },
                        "& .MuiButton-endDecorator": {
                          marginLeft: "0.35rem"
                        }
                      }
                    }),
                JsxRuntime.jsx(Menu, {
                      children: Caml_option.some(props.children),
                      sx: {
                        borderRadius: "4px"
                      }
                    })
              ],
              open: isOpen,
              onOpenChange: (function (param) {
                  if (disabled$1) {
                    return ;
                  } else {
                    return Curry._1(setIsOpen, (function (param) {
                                  return !isOpen;
                                }));
                  }
                })
            });
}

var make = BDMenu;

exports.make = make;
/* react Not a pure module */
