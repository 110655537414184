// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

var Types = {};

var fragmentConverter = {};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, undefined, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: undefined,
  convertFragment: convertFragment
};

function brandfamPermission_decode($$enum) {
  if ($$enum === "VIEW_MANIFEST" || $$enum === "VIEW_ALL_USERS" || $$enum === "ADD_USER" || $$enum === "VIEW_ALL_VOYAGES" || $$enum === "VIEW_BOOKINGS" || $$enum === "MANAGE_BOOKINGS" || $$enum === "VIEW_PRICING" || $$enum === "GRANT_REVOKE" || $$enum === "VIEW_CUSTOMER_DETAILS" || $$enum === "MANAGE_CABIN_INVENTORY" || $$enum === "VIEW_VOYAGE_META" || $$enum === "MANAGE_USER" || $$enum === "MANAGE_PRICING" || $$enum === "MANAGE_BF_CUSTOMERS" || $$enum === "REQUEST_BRAND_FAMILY_GRANT" || $$enum === "WORKFLOW_PARTICIPANT" || $$enum === "VIEW_CABIN_INVENTORY") {
    return $$enum;
  }
  
}

var brandfamPermission_fromString = brandfamPermission_decode;

function signonStatus_decode($$enum) {
  if ($$enum === "PENDING_2FA" || $$enum === "ANONYMOUS" || $$enum === "CLEAR_JWT" || $$enum === "SIGNED_ON" || $$enum === "INVITED") {
    return $$enum;
  }
  
}

var signonStatus_fromString = signonStatus_decode;

var Utils = {
  brandfamPermission_decode: brandfamPermission_decode,
  brandfamPermission_fromString: brandfamPermission_fromString,
  signonStatus_decode: signonStatus_decode,
  signonStatus_fromString: signonStatus_fromString
};

var node = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "brandFamily"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "BrandPermProtected_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "forBrandFamilySlug",
          "variableName": "brandFamily"
        }
      ],
      "kind": "ScalarField",
      "name": "currentUserCanForBrand",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentUserSignonStatus",
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* RescriptRelay Not a pure module */
