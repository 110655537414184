// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");

var Types = {};

var variablesConverter = {"changeCabinCapacityInput":{"onVoyage":{"c":"CustomScalars.VoyageSlug"},"onBrandFamily":{"c":"CustomScalars.BrandFamilySlug"},"maybeNewSalesMin":{"b":""},"maybeNewSalesMax":{"b":""},"forCabinNumbers":{"c":"CustomScalars.CabinNumber"}},"__root":{"input":{"r":"changeCabinCapacityInput"}}};

var variablesConverterMap = {
  "CustomScalars.CabinNumber": CustomScalars$Thick.CabinNumber.serialize,
  "CustomScalars.BrandFamilySlug": CustomScalars$Thick.BrandFamilySlug.serialize,
  "CustomScalars.VoyageSlug": CustomScalars$Thick.VoyageSlug.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangeCabinCapacityPayload",
    "kind": "LinkedField",
    "name": "changeCabinCapacity",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "boolean",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeCabinCapacitySubmit_ChangeCabinCapacityMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeCabinCapacitySubmit_ChangeCabinCapacityMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "159dcfa4cf32b52639688730baad495a",
    "id": null,
    "metadata": {},
    "name": "ChangeCabinCapacitySubmit_ChangeCabinCapacityMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeCabinCapacitySubmit_ChangeCabinCapacityMutation(\n  $input: ChangeCabinCapacityInput!\n) {\n  changeCabinCapacity(input: $input) {\n    boolean\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
