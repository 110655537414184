// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../utils/Common.bs.js");
var CustomScalars$Thick = require("../../utils/CustomScalars.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var BrandProtectedRoute_query_graphql$Thick = require("../../__generated__/BrandProtectedRoute_query_graphql.bs.js");

var convertFragment = BrandProtectedRoute_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BrandProtectedRoute_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BrandProtectedRoute_query_graphql$Thick.node, convertFragment);
}

var QueryFragment_brandfamRole_decode = BrandProtectedRoute_query_graphql$Thick.Utils.brandfamRole_decode;

var QueryFragment_brandfamRole_fromString = BrandProtectedRoute_query_graphql$Thick.Utils.brandfamRole_fromString;

var QueryFragment_signonStatus_decode = BrandProtectedRoute_query_graphql$Thick.Utils.signonStatus_decode;

var QueryFragment_signonStatus_fromString = BrandProtectedRoute_query_graphql$Thick.Utils.signonStatus_fromString;

var QueryFragment = {
  brandfamRole_decode: QueryFragment_brandfamRole_decode,
  brandfamRole_fromString: QueryFragment_brandfamRole_fromString,
  signonStatus_decode: QueryFragment_signonStatus_decode,
  signonStatus_fromString: QueryFragment_signonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function BrandProtectedRoute(props) {
  var brandFamily = props.brandFamily;
  var data = RescriptRelay_Fragment.useFragment(BrandProtectedRoute_query_graphql$Thick.node, convertFragment, props.fragmentRefs);
  var match = data.currentUserProfiles;
  var canAccess = match !== undefined ? Belt_Array.some(match.nodes, (function (node) {
            var slug = node.brandFamilySlug;
            if (slug !== undefined) {
              return Caml_obj.equal(Caml_option.valFromOption(slug), brandFamily);
            } else {
              return false;
            }
          })) : false;
  React.useEffect((function () {
          if (canAccess) {
            var navBF = CustomScalars$Thick.BrandFamilySlug.serialize(brandFamily);
            var defaultBrand = Common$Thick.LS.getSelectedBrand(undefined);
            if (defaultBrand !== undefined && defaultBrand === navBF) {
              
            } else {
              Common$Thick.LS.setSelectedBrand(navBF);
            }
          } else {
            console.log("Redirecting to dashboard");
            RescriptReactRouter.push("/dashboard");
          }
        }), [canAccess]);
  var match$1 = data.currentUserSignonStatus;
  if (canAccess && match$1 === "SIGNED_ON") {
    return props.children;
  } else {
    return null;
  }
}

var make = BrandProtectedRoute;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
