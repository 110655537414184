// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../utils/Common.bs.js");
var List = require("@mui/joy/List").default;
var Stack = require("@mui/joy/Stack").default;
var Tooltip = require("@mui/joy/Tooltip").default;
var ListItem = require("@mui/joy/ListItem").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var SquareRounded = require("@mui/icons-material/SquareRounded").default;
var BDVersionHistory_query_graphql$Thick = require("../../__generated__/BDVersionHistory_query_graphql.bs.js");
var KeyboardDoubleArrowRightOutlined = require("@mui/icons-material/KeyboardDoubleArrowRightOutlined").default;

var convertFragment = BDVersionHistory_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BDVersionHistory_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDVersionHistory_query_graphql$Thick.node, convertFragment);
}

var QueryFragment_lifecycleStatus_decode = BDVersionHistory_query_graphql$Thick.Utils.lifecycleStatus_decode;

var QueryFragment_lifecycleStatus_fromString = BDVersionHistory_query_graphql$Thick.Utils.lifecycleStatus_fromString;

var QueryFragment = {
  lifecycleStatus_decode: QueryFragment_lifecycleStatus_decode,
  lifecycleStatus_fromString: QueryFragment_lifecycleStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function BDVersionHistory$VersionHistory(props) {
  var onClose = props.onClose;
  var redirect = props.redirect;
  var content = props.content;
  var len = content.versionHistory.length;
  return JsxRuntime.jsx(List, {
              children: Caml_option.some(Belt_Array.mapWithIndex(content.versionHistory, (function (i, item) {
                          var name = item.name;
                          var bookingVersionNumber = item.bookingVersionNumber;
                          return JsxRuntime.jsx(ListItem, {
                                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                direction: "row",
                                                sx: {
                                                  py: 1,
                                                  width: "100%"
                                                },
                                                children: [
                                                  JsxRuntime.jsx(Stack, {
                                                        direction: "column",
                                                        alignItems: "center",
                                                        sx: {
                                                          px: 2,
                                                          position: "relative",
                                                          "&::before": {
                                                            content: "\"\"",
                                                            position: "absolute",
                                                            top: "25px",
                                                            left: "26px",
                                                            width: (i + 1 | 0) === len ? "0px" : "2px",
                                                            height: "99%",
                                                            backgroundColor: "divider"
                                                          }
                                                        },
                                                        children: Caml_option.some(JsxRuntime.jsx(SquareRounded, {
                                                                  fontSize: "sm",
                                                                  sx: {
                                                                    border: "2px solid",
                                                                    borderColor: "#F4F4F4",
                                                                    p: "2px",
                                                                    backgroundColor: "#F4F4F4",
                                                                    borderRadius: "50%"
                                                                  }
                                                                }))
                                                      }),
                                                  JsxRuntime.jsxs(Stack, {
                                                        direction: "row",
                                                        justifyContent: "space-between",
                                                        sx: {
                                                          width: "100%"
                                                        },
                                                        children: [
                                                          JsxRuntime.jsxs(Stack, {
                                                                spacing: 1,
                                                                sx: {
                                                                  pb: 2
                                                                },
                                                                children: [
                                                                  Common$Thick.Lifecycle.makeChip("md", item.lifecycleStatus, bookingVersionNumber),
                                                                  name !== undefined ? JsxRuntime.jsx(Typography, {
                                                                          level: "body-md",
                                                                          children: Caml_option.some(name)
                                                                        }) : null,
                                                                  JsxRuntime.jsx(Typography, {
                                                                        level: "body-xs",
                                                                        children: Caml_option.some("Version " + String(bookingVersionNumber) + "")
                                                                      })
                                                                ]
                                                              }),
                                                          JsxRuntime.jsx(Stack, {
                                                                direction: "column",
                                                                alignItems: "flex-end",
                                                                sx: {
                                                                  width: "100%"
                                                                },
                                                                children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                                                          children: JsxRuntime.jsx(IconButton, {
                                                                                onClick: (function (param) {
                                                                                    RescriptReactRouter.push(redirect + "/" + String(bookingVersionNumber));
                                                                                    Curry._1(onClose, undefined);
                                                                                  }),
                                                                                variant: "soft",
                                                                                color: "neutral",
                                                                                size: "sm",
                                                                                children: Caml_option.some(JsxRuntime.jsx(KeyboardDoubleArrowRightOutlined, {}))
                                                                              }),
                                                                          arrow: true,
                                                                          placement: "left",
                                                                          size: "sm",
                                                                          title: "View " + (
                                                                            i === 0 ? "active version" : "version " + String(bookingVersionNumber) + ""
                                                                          ) + ""
                                                                        }))
                                                              })
                                                        ]
                                                      })
                                                ]
                                              }))
                                    }, String(i));
                        }))),
              size: "lg",
              sx: {
                position: "relative"
              }
            });
}

var VersionHistory = {
  make: BDVersionHistory$VersionHistory
};

function BDVersionHistory(props) {
  var query = RescriptRelay_Fragment.useFragment(BDVersionHistory_query_graphql$Thick.node, convertFragment, props.fragmentRefs);
  var match = query.versionHistory;
  var versionHistory = match !== undefined ? Belt_Array.map(match.nodes, (function (node) {
            return {
                    bookingVersionNumber: node.bookingVersionNumber,
                    name: undefined,
                    lifecycleStatus: node.lifecycleStatus
                  };
          })) : [];
  var content = {
    versionHistory: versionHistory
  };
  return JsxRuntime.jsx(BDVersionHistory$VersionHistory, {
              content: content,
              redirect: props.redirect,
              onClose: props.onClose
            });
}

var make = BDVersionHistory;

exports.QueryFragment = QueryFragment;
exports.VersionHistory = VersionHistory;
exports.make = make;
/* Common-Thick Not a pure module */
