// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");

function makeRefetchVariables(brandFamily, param) {
  return {
          brandFamily: brandFamily
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {"__root":{"brandFamily":{"c":"CustomScalars.BrandFamilySlug"}}};

var variablesConverterMap = {
  "CustomScalars.BrandFamilySlug": CustomScalars$Thick.BrandFamilySlug.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function brandfamPermission_decode($$enum) {
  if ($$enum === "VIEW_MANIFEST" || $$enum === "VIEW_ALL_USERS" || $$enum === "ADD_USER" || $$enum === "VIEW_ALL_VOYAGES" || $$enum === "VIEW_BOOKINGS" || $$enum === "MANAGE_BOOKINGS" || $$enum === "VIEW_PRICING" || $$enum === "GRANT_REVOKE" || $$enum === "VIEW_CUSTOMER_DETAILS" || $$enum === "MANAGE_CABIN_INVENTORY" || $$enum === "VIEW_VOYAGE_META" || $$enum === "MANAGE_USER" || $$enum === "MANAGE_PRICING" || $$enum === "MANAGE_BF_CUSTOMERS" || $$enum === "REQUEST_BRAND_FAMILY_GRANT" || $$enum === "WORKFLOW_PARTICIPANT" || $$enum === "VIEW_CABIN_INVENTORY") {
    return $$enum;
  }
  
}

var brandfamPermission_fromString = brandfamPermission_decode;

var Utils = {
  brandfamPermission_decode: brandfamPermission_decode,
  brandfamPermission_fromString: brandfamPermission_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brandFamily"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "forBrandFamilySlug",
        "variableName": "brandFamily"
      }
    ],
    "kind": "ScalarField",
    "name": "currentUserCanForBrand",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NavBarQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NavBarQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9671af9828e9edc632a9162b37f6c712",
    "id": null,
    "metadata": {},
    "name": "NavBarQuery",
    "operationKind": "query",
    "text": "query NavBarQuery(\n  $brandFamily: BrandFamilySlug!\n) {\n  currentUserCanForBrand(forBrandFamilySlug: $brandFamily)\n}\n"
  }
};
})());

var include = RescriptRelay.MakeLoadQuery({
      query: node,
      convertVariables: convertVariables
    });

var load = include.load;

var queryRefToObservable = include.queryRefToObservable;

var queryRefToPromise = include.queryRefToPromise;

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
