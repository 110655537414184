// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"brandFamilyVoyages":{"c":"CustomScalars.VoyageSlug"},"brandFamilySlug":{"c":"CustomScalars.BrandFamilySlug"}}};

var fragmentConverterMap = {
  "CustomScalars.BrandFamilySlug": CustomScalars$Thick.BrandFamilySlug.parse,
  "CustomScalars.VoyageSlug": CustomScalars$Thick.VoyageSlug.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function brandfamRole_decode($$enum) {
  if ($$enum === "BRAND_FAMILY_ADMIN" || $$enum === "INVENTORY_MANAGER" || $$enum === "CUSTOMER_SERVICE") {
    return $$enum;
  }
  
}

var brandfamRole_fromString = brandfamRole_decode;

var Utils = {
  brandfamRole_decode: brandfamRole_decode,
  brandfamRole_fromString: brandfamRole_fromString
};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BrandFamilyAccessSection_profile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandFamilySlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandFamilyRoles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandFamilyVoyages",
      "storageKey": null
    }
  ],
  "type": "CurrentUserProfile",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* RescriptRelay Not a pure module */
