// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var VoyageMenu$Thick = require("./VoyageMenu.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var LeftNavMenu_profile_graphql$Thick = require("../../../__generated__/LeftNavMenu_profile_graphql.bs.js");

var convertFragment = LeftNavMenu_profile_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(LeftNavMenu_profile_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, LeftNavMenu_profile_graphql$Thick.node, convertFragment);
}

var ProfileFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function LeftNavMenu(props) {
  var userPerms = props.userPerms;
  var selectedVoyage = props.selectedVoyage;
  var brandFamily = props.brandFamily;
  var expandFullNav = props.setIsExpanded;
  var leftNavExpanded = props.isExpanded;
  var profile = RescriptRelay_Fragment.useFragment(LeftNavMenu_profile_graphql$Thick.node, convertFragment, props.profile);
  var voyages = Belt_Array.mapWithIndex(Belt_Array.keepMap(profile.brandFamilyVoyages, (function (v) {
              if (v !== undefined) {
                return Caml_option.some(Caml_option.valFromOption(v));
              }
              
            })), (function (i, voyage) {
          return JsxRuntime.jsx(VoyageMenu$Thick.make, {
                      leftNavExpanded: leftNavExpanded,
                      expandFullNav: expandFullNav,
                      voyage: voyage,
                      brandFamily: brandFamily,
                      selectedVoyage: selectedVoyage,
                      userPerms: userPerms
                    }, "voyagemenu-" + i + "");
        }));
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(voyages)
            });
}

var make = LeftNavMenu;

exports.ProfileFragment = ProfileFragment;
exports.make = make;
/* VoyageMenu-Thick Not a pure module */
