// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var DashboardPage_query_graphql$Thick = require("../__generated__/DashboardPage_query_graphql.bs.js");

var convertFragment = DashboardPage_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(DashboardPage_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, DashboardPage_query_graphql$Thick.node, convertFragment);
}

var QueryFragment_signonStatus_decode = DashboardPage_query_graphql$Thick.Utils.signonStatus_decode;

var QueryFragment_signonStatus_fromString = DashboardPage_query_graphql$Thick.Utils.signonStatus_fromString;

var QueryFragment = {
  signonStatus_decode: QueryFragment_signonStatus_decode,
  signonStatus_fromString: QueryFragment_signonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function DashboardPage(props) {
  RescriptRelay_Fragment.useFragment(DashboardPage_query_graphql$Thick.node, convertFragment, props.fragmentRefs);
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("h1", {
                    children: "Dashboard"
                  })
            });
}

var make = DashboardPage;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react/jsx-runtime Not a pure module */
