// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var BFGrantWorkflowView$Thick = require("./BFGrantWorkflowView.bs.js");
var BFGrantWorkflowsQuery_graphql$Thick = require("../../__generated__/BFGrantWorkflowsQuery_graphql.bs.js");
var BFGrantWorkflows_query_graphql$Thick = require("../../__generated__/BFGrantWorkflows_query_graphql.bs.js");

var convertFragment = BFGrantWorkflows_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BFGrantWorkflows_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BFGrantWorkflows_query_graphql$Thick.node, convertFragment);
}

var makeRefetchVariables = BFGrantWorkflowsQuery_graphql$Thick.Types.makeRefetchVariables;

var convertRefetchVariables = BFGrantWorkflowsQuery_graphql$Thick.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(BFGrantWorkflows_query_graphql$Thick.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment_actionT_decode = BFGrantWorkflows_query_graphql$Thick.Utils.actionT_decode;

var QueryFragment_actionT_fromString = BFGrantWorkflows_query_graphql$Thick.Utils.actionT_fromString;

var QueryFragment = {
  actionT_decode: QueryFragment_actionT_decode,
  actionT_fromString: QueryFragment_actionT_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function BFGrantWorkflows(props) {
  var refreshTeam = props.refreshTeam;
  var match = useRefetchable(props.fragmentRefs);
  var refetch = match[1];
  var query = match[0];
  var refreshWorkflows = function (param) {
    Curry._4(refetch, Curry._2(makeRefetchVariables, undefined, undefined), /* StoreAndNetwork */2, undefined, undefined);
    return Curry._1(refreshTeam, undefined);
  };
  var actionableWorkflows = Belt_Option.getWithDefault(query.actionableBFGrants, {
        nodes: []
      }).nodes;
  var match$1 = actionableWorkflows.length;
  var actionable;
  if (match$1 !== 0) {
    var arr = Belt_Array.keepMap(actionableWorkflows, (function (workflow) {
            var workflow$1 = workflow.brandFamilyGrantWorkflows;
            if (workflow$1 === undefined) {
              return ;
            }
            var actions = workflow.currentUserCan;
            if (actions !== undefined) {
              return Caml_option.some(JsxRuntime.jsx(BFGrantWorkflowView$Thick.make, {
                              fragmentRefs: workflow$1.fragmentRefs,
                              refreshWorkflows: refreshWorkflows,
                              currentUserCan: Belt_Array.keepMap(actions, (function (action) {
                                      if (action !== undefined) {
                                        return action;
                                      }
                                      
                                    })),
                              isActionable: true
                            }, workflow$1.__id));
            }
            
          }));
    var match$2 = arr.length;
    actionable = match$2 !== 0 ? arr : JsxRuntime.jsx(Typography, {
            level: "body-sm",
            children: "No actionable workflows"
          });
  } else {
    actionable = JsxRuntime.jsx(Typography, {
          level: "body-sm",
          children: "No actionable workflows"
        });
  }
  var otherWorkflows = Belt_Option.getWithDefault(query.viewableBFGrants, {
        nodes: []
      }).nodes;
  var match$3 = otherWorkflows.length;
  var other;
  if (match$3 !== 0) {
    var arr$1 = Belt_Array.keepMap(otherWorkflows, (function (workflow) {
            var workflow$1 = workflow.brandFamilyGrantWorkflows;
            if (workflow$1 === undefined) {
              return ;
            }
            var actions = workflow.currentUserCan;
            if (actions !== undefined) {
              return Caml_option.some(JsxRuntime.jsx(BFGrantWorkflowView$Thick.make, {
                              fragmentRefs: workflow$1.fragmentRefs,
                              refreshWorkflows: refreshWorkflows,
                              currentUserCan: Belt_Array.keepMap(actions, (function (action) {
                                      if (action !== undefined) {
                                        return action;
                                      }
                                      
                                    })),
                              isActionable: false
                            }, workflow$1.__id));
            }
            
          }));
    var match$4 = arr$1.length;
    other = match$4 !== 0 ? arr$1 : JsxRuntime.jsx(Typography, {
            level: "body-sm",
            children: "No viewable workflows"
          });
  } else {
    other = JsxRuntime.jsx(Typography, {
          level: "body-sm",
          children: "No viewable workflows"
        });
  }
  return JsxRuntime.jsxs(Sheet, {
              variant: "outlined",
              sx: {
                width: 300,
                mx: "auto",
                my: 4,
                py: 3,
                px: 2,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                borderRadius: "sm"
              },
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "h4",
                      component: "h2",
                      children: "Workflows"
                    }),
                JsxRuntime.jsxs(Stack, {
                      spacing: 2,
                      children: [
                        JsxRuntime.jsx(Typography, {
                              level: "body-md",
                              component: "h3",
                              children: "Actionable"
                            }),
                        actionable,
                        JsxRuntime.jsx(Typography, {
                              level: "body-md",
                              component: "h3",
                              children: "Other Workflows"
                            }),
                        other
                      ]
                    })
              ]
            });
}

var make = BFGrantWorkflows;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* @mui/joy/Sheet Not a pure module */
