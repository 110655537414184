// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_exn = require("rescript/lib/js/js_exn.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_types = require("rescript/lib/js/js_types.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");

function test(data) {
  if (typeof data === "number" && data < 2147483648 && data > -2147483649) {
    return data % 1 === 0;
  } else {
    return false;
  }
}

function fromString(string) {
  return JSON.stringify(string);
}

function toArray(path) {
  if (path === "") {
    return [];
  } else {
    return JSON.parse(path.split("\"][\"").join("\",\""));
  }
}

function fromLocation($$location) {
  return "[" + JSON.stringify($$location) + "]";
}

function fromArray(array) {
  var len = array.length;
  if (len !== 1) {
    if (len !== 0) {
      return "[" + array.map(fromString).join("][") + "]";
    } else {
      return "";
    }
  } else {
    return "[" + JSON.stringify(array[0]) + "]";
  }
}

function concat(path, concatedPath) {
  return path + concatedPath;
}

var Exception = /* @__PURE__ */Caml_exceptions.create("S-RescriptStruct.Error.Internal.Exception");

function raise(expected, received, initialPathOpt, param) {
  var initialPath = initialPathOpt !== undefined ? initialPathOpt : "";
  throw {
        RE_EXN_ID: Exception,
        _1: {
          c: {
            TAG: /* UnexpectedValue */2,
            expected: expected === undefined ? "undefined" : JSON.stringify(expected),
            received: received === undefined ? "undefined" : JSON.stringify(received)
          },
          p: initialPath
        },
        Error: new Error()
      };
}

function raise$1(code) {
  throw {
        RE_EXN_ID: Exception,
        _1: {
          c: code,
          p: ""
        },
        Error: new Error()
      };
}

function toParseError(internalError) {
  return {
          operation: /* Parsing */1,
          code: internalError.c,
          path: internalError.p
        };
}

function toSerializeError(internalError) {
  return {
          operation: /* Serializing */0,
          code: internalError.c,
          path: internalError.p
        };
}

function prependPath(error, path) {
  return {
          c: error.c,
          p: path + error.p
        };
}

function panic($$location) {
  throw new Error("[rescript-struct] " + ("For a " + $$location + " either a parser, or a serializer is required") + "");
}

function panic$1(param) {
  throw new Error("[rescript-struct] Unreachable");
}

function toReason(nestedLevelOpt, error) {
  var nestedLevel = nestedLevelOpt !== undefined ? nestedLevelOpt : 0;
  var reason = error.code;
  if (typeof reason === "number") {
    switch (reason) {
      case /* MissingParser */0 :
          return "Struct parser is missing";
      case /* MissingSerializer */1 :
          return "Struct serializer is missing";
      case /* UnexpectedAsync */2 :
          return "Encountered unexpected asynchronous transform or refine. Use S.parseAsyncWith instead of S.parseWith";
      
    }
  } else {
    switch (reason.TAG | 0) {
      case /* OperationFailed */0 :
          return reason._0;
      case /* UnexpectedType */1 :
      case /* UnexpectedValue */2 :
          break;
      case /* TupleSize */3 :
          return "Expected Tuple with " + reason.expected.toString() + " items, received " + reason.received.toString() + "";
      case /* ExcessField */4 :
          return "Encountered disallowed excess key \"" + reason._0 + "\" on an object. Use Deprecated to ignore a specific field, or S.Object.strip to ignore excess keys completely";
      case /* InvalidUnion */5 :
          var lineBreak = "\n" + " ".repeat((nestedLevel << 1)) + "";
          var array = reason._0.map(function (error) {
                var reason = toReason(nestedLevel + 1, error);
                var nonEmptyPath = error.path;
                var $$location = nonEmptyPath === "" ? "" : "Failed at " + nonEmptyPath + ". ";
                return "- " + $$location + "" + reason + "";
              });
          var reasons = Array.from(new Set(array));
          return "Invalid union with following errors" + lineBreak + "" + reasons.join(lineBreak) + "";
      case /* InvalidJsonStruct */6 :
          return "The struct " + reason.received + " is not compatible with JSON";
      
    }
  }
  return "Expected " + reason.expected + ", received " + reason.received + "";
}

function toString(error) {
  var match = error.operation;
  var operation = match ? "parsing" : "serializing";
  var reason = toReason(undefined, error);
  var nonEmptyPath = error.path;
  var pathText = nonEmptyPath === "" ? "root" : nonEmptyPath;
  return "Failed " + operation + " at " + pathText + ". Reason: " + reason + "";
}

function advancedFail(error) {
  throw {
        RE_EXN_ID: Exception,
        _1: {
          c: error.code,
          p: error.path
        },
        Error: new Error()
      };
}

function fail(pathOpt, message) {
  var path = pathOpt !== undefined ? pathOpt : "";
  throw {
        RE_EXN_ID: Exception,
        _1: {
          c: {
            TAG: /* OperationFailed */0,
            _0: message
          },
          p: path
        },
        Error: new Error()
      };
}

var Raised = /* @__PURE__ */Caml_exceptions.create("S-RescriptStruct.Raised");

var emptyMetadataMap = {};

function planSyncTransformation(ctx, transformation) {
  var prevSyncTransformation = ctx.t;
  var prevAsyncTransformation = ctx.a;
  var match = ctx.p;
  if (match !== 1) {
    if (match !== 0) {
      ctx.a = (function (input) {
          return prevAsyncTransformation(input).then(transformation);
        });
    } else {
      ctx.p = /* OnlySync */1;
      ctx.t = transformation;
    }
  } else {
    ctx.t = (function (input) {
        return transformation(prevSyncTransformation(input));
      });
  }
}

function planAsyncTransformation(ctx, transformation) {
  var prevAsyncTransformation = ctx.a;
  var match = ctx.p;
  if (match !== 1) {
    if (match !== 0) {
      ctx.a = (function (input) {
          return prevAsyncTransformation(input).then(transformation);
        });
    } else {
      ctx.p = /* OnlyAsync */2;
      ctx.a = transformation;
    }
  } else {
    ctx.p = /* SyncAndAsync */3;
    ctx.a = transformation;
  }
}

function planMissingParserTransformation(ctx) {
  planSyncTransformation(ctx, (function (param) {
          return raise$1(/* MissingParser */0);
        }));
}

function empty(param) {
  
}

function compile(transformationFactory, struct) {
  var ctx = {
    s: struct,
    p: /* NoTransformation */0,
    t: undefined,
    a: undefined
  };
  transformationFactory(ctx);
  var match = ctx.p;
  switch (match) {
    case /* NoTransformation */0 :
        return /* NoOperation */0;
    case /* OnlySync */1 :
        return {
                TAG: /* SyncOperation */0,
                _0: ctx.t
              };
    case /* OnlyAsync */2 :
        return {
                TAG: /* AsyncOperation */1,
                _0: (function (input) {
                    return function () {
                      return ctx.a(input);
                    };
                  })
              };
    case /* SyncAndAsync */3 :
        return {
                TAG: /* AsyncOperation */1,
                _0: (function (input) {
                    var syncOutput = ctx.t(input);
                    return function () {
                      return ctx.a(syncOutput);
                    };
                  })
              };
    
  }
}

function classify(struct) {
  return struct.t;
}

function name(struct) {
  return struct.n;
}

function getParseOperation(struct) {
  var parseOperationState = struct.r;
  if (typeof parseOperationState !== "number") {
    return parseOperationState;
  }
  if (parseOperationState === 2) {
    return {
            TAG: /* SyncOperation */0,
            _0: (function (input) {
                return struct.p(input);
              })
          };
  }
  if (parseOperationState === 3) {
    return {
            TAG: /* AsyncOperation */1,
            _0: (function (input) {
                return struct.a(input);
              })
          };
  }
  struct.r = parseOperationState === 1 ? 3 : 2;
  var compiledParseOperation = compile(struct.pf, struct);
  struct.r = compiledParseOperation;
  return compiledParseOperation;
}

function getSerializeOperation(struct) {
  var serializeOperationState = struct.e;
  if (typeof serializeOperationState !== "number") {
    return serializeOperationState;
  }
  if (serializeOperationState === 1) {
    return (function (input) {
              return struct.s(input);
            });
  }
  struct.e = 1;
  var fn = compile(struct.sf, struct);
  var compiledSerializeOperation;
  compiledSerializeOperation = typeof fn === "number" ? undefined : (
      fn.TAG === /* SyncOperation */0 ? fn._0 : panic$1(undefined)
    );
  struct.e = compiledSerializeOperation;
  return compiledSerializeOperation;
}

function isAsyncParse(struct) {
  var match = getParseOperation(struct);
  if (typeof match === "number" || match.TAG === /* SyncOperation */0) {
    return false;
  } else {
    return true;
  }
}

function raiseUnexpectedTypeError(input, struct) {
  var number = Js_types.classify(input);
  var tmp;
  if (typeof number === "number") {
    switch (number) {
      case /* JSFalse */0 :
      case /* JSTrue */1 :
          tmp = "Bool";
          break;
      case /* JSNull */2 :
          tmp = "Null";
          break;
      case /* JSUndefined */3 :
          tmp = "Option";
          break;
      
    }
  } else {
    switch (number.TAG | 0) {
      case /* JSNumber */0 :
          tmp = Number.isNaN(number._0) ? "NaN Literal (NaN)" : "Float";
          break;
      case /* JSString */1 :
          tmp = "String";
          break;
      case /* JSFunction */2 :
          tmp = "Function";
          break;
      case /* JSObject */3 :
          tmp = Array.isArray(number._0) ? "Array" : "Object";
          break;
      case /* JSSymbol */4 :
          tmp = "Symbol";
          break;
      case /* JSBigInt */5 :
          tmp = "BigInt";
          break;
      
    }
  }
  return raise$1({
              TAG: /* UnexpectedType */1,
              expected: struct.n,
              received: tmp
            });
}

function noOperation(input) {
  return input;
}

function initialSerialize(input) {
  var struct = this;
  var fn = getSerializeOperation(struct);
  var compiledSerialize = fn !== undefined ? fn : noOperation;
  struct.s = compiledSerialize;
  return compiledSerialize(input);
}

function validateJsonableStruct(_struct, rootStruct, _isRootOpt, _param) {
  while(true) {
    var isRootOpt = _isRootOpt;
    var struct = _struct;
    var isRoot = isRootOpt !== undefined ? isRootOpt : false;
    if (!(isRoot || rootStruct !== struct)) {
      return ;
    }
    var childrenStructs = struct.t;
    if (typeof childrenStructs === "number") {
      if (childrenStructs === /* Unknown */1) {
        return raise$1({
                    TAG: /* InvalidJsonStruct */6,
                    received: struct.n
                  });
      } else {
        return ;
      }
    }
    switch (childrenStructs.TAG | 0) {
      case /* Literal */0 :
          var match = childrenStructs._0;
          if (typeof match === "number" && match !== 0) {
            return raise$1({
                        TAG: /* InvalidJsonStruct */6,
                        received: struct.n
                      });
          } else {
            return ;
          }
      case /* Option */1 :
          return raise$1({
                      TAG: /* InvalidJsonStruct */6,
                      received: struct.n
                    });
      case /* Object */4 :
          var fieldNames = childrenStructs.fieldNames;
          var fields = childrenStructs.fields;
          for(var idx = 0 ,idx_finish = fieldNames.length; idx < idx_finish; ++idx){
            var fieldName = fieldNames[idx];
            var fieldStruct = fields[fieldName];
            try {
              var s = fieldStruct.t;
              var tmp;
              tmp = typeof s === "number" || s.TAG !== /* Option */1 ? fieldStruct : s._0;
              validateJsonableStruct(tmp, rootStruct, undefined, undefined);
            }
            catch (raw_e){
              var e = Caml_js_exceptions.internalToOCamlException(raw_e);
              if (e.RE_EXN_ID === Exception) {
                throw {
                      RE_EXN_ID: Exception,
                      _1: prependPath(e._1, "[" + JSON.stringify(fieldName) + "]"),
                      Error: new Error()
                    };
              }
              throw e;
            }
          }
          return ;
      case /* Tuple */5 :
          childrenStructs._0.forEach(function (childStruct, i) {
                try {
                  return validateJsonableStruct(childStruct, rootStruct, undefined, undefined);
                }
                catch (raw_e){
                  var e = Caml_js_exceptions.internalToOCamlException(raw_e);
                  if (e.RE_EXN_ID === Exception) {
                    var $$location = i.toString();
                    throw {
                          RE_EXN_ID: Exception,
                          _1: prependPath(e._1, "[" + JSON.stringify($$location) + "]"),
                          Error: new Error()
                        };
                  }
                  throw e;
                }
              });
          return ;
      case /* Union */6 :
          childrenStructs._0.forEach(function (childStruct) {
                validateJsonableStruct(childStruct, rootStruct, undefined, undefined);
              });
          return ;
      case /* Null */2 :
      case /* Array */3 :
      case /* Dict */7 :
          break;
      
    }
    _param = undefined;
    _isRootOpt = undefined;
    _struct = childrenStructs._0;
    continue ;
  };
}

function initialSerializeToJson(input) {
  var struct = this;
  try {
    validateJsonableStruct(struct, struct, true, undefined);
    if (struct.s === initialSerialize) {
      var fn = getSerializeOperation(struct);
      var compiledSerialize = fn !== undefined ? fn : noOperation;
      struct.s = compiledSerialize;
    }
    struct.j = struct.s;
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Exception) {
      struct.j = (function (param) {
          throw exn;
        });
    } else {
      throw exn;
    }
  }
  return struct.j(input);
}

function intitialParse(input) {
  var struct = this;
  var fn = getParseOperation(struct);
  var compiledParse;
  compiledParse = typeof fn === "number" ? noOperation : (
      fn.TAG === /* SyncOperation */0 ? fn._0 : (function (param) {
            return raise$1(/* UnexpectedAsync */2);
          })
    );
  struct.p = compiledParse;
  return compiledParse(input);
}

function asyncNoopOperation(input) {
  return function () {
    return Promise.resolve(input);
  };
}

function intitialParseAsync(input) {
  var struct = this;
  var fn = getParseOperation(struct);
  var compiledParseAsync;
  if (typeof fn === "number") {
    compiledParseAsync = asyncNoopOperation;
  } else if (fn.TAG === /* SyncOperation */0) {
    var fn$1 = fn._0;
    compiledParseAsync = (function (input) {
        var syncValue = fn$1(input);
        return function () {
          return Promise.resolve(syncValue);
        };
      });
  } else {
    compiledParseAsync = fn._0;
  }
  struct.a = compiledParseAsync;
  return compiledParseAsync(input);
}

function parseAnyWith(any, struct) {
  try {
    return {
            TAG: /* Ok */0,
            _0: struct.p(any)
          };
  }
  catch (raw_internalError){
    var internalError = Caml_js_exceptions.internalToOCamlException(raw_internalError);
    if (internalError.RE_EXN_ID === Exception) {
      return {
              TAG: /* Error */1,
              _0: toParseError(internalError._1)
            };
    }
    throw internalError;
  }
}

function parseAnyOrRaiseWith(any, struct) {
  try {
    return struct.p(any);
  }
  catch (raw_internalError){
    var internalError = Caml_js_exceptions.internalToOCamlException(raw_internalError);
    if (internalError.RE_EXN_ID === Exception) {
      throw {
            RE_EXN_ID: Raised,
            _1: toParseError(internalError._1),
            Error: new Error()
          };
    }
    throw internalError;
  }
}

function asyncPrepareOk(value) {
  return {
          TAG: /* Ok */0,
          _0: value
        };
}

function asyncPrepareError(exn) {
  if (exn.RE_EXN_ID === Exception) {
    return {
            TAG: /* Error */1,
            _0: toParseError(exn._1)
          };
  }
  throw exn;
}

function parseAnyAsyncWith(any, struct) {
  try {
    return struct.a(any)().then(asyncPrepareOk, asyncPrepareError);
  }
  catch (raw_internalError){
    var internalError = Caml_js_exceptions.internalToOCamlException(raw_internalError);
    if (internalError.RE_EXN_ID === Exception) {
      return Promise.resolve({
                  TAG: /* Error */1,
                  _0: toParseError(internalError._1)
                });
    }
    throw internalError;
  }
}

function parseAnyAsyncInStepsWith(any, struct) {
  try {
    var asyncFn = struct.a(any);
    return {
            TAG: /* Ok */0,
            _0: (function () {
                return asyncFn().then(asyncPrepareOk, asyncPrepareError);
              })
          };
  }
  catch (raw_internalError){
    var internalError = Caml_js_exceptions.internalToOCamlException(raw_internalError);
    if (internalError.RE_EXN_ID === Exception) {
      return {
              TAG: /* Error */1,
              _0: toParseError(internalError._1)
            };
    }
    throw internalError;
  }
}

function serializeToUnknownWith(value, struct) {
  try {
    return {
            TAG: /* Ok */0,
            _0: struct.s(value)
          };
  }
  catch (raw_internalError){
    var internalError = Caml_js_exceptions.internalToOCamlException(raw_internalError);
    if (internalError.RE_EXN_ID === Exception) {
      return {
              TAG: /* Error */1,
              _0: toSerializeError(internalError._1)
            };
    }
    throw internalError;
  }
}

function serializeOrRaiseWith(value, struct) {
  try {
    return struct.j(value);
  }
  catch (raw_internalError){
    var internalError = Caml_js_exceptions.internalToOCamlException(raw_internalError);
    if (internalError.RE_EXN_ID === Exception) {
      throw {
            RE_EXN_ID: Raised,
            _1: toSerializeError(internalError._1),
            Error: new Error()
          };
    }
    throw internalError;
  }
}

function serializeToUnknownOrRaiseWith(value, struct) {
  try {
    return struct.s(value);
  }
  catch (raw_internalError){
    var internalError = Caml_js_exceptions.internalToOCamlException(raw_internalError);
    if (internalError.RE_EXN_ID === Exception) {
      throw {
            RE_EXN_ID: Raised,
            _1: toSerializeError(internalError._1),
            Error: new Error()
          };
    }
    throw internalError;
  }
}

function serializeWith(value, struct) {
  try {
    return {
            TAG: /* Ok */0,
            _0: struct.j(value)
          };
  }
  catch (raw_internalError){
    var internalError = Caml_js_exceptions.internalToOCamlException(raw_internalError);
    if (internalError.RE_EXN_ID === Exception) {
      return {
              TAG: /* Error */1,
              _0: toSerializeError(internalError._1)
            };
    }
    throw internalError;
  }
}

function serializeToJsonWith(value, spaceOpt, struct) {
  var space = spaceOpt !== undefined ? spaceOpt : 0;
  var json = serializeWith(value, struct);
  if (json.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: JSON.stringify(json._0, null, space)
          };
  } else {
    return json;
  }
}

function parseJsonWith(json, struct) {
  var json$1;
  try {
    json$1 = {
      TAG: /* Ok */0,
      _0: JSON.parse(json)
    };
  }
  catch (raw_error){
    var error = Caml_js_exceptions.internalToOCamlException(raw_error);
    if (error.RE_EXN_ID === Js_exn.$$Error) {
      json$1 = {
        TAG: /* Error */1,
        _0: {
          operation: /* Parsing */1,
          code: {
            TAG: /* OperationFailed */0,
            _0: error._1.message
          },
          path: ""
        }
      };
    } else {
      throw error;
    }
  }
  if (json$1.TAG === /* Ok */0) {
    return parseAnyWith(json$1._0, struct);
  } else {
    return json$1;
  }
}

function recursive(fn) {
  var placeholder = ({m:emptyMetadataMap});
  var struct = fn(placeholder);
  Object.assign(placeholder, struct);
  if (isAsyncParse(placeholder)) {
    throw new Error("[rescript-struct] " + ("The \"" + struct.n + "\" struct in the S.recursive has an async parser. To make it work, use S.asyncRecursive instead.") + "");
  }
  return placeholder;
}

function asyncRecursive(fn) {
  var placeholder = ({m:emptyMetadataMap});
  var struct = fn(placeholder);
  Object.assign(placeholder, struct);
  placeholder.r = 1;
  return placeholder;
}

function make(namespace, name) {
  return "" + namespace + ":" + name + "";
}

var Id = {
  make: make
};

function get(struct, id) {
  return Js_dict.get(struct.m, id);
}

function set(struct, id, metadata) {
  var metadataMap = Object.assign({}, struct.m);
  metadataMap[id] = metadata;
  return {
          n: struct.n,
          t: struct.t,
          pf: struct.pf,
          sf: struct.sf,
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: undefined,
          m: metadataMap
        };
}

var Metadata = {
  Id: Id,
  get: get,
  set: set
};

function refine(struct, maybeParser, maybeAsyncParser, maybeSerializer, param) {
  if (maybeParser === undefined && maybeAsyncParser === undefined && maybeSerializer === undefined) {
    panic("struct factory Refine");
  }
  var nextParseTransformationFactory = maybeParser !== undefined ? (
      maybeAsyncParser !== undefined ? (function (ctx) {
            struct.pf(ctx);
            planSyncTransformation(ctx, (function (input) {
                    maybeParser(input);
                    return input;
                  }));
            planAsyncTransformation(ctx, (function (input) {
                    return maybeAsyncParser(input).then(function (param) {
                                return input;
                              });
                  }));
          }) : (function (ctx) {
            struct.pf(ctx);
            planSyncTransformation(ctx, (function (input) {
                    maybeParser(input);
                    return input;
                  }));
          })
    ) : (
      maybeAsyncParser !== undefined ? (function (ctx) {
            struct.pf(ctx);
            planAsyncTransformation(ctx, (function (input) {
                    return maybeAsyncParser(input).then(function (param) {
                                return input;
                              });
                  }));
          }) : struct.pf
    );
  return {
          n: struct.n,
          t: struct.t,
          pf: nextParseTransformationFactory,
          sf: maybeSerializer !== undefined ? (function (ctx) {
                planSyncTransformation(ctx, (function (input) {
                        maybeSerializer(input);
                        return input;
                      }));
                struct.sf(ctx);
              }) : struct.sf,
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: nextParseTransformationFactory === struct.pf ? struct.i : undefined,
          m: struct.m
        };
}

function addRefinement(struct, metadataId, refinement, refiner) {
  var refinements = Js_dict.get(struct.m, metadataId);
  return refine(set(struct, metadataId, refinements !== undefined ? refinements.concat(refinement) : [refinement]), refiner, undefined, refiner, undefined);
}

function transform(struct, maybeParser, maybeAsyncParser, maybeSerializer, param) {
  if (maybeParser === undefined && maybeAsyncParser === undefined && maybeSerializer === undefined) {
    panic("struct factory Transform");
  }
  var planParser;
  if (maybeParser !== undefined) {
    if (maybeAsyncParser !== undefined) {
      throw new Error("[rescript-struct] The S.transform doesn't support the `parser` and `asyncParser` arguments simultaneously. Move `asyncParser` to another S.transform.");
    }
    planParser = (function (ctx) {
        planSyncTransformation(ctx, maybeParser);
      });
  } else {
    planParser = maybeAsyncParser !== undefined ? (function (ctx) {
          planAsyncTransformation(ctx, maybeAsyncParser);
        }) : planMissingParserTransformation;
  }
  return {
          n: struct.n,
          t: struct.t,
          pf: (function (ctx) {
              struct.pf(ctx);
              planParser(ctx);
            }),
          sf: (function (ctx) {
              if (maybeSerializer !== undefined) {
                planSyncTransformation(ctx, maybeSerializer);
              } else {
                planSyncTransformation(ctx, (function (param) {
                        return raise$1(/* MissingSerializer */1);
                      }));
              }
              struct.sf(ctx);
            }),
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: undefined,
          m: struct.m
        };
}

function advancedTransform(struct, maybeParser, maybeSerializer, param) {
  if (maybeParser === undefined && maybeSerializer === undefined) {
    panic("struct factory Transform");
  }
  return {
          n: struct.n,
          t: struct.t,
          pf: (function (ctx) {
              struct.pf(ctx);
              if (maybeParser === undefined) {
                return planSyncTransformation(ctx, (function (param) {
                              return raise$1(/* MissingParser */0);
                            }));
              }
              var syncTransformation = maybeParser(ctx.s);
              if (typeof syncTransformation === "number") {
                return ;
              } else if (syncTransformation.TAG === /* Sync */0) {
                return planSyncTransformation(ctx, syncTransformation._0);
              } else {
                return planAsyncTransformation(ctx, syncTransformation._0);
              }
            }),
          sf: (function (ctx) {
              if (maybeSerializer !== undefined) {
                var syncTransformation = maybeSerializer(ctx.s);
                if (typeof syncTransformation !== "number") {
                  if (syncTransformation.TAG === /* Sync */0) {
                    planSyncTransformation(ctx, syncTransformation._0);
                  } else {
                    planAsyncTransformation(ctx, syncTransformation._0);
                  }
                }
                
              } else {
                planSyncTransformation(ctx, (function (param) {
                        return raise$1(/* MissingSerializer */1);
                      }));
              }
              struct.sf(ctx);
            }),
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: undefined,
          m: struct.m
        };
}

function advancedPreprocess(struct, maybePreprocessParser, maybePreprocessSerializer, param) {
  if (maybePreprocessParser === undefined && maybePreprocessSerializer === undefined) {
    panic("struct factory Preprocess");
  }
  var unionStructs = struct.t;
  if (typeof unionStructs !== "number" && unionStructs.TAG === /* Union */6) {
    var tagged = {
      TAG: /* Union */6,
      _0: unionStructs._0.map(function (unionStruct) {
            return advancedPreprocess(unionStruct, maybePreprocessParser, maybePreprocessSerializer, undefined);
          })
    };
    return {
            n: struct.n,
            t: tagged,
            pf: struct.pf,
            sf: struct.sf,
            r: 0,
            e: 0,
            s: initialSerialize,
            j: initialSerializeToJson,
            p: intitialParse,
            a: intitialParseAsync,
            i: undefined,
            m: struct.m
          };
  }
  return {
          n: struct.n,
          t: struct.t,
          pf: (function (ctx) {
              if (maybePreprocessParser !== undefined) {
                var syncTransformation = maybePreprocessParser(ctx.s);
                if (typeof syncTransformation !== "number") {
                  if (syncTransformation.TAG === /* Sync */0) {
                    planSyncTransformation(ctx, syncTransformation._0);
                  } else {
                    planAsyncTransformation(ctx, syncTransformation._0);
                  }
                }
                
              } else {
                planSyncTransformation(ctx, (function (param) {
                        return raise$1(/* MissingParser */0);
                      }));
              }
              struct.pf(ctx);
            }),
          sf: (function (ctx) {
              struct.sf(ctx);
              if (maybePreprocessSerializer === undefined) {
                return planSyncTransformation(ctx, (function (param) {
                              return raise$1(/* MissingSerializer */1);
                            }));
              }
              var syncTransformation = maybePreprocessSerializer(ctx.s);
              if (typeof syncTransformation === "number") {
                return ;
              } else if (syncTransformation.TAG === /* Sync */0) {
                return planSyncTransformation(ctx, syncTransformation._0);
              } else {
                return planAsyncTransformation(ctx, syncTransformation._0);
              }
            }),
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: undefined,
          m: struct.m
        };
}

function custom(name, maybeParser, maybeAsyncParser, maybeSerializer, param) {
  if (maybeParser === undefined && maybeAsyncParser === undefined && maybeSerializer === undefined) {
    panic("Custom struct factory");
  }
  var planParser;
  if (maybeParser !== undefined) {
    if (maybeAsyncParser !== undefined) {
      throw new Error("[rescript-struct] The S.custom doesn't support the `parser` and `asyncParser` arguments simultaneously. Keep only `asyncParser`.");
    }
    planParser = (function (ctx) {
        planSyncTransformation(ctx, maybeParser);
      });
  } else {
    planParser = maybeAsyncParser !== undefined ? (function (ctx) {
          planAsyncTransformation(ctx, maybeAsyncParser);
        }) : planMissingParserTransformation;
  }
  return {
          n: name,
          t: /* Unknown */1,
          pf: (function (ctx) {
              planParser(ctx);
            }),
          sf: (function (ctx) {
              if (maybeSerializer !== undefined) {
                return planSyncTransformation(ctx, maybeSerializer);
              } else {
                return planSyncTransformation(ctx, (function (param) {
                              return raise$1(/* MissingSerializer */1);
                            }));
              }
            }),
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: undefined,
          m: emptyMetadataMap
        };
}

function internalToInlinedValue(_struct) {
  while(true) {
    var struct = _struct;
    var unionStructs = struct.t;
    if (typeof unionStructs === "number") {
      throw undefined;
    }
    switch (unionStructs.TAG | 0) {
      case /* Literal */0 :
          var string = unionStructs._0;
          if (typeof string !== "number") {
            if (string.TAG === /* String */0) {
              return JSON.stringify(string._0);
            } else {
              return string._0.toString();
            }
          }
          switch (string) {
            case /* EmptyNull */0 :
                return "null";
            case /* EmptyOption */1 :
                return "undefined";
            case /* NaN */2 :
                return "NaN";
            
          }
      case /* Object */4 :
          var fields = unionStructs.fields;
          return "{" + unionStructs.fieldNames.map((function(fields){
                      return function (fieldName) {
                        return "" + JSON.stringify(fieldName) + ":" + internalToInlinedValue(fields[fieldName]) + "";
                      }
                      }(fields))).join(",") + "}";
      case /* Tuple */5 :
          return "[" + unionStructs._0.map(internalToInlinedValue).join(",") + "]";
      case /* Union */6 :
          _struct = unionStructs._0[0];
          continue ;
      default:
        throw undefined;
    }
  };
}

function analyzeDefinition(definition, definerCtx, path) {
  if (definition === definerCtx) {
    if (definerCtx.r) {
      throw new Error("[rescript-struct] The variant's value is registered multiple times. If you want to duplicate it, use S.transform instead.");
    }
    definerCtx.a = path;
    definerCtx.r = true;
    return ;
  }
  if (typeof definition === "object" && definition !== null) {
    var definitionFieldNames = Object.keys(definition);
    for(var idx = 0 ,idx_finish = definitionFieldNames.length; idx < idx_finish; ++idx){
      var definitionFieldName = definitionFieldNames[idx];
      var fieldDefinition = definition[definitionFieldName];
      analyzeDefinition(fieldDefinition, definerCtx, path + ("[" + JSON.stringify(definitionFieldName) + "]"));
    }
    return ;
  }
  definerCtx.c.push({
        v: definition,
        p: path
      });
}

function factory(struct, definer) {
  var definerCtx = {
    a: "",
    r: false,
    c: []
  };
  var definition = definer(definerCtx);
  analyzeDefinition(definition, definerCtx, "");
  return {
          n: struct.n,
          t: struct.t,
          pf: (function (ctx) {
              struct.pf(ctx);
              planSyncTransformation(ctx, definer);
            }),
          sf: (function (ctx) {
              ((function (ctx) {
                      try {
                        var valuePath = definerCtx.a;
                        var isValueRegistered = definerCtx.r;
                        var constantDefinitions = definerCtx.c;
                        var stringRef = "";
                        for(var idx = 0 ,idx_finish = constantDefinitions.length; idx < idx_finish; ++idx){
                          var match = constantDefinitions[idx];
                          var path = match.p;
                          var content = "r(" + idx.toString() + ",t" + path + ")";
                          var condition = "t" + path + "!==d[" + idx.toString() + "].v";
                          stringRef = stringRef + ("if(" + condition + "){" + content + "}");
                        }
                        var constants = stringRef;
                        var content$1 = "" + constants + "return " + (
                          isValueRegistered ? "t" + valuePath + "" : internalToInlinedValue(ctx.s)
                        ) + "";
                        var inlinedSerializeFunction = "function(t){" + content$1 + "}";
                        planSyncTransformation(ctx, new Function("d", "r", "return " + inlinedSerializeFunction + "")(constantDefinitions, (function (fieldDefinitionIdx, received) {
                                    var match = constantDefinitions[fieldDefinitionIdx];
                                    return raise(match.v, received, match.p, undefined);
                                  })));
                      }
                      catch (exn){
                        planSyncTransformation(ctx, (function (param) {
                                return raise$1(/* MissingSerializer */1);
                              }));
                      }
                    })(ctx));
              struct.sf(ctx);
            }),
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: undefined,
          m: struct.m
        };
}

function factory$1(innerLiteral, variant) {
  var tagged = {
    TAG: /* Literal */0,
    _0: innerLiteral
  };
  var makeParseTransformationFactory = function (literalValue, test) {
    return function (ctx) {
      planSyncTransformation(ctx, (function (input) {
              if (test(input)) {
                if (literalValue === input) {
                  return variant;
                } else {
                  return raise(literalValue, input, undefined, undefined);
                }
              } else {
                return raiseUnexpectedTypeError(input, ctx.s);
              }
            }));
    };
  };
  var makeSerializeTransformationFactory = function (output) {
    return function (ctx) {
      planSyncTransformation(ctx, (function (input) {
              if (input === variant) {
                return output;
              } else {
                return raise(variant, input, undefined, undefined);
              }
            }));
    };
  };
  if (typeof innerLiteral === "number") {
    switch (innerLiteral) {
      case /* EmptyNull */0 :
          var serializeTransformationFactory = makeSerializeTransformationFactory(null);
          return {
                  n: "EmptyNull Literal (null)",
                  t: tagged,
                  pf: (function (ctx) {
                      planSyncTransformation(ctx, (function (input) {
                              if (input === null) {
                                return variant;
                              } else {
                                return raiseUnexpectedTypeError(input, ctx.s);
                              }
                            }));
                    }),
                  sf: serializeTransformationFactory,
                  r: 0,
                  e: 0,
                  s: initialSerialize,
                  j: initialSerializeToJson,
                  p: intitialParse,
                  a: intitialParseAsync,
                  i: undefined,
                  m: emptyMetadataMap
                };
      case /* EmptyOption */1 :
          var serializeTransformationFactory$1 = makeSerializeTransformationFactory(undefined);
          return {
                  n: "EmptyOption Literal (undefined)",
                  t: tagged,
                  pf: (function (ctx) {
                      planSyncTransformation(ctx, (function (input) {
                              if (input === undefined) {
                                return variant;
                              } else {
                                return raiseUnexpectedTypeError(input, ctx.s);
                              }
                            }));
                    }),
                  sf: serializeTransformationFactory$1,
                  r: 0,
                  e: 0,
                  s: initialSerialize,
                  j: initialSerializeToJson,
                  p: intitialParse,
                  a: intitialParseAsync,
                  i: undefined,
                  m: emptyMetadataMap
                };
      case /* NaN */2 :
          var serializeTransformationFactory$2 = makeSerializeTransformationFactory(NaN);
          return {
                  n: "NaN Literal (NaN)",
                  t: tagged,
                  pf: (function (ctx) {
                      planSyncTransformation(ctx, (function (input) {
                              if (Number.isNaN(input)) {
                                return variant;
                              } else {
                                return raiseUnexpectedTypeError(input, ctx.s);
                              }
                            }));
                    }),
                  sf: serializeTransformationFactory$2,
                  r: 0,
                  e: 0,
                  s: initialSerialize,
                  j: initialSerializeToJson,
                  p: intitialParse,
                  a: intitialParseAsync,
                  i: undefined,
                  m: emptyMetadataMap
                };
      
    }
  } else {
    switch (innerLiteral.TAG | 0) {
      case /* String */0 :
          var string = innerLiteral._0;
          var serializeTransformationFactory$3 = makeSerializeTransformationFactory(string);
          var parseTransformationFactory = makeParseTransformationFactory(string, (function (input) {
                  return typeof input === "string";
                }));
          return {
                  n: "String Literal (\"" + string + "\")",
                  t: tagged,
                  pf: parseTransformationFactory,
                  sf: serializeTransformationFactory$3,
                  r: 0,
                  e: 0,
                  s: initialSerialize,
                  j: initialSerializeToJson,
                  p: intitialParse,
                  a: intitialParseAsync,
                  i: undefined,
                  m: emptyMetadataMap
                };
      case /* Int */1 :
          var $$int = innerLiteral._0;
          var serializeTransformationFactory$4 = makeSerializeTransformationFactory($$int);
          var parseTransformationFactory$1 = makeParseTransformationFactory($$int, test);
          var name = "Int Literal (" + $$int.toString() + ")";
          return {
                  n: name,
                  t: tagged,
                  pf: parseTransformationFactory$1,
                  sf: serializeTransformationFactory$4,
                  r: 0,
                  e: 0,
                  s: initialSerialize,
                  j: initialSerializeToJson,
                  p: intitialParse,
                  a: intitialParseAsync,
                  i: undefined,
                  m: emptyMetadataMap
                };
      case /* Float */2 :
          var $$float = innerLiteral._0;
          var serializeTransformationFactory$5 = makeSerializeTransformationFactory($$float);
          var parseTransformationFactory$2 = makeParseTransformationFactory($$float, (function (input) {
                  return typeof input === "number";
                }));
          var name$1 = "Float Literal (" + $$float.toString() + ")";
          return {
                  n: name$1,
                  t: tagged,
                  pf: parseTransformationFactory$2,
                  sf: serializeTransformationFactory$5,
                  r: 0,
                  e: 0,
                  s: initialSerialize,
                  j: initialSerializeToJson,
                  p: intitialParse,
                  a: intitialParseAsync,
                  i: undefined,
                  m: emptyMetadataMap
                };
      case /* Bool */3 :
          var bool = innerLiteral._0;
          var serializeTransformationFactory$6 = makeSerializeTransformationFactory(bool);
          var parseTransformationFactory$3 = makeParseTransformationFactory(bool, (function (input) {
                  return typeof input === "boolean";
                }));
          var name$2 = "Bool Literal (" + bool.toString() + ")";
          return {
                  n: name$2,
                  t: tagged,
                  pf: parseTransformationFactory$3,
                  sf: serializeTransformationFactory$6,
                  r: 0,
                  e: 0,
                  s: initialSerialize,
                  j: initialSerializeToJson,
                  p: intitialParse,
                  a: intitialParseAsync,
                  i: undefined,
                  m: emptyMetadataMap
                };
      
    }
  }
}

function factory$2(innerLiteral) {
  if (typeof innerLiteral === "number") {
    return factory$1(innerLiteral, undefined);
  } else {
    return factory$1(innerLiteral, innerLiteral._0);
  }
}

var metadataId = "rescript-struct:Object.UnknownKeys";

function classify$1(struct) {
  var t = Js_dict.get(struct.m, metadataId);
  if (t !== undefined) {
    return t;
  } else {
    return /* Strip */1;
  }
}

function analyzeDefinition$1(definition, definerCtx, path) {
  if (definerCtx.s.has(definition)) {
    if (definition.r) {
      throw new Error("[rescript-struct] " + ("The field \"" + definition.n + "\" is registered multiple times. If you want to duplicate a field, use S.transform instead.") + "");
    }
    definition.p = path;
    definition.r = true;
    return ;
  }
  if (typeof definition === "object" && definition !== null) {
    definerCtx.p.push(path);
    definerCtx.v.push(Array.isArray(definition) ? "[]" : "{}");
    var definitionFieldNames = Object.keys(definition);
    for(var idx = 0 ,idx_finish = definitionFieldNames.length; idx < idx_finish; ++idx){
      var definitionFieldName = definitionFieldNames[idx];
      var fieldDefinition = definition[definitionFieldName];
      analyzeDefinition$1(fieldDefinition, definerCtx, path + ("[" + JSON.stringify(definitionFieldName) + "]"));
    }
    return ;
  }
  definerCtx.c.push({
        v: definition,
        p: path
      });
}

function factory$3(definer) {
  var definerCtx_n = [];
  var definerCtx_f = {};
  var definerCtx_d = [];
  var definerCtx_p = [];
  var definerCtx_v = [];
  var definerCtx_c = [];
  var definerCtx_s = new Set();
  var definerCtx = {
    n: definerCtx_n,
    f: definerCtx_f,
    d: definerCtx_d,
    p: definerCtx_p,
    v: definerCtx_v,
    c: definerCtx_c,
    s: definerCtx_s
  };
  var definition = definer(definerCtx);
  analyzeDefinition$1(definition, definerCtx, "");
  var serializeTransformationFactory = function (ctx) {
    var inliningFieldNameRef = undefined;
    try {
      var constantDefinitions = definerCtx_c;
      var fieldDefinitions = definerCtx_d;
      var serializeFnsByFieldDefinitionIdx = {};
      var stringRef = "";
      for(var idx = 0 ,idx_finish = constantDefinitions.length; idx < idx_finish; ++idx){
        var match = constantDefinitions[idx];
        var path = match.p;
        var content = "r(" + idx.toString() + ",t" + path + ")";
        var condition = "t" + path + "!==d[" + idx.toString() + "].v";
        stringRef = stringRef + ("if(" + condition + "){" + content + "}");
      }
      var constants = stringRef;
      var contentRef = "var i;return{";
      for(var idx$1 = 0 ,idx_finish$1 = fieldDefinitions.length; idx$1 < idx_finish$1; ++idx$1){
        var fieldDefinition = fieldDefinitions[idx$1];
        var inlinedFieldName = fieldDefinition.i;
        var fieldStruct = fieldDefinition.s;
        var path$1 = fieldDefinition.p;
        var isRegistered = fieldDefinition.r;
        var inlinedIdx = idx$1.toString();
        var tmp;
        if (isRegistered) {
          var fn = getSerializeOperation(fieldStruct);
          if (fn !== undefined) {
            serializeFnsByFieldDefinitionIdx[inlinedIdx] = fn;
            tmp = "" + inlinedFieldName + ":(i=" + inlinedIdx + ",s[" + inlinedIdx + "](t" + path$1 + ")),";
          } else {
            tmp = "" + inlinedFieldName + ":t" + path$1 + ",";
          }
        } else {
          inliningFieldNameRef = fieldDefinition.n;
          tmp = "" + inlinedFieldName + ":" + internalToInlinedValue(fieldStruct) + ",";
        }
        contentRef = contentRef + tmp;
      }
      var tryContent = contentRef + "}";
      var originalObjectConstructionAndReturn = "try{" + tryContent + "}catch(e){c(e,i)}";
      var inlinedSerializeFunction = "function(t){" + ("" + constants + "" + originalObjectConstructionAndReturn + "") + "}";
      planSyncTransformation(ctx, new Function("s", "d", "r", "c", "return " + inlinedSerializeFunction + "")(serializeFnsByFieldDefinitionIdx, constantDefinitions, (function (fieldDefinitionIdx, received) {
                  var match = constantDefinitions[fieldDefinitionIdx];
                  return raise(match.v, received, match.p, undefined);
                }), (function (exn, fieldDefinitionIdx) {
                  var tmp;
                  if (exn.RE_EXN_ID === Exception) {
                    var match = fieldDefinitions[fieldDefinitionIdx];
                    var path = match.p;
                    tmp = {
                      RE_EXN_ID: Exception,
                      _1: prependPath(exn._1, path)
                    };
                  } else {
                    tmp = exn;
                  }
                  throw tmp;
                })));
    }
    catch (exn){
      var inliningOriginalFieldName = inliningFieldNameRef;
      planSyncTransformation(ctx, (function (param) {
              throw {
                    RE_EXN_ID: Exception,
                    _1: {
                      c: /* MissingSerializer */1,
                      p: "[" + JSON.stringify(inliningOriginalFieldName) + "]"
                    },
                    Error: new Error()
                  };
            }));
    }
  };
  var parseTransformationFactory = function (ctx) {
    var constantDefinitions = definerCtx_c;
    var inlinedPreparationValues = definerCtx_v;
    var preparationPathes = definerCtx_p;
    var fieldDefinitions = definerCtx_d;
    var withUnknownKeysRefinement = classify$1(ctx.s) === /* Strict */0;
    var asyncFieldDefinitions = [];
    var parseFnsByInstructionIdx = {};
    var withFieldDefinitions = fieldDefinitions.length !== 0;
    var refinement = "if(!(typeof o===\"object\"&&o!==null&&!Array.isArray(o))){u(o)}";
    var stringRef = "var t;";
    for(var idx = 0 ,idx_finish = preparationPathes.length; idx < idx_finish; ++idx){
      var preparationPath = preparationPathes[idx];
      var preparationInlinedValue = inlinedPreparationValues[idx];
      stringRef = stringRef + ("t" + preparationPath + "=" + preparationInlinedValue + ";");
    }
    var preparation = stringRef;
    var transformedObjectConstruction;
    if (withFieldDefinitions) {
      var stringRef$1 = "";
      for(var idx$1 = 0 ,idx_finish$1 = fieldDefinitions.length; idx$1 < idx_finish$1; ++idx$1){
        var fieldDefinition = fieldDefinitions[idx$1];
        var inlinedFieldName = fieldDefinition.i;
        var fieldStruct = fieldDefinition.s;
        var path = fieldDefinition.p;
        var isRegistered = fieldDefinition.r;
        var inlinedIdx = idx$1.toString();
        var parseOperation = getParseOperation(fieldStruct);
        var maybeParseFn;
        maybeParseFn = typeof parseOperation === "number" ? undefined : parseOperation._0;
        var isAsync;
        isAsync = typeof parseOperation === "number" || parseOperation.TAG === /* SyncOperation */0 ? false : true;
        var inlinedInputData = "o[" + inlinedFieldName + "]";
        var maybeInlinedDestination;
        if (isAsync) {
          if (asyncFieldDefinitions.length === 0) {
            stringRef$1 = stringRef$1 + "var a={};";
          }
          if (isRegistered) {
            stringRef$1 = stringRef$1 + ("t" + path + "=undefined;");
          }
          var inlinedDestination = "a[" + asyncFieldDefinitions.length.toString() + "]";
          asyncFieldDefinitions.push(fieldDefinition);
          maybeInlinedDestination = inlinedDestination;
        } else {
          maybeInlinedDestination = isRegistered ? "t" + path + "" : undefined;
        }
        var match = fieldStruct.i;
        stringRef$1 = stringRef$1 + (
          maybeParseFn !== undefined ? (
              match !== undefined ? "var v=" + inlinedInputData + ";if(" + match + "){" + (
                  maybeInlinedDestination !== undefined ? "" + maybeInlinedDestination + "=v" : ""
                ) + "}else{i=" + inlinedIdx + ";s(v,f[" + inlinedFieldName + "])}" : (parseFnsByInstructionIdx[inlinedIdx] = maybeParseFn, "i=" + inlinedIdx + ";" + (
                    maybeInlinedDestination !== undefined ? "" + maybeInlinedDestination + "=" : ""
                  ) + "p[" + inlinedIdx + "](" + inlinedInputData + ");")
            ) : (
              maybeInlinedDestination !== undefined ? "" + maybeInlinedDestination + "=" + inlinedInputData + ";" : ""
            )
        );
      }
      var tryContent = stringRef$1;
      transformedObjectConstruction = "var i;" + ("try{" + tryContent + "}catch(e){c(e,i)}");
    } else {
      transformedObjectConstruction = "";
    }
    var unknownKeysRefinement;
    if (withUnknownKeysRefinement) {
      if (withFieldDefinitions) {
        var stringRef$2 = "for(var k in o){if(!(";
        for(var idx$2 = 0 ,idx_finish$2 = fieldDefinitions.length; idx$2 < idx_finish$2; ++idx$2){
          var fieldDefinition$1 = fieldDefinitions[idx$2];
          if (idx$2 !== 0) {
            stringRef$2 = stringRef$2 + "||";
          }
          stringRef$2 = stringRef$2 + ("k===" + fieldDefinition$1.i + "");
        }
        unknownKeysRefinement = stringRef$2 + ")){x(k)}}";
      } else {
        unknownKeysRefinement = "for(var k in o){x(k)}";
      }
    } else {
      unknownKeysRefinement = "";
    }
    var stringRef$3 = "";
    for(var idx$3 = 0 ,idx_finish$3 = constantDefinitions.length; idx$3 < idx_finish$3; ++idx$3){
      var constantDefinition = constantDefinitions[idx$3];
      stringRef$3 = stringRef$3 + ("t" + constantDefinition.p + "=d[" + idx$3.toString() + "].v;");
    }
    var constants = stringRef$3;
    var returnValue = asyncFieldDefinitions.length === 0 ? "t" : "a.t=t,a";
    var inlinedParseFunction = "function(o){" + ("" + refinement + "" + preparation + "" + transformedObjectConstruction + "" + unknownKeysRefinement + "" + constants + "return " + returnValue + "") + "}";
    planSyncTransformation(ctx, new Function("c", "p", "f", "d", "u", "s", "x", "return " + inlinedParseFunction + "")((function (exn, fieldDefinitionIdx) {
                throw exn.RE_EXN_ID === Exception ? ({
                          RE_EXN_ID: Exception,
                          _1: prependPath(exn._1, "[" + JSON.stringify(fieldDefinitions[fieldDefinitionIdx].n) + "]")
                        }) : exn;
              }), parseFnsByInstructionIdx, definerCtx_f, constantDefinitions, (function (input) {
                return raiseUnexpectedTypeError(input, ctx.s);
              }), raiseUnexpectedTypeError, (function (exccessFieldName) {
                return raise$1({
                            TAG: /* ExcessField */4,
                            _0: exccessFieldName
                          });
              })));
    if (asyncFieldDefinitions.length <= 0) {
      return ;
    }
    var resolveVar = "rs";
    var rejectVar = "rj";
    var contentRef = "var y=" + asyncFieldDefinitions.length.toString() + ",t=a.t;";
    for(var idx$4 = 0 ,idx_finish$4 = asyncFieldDefinitions.length; idx$4 < idx_finish$4; ++idx$4){
      var fieldDefinition$2 = asyncFieldDefinitions[idx$4];
      var path$1 = fieldDefinition$2.p;
      var isRegistered$1 = fieldDefinition$2.r;
      var inlinedIdx$1 = idx$4.toString();
      var fieldValueVar = "z";
      var inlinedFieldValueAssignment = isRegistered$1 ? "t" + path$1 + "=" + fieldValueVar + "" : "";
      var inlinedIteration = "if(y--===1){" + ("" + resolveVar + "(t)") + "}";
      var onFieldSuccessInlinedFnContent = "" + inlinedFieldValueAssignment + ";" + inlinedIteration + "";
      var onFieldSuccessInlinedFn = "function(" + fieldValueVar + "){" + onFieldSuccessInlinedFnContent + "}";
      var errorVar = "z";
      var onFieldErrorInlinedFn = "function(" + errorVar + "){" + ("" + rejectVar + "(j(" + errorVar + "," + inlinedIdx$1 + "))") + "}";
      contentRef = contentRef + ("a[" + inlinedIdx$1 + "]().then(" + onFieldSuccessInlinedFn + "," + onFieldErrorInlinedFn + ");");
    }
    var content = contentRef;
    var inlinedAsyncParseFunction = "function(a){" + ("return " + ("new Promise(function(" + resolveVar + "," + rejectVar + "){" + content + "})") + "") + "}";
    planAsyncTransformation(ctx, new Function("j", "return " + inlinedAsyncParseFunction + "")(function (exn, asyncFieldDefinitionIdx) {
              if (exn.RE_EXN_ID === Exception) {
                return {
                        RE_EXN_ID: Exception,
                        _1: prependPath(exn._1, "[" + JSON.stringify(asyncFieldDefinitions[asyncFieldDefinitionIdx].n) + "]")
                      };
              } else {
                return exn;
              }
            }));
  };
  return {
          n: "Object",
          t: {
            TAG: /* Object */4,
            fields: definerCtx_f,
            fieldNames: definerCtx_n
          },
          pf: parseTransformationFactory,
          sf: serializeTransformationFactory,
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: undefined,
          m: emptyMetadataMap
        };
}

function field(definerCtx, fieldName, struct) {
  if (definerCtx.f.hasOwnProperty(fieldName)) {
    throw new Error("[rescript-struct] " + ("The field \"" + fieldName + "\" is defined multiple times. If you want to duplicate a field, use S.transform instead.") + "");
  }
  var fieldDefinition = {
    s: struct,
    i: JSON.stringify(fieldName),
    n: fieldName,
    p: "",
    r: false
  };
  definerCtx.f[fieldName] = struct;
  definerCtx.n.push(fieldName);
  definerCtx.d.push(fieldDefinition);
  definerCtx.s.add(fieldDefinition);
  return fieldDefinition;
}

function strip(struct) {
  return set(struct, metadataId, /* Strip */1);
}

function strict(struct) {
  return set(struct, metadataId, /* Strict */0);
}

function transformationFactory(ctx) {
  planSyncTransformation(ctx, (function (input) {
          return raiseUnexpectedTypeError(input, ctx.s);
        }));
}

function factory$4(param) {
  return {
          n: "Never",
          t: /* Never */0,
          pf: transformationFactory,
          sf: transformationFactory,
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: "false",
          m: emptyMetadataMap
        };
}

function factory$5(param) {
  return {
          n: "Unknown",
          t: /* Unknown */1,
          pf: empty,
          sf: empty,
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: undefined,
          m: emptyMetadataMap
        };
}

var metadataId$1 = "rescript-struct:String.refinements";

function refinements(struct) {
  var m = Js_dict.get(struct.m, metadataId$1);
  if (m !== undefined) {
    return m;
  } else {
    return [];
  }
}

var cuidRegex = /^c[^\s-]{8,}$/i;

var uuidRegex = /^([a-f0-9]{8}-[a-f0-9]{4}-[1-5][a-f0-9]{3}-[a-f0-9]{4}-[a-f0-9]{12}|00000000-0000-0000-0000-000000000000)$/i;

var emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\])|(\[IPv6:(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))\])|([A-Za-z0-9]([A-Za-z0-9-]*[A-Za-z0-9])*(\.[A-Za-z]{2,})+))$/;

var datetimeRe = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z$/;

function parseTransformationFactory(ctx) {
  planSyncTransformation(ctx, (function (input) {
          if (typeof input === "string") {
            return input;
          } else {
            return raiseUnexpectedTypeError(input, ctx.s);
          }
        }));
}

function factory$6(param) {
  return {
          n: "String",
          t: /* String */2,
          pf: parseTransformationFactory,
          sf: empty,
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: "typeof v===\"string\"",
          m: emptyMetadataMap
        };
}

function min(struct, maybeMessage, length) {
  var message = maybeMessage !== undefined ? maybeMessage : "String must be " + length.toString() + " or more characters long";
  var refiner = function (value) {
    if (value.length < length) {
      return fail(undefined, message);
    }
    
  };
  return addRefinement(struct, metadataId$1, {
              kind: {
                TAG: /* Min */0,
                length: length
              },
              message: message
            }, refiner);
}

function max(struct, maybeMessage, length) {
  var message = maybeMessage !== undefined ? maybeMessage : "String must be " + length.toString() + " or fewer characters long";
  var refiner = function (value) {
    if (value.length > length) {
      return fail(undefined, message);
    }
    
  };
  return addRefinement(struct, metadataId$1, {
              kind: {
                TAG: /* Max */1,
                length: length
              },
              message: message
            }, refiner);
}

function length(struct, maybeMessage, length$1) {
  var message = maybeMessage !== undefined ? maybeMessage : "String must be exactly " + length$1.toString() + " characters long";
  var refiner = function (value) {
    if (value.length !== length$1) {
      return fail(undefined, message);
    }
    
  };
  return addRefinement(struct, metadataId$1, {
              kind: {
                TAG: /* Length */2,
                length: length$1
              },
              message: message
            }, refiner);
}

function email(struct, messageOpt, param) {
  var message = messageOpt !== undefined ? messageOpt : "Invalid email address";
  var refiner = function (value) {
    if (!emailRegex.test(value)) {
      return fail(undefined, message);
    }
    
  };
  return addRefinement(struct, metadataId$1, {
              kind: /* Email */0,
              message: message
            }, refiner);
}

function uuid(struct, messageOpt, param) {
  var message = messageOpt !== undefined ? messageOpt : "Invalid UUID";
  var refiner = function (value) {
    if (!uuidRegex.test(value)) {
      return fail(undefined, message);
    }
    
  };
  return addRefinement(struct, metadataId$1, {
              kind: /* Uuid */1,
              message: message
            }, refiner);
}

function cuid(struct, messageOpt, param) {
  var message = messageOpt !== undefined ? messageOpt : "Invalid CUID";
  var refiner = function (value) {
    if (!cuidRegex.test(value)) {
      return fail(undefined, message);
    }
    
  };
  return addRefinement(struct, metadataId$1, {
              kind: /* Cuid */2,
              message: message
            }, refiner);
}

function url(struct, messageOpt, param) {
  var message = messageOpt !== undefined ? messageOpt : "Invalid url";
  var refiner = function (value) {
    var tmp;
    try {
      new URL(value);
      tmp = true;
    }
    catch (exn){
      tmp = false;
    }
    if (!tmp) {
      return fail(undefined, message);
    }
    
  };
  return addRefinement(struct, metadataId$1, {
              kind: /* Url */3,
              message: message
            }, refiner);
}

function pattern(struct, messageOpt, re) {
  var message = messageOpt !== undefined ? messageOpt : "Invalid";
  var refiner = function (value) {
    re.lastIndex = 0;
    if (!re.test(value)) {
      return fail(undefined, message);
    }
    
  };
  return addRefinement(struct, metadataId$1, {
              kind: {
                TAG: /* Pattern */3,
                re: re
              },
              message: message
            }, refiner);
}

function datetime(struct, messageOpt, param) {
  var message = messageOpt !== undefined ? messageOpt : "Invalid datetime string! Must be UTC";
  var refinement = {
    kind: /* Datetime */4,
    message: message
  };
  var refinements = Js_dict.get(struct.m, metadataId$1);
  return transform(set(struct, metadataId$1, refinements !== undefined ? refinements.concat(refinement) : [refinement]), (function (string) {
                if (!datetimeRe.test(string)) {
                  fail(undefined, message);
                }
                return new Date(string);
              }), undefined, (function (date) {
                return date.toISOString();
              }), undefined);
}

function trim(struct, param) {
  var transformer = function (string) {
    return string.trim();
  };
  return transform(struct, transformer, undefined, transformer, undefined);
}

function factory$7(innerStruct) {
  return {
          n: "Json",
          t: /* String */2,
          pf: (function (ctx) {
              var fn = getParseOperation(innerStruct);
              var $$process = typeof fn === "number" ? (function (prim) {
                    return prim;
                  }) : fn._0;
              planSyncTransformation(ctx, (function (input) {
                      if (typeof input !== "string") {
                        return raiseUnexpectedTypeError(input, ctx.s);
                      }
                      var __x;
                      try {
                        __x = JSON.parse(input);
                      }
                      catch (raw_obj){
                        var obj = Caml_js_exceptions.internalToOCamlException(raw_obj);
                        if (obj.RE_EXN_ID === Js_exn.$$Error) {
                          var m = obj._1.message;
                          __x = fail(undefined, m !== undefined ? m : "Failed to parse JSON");
                        } else {
                          throw obj;
                        }
                      }
                      return $$process(__x);
                    }));
              var match = getParseOperation(innerStruct);
              if (typeof match === "number" || match.TAG === /* SyncOperation */0) {
                return ;
              } else {
                return planAsyncTransformation(ctx, (function (asyncFn) {
                              return asyncFn();
                            }));
              }
            }),
          sf: (function (ctx) {
              var fn = getSerializeOperation(innerStruct);
              if (fn !== undefined) {
                return planSyncTransformation(ctx, (function (input) {
                              return JSON.stringify(fn(input));
                            }));
              } else {
                return planSyncTransformation(ctx, (function (input) {
                              return JSON.stringify(input);
                            }));
              }
            }),
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: undefined,
          m: emptyMetadataMap
        };
}

function parseTransformationFactory$1(ctx) {
  planSyncTransformation(ctx, (function (input) {
          if (typeof input === "boolean") {
            return input;
          } else {
            return raiseUnexpectedTypeError(input, ctx.s);
          }
        }));
}

function factory$8(param) {
  return {
          n: "Bool",
          t: /* Bool */5,
          pf: parseTransformationFactory$1,
          sf: empty,
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: "typeof v===\"boolean\"",
          m: emptyMetadataMap
        };
}

var metadataId$2 = "rescript-struct:Int.refinements";

function refinements$1(struct) {
  var m = Js_dict.get(struct.m, metadataId$2);
  if (m !== undefined) {
    return m;
  } else {
    return [];
  }
}

function parseTransformationFactory$2(ctx) {
  planSyncTransformation(ctx, (function (input) {
          if (typeof input === "number" && input < 2147483648 && input > -2147483649 && input % 1 === 0) {
            return input;
          } else {
            return raiseUnexpectedTypeError(input, ctx.s);
          }
        }));
}

function factory$9(param) {
  return {
          n: "Int",
          t: /* Int */3,
          pf: parseTransformationFactory$2,
          sf: empty,
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: "typeof v===\"number\"&&v<2147483648&&v>-2147483649&&v%1===0",
          m: emptyMetadataMap
        };
}

function min$1(struct, maybeMessage, minValue) {
  var message = maybeMessage !== undefined ? maybeMessage : "Number must be greater than or equal to " + minValue.toString() + "";
  var refiner = function (value) {
    if (value < minValue) {
      return fail(undefined, message);
    }
    
  };
  return addRefinement(struct, metadataId$2, {
              kind: {
                TAG: /* Min */0,
                value: minValue
              },
              message: message
            }, refiner);
}

function max$1(struct, maybeMessage, maxValue) {
  var message = maybeMessage !== undefined ? maybeMessage : "Number must be lower than or equal to " + maxValue.toString() + "";
  var refiner = function (value) {
    if (value > maxValue) {
      return fail(undefined, message);
    }
    
  };
  return addRefinement(struct, metadataId$2, {
              kind: {
                TAG: /* Max */1,
                value: maxValue
              },
              message: message
            }, refiner);
}

function port(struct, messageOpt, param) {
  var message = messageOpt !== undefined ? messageOpt : "Invalid port";
  var refiner = function (value) {
    if (value < 1 || value > 65535) {
      return fail(undefined, message);
    }
    
  };
  return addRefinement(struct, metadataId$2, {
              kind: /* Port */0,
              message: message
            }, refiner);
}

var metadataId$3 = "rescript-struct:Float.refinements";

function refinements$2(struct) {
  var m = Js_dict.get(struct.m, metadataId$3);
  if (m !== undefined) {
    return m;
  } else {
    return [];
  }
}

function parseTransformationFactory$3(ctx) {
  planSyncTransformation(ctx, (function (input) {
          if (typeof input === "number" && !Number.isNaN(input)) {
            return input;
          } else {
            return raiseUnexpectedTypeError(input, ctx.s);
          }
        }));
}

function factory$10(param) {
  return {
          n: "Float",
          t: /* Float */4,
          pf: parseTransformationFactory$3,
          sf: empty,
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: "typeof v===\"number\"&&!Number.isNaN(v)",
          m: emptyMetadataMap
        };
}

function min$2(struct, maybeMessage, minValue) {
  var message = maybeMessage !== undefined ? maybeMessage : "Number must be greater than or equal to " + minValue.toString() + "";
  var refiner = function (value) {
    if (value < minValue) {
      return fail(undefined, message);
    }
    
  };
  return addRefinement(struct, metadataId$3, {
              kind: {
                TAG: /* Min */0,
                value: minValue
              },
              message: message
            }, refiner);
}

function max$2(struct, maybeMessage, maxValue) {
  var message = maybeMessage !== undefined ? maybeMessage : "Number must be lower than or equal to " + maxValue.toString() + "";
  var refiner = function (value) {
    if (value > maxValue) {
      return fail(undefined, message);
    }
    
  };
  return addRefinement(struct, metadataId$3, {
              kind: {
                TAG: /* Max */1,
                value: maxValue
              },
              message: message
            }, refiner);
}

function factory$11(innerStruct) {
  return {
          n: "Null",
          t: {
            TAG: /* Null */2,
            _0: innerStruct
          },
          pf: (function (ctx) {
              var planSyncTransformation$1 = function (fn) {
                planSyncTransformation(ctx, (function (input) {
                        if (input !== null) {
                          return Caml_option.some(fn(input));
                        }
                        
                      }));
              };
              var fn = getParseOperation(innerStruct);
              if (typeof fn === "number") {
                return planSyncTransformation(ctx, (function ($$null) {
                              if ($$null === null) {
                                return ;
                              } else {
                                return Caml_option.some($$null);
                              }
                            }));
              }
              if (fn.TAG === /* SyncOperation */0) {
                return planSyncTransformation$1(fn._0);
              }
              planSyncTransformation$1(fn._0);
              planAsyncTransformation(ctx, (function (input) {
                      if (input !== undefined) {
                        return input().then(function (value) {
                                    return Caml_option.some(value);
                                  });
                      } else {
                        return Promise.resolve(undefined);
                      }
                    }));
            }),
          sf: (function (ctx) {
              var fn = getSerializeOperation(innerStruct);
              if (fn !== undefined) {
                return planSyncTransformation(ctx, (function (input) {
                              if (input !== undefined) {
                                return fn(Caml_option.valFromOption(input));
                              } else {
                                return null;
                              }
                            }));
              } else {
                return planSyncTransformation(ctx, (function (input) {
                              if (input !== undefined) {
                                return Caml_option.valFromOption(input);
                              } else {
                                return null;
                              }
                            }));
              }
            }),
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: undefined,
          m: emptyMetadataMap
        };
}

function factory$12(innerStruct) {
  return {
          n: "Option",
          t: {
            TAG: /* Option */1,
            _0: innerStruct
          },
          pf: (function (ctx) {
              var planSyncTransformation$1 = function (fn) {
                planSyncTransformation(ctx, (function (input) {
                        if (input !== undefined) {
                          return Caml_option.some(fn(Caml_option.valFromOption(input)));
                        }
                        
                      }));
              };
              var fn = getParseOperation(innerStruct);
              if (typeof fn === "number") {
                return ;
              }
              if (fn.TAG === /* SyncOperation */0) {
                return planSyncTransformation$1(fn._0);
              }
              planSyncTransformation$1(fn._0);
              planAsyncTransformation(ctx, (function (input) {
                      if (input !== undefined) {
                        return input().then(function (value) {
                                    return Caml_option.some(value);
                                  });
                      } else {
                        return Promise.resolve(undefined);
                      }
                    }));
            }),
          sf: (function (ctx) {
              var fn = getSerializeOperation(innerStruct);
              if (fn !== undefined) {
                return planSyncTransformation(ctx, (function (input) {
                              if (input !== undefined) {
                                return fn(Caml_option.valFromOption(input));
                              }
                              
                            }));
              } else {
                return planSyncTransformation(ctx, (function (input) {
                              if (input !== undefined) {
                                return Caml_option.valFromOption(input);
                              }
                              
                            }));
              }
            }),
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: undefined,
          m: emptyMetadataMap
        };
}

var metadataId$4 = "rescript-struct:Array.refinements";

function refinements$3(struct) {
  var m = Js_dict.get(struct.m, metadataId$4);
  if (m !== undefined) {
    return m;
  } else {
    return [];
  }
}

function factory$13(innerStruct) {
  return {
          n: "Array",
          t: {
            TAG: /* Array */3,
            _0: innerStruct
          },
          pf: (function (ctx) {
              planSyncTransformation(ctx, (function (input) {
                      if (Array.isArray(input) === false) {
                        return raiseUnexpectedTypeError(input, ctx.s);
                      } else {
                        return input;
                      }
                    }));
              var planSyncTransformation$1 = function (fn) {
                planSyncTransformation(ctx, (function (input) {
                        var newArray = [];
                        for(var idx = 0 ,idx_finish = input.length; idx < idx_finish; ++idx){
                          var innerData = input[idx];
                          try {
                            var value = fn(innerData);
                            newArray.push(value);
                          }
                          catch (raw_internalError){
                            var internalError = Caml_js_exceptions.internalToOCamlException(raw_internalError);
                            if (internalError.RE_EXN_ID === Exception) {
                              var $$location = idx.toString();
                              throw {
                                    RE_EXN_ID: Exception,
                                    _1: prependPath(internalError._1, "[" + JSON.stringify($$location) + "]"),
                                    Error: new Error()
                                  };
                            }
                            throw internalError;
                          }
                        }
                        return newArray;
                      }));
              };
              var fn = getParseOperation(innerStruct);
              if (typeof fn === "number") {
                return ;
              }
              if (fn.TAG === /* SyncOperation */0) {
                return planSyncTransformation$1(fn._0);
              }
              planSyncTransformation$1(fn._0);
              planAsyncTransformation(ctx, (function (input) {
                      return Promise.all(input.map(function (asyncFn, idx) {
                                      return asyncFn().catch(function (exn) {
                                                  var tmp;
                                                  if (exn.RE_EXN_ID === Exception) {
                                                    var $$location = idx.toString();
                                                    tmp = {
                                                      RE_EXN_ID: Exception,
                                                      _1: prependPath(exn._1, "[" + JSON.stringify($$location) + "]")
                                                    };
                                                  } else {
                                                    tmp = exn;
                                                  }
                                                  throw tmp;
                                                });
                                    }));
                    }));
            }),
          sf: (function (ctx) {
              var fn = getSerializeOperation(innerStruct);
              if (fn !== undefined) {
                return planSyncTransformation(ctx, (function (input) {
                              var newArray = [];
                              for(var idx = 0 ,idx_finish = input.length; idx < idx_finish; ++idx){
                                var innerData = input[idx];
                                try {
                                  var value = fn(innerData);
                                  newArray.push(value);
                                }
                                catch (raw_internalError){
                                  var internalError = Caml_js_exceptions.internalToOCamlException(raw_internalError);
                                  if (internalError.RE_EXN_ID === Exception) {
                                    var $$location = idx.toString();
                                    throw {
                                          RE_EXN_ID: Exception,
                                          _1: prependPath(internalError._1, "[" + JSON.stringify($$location) + "]"),
                                          Error: new Error()
                                        };
                                  }
                                  throw internalError;
                                }
                              }
                              return newArray;
                            }));
              }
              
            }),
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: undefined,
          m: emptyMetadataMap
        };
}

function min$3(struct, maybeMessage, length) {
  var message = maybeMessage !== undefined ? maybeMessage : "Array must be " + length.toString() + " or more items long";
  var refiner = function (value) {
    if (value.length < length) {
      return fail(undefined, message);
    }
    
  };
  return addRefinement(struct, metadataId$4, {
              kind: {
                TAG: /* Min */0,
                length: length
              },
              message: message
            }, refiner);
}

function max$3(struct, maybeMessage, length) {
  var message = maybeMessage !== undefined ? maybeMessage : "Array must be " + length.toString() + " or fewer items long";
  var refiner = function (value) {
    if (value.length > length) {
      return fail(undefined, message);
    }
    
  };
  return addRefinement(struct, metadataId$4, {
              kind: {
                TAG: /* Max */1,
                length: length
              },
              message: message
            }, refiner);
}

function length$1(struct, maybeMessage, length$2) {
  var message = maybeMessage !== undefined ? maybeMessage : "Array must be exactly " + length$2.toString() + " items long";
  var refiner = function (value) {
    if (value.length !== length$2) {
      return fail(undefined, message);
    }
    
  };
  return addRefinement(struct, metadataId$4, {
              kind: {
                TAG: /* Length */2,
                length: length$2
              },
              message: message
            }, refiner);
}

function factory$14(innerStruct) {
  return {
          n: "Dict",
          t: {
            TAG: /* Dict */7,
            _0: innerStruct
          },
          pf: (function (ctx) {
              var planSyncTransformation$1 = function (fn) {
                planSyncTransformation(ctx, (function (input) {
                        var newDict = {};
                        var keys = Object.keys(input);
                        for(var idx = 0 ,idx_finish = keys.length; idx < idx_finish; ++idx){
                          var key = keys[idx];
                          var innerData = input[key];
                          try {
                            var value = fn(innerData);
                            newDict[key] = value;
                          }
                          catch (raw_internalError){
                            var internalError = Caml_js_exceptions.internalToOCamlException(raw_internalError);
                            if (internalError.RE_EXN_ID === Exception) {
                              throw {
                                    RE_EXN_ID: Exception,
                                    _1: prependPath(internalError._1, "[" + JSON.stringify(key) + "]"),
                                    Error: new Error()
                                  };
                            }
                            throw internalError;
                          }
                        }
                        return newDict;
                      }));
              };
              planSyncTransformation(ctx, (function (input) {
                      if ((typeof input === "object" && input !== null && !Array.isArray(input)) === false) {
                        return raiseUnexpectedTypeError(input, ctx.s);
                      } else {
                        return input;
                      }
                    }));
              var fn = getParseOperation(innerStruct);
              if (typeof fn === "number") {
                return ;
              }
              if (fn.TAG === /* SyncOperation */0) {
                return planSyncTransformation$1(fn._0);
              }
              planSyncTransformation$1(fn._0);
              planAsyncTransformation(ctx, (function (input) {
                      var keys = Object.keys(input);
                      return Promise.all(keys.map(function (key) {
                                        var asyncFn = input[key];
                                        try {
                                          return asyncFn().catch(function (exn) {
                                                      throw exn.RE_EXN_ID === Exception ? ({
                                                                RE_EXN_ID: Exception,
                                                                _1: prependPath(exn._1, "[" + JSON.stringify(key) + "]")
                                                              }) : exn;
                                                    });
                                        }
                                        catch (raw_internalError){
                                          var internalError = Caml_js_exceptions.internalToOCamlException(raw_internalError);
                                          if (internalError.RE_EXN_ID === Exception) {
                                            throw {
                                                  RE_EXN_ID: Exception,
                                                  _1: prependPath(internalError._1, "[" + JSON.stringify(key) + "]"),
                                                  Error: new Error()
                                                };
                                          }
                                          throw internalError;
                                        }
                                      })).then(function (values) {
                                  var tempDict = {};
                                  values.forEach(function (value, idx) {
                                        var key = keys[idx];
                                        tempDict[key] = value;
                                      });
                                  return tempDict;
                                });
                    }));
            }),
          sf: (function (ctx) {
              var fn = getSerializeOperation(innerStruct);
              if (fn !== undefined) {
                return planSyncTransformation(ctx, (function (input) {
                              var newDict = {};
                              var keys = Object.keys(input);
                              for(var idx = 0 ,idx_finish = keys.length; idx < idx_finish; ++idx){
                                var key = keys[idx];
                                var innerData = input[key];
                                try {
                                  var value = fn(innerData);
                                  newDict[key] = value;
                                }
                                catch (raw_internalError){
                                  var internalError = Caml_js_exceptions.internalToOCamlException(raw_internalError);
                                  if (internalError.RE_EXN_ID === Exception) {
                                    throw {
                                          RE_EXN_ID: Exception,
                                          _1: prependPath(internalError._1, "[" + JSON.stringify(key) + "]"),
                                          Error: new Error()
                                        };
                                  }
                                  throw internalError;
                                }
                              }
                              return newDict;
                            }));
              }
              
            }),
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: undefined,
          m: emptyMetadataMap
        };
}

var metadataId$5 = "rescript-struct:Default";

function factory$15(innerStruct, getDefaultValue) {
  return set({
              n: innerStruct.n,
              t: innerStruct.t,
              pf: (function (ctx) {
                  var fn = getParseOperation(innerStruct);
                  if (typeof fn === "number") {
                    return planSyncTransformation(ctx, (function (input) {
                                  if (input !== undefined) {
                                    return Caml_option.valFromOption(input);
                                  } else {
                                    return getDefaultValue();
                                  }
                                }));
                  }
                  if (fn.TAG === /* SyncOperation */0) {
                    var fn$1 = fn._0;
                    return planSyncTransformation(ctx, (function (input) {
                                  var v = fn$1(input);
                                  if (v !== undefined) {
                                    return Caml_option.valFromOption(v);
                                  } else {
                                    return getDefaultValue();
                                  }
                                }));
                  }
                  planSyncTransformation(ctx, fn._0);
                  planAsyncTransformation(ctx, (function (asyncFn) {
                          return asyncFn().then(function (value) {
                                      if (value !== undefined) {
                                        return Caml_option.valFromOption(value);
                                      } else {
                                        return getDefaultValue();
                                      }
                                    });
                        }));
                }),
              sf: (function (ctx) {
                  var fn = getSerializeOperation(innerStruct);
                  if (fn !== undefined) {
                    return planSyncTransformation(ctx, (function (input) {
                                  var value = Caml_option.some(input);
                                  return fn(value);
                                }));
                  } else {
                    return planSyncTransformation(ctx, (function (input) {
                                  return Caml_option.some(input);
                                }));
                  }
                }),
              r: 0,
              e: 0,
              s: initialSerialize,
              j: initialSerializeToJson,
              p: intitialParse,
              a: intitialParseAsync,
              i: undefined,
              m: emptyMetadataMap
            }, metadataId$5, getDefaultValue);
}

function classify$2(struct) {
  var getDefaultValue = Js_dict.get(struct.m, metadataId$5);
  if (getDefaultValue !== undefined) {
    return Caml_option.some(getDefaultValue());
  }
  
}

function factory$16(param) {
  var structs = (Array.from(arguments));
  var structs$1 = structs.length === 1 && structs[0] === undefined ? [] : structs;
  var numberOfStructs = structs$1.length;
  return {
          n: "Tuple",
          t: {
            TAG: /* Tuple */5,
            _0: structs$1
          },
          pf: (function (ctx) {
              var noopOps = [];
              var syncOps = [];
              var asyncOps = [];
              for(var idx = 0 ,idx_finish = structs$1.length; idx < idx_finish; ++idx){
                var innerStruct = structs$1[idx];
                var fn = getParseOperation(innerStruct);
                if (typeof fn === "number") {
                  noopOps.push(idx);
                } else if (fn.TAG === /* SyncOperation */0) {
                  syncOps.push([
                        idx,
                        fn._0
                      ]);
                } else {
                  syncOps.push([
                        idx,
                        fn._0
                      ]);
                  asyncOps.push(idx);
                }
              }
              var withAsyncOps = asyncOps.length > 0;
              planSyncTransformation(ctx, (function (input) {
                      if (Array.isArray(input)) {
                        var numberOfInputItems = input.length;
                        if (numberOfStructs !== numberOfInputItems) {
                          raise$1({
                                TAG: /* TupleSize */3,
                                expected: numberOfStructs,
                                received: numberOfInputItems
                              });
                        }
                        
                      } else {
                        raiseUnexpectedTypeError(input, ctx.s);
                      }
                      var newArray = [];
                      for(var idx = 0 ,idx_finish = syncOps.length; idx < idx_finish; ++idx){
                        var match = syncOps[idx];
                        var originalIdx = match[0];
                        var innerData = input[originalIdx];
                        try {
                          var value = match[1](innerData);
                          newArray[originalIdx] = value;
                        }
                        catch (raw_internalError){
                          var internalError = Caml_js_exceptions.internalToOCamlException(raw_internalError);
                          if (internalError.RE_EXN_ID === Exception) {
                            var $$location = idx.toString();
                            throw {
                                  RE_EXN_ID: Exception,
                                  _1: prependPath(internalError._1, "[" + JSON.stringify($$location) + "]"),
                                  Error: new Error()
                                };
                          }
                          throw internalError;
                        }
                      }
                      for(var idx$1 = 0 ,idx_finish$1 = noopOps.length; idx$1 < idx_finish$1; ++idx$1){
                        var originalIdx$1 = noopOps[idx$1];
                        var innerData$1 = input[originalIdx$1];
                        newArray[originalIdx$1] = innerData$1;
                      }
                      if (withAsyncOps) {
                        return newArray;
                      } else if (numberOfStructs !== 0) {
                        if (numberOfStructs !== 1) {
                          return newArray;
                        } else {
                          return newArray[0];
                        }
                      } else {
                        return ;
                      }
                    }));
              if (withAsyncOps) {
                return planAsyncTransformation(ctx, (function (tempArray) {
                              return Promise.all(asyncOps.map(function (originalIdx) {
                                                return tempArray[originalIdx]().catch(function (exn) {
                                                            var tmp;
                                                            if (exn.RE_EXN_ID === Exception) {
                                                              var $$location = originalIdx.toString();
                                                              tmp = {
                                                                RE_EXN_ID: Exception,
                                                                _1: prependPath(exn._1, "[" + JSON.stringify($$location) + "]")
                                                              };
                                                            } else {
                                                              tmp = exn;
                                                            }
                                                            throw tmp;
                                                          });
                                              })).then(function (values) {
                                          values.forEach(function (value, idx) {
                                                var originalIdx = asyncOps[idx];
                                                tempArray[originalIdx] = value;
                                              });
                                          if (tempArray.length <= 1) {
                                            return tempArray[0];
                                          } else {
                                            return tempArray;
                                          }
                                        });
                            }));
              }
              
            }),
          sf: (function (ctx) {
              var serializeOperations = [];
              for(var idx = 0 ,idx_finish = structs$1.length; idx < idx_finish; ++idx){
                serializeOperations.push(getSerializeOperation(structs$1[idx]));
              }
              planSyncTransformation(ctx, (function (input) {
                      var inputArray = numberOfStructs === 1 ? [input] : input;
                      var newArray = [];
                      for(var idx = 0 ,idx_finish = serializeOperations.length; idx < idx_finish; ++idx){
                        var innerData = inputArray[idx];
                        var serializeOperation = serializeOperations[idx];
                        if (serializeOperation !== undefined) {
                          try {
                            var value = serializeOperation(innerData);
                            newArray.push(value);
                          }
                          catch (raw_internalError){
                            var internalError = Caml_js_exceptions.internalToOCamlException(raw_internalError);
                            if (internalError.RE_EXN_ID === Exception) {
                              var $$location = idx.toString();
                              throw {
                                    RE_EXN_ID: Exception,
                                    _1: prependPath(internalError._1, "[" + JSON.stringify($$location) + "]"),
                                    Error: new Error()
                                  };
                            }
                            throw internalError;
                          }
                        } else {
                          newArray.push(innerData);
                        }
                      }
                      return newArray;
                    }));
            }),
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: undefined,
          m: emptyMetadataMap
        };
}

var Tuple = {
  factory: factory$16
};

var HackyValidValue = /* @__PURE__ */Caml_exceptions.create("S-RescriptStruct.Union.HackyValidValue");

function factory$17(structs) {
  if (structs.length < 2) {
    throw new Error("[rescript-struct] A Union struct factory require at least two structs.");
  }
  return {
          n: "Union",
          t: {
            TAG: /* Union */6,
            _0: structs
          },
          pf: (function (ctx) {
              var structs = ctx.s.t._0;
              var noopOps = [];
              var syncOps = [];
              var asyncOps = [];
              for(var idx = 0 ,idx_finish = structs.length; idx < idx_finish; ++idx){
                var innerStruct = structs[idx];
                var fn = getParseOperation(innerStruct);
                if (typeof fn === "number") {
                  noopOps.push(undefined);
                } else if (fn.TAG === /* SyncOperation */0) {
                  syncOps.push([
                        idx,
                        fn._0
                      ]);
                } else {
                  asyncOps.push([
                        idx,
                        fn._0
                      ]);
                }
              }
              var withAsyncOps = asyncOps.length > 0;
              if (noopOps.length === 0) {
                planSyncTransformation(ctx, (function (input) {
                        var idxRef = 0;
                        var errorsRef = [];
                        var maybeNewValueRef;
                        while(idxRef < syncOps.length && maybeNewValueRef === undefined) {
                          var idx = idxRef;
                          var match = syncOps[idx];
                          try {
                            var newValue = match[1](input);
                            maybeNewValueRef = Caml_option.some(newValue);
                          }
                          catch (raw_internalError){
                            var internalError = Caml_js_exceptions.internalToOCamlException(raw_internalError);
                            if (internalError.RE_EXN_ID === Exception) {
                              errorsRef[match[0]] = internalError._1;
                              idxRef = idxRef + 1;
                            } else {
                              throw internalError;
                            }
                          }
                        };
                        var match$1 = maybeNewValueRef;
                        if (match$1 !== undefined) {
                          if (withAsyncOps) {
                            return {
                                    maybeSyncValue: match$1,
                                    tempErrors: errorsRef,
                                    originalInput: input
                                  };
                          } else {
                            return Caml_option.valFromOption(match$1);
                          }
                        } else if (withAsyncOps) {
                          return {
                                  maybeSyncValue: match$1,
                                  tempErrors: errorsRef,
                                  originalInput: input
                                };
                        } else {
                          return raise$1({
                                      TAG: /* InvalidUnion */5,
                                      _0: errorsRef.map(toParseError)
                                    });
                        }
                      }));
                if (withAsyncOps) {
                  return planAsyncTransformation(ctx, (function (input) {
                                var syncValue = input.maybeSyncValue;
                                if (syncValue !== undefined) {
                                  return Promise.resolve(Caml_option.valFromOption(syncValue));
                                } else {
                                  return Promise.all(asyncOps.map(function (param) {
                                                    var originalIdx = param[0];
                                                    try {
                                                      return param[1](input.originalInput)().then((function (value) {
                                                                    throw {
                                                                          RE_EXN_ID: HackyValidValue,
                                                                          _1: value,
                                                                          Error: new Error()
                                                                        };
                                                                  }), (function (exn) {
                                                                    if (exn.RE_EXN_ID === Exception) {
                                                                      var array = input.tempErrors;
                                                                      array[originalIdx] = exn._1;
                                                                      return ;
                                                                    }
                                                                    throw exn;
                                                                  }));
                                                    }
                                                    catch (raw_internalError){
                                                      var internalError = Caml_js_exceptions.internalToOCamlException(raw_internalError);
                                                      if (internalError.RE_EXN_ID === Exception) {
                                                        var array = input.tempErrors;
                                                        return Promise.resolve((array[originalIdx] = internalError._1, undefined));
                                                      }
                                                      throw internalError;
                                                    }
                                                  })).then((function (param) {
                                                return raise$1({
                                                            TAG: /* InvalidUnion */5,
                                                            _0: input.tempErrors.map(toParseError)
                                                          });
                                              }), (function (exn) {
                                                if (exn.RE_EXN_ID === HackyValidValue) {
                                                  return exn._1;
                                                }
                                                throw exn;
                                              }));
                                }
                              }));
                } else {
                  return ;
                }
              }
              
            }),
          sf: (function (ctx) {
              var serializeOperations = [];
              for(var idx = 0 ,idx_finish = structs.length; idx < idx_finish; ++idx){
                serializeOperations.push(getSerializeOperation(structs[idx]));
              }
              planSyncTransformation(ctx, (function (input) {
                      var idxRef = 0;
                      var errors = [];
                      var maybeNewValueRef;
                      while(idxRef < serializeOperations.length && maybeNewValueRef === undefined) {
                        var idx = idxRef;
                        var serializeOperation = serializeOperations[idx];
                        try {
                          var newValue = serializeOperation !== undefined ? serializeOperation(input) : input;
                          maybeNewValueRef = Caml_option.some(newValue);
                        }
                        catch (raw_internalError){
                          var internalError = Caml_js_exceptions.internalToOCamlException(raw_internalError);
                          if (internalError.RE_EXN_ID === Exception) {
                            errors.push(internalError._1);
                            idxRef = idxRef + 1;
                          } else {
                            throw internalError;
                          }
                        }
                      };
                      var ok = maybeNewValueRef;
                      if (ok !== undefined) {
                        return Caml_option.valFromOption(ok);
                      } else {
                        return raise$1({
                                    TAG: /* InvalidUnion */5,
                                    _0: errors.map(toSerializeError)
                                  });
                      }
                    }));
            }),
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: undefined,
          m: emptyMetadataMap
        };
}

function list(innerStruct) {
  return transform(factory$13(innerStruct), Belt_List.fromArray, undefined, Belt_List.toArray, undefined);
}

function parse(input, ctx) {
  var match = typeof input;
  switch (match) {
    case "number" :
        if (Number.isNaN(input)) {
          return raiseUnexpectedTypeError(input, ctx.s);
        } else {
          return input;
        }
    case "object" :
        if (input === null) {
          return input;
        }
        if (Array.isArray(input)) {
          var output = [];
          for(var idx = 0 ,idx_finish = input.length; idx < idx_finish; ++idx){
            var inputItem = input[idx];
            output.push(parse(inputItem, ctx));
          }
          return output;
        }
        var keys = Object.keys(input);
        var output$1 = {};
        for(var idx$1 = 0 ,idx_finish$1 = keys.length; idx$1 < idx_finish$1; ++idx$1){
          var key = keys[idx$1];
          var field = input[key];
          output$1[key] = parse(field, ctx);
        }
        return output$1;
    case "boolean" :
    case "string" :
        return input;
    default:
      return raiseUnexpectedTypeError(input, ctx.s);
  }
}

function parseTransformationFactory$4(ctx) {
  planSyncTransformation(ctx, (function (input) {
          return parse(input, ctx);
        }));
}

function jsonable(param) {
  return {
          n: "JSON",
          t: /* JSON */6,
          pf: parseTransformationFactory$4,
          sf: empty,
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: undefined,
          m: emptyMetadataMap
        };
}

function $$catch(struct, getFallbackValue) {
  return {
          n: struct.n,
          t: struct.t,
          pf: (function (ctx) {
              var fn = getParseOperation(struct);
              if (typeof fn === "number") {
                return ;
              }
              if (fn.TAG === /* SyncOperation */0) {
                var fn$1 = fn._0;
                return planSyncTransformation(ctx, (function (input) {
                              try {
                                return fn$1(input);
                              }
                              catch (raw_e){
                                var e = Caml_js_exceptions.internalToOCamlException(raw_e);
                                if (e.RE_EXN_ID === Exception) {
                                  return getFallbackValue({
                                              error: toParseError(e._1),
                                              input: input
                                            });
                                }
                                throw e;
                              }
                            }));
              }
              var fn$2 = fn._0;
              planSyncTransformation(ctx, (function (input) {
                      try {
                        return {
                                TAG: /* Parsed */0,
                                input: input,
                                asyncFn: fn$2(input)
                              };
                      }
                      catch (raw_e){
                        var e = Caml_js_exceptions.internalToOCamlException(raw_e);
                        if (e.RE_EXN_ID === Exception) {
                          return {
                                  TAG: /* Fallback */1,
                                  _0: getFallbackValue({
                                        error: toParseError(e._1),
                                        input: input
                                      })
                                };
                        }
                        throw e;
                      }
                    }));
              planAsyncTransformation(ctx, (function (syncResult) {
                      if (syncResult.TAG !== /* Parsed */0) {
                        return Promise.resolve(syncResult._0);
                      }
                      var input = syncResult.input;
                      try {
                        return syncResult.asyncFn().catch(function (exn) {
                                    if (exn.RE_EXN_ID === Exception) {
                                      return getFallbackValue({
                                                  error: toParseError(exn._1),
                                                  input: input
                                                });
                                    }
                                    throw exn;
                                  });
                      }
                      catch (raw_e){
                        var e = Caml_js_exceptions.internalToOCamlException(raw_e);
                        if (e.RE_EXN_ID === Exception) {
                          return Promise.resolve(getFallbackValue({
                                          error: toParseError(e._1),
                                          input: input
                                        }));
                        }
                        throw e;
                      }
                    }));
            }),
          sf: struct.sf,
          r: 0,
          e: 0,
          s: initialSerialize,
          j: initialSerializeToJson,
          p: intitialParse,
          a: intitialParseAsync,
          i: undefined,
          m: struct.m
        };
}

var deprecationMetadataId = "rescript-struct:deprecation";

function deprecate(struct, message) {
  return set(factory$12(struct), deprecationMetadataId, message);
}

function deprecation(struct) {
  return Js_dict.get(struct.m, deprecationMetadataId);
}

var descriptionMetadataId = "rescript-struct:description";

function describe(struct, description) {
  return set(struct, descriptionMetadataId, description);
}

function description(struct) {
  return Js_dict.get(struct.m, descriptionMetadataId);
}

function getExn(result) {
  if (result.TAG === /* Ok */0) {
    return result._0;
  }
  var message = toString(result._0);
  throw new Error("[rescript-struct] " + message + "");
}

function mapErrorToString(result) {
  if (result.TAG === /* Ok */0) {
    return result;
  } else {
    return {
            TAG: /* Error */1,
            _0: toString(result._0)
          };
  }
}

var Result = {
  getExn: getExn,
  mapErrorToString: mapErrorToString
};

function toVariantName(struct) {
  var s = struct.t;
  if (typeof s === "number") {
    switch (s) {
      case /* Never */0 :
          return "Never";
      case /* Unknown */1 :
          return "Unknown";
      case /* String */2 :
          return "String";
      case /* Int */3 :
          return "Int";
      case /* Float */4 :
          return "Float";
      case /* Bool */5 :
          return "Bool";
      case /* JSON */6 :
          return "JSON";
      
    }
  } else {
    switch (s.TAG | 0) {
      case /* Literal */0 :
          var string = s._0;
          if (typeof string === "number") {
            switch (string) {
              case /* EmptyNull */0 :
                  return "EmptyNull";
              case /* EmptyOption */1 :
                  return "EmptyOption";
              case /* NaN */2 :
                  return "NaN";
              
            }
          } else {
            switch (string.TAG | 0) {
              case /* String */0 :
                  return string._0;
              case /* Int */1 :
              case /* Float */2 :
                  return string._0.toString();
              case /* Bool */3 :
                  if (string._0) {
                    return "True";
                  } else {
                    return "False";
                  }
              
            }
          }
      case /* Option */1 :
          return "OptionOf" + toVariantName(s._0) + "";
      case /* Null */2 :
          return "NullOf" + toVariantName(s._0) + "";
      case /* Array */3 :
          return "ArrayOf" + toVariantName(s._0) + "";
      case /* Object */4 :
          if (s.fieldNames.length !== 0) {
            return "Object";
          } else {
            return "EmptyObject";
          }
      case /* Tuple */5 :
          if (s._0.length !== 0) {
            return "Tuple";
          } else {
            return "EmptyTuple";
          }
      case /* Union */6 :
          return "Union";
      case /* Dict */7 :
          return "DictOf" + toVariantName(s._0) + "";
      
    }
  }
}

function internalInline(struct, maybeVariant, param) {
  var metadataMap = Object.assign({}, struct.m);
  var taggedLiteral = struct.t;
  var inlinedStruct;
  if (typeof taggedLiteral === "number") {
    switch (taggedLiteral) {
      case /* Never */0 :
          inlinedStruct = "S.never()";
          break;
      case /* Unknown */1 :
          inlinedStruct = "S.unknown()";
          break;
      case /* String */2 :
          inlinedStruct = "S.string()";
          break;
      case /* Int */3 :
          inlinedStruct = "S.int()";
          break;
      case /* Float */4 :
          inlinedStruct = "S.float()";
          break;
      case /* Bool */5 :
          inlinedStruct = "S.bool()";
          break;
      case /* JSON */6 :
          inlinedStruct = "S.jsonable()";
          break;
      
    }
  } else {
    switch (taggedLiteral.TAG | 0) {
      case /* Literal */0 :
          var taggedLiteral$1 = taggedLiteral._0;
          var inlinedLiteral;
          if (typeof taggedLiteral$1 === "number") {
            switch (taggedLiteral$1) {
              case /* EmptyNull */0 :
                  inlinedLiteral = "EmptyNull";
                  break;
              case /* EmptyOption */1 :
                  inlinedLiteral = "EmptyOption";
                  break;
              case /* NaN */2 :
                  inlinedLiteral = "NaN";
                  break;
              
            }
          } else {
            switch (taggedLiteral$1.TAG | 0) {
              case /* String */0 :
                  inlinedLiteral = "String(" + JSON.stringify(taggedLiteral$1._0) + ")";
                  break;
              case /* Int */1 :
                  inlinedLiteral = "Int(" + taggedLiteral$1._0.toString() + ")";
                  break;
              case /* Float */2 :
                  var $$float = taggedLiteral$1._0;
                  inlinedLiteral = "Float(" + ($$float.toString() + (
                      $$float % 1 === 0 ? "." : ""
                    )) + ")";
                  break;
              case /* Bool */3 :
                  inlinedLiteral = "Bool(" + taggedLiteral$1._0.toString() + ")";
                  break;
              
            }
          }
          inlinedStruct = maybeVariant !== undefined ? "S.literalVariant(" + inlinedLiteral + ", " + maybeVariant + ")" : "S.literal(" + inlinedLiteral + ")";
          break;
      case /* Option */1 :
          var inlinedInnerStruct = internalInline(taggedLiteral._0, undefined, undefined);
          var message = deprecation(struct);
          if (message !== undefined) {
            Js_dict.unsafeDeleteKey(metadataMap, deprecationMetadataId);
            inlinedStruct = inlinedInnerStruct + ("->S.deprecate(" + JSON.stringify(message) + ")");
          } else {
            inlinedStruct = "S.option(" + inlinedInnerStruct + ")";
          }
          break;
      case /* Null */2 :
          inlinedStruct = "S.null(" + internalInline(taggedLiteral._0, undefined, undefined) + ")";
          break;
      case /* Array */3 :
          inlinedStruct = "S.array(" + internalInline(taggedLiteral._0, undefined, undefined) + ")";
          break;
      case /* Object */4 :
          var fieldNames = taggedLiteral.fieldNames;
          var fields = taggedLiteral.fields;
          inlinedStruct = fieldNames.length !== 0 ? "S.object(o =>\n  {\n    " + fieldNames.map(function (fieldName) {
                    return "" + JSON.stringify(fieldName) + ": o->S.field(" + JSON.stringify(fieldName) + ", " + internalInline(fields[fieldName], undefined, undefined) + ")";
                  }).join(",\n    ") + ",\n  }\n)" : "S.object(_ => ())";
          break;
      case /* Tuple */5 :
          var tupleStructs = taggedLiteral._0;
          if (tupleStructs.length !== 0) {
            var numberOfItems = tupleStructs.length;
            if (numberOfItems > 10) {
              throw new Error("[rescript-struct] The S.inline doesn't support tuples with more than 10 items.");
            }
            inlinedStruct = "S.tuple" + numberOfItems.toString() + "(. " + tupleStructs.map(function (s) {
                    return internalInline(s, undefined, undefined);
                  }).join(", ") + ")";
          } else {
            inlinedStruct = "S.tuple0(.)";
          }
          break;
      case /* Union */6 :
          var variantNamesCounter = {};
          inlinedStruct = "S.union([" + taggedLiteral._0.map(function (s) {
                  var variantName = toVariantName(s);
                  var n = Js_dict.get(variantNamesCounter, variantName);
                  var numberOfVariantNames = n !== undefined ? n : 0;
                  variantNamesCounter[variantName] = numberOfVariantNames + 1;
                  var variantName$1 = numberOfVariantNames !== 0 ? variantName + (numberOfVariantNames + 1).toString() : variantName;
                  var inlinedVariant = "#" + JSON.stringify(variantName$1) + "";
                  return internalInline(s, inlinedVariant, undefined);
                }).join(", ") + "])";
          break;
      case /* Dict */7 :
          inlinedStruct = "S.dict(" + internalInline(taggedLiteral._0, undefined, undefined) + ")";
          break;
      
    }
  }
  var defaultValue = classify$2(struct);
  var inlinedStruct$1;
  if (defaultValue !== undefined) {
    var defaultValue$1 = Caml_option.valFromOption(defaultValue);
    Js_dict.unsafeDeleteKey(metadataMap, metadataId$5);
    inlinedStruct$1 = inlinedStruct + ("->S.default(() => %raw(\`" + (
        defaultValue$1 === undefined ? "undefined" : JSON.stringify(defaultValue$1)
      ) + "\`))");
  } else {
    inlinedStruct$1 = inlinedStruct;
  }
  var message$1 = description(struct);
  var inlinedStruct$2 = message$1 !== undefined ? (Js_dict.unsafeDeleteKey(metadataMap, descriptionMetadataId), inlinedStruct$1 + ("->S.describe(" + (
          message$1 === undefined ? "undefined" : JSON.stringify(message$1)
        ) + ")")) : inlinedStruct$1;
  var match = classify$1(struct);
  var inlinedStruct$3 = match ? inlinedStruct$2 : inlinedStruct$2 + "->S.Object.strict";
  Js_dict.unsafeDeleteKey(metadataMap, metadataId);
  var match$1 = struct.t;
  var inlinedStruct$4;
  var exit = 0;
  if (typeof match$1 === "number") {
    switch (match$1) {
      case /* String */2 :
          exit = 1;
          break;
      case /* Int */3 :
          exit = 2;
          break;
      case /* Float */4 :
          exit = 3;
          break;
      default:
        inlinedStruct$4 = inlinedStruct$3;
    }
  } else {
    switch (match$1.TAG | 0) {
      case /* Literal */0 :
          var tmp = match$1._0;
          if (typeof tmp === "number") {
            inlinedStruct$4 = inlinedStruct$3;
          } else {
            switch (tmp.TAG | 0) {
              case /* String */0 :
                  exit = 1;
                  break;
              case /* Int */1 :
                  exit = 2;
                  break;
              case /* Float */2 :
                  exit = 3;
                  break;
              default:
                inlinedStruct$4 = inlinedStruct$3;
            }
          }
          break;
      case /* Array */3 :
          var refinements$4 = refinements$3(struct);
          if (refinements$4.length !== 0) {
            Js_dict.unsafeDeleteKey(metadataMap, metadataId$4);
            inlinedStruct$4 = inlinedStruct$3 + refinements$4.map(function (refinement) {
                    var match = refinement.kind;
                    switch (match.TAG | 0) {
                      case /* Min */0 :
                          return "->S.Array.min(~message=" + JSON.stringify(refinement.message) + ", " + match.length.toString() + ")";
                      case /* Max */1 :
                          return "->S.Array.max(~message=" + JSON.stringify(refinement.message) + ", " + match.length.toString() + ")";
                      case /* Length */2 :
                          return "->S.Array.length(~message=" + JSON.stringify(refinement.message) + ", " + match.length.toString() + ")";
                      
                    }
                  }).join("");
          } else {
            inlinedStruct$4 = inlinedStruct$3;
          }
          break;
      default:
        inlinedStruct$4 = inlinedStruct$3;
    }
  }
  switch (exit) {
    case 1 :
        var refinements$5 = refinements(struct);
        if (refinements$5.length !== 0) {
          Js_dict.unsafeDeleteKey(metadataMap, metadataId$1);
          inlinedStruct$4 = inlinedStruct$3 + refinements$5.map(function (refinement) {
                  var match = refinement.kind;
                  if (typeof match === "number") {
                    switch (match) {
                      case /* Email */0 :
                          return "->S.String.email(~message=" + JSON.stringify(refinement.message) + ", ())";
                      case /* Uuid */1 :
                          return "->S.String.uuid(~message=" + JSON.stringify(refinement.message) + ", ())";
                      case /* Cuid */2 :
                          return "->S.String.cuid(~message=" + JSON.stringify(refinement.message) + ", ())";
                      case /* Url */3 :
                          return "->S.String.url(~message=" + JSON.stringify(refinement.message) + ", ())";
                      case /* Datetime */4 :
                          return "->S.String.datetime(~message=" + JSON.stringify(refinement.message) + ", ())";
                      
                    }
                  } else {
                    switch (match.TAG | 0) {
                      case /* Min */0 :
                          return "->S.String.min(~message=" + JSON.stringify(refinement.message) + ", " + match.length.toString() + ")";
                      case /* Max */1 :
                          return "->S.String.max(~message=" + JSON.stringify(refinement.message) + ", " + match.length.toString() + ")";
                      case /* Length */2 :
                          return "->S.String.length(~message=" + JSON.stringify(refinement.message) + ", " + match.length.toString() + ")";
                      case /* Pattern */3 :
                          return "->S.String.pattern(~message=" + JSON.stringify(refinement.message) + ", %re(" + JSON.stringify(match.re.toString()) + "))";
                      
                    }
                  }
                }).join("");
        } else {
          inlinedStruct$4 = inlinedStruct$3;
        }
        break;
    case 2 :
        var refinements$6 = refinements$1(struct);
        if (refinements$6.length !== 0) {
          Js_dict.unsafeDeleteKey(metadataMap, metadataId$2);
          inlinedStruct$4 = inlinedStruct$3 + refinements$6.map(function (refinement) {
                  var match = refinement.kind;
                  if (typeof match === "number") {
                    return "->S.Int.port(~message=" + JSON.stringify(refinement.message) + ", ())";
                  } else if (match.TAG === /* Min */0) {
                    return "->S.Int.min(~message=" + JSON.stringify(refinement.message) + ", " + match.value.toString() + ")";
                  } else {
                    return "->S.Int.max(~message=" + JSON.stringify(refinement.message) + ", " + match.value.toString() + ")";
                  }
                }).join("");
        } else {
          inlinedStruct$4 = inlinedStruct$3;
        }
        break;
    case 3 :
        var refinements$7 = refinements$2(struct);
        if (refinements$7.length !== 0) {
          Js_dict.unsafeDeleteKey(metadataMap, metadataId$3);
          inlinedStruct$4 = inlinedStruct$3 + refinements$7.map(function (refinement) {
                  var match = refinement.kind;
                  if (match.TAG === /* Min */0) {
                    var value = match.value;
                    return "->S.Float.min(~message=" + JSON.stringify(refinement.message) + ", " + (value.toString() + (
                              value % 1 === 0 ? "." : ""
                            )) + ")";
                  }
                  var value$1 = match.value;
                  return "->S.Float.max(~message=" + JSON.stringify(refinement.message) + ", " + (value$1.toString() + (
                            value$1 % 1 === 0 ? "." : ""
                          )) + ")";
                }).join("");
        } else {
          inlinedStruct$4 = inlinedStruct$3;
        }
        break;
    
  }
  var inlinedStruct$5 = Object.keys(metadataMap).length !== 0 ? "{\n  let s = " + inlinedStruct$4 + "\n  let _ = %raw(\`s.m = " + JSON.stringify(metadataMap) + "\`)\n  s\n}" : inlinedStruct$4;
  var match$2 = struct.t;
  if (typeof match$2 !== "number" && match$2.TAG === /* Literal */0) {
    return inlinedStruct$5;
  }
  if (maybeVariant !== undefined) {
    return inlinedStruct$5 + ("->S.variant(v => " + maybeVariant + "(v))");
  } else {
    return inlinedStruct$5;
  }
}

function inline(struct) {
  return internalInline(struct, undefined, undefined);
}

function unit(param) {
  return factory$2(/* EmptyOption */1);
}

var Path = {
  empty: "",
  toArray: toArray,
  fromArray: fromArray,
  fromLocation: fromLocation,
  concat: concat
};

var $$Error$1 = {
  toString: toString
};

var never = factory$4;

var unknown = factory$5;

var string = factory$6;

var bool = factory$8;

var $$int = factory$9;

var $$float = factory$10;

var literal = factory$2;

var literalVariant = factory$1;

var array = factory$13;

var dict = factory$14;

var option = factory$12;

var $$null = factory$11;

var json = factory$7;

var union = factory$17;

var $$default = factory$15;

var variant = factory;

var parseWith = parseAnyWith;

var parseOrRaiseWith = parseAnyOrRaiseWith;

var parseAsyncWith = parseAnyAsyncWith;

var parseAsyncInStepsWith = parseAnyAsyncInStepsWith;

var Object_UnknownKeys = {
  classify: classify$1
};

var $$Object = {
  UnknownKeys: Object_UnknownKeys,
  strip: strip,
  strict: strict
};

var object = factory$3;

var tuple0 = factory$16;

var tuple1 = factory$16;

var tuple2 = factory$16;

var tuple3 = factory$16;

var tuple4 = factory$16;

var tuple5 = factory$16;

var tuple6 = factory$16;

var tuple7 = factory$16;

var tuple8 = factory$16;

var tuple9 = factory$16;

var tuple10 = factory$16;

var String_Refinement = {};

var $$String = {
  Refinement: String_Refinement,
  refinements: refinements,
  min: min,
  max: max,
  length: length,
  email: email,
  uuid: uuid,
  cuid: cuid,
  url: url,
  pattern: pattern,
  datetime: datetime,
  trim: trim
};

var Int_Refinement = {};

var Int = {
  Refinement: Int_Refinement,
  refinements: refinements$1,
  min: min$1,
  max: max$1,
  port: port
};

var Float_Refinement = {};

var Float = {
  Refinement: Float_Refinement,
  refinements: refinements$2,
  min: min$2,
  max: max$2
};

var Array_Refinement = {};

var $$Array = {
  Refinement: Array_Refinement,
  refinements: refinements$3,
  min: min$3,
  max: max$3,
  length: length$1
};

var Default = {
  classify: classify$2
};

exports.Path = Path;
exports.$$Error = $$Error$1;
exports.Raised = Raised;
exports.never = never;
exports.unknown = unknown;
exports.unit = unit;
exports.string = string;
exports.bool = bool;
exports.$$int = $$int;
exports.$$float = $$float;
exports.literal = literal;
exports.literalVariant = literalVariant;
exports.array = array;
exports.list = list;
exports.dict = dict;
exports.option = option;
exports.$$null = $$null;
exports.json = json;
exports.jsonable = jsonable;
exports.union = union;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
exports.$$catch = $$catch;
exports.describe = describe;
exports.description = description;
exports.deprecate = deprecate;
exports.deprecation = deprecation;
exports.transform = transform;
exports.advancedTransform = advancedTransform;
exports.advancedPreprocess = advancedPreprocess;
exports.custom = custom;
exports.refine = refine;
exports.variant = variant;
exports.parseWith = parseWith;
exports.parseAnyWith = parseAnyWith;
exports.parseJsonWith = parseJsonWith;
exports.parseOrRaiseWith = parseOrRaiseWith;
exports.parseAnyOrRaiseWith = parseAnyOrRaiseWith;
exports.parseAsyncWith = parseAsyncWith;
exports.parseAnyAsyncWith = parseAnyAsyncWith;
exports.parseAsyncInStepsWith = parseAsyncInStepsWith;
exports.parseAnyAsyncInStepsWith = parseAnyAsyncInStepsWith;
exports.serializeWith = serializeWith;
exports.serializeToUnknownWith = serializeToUnknownWith;
exports.serializeToJsonWith = serializeToJsonWith;
exports.serializeOrRaiseWith = serializeOrRaiseWith;
exports.serializeToUnknownOrRaiseWith = serializeToUnknownOrRaiseWith;
exports.isAsyncParse = isAsyncParse;
exports.recursive = recursive;
exports.asyncRecursive = asyncRecursive;
exports.classify = classify;
exports.name = name;
exports.fail = fail;
exports.advancedFail = advancedFail;
exports.$$Object = $$Object;
exports.object = object;
exports.field = field;
exports.Tuple = Tuple;
exports.tuple0 = tuple0;
exports.tuple1 = tuple1;
exports.tuple2 = tuple2;
exports.tuple3 = tuple3;
exports.tuple4 = tuple4;
exports.tuple5 = tuple5;
exports.tuple6 = tuple6;
exports.tuple7 = tuple7;
exports.tuple8 = tuple8;
exports.tuple9 = tuple9;
exports.tuple10 = tuple10;
exports.$$String = $$String;
exports.Int = Int;
exports.Float = Float;
exports.$$Array = $$Array;
exports.Default = Default;
exports.Result = Result;
exports.Metadata = Metadata;
exports.inline = inline;
/* No side effect */
