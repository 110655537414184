// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var BDMenu$Thick = require("./atoms/BDMenu.bs.js");
var Common$Thick = require("../../utils/Common.bs.js");
var Menu = require("@mui/joy/Menu").default;
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var RelayEnv$Thick = require("../../RelayEnv.bs.js");
var Avatar = require("@mui/joy/Avatar").default;
var Divider = require("@mui/joy/Divider").default;
var Tooltip = require("@mui/joy/Tooltip").default;
var Dropdown = require("@mui/joy/Dropdown").default;
var MenuItem = require("@mui/joy/MenuItem").default;
var AddBerthBtn$Thick = require("./drafts/AddBerthBtn.bs.js");
var BookingDiff$Thick = require("../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PreviewModal$Thick = require("./drafts/PreviewModal.bs.js");
var IconButton = require("@mui/joy/IconButton").default;
var MenuButton = require("@mui/joy/MenuButton").default;
var Typography = require("@mui/joy/Typography").default;
var CustomScalars$Thick = require("../../utils/CustomScalars.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var BDContentBlock$Thick = require("./molecules/BDContentBlock.bs.js");
var PreviewChanges$Thick = require("./drafts/PreviewChanges.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var MoreHoriz = require("@mui/icons-material/MoreHoriz").default;
var InfoOutlined = require("@mui/icons-material/InfoOutlined").default;
var AttachReplaceExistingModal$Thick = require("./drafts/AttachReplaceExistingModal.bs.js");
var BDBerthsPassengersQuery_graphql$Thick = require("../../__generated__/BDBerthsPassengersQuery_graphql.bs.js");
var BDBerthsPassengers_version_graphql$Thick = require("../../__generated__/BDBerthsPassengers_version_graphql.bs.js");

var convertFragment = BDBerthsPassengers_version_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BDBerthsPassengers_version_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDBerthsPassengers_version_graphql$Thick.node, convertFragment);
}

var VersionFragment_paxDataStatus_decode = BDBerthsPassengers_version_graphql$Thick.Utils.paxDataStatus_decode;

var VersionFragment_paxDataStatus_fromString = BDBerthsPassengers_version_graphql$Thick.Utils.paxDataStatus_fromString;

var VersionFragment = {
  paxDataStatus_decode: VersionFragment_paxDataStatus_decode,
  paxDataStatus_fromString: VersionFragment_paxDataStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

var convertVariables = BDBerthsPassengersQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = BDBerthsPassengersQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = BDBerthsPassengersQuery_graphql$Thick.Internal.convertWrapRawResponse;

function use$1(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.useQuery(convertVariables, BDBerthsPassengersQuery_graphql$Thick.node, convertResponse, param, param$1, param$2, param$3, param$4);
}

function useLoader(param) {
  return RescriptRelay_Query.useLoader(convertVariables, BDBerthsPassengersQuery_graphql$Thick.node, (function (prim) {
                return prim;
              }), param);
}

function usePreloaded(param) {
  return RescriptRelay_Query.usePreloaded(BDBerthsPassengersQuery_graphql$Thick.node, convertResponse, (function (prim) {
                return prim;
              }), param);
}

function $$fetch(param, param$1, param$2, param$3, param$4, param$5) {
  return RescriptRelay_Query.$$fetch(BDBerthsPassengersQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4, param$5);
}

function fetchPromised(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.fetchPromised(BDBerthsPassengersQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4);
}

function retain(param, param$1) {
  return RescriptRelay_Query.retain(BDBerthsPassengersQuery_graphql$Thick.node, convertVariables, param, param$1);
}

var FetchAllBfCustUsers_accountStatus_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.accountStatus_decode;

var FetchAllBfCustUsers_accountStatus_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.accountStatus_fromString;

var FetchAllBfCustUsers_custSignonStatus_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.custSignonStatus_decode;

var FetchAllBfCustUsers_custSignonStatus_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.custSignonStatus_fromString;

var FetchAllBfCustUsers_docGender_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.docGender_decode;

var FetchAllBfCustUsers_docGender_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.docGender_fromString;

var FetchAllBfCustUsers_lifecycleStatus_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.lifecycleStatus_decode;

var FetchAllBfCustUsers_lifecycleStatus_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.lifecycleStatus_fromString;

var FetchAllBfCustUsers_paxDataStatus_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.paxDataStatus_decode;

var FetchAllBfCustUsers_paxDataStatus_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.paxDataStatus_fromString;

var FetchAllBfCustUsers_pmtDirection_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.pmtDirection_decode;

var FetchAllBfCustUsers_pmtDirection_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.pmtDirection_fromString;

var FetchAllBfCustUsers_pmtLifecycleStatus_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var FetchAllBfCustUsers_pmtLifecycleStatus_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var FetchAllBfCustUsers_pmtProcessor_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.pmtProcessor_decode;

var FetchAllBfCustUsers_pmtProcessor_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.pmtProcessor_fromString;

var FetchAllBfCustUsers_subtotalCategory_decode = BDBerthsPassengersQuery_graphql$Thick.Utils.subtotalCategory_decode;

var FetchAllBfCustUsers_subtotalCategory_fromString = BDBerthsPassengersQuery_graphql$Thick.Utils.subtotalCategory_fromString;

var FetchAllBfCustUsers = {
  accountStatus_decode: FetchAllBfCustUsers_accountStatus_decode,
  accountStatus_fromString: FetchAllBfCustUsers_accountStatus_fromString,
  custSignonStatus_decode: FetchAllBfCustUsers_custSignonStatus_decode,
  custSignonStatus_fromString: FetchAllBfCustUsers_custSignonStatus_fromString,
  docGender_decode: FetchAllBfCustUsers_docGender_decode,
  docGender_fromString: FetchAllBfCustUsers_docGender_fromString,
  lifecycleStatus_decode: FetchAllBfCustUsers_lifecycleStatus_decode,
  lifecycleStatus_fromString: FetchAllBfCustUsers_lifecycleStatus_fromString,
  paxDataStatus_decode: FetchAllBfCustUsers_paxDataStatus_decode,
  paxDataStatus_fromString: FetchAllBfCustUsers_paxDataStatus_fromString,
  pmtDirection_decode: FetchAllBfCustUsers_pmtDirection_decode,
  pmtDirection_fromString: FetchAllBfCustUsers_pmtDirection_fromString,
  pmtLifecycleStatus_decode: FetchAllBfCustUsers_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: FetchAllBfCustUsers_pmtLifecycleStatus_fromString,
  pmtProcessor_decode: FetchAllBfCustUsers_pmtProcessor_decode,
  pmtProcessor_fromString: FetchAllBfCustUsers_pmtProcessor_fromString,
  subtotalCategory_decode: FetchAllBfCustUsers_subtotalCategory_decode,
  subtotalCategory_fromString: FetchAllBfCustUsers_subtotalCategory_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use$1,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function BDBerthsPassengers$BPMenu(props) {
  return JsxRuntime.jsxs(BDMenu$Thick.make, {
              text: "More Actions",
              disabled: true,
              children: [
                JsxRuntime.jsx(MenuItem, {
                      children: "Action 1"
                    }),
                JsxRuntime.jsx(MenuItem, {
                      children: "Action 2"
                    }),
                JsxRuntime.jsx(MenuItem, {
                      children: "Action 3"
                    })
              ]
            });
}

var BPMenu = {
  make: BDBerthsPassengers$BPMenu
};

function BDBerthsPassengers$PaxMenu(props) {
  var generatePreviewFromDiff = props.generatePreviewFromDiff;
  var blockDrop = props.blockDrop;
  var name = props.name;
  var versionUrl = props.versionUrl;
  var billPayRec = props.billPayRec;
  var idx = props.idx;
  var status = props.status;
  var match = React.useState(function () {
        return "drop";
      });
  var setOpt = match[1];
  var opt = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setDropModalOpen = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setAttachReplaceExistingModalOpen = match$2[1];
  var match$3 = React.useState(function () {
        return "attachExisting";
      });
  var setAttachReplaceAction = match$3[1];
  var match$4 = React.useState(function () {
        return false;
      });
  var match$5 = React.useState(function () {
        
      });
  var setError = match$5[1];
  var match$6 = React.useState(function () {
        
      });
  var setBillPayNew = match$6[1];
  var onError = function (s) {
    Curry._1(setError, (function (param) {
            return s;
          }));
    Curry._1(setDropModalOpen, (function (param) {
            return true;
          }));
  };
  var onSuccess = function (b) {
    Curry._1(setBillPayNew, (function (param) {
            return b;
          }));
    Curry._1(setDropModalOpen, (function (param) {
            return true;
          }));
  };
  var openAttachModal = function (param) {
    Curry._1(setAttachReplaceAction, (function (param) {
            return "attachExisting";
          }));
    Curry._1(setAttachReplaceExistingModalOpen, (function (param) {
            return true;
          }));
  };
  var openReplaceModal = function (param) {
    Curry._1(setAttachReplaceAction, (function (param) {
            return "replaceExisting";
          }));
    Curry._1(setAttachReplaceExistingModalOpen, (function (param) {
            return true;
          }));
  };
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var dropDetachApplyDiff = React.useMemo((function () {
          if (opt === "detach") {
            return BookingDiff$Thick.Alter.detachSingleBerth(diffStruct, idx);
          } else {
            return BookingDiff$Thick.Alter.dropSingleBerth(diffStruct, idx);
          }
        }), [
        url.search,
        opt
      ]);
  var match$7 = PreviewChanges$Thick.useQuery(generatePreviewFromDiff, dropDetachApplyDiff, match$4[1], onSuccess, onError);
  var onClick = match$7.onClick;
  var match$8 = React.useMemo((function () {
          if (opt === "detach") {
            var name$1 = name !== undefined ? "" + name + " from this berth" : "a passenger from this berth";
            return [
                    "Detach Passenger",
                    "You are proposing to detach " + name$1 + ". Please carefully review the changes below."
                  ];
          }
          var name$2 = name !== undefined ? "the berth occupied by " + name + "" : "a berth";
          return [
                  "Drop Berth",
                  "You are proposing to drop " + name$2 + " from this booking. Please carefully review the changes below."
                ];
        }), [opt]);
  if (props.isActive && !(props.snapshot || !props.canManage)) {
    return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsxs(Dropdown, {
                        children: [
                          JsxRuntime.jsx(MenuButton, {
                                children: Caml_option.some(JsxRuntime.jsx(MoreHoriz, {})),
                                size: "md",
                                slotProps: {
                                  root: {
                                    color: "neutral"
                                  }
                                },
                                slots: {
                                  root: IconButton
                                }
                              }),
                          JsxRuntime.jsxs(Menu, {
                                children: [
                                  JsxRuntime.jsx(MenuItem, {
                                        children: "Drop Berth",
                                        disabled: blockDrop,
                                        onClick: (function (param) {
                                            Curry._1(setOpt, (function (param) {
                                                    return "drop";
                                                  }));
                                            Curry._1(onClick, undefined);
                                          })
                                      }),
                                  status ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                          children: [
                                            JsxRuntime.jsx(MenuItem, {
                                                  children: "Replace With Existing Passenger",
                                                  onClick: (function (param) {
                                                      openReplaceModal(undefined);
                                                    })
                                                }),
                                            JsxRuntime.jsx(MenuItem, {
                                                  children: "Detach Passenger",
                                                  disabled: blockDrop,
                                                  onClick: (function (param) {
                                                      Curry._1(setOpt, (function (param) {
                                                              return "detach";
                                                            }));
                                                      Curry._1(onClick, undefined);
                                                    })
                                                })
                                          ]
                                        }) : null,
                                  status || props.shouldAttachToIdx === undefined ? null : JsxRuntime.jsx(MenuItem, {
                                          children: "Attach Existing Passenger",
                                          onClick: openAttachModal
                                        })
                                ]
                              })
                        ]
                      }),
                  JsxRuntime.jsx(PreviewModal$Thick.make, {
                        billPayCurrent: billPayRec,
                        billPayNew: match$6[0],
                        applyDiff: dropDetachApplyDiff,
                        title: match$8[0],
                        description: match$8[1],
                        onClose: (function (param) {
                            Curry._1(setBillPayNew, (function (param) {
                                    
                                  }));
                            Curry._1(setError, (function (param) {
                                    
                                  }));
                            Curry._1(setDropModalOpen, (function (param) {
                                    return false;
                                  }));
                          }),
                        open_: match$1[0],
                        versionUrl: versionUrl,
                        error: match$5[0]
                      }),
                  JsxRuntime.jsx(AttachReplaceExistingModal$Thick.make, {
                        open_: match$2[0],
                        onClose: (function (param) {
                            Curry._1(setAttachReplaceExistingModalOpen, (function (param) {
                                    return false;
                                  }));
                          }),
                        billPayCurrent: billPayRec,
                        versionUrl: versionUrl,
                        shouldAttachToIdx: idx,
                        brandFamily: props.brandFamily,
                        generatePreviewFromDiff: generatePreviewFromDiff,
                        action: match$3[0],
                        name: name,
                        voyage: props.voyage
                      })
                ]
              });
  } else {
    return null;
  }
}

var PaxMenu = {
  make: BDBerthsPassengers$PaxMenu
};

function BDBerthsPassengers$BerthsPassengers$ContactCard(props) {
  var pax = props.pax;
  var match = pax.legalDocumentFirstName;
  var match$1 = pax.legalDocumentLastName;
  var match$2 = pax.firstName;
  var match$3 = pax.lastName;
  var match$4;
  if (match !== undefined && match$1 !== undefined && match$2 !== undefined && match$3 !== undefined && (match !== match$2 || match$1 !== match$3)) {
    var legalName = match + " " + match$1;
    match$4 = [
      true,
      legalName
    ];
  } else {
    match$4 = [
      false,
      ""
    ];
  }
  var match$5 = pax.firstName;
  var match$6 = pax.lastName;
  var initials;
  if (match$5 !== undefined) {
    if (match$6 !== undefined) {
      var firstInitial = match$5[0];
      var lastInitial = match$6[0];
      initials = firstInitial + lastInitial;
    } else {
      initials = match$5[0];
    }
  } else {
    initials = match$6 !== undefined ? match$6[0] : "TBA";
  }
  var match$7 = pax.paxDataStatus;
  var match$8 = pax.paxDataStatus;
  var name = pax.fullName;
  var id = pax.accountId;
  var email = pax.email;
  return JsxRuntime.jsx(Sheet, {
              sx: {
                p: 2,
                border: "1px solid",
                borderRadius: "4px",
                borderColor: "divider"
              },
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "column",
                        spacing: 2,
                        children: [
                          JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                justifyContent: "space-between",
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        alignItems: "center",
                                        spacing: 1,
                                        children: [
                                          JsxRuntime.jsx(Avatar, {
                                                children: Caml_option.some(initials),
                                                color: match$7 ? "primary" : "neutral"
                                              }),
                                          JsxRuntime.jsxs(Stack, {
                                                direction: "row",
                                                children: [
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "body-md",
                                                        sx: {
                                                          color: match$8 ? "text.primary" : "neutral.400"
                                                        },
                                                        fontWeight: "normal",
                                                        children: Caml_option.some(name !== undefined ? name : "TBA")
                                                      }),
                                                  match$4[0] ? JsxRuntime.jsx(Stack, {
                                                          direction: "row",
                                                          sx: {
                                                            flex: 1,
                                                            ml: "4px"
                                                          },
                                                          children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                                                    children: JsxRuntime.jsx(Typography, {
                                                                          level: "body-md",
                                                                          children: Caml_option.some(JsxRuntime.jsx("sup", {
                                                                                    children: JsxRuntime.jsx(InfoOutlined, {
                                                                                          color: "info",
                                                                                          fontSize: "inherit"
                                                                                        })
                                                                                  }))
                                                                        }),
                                                                    arrow: true,
                                                                    placement: "top",
                                                                    size: "sm",
                                                                    sx: {
                                                                      justifySelf: "flex-start",
                                                                      cursor: "pointer"
                                                                    },
                                                                    title: "Traveling as " + match$4[1] + ""
                                                                  }))
                                                        }) : null
                                                ]
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsx(BDBerthsPassengers$PaxMenu, {
                                        snapshot: props.snapshot,
                                        isActive: props.isActive,
                                        status: pax.paxDataStatus,
                                        canManage: props.canManage,
                                        idx: pax.idx,
                                        billPayRec: props.billPayRec,
                                        versionUrl: props.versionUrl,
                                        name: pax.fullName,
                                        blockDrop: props.blockDrop,
                                        shouldAttachToIdx: props.shouldAttachToIdx,
                                        brandFamily: props.brandFamily,
                                        generatePreviewFromDiff: props.generatePreviewFromDiff,
                                        voyage: props.voyage
                                      })
                                ]
                              }),
                          JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        spacing: 2,
                                        children: [
                                          JsxRuntime.jsxs(Stack, {
                                                direction: "row",
                                                spacing: 1,
                                                children: [
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "body-sm",
                                                        noWrap: true,
                                                        sx: {
                                                          color: "neutral.500"
                                                        },
                                                        fontWeight: "normal",
                                                        children: "Account ID"
                                                      }),
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "body-sm",
                                                        noWrap: true,
                                                        sx: {
                                                          color: "text.primary"
                                                        },
                                                        fontWeight: "normal",
                                                        children: Caml_option.some(id !== undefined ? id : "--")
                                                      })
                                                ]
                                              }),
                                          JsxRuntime.jsx(Divider, {
                                                orientation: "vertical"
                                              }),
                                          JsxRuntime.jsxs(Stack, {
                                                direction: "row",
                                                spacing: 1,
                                                children: [
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "body-sm",
                                                        noWrap: true,
                                                        sx: {
                                                          color: "neutral.500"
                                                        },
                                                        fontWeight: "normal",
                                                        children: "Email"
                                                      }),
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "body-sm",
                                                        noWrap: true,
                                                        sx: {
                                                          color: "text.primary"
                                                        },
                                                        fontWeight: "normal",
                                                        children: Caml_option.some(email !== undefined ? email : "--")
                                                      })
                                                ]
                                              })
                                        ]
                                      }),
                                  Common$Thick.PaxDataStatus.makeChip("md", pax.paxDataStatus)
                                ]
                              })
                        ]
                      }))
            });
}

var ContactCard = {
  make: BDBerthsPassengers$BerthsPassengers$ContactCard
};

function BDBerthsPassengers$BerthsPassengers(props) {
  var voyage = props.voyage;
  var generatePreviewFromDiff = props.generatePreviewFromDiff;
  var toAttach = props.toAttach;
  var brandFamily = props.brandFamily;
  var diffStruct = props.diffStruct;
  var versionUrl = props.versionUrl;
  var billPayRec = props.billPayRec;
  var canManage = props.canManage;
  var snapshot = props.snapshot;
  var isActive = props.isActive;
  var content = props.content;
  var actions = isActive && !(snapshot || !canManage) ? JsxRuntime.jsxs(Stack, {
          direction: "row",
          spacing: 1,
          children: [
            JsxRuntime.jsx(AddBerthBtn$Thick.make, {
                  billPayCurrent: billPayRec,
                  versionUrl: versionUrl,
                  disabled: !props.canAddBerth,
                  generatePreviewFromDiff: generatePreviewFromDiff
                }),
            JsxRuntime.jsx(BDBerthsPassengers$BPMenu, {})
          ]
        }) : null;
  var paxArray = Belt_Array.keep(Belt_Array.keep(content.passengers, (function (pax) {
              var match = diffStruct.dropBerths;
              var match$1 = pax.idx;
              if (match !== undefined) {
                return !Belt_Array.some(match, (function (b) {
                              return b === match$1;
                            }));
              } else {
                return true;
              }
            })), (function (pax) {
          var match = diffStruct.detachPax;
          var match$1 = pax.idx;
          if (match !== undefined) {
            return !Belt_Array.some(match, (function (b) {
                          return b === match$1;
                        }));
          } else {
            return true;
          }
        }));
  var paxes = Belt_Array.mapWithIndex(paxArray, (function (i, pax) {
          return JsxRuntime.jsx(BDBerthsPassengers$BerthsPassengers$ContactCard, {
                      pax: pax,
                      snapshot: snapshot,
                      isActive: isActive,
                      canManage: canManage,
                      billPayRec: billPayRec,
                      versionUrl: versionUrl,
                      blockDrop: paxArray.length === 1,
                      shouldAttachToIdx: undefined,
                      brandFamily: brandFamily,
                      generatePreviewFromDiff: generatePreviewFromDiff,
                      voyage: voyage
                    }, "pax-" + String(i) + "");
        }));
  var latestIndex = Belt_Array.reduce(paxArray, 0, (function (acc, pax) {
          return Math.max(acc, pax.idx);
        }));
  var shouldAttachToIdx = latestIndex + 1 | 0;
  var loop = function (_i, _acc) {
    while(true) {
      var acc = _acc;
      var i = _i;
      if (i >= content.numBerths) {
        return acc;
      }
      var newIdx = i + 1 | 0;
      var newPax;
      if (toAttach !== undefined) {
        var pax = Belt_MapInt.get(Caml_option.valFromOption(toAttach), newIdx);
        newPax = pax !== undefined ? pax : ({
              paxDataStatus: /* TBA */0,
              idx: newIdx,
              accountId: undefined,
              email: undefined,
              fullName: undefined,
              firstName: undefined,
              lastName: undefined,
              legalDocumentFirstName: undefined,
              legalDocumentLastName: undefined
            });
      } else {
        newPax = {
          paxDataStatus: /* TBA */0,
          idx: newIdx,
          accountId: undefined,
          email: undefined,
          fullName: undefined,
          firstName: undefined,
          lastName: undefined,
          legalDocumentFirstName: undefined,
          legalDocumentLastName: undefined
        };
      }
      _acc = Belt_Array.concat(acc, [JsxRuntime.jsx(BDBerthsPassengers$BerthsPassengers$ContactCard, {
                  pax: newPax,
                  snapshot: snapshot,
                  isActive: isActive,
                  canManage: canManage,
                  billPayRec: billPayRec,
                  versionUrl: versionUrl,
                  blockDrop: false,
                  shouldAttachToIdx: shouldAttachToIdx,
                  brandFamily: brandFamily,
                  generatePreviewFromDiff: generatePreviewFromDiff,
                  voyage: voyage
                }, "tba-" + String(i) + "")]);
      _i = i + 1 | 0;
      continue ;
    };
  };
  var allPax = loop(latestIndex, paxes);
  return JsxRuntime.jsx(BDContentBlock$Thick.make, {
              title: "Berths & Passengers",
              actions: Caml_option.some(actions),
              children: JsxRuntime.jsx(Stack, {
                    spacing: 1,
                    children: Caml_option.some(allPax)
                  })
            });
}

var BerthsPassengers = {
  ContactCard: ContactCard,
  make: BDBerthsPassengers$BerthsPassengers
};

function BDBerthsPassengers(props) {
  var brandFamily = props.brandFamily;
  var data = RescriptRelay_Fragment.useFragment(BDBerthsPassengers_version_graphql$Thick.node, convertFragment, props.fragmentRefs);
  var match = React.useState(function () {
        
      });
  var setToAttachOrReplace = match[1];
  var toAttachOrReplace = match[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var match$1 = diffStruct.addBerths;
  var match$2 = diffStruct.dropBerths;
  var numBerths = match$1 !== undefined ? (
      match$2 !== undefined ? (data.numBerths + match$1 | 0) - match$2.length | 0 : data.numBerths + match$1 | 0
    ) : (
      match$2 !== undefined ? data.numBerths - match$2.length | 0 : data.numBerths
    );
  var match$3 = data.occRange.end;
  var occMax = match$3 !== undefined ? (
      match$3.inclusive ? match$3.value : match$3.value - 1 | 0
    ) : 0;
  var canAddBerth = numBerths < occMax;
  var passengers = Belt_Array.map(data.passenger.nodes, (function (node) {
          var match = node.bfcustUser;
          var match$1 = node.bfcustUser;
          var tmp;
          if (match$1 !== undefined) {
            var email = match$1.email;
            tmp = email !== undefined ? CustomScalars$Thick.Email.serialize(Caml_option.valFromOption(email)) : undefined;
          } else {
            tmp = undefined;
          }
          var match$2 = node.bfcustUser;
          var match$3 = node.bfcustUser;
          var match$4 = node.bfcustUser;
          var tmp$1;
          if (match$4 !== undefined) {
            var name = match$4.lastName;
            tmp$1 = name !== undefined ? CustomScalars$Thick.NonemptyString.serialize(Caml_option.valFromOption(name)) : undefined;
          } else {
            tmp$1 = undefined;
          }
          var match$5 = node.bfcustUser;
          var tmp$2;
          if (match$5 !== undefined) {
            var name$1 = match$5.legalDocumentFirstName;
            tmp$2 = name$1 !== undefined ? CustomScalars$Thick.NonemptyString.serialize(Caml_option.valFromOption(name$1)) : undefined;
          } else {
            tmp$2 = undefined;
          }
          var match$6 = node.bfcustUser;
          var tmp$3;
          if (match$6 !== undefined) {
            var name$2 = match$6.legalDocumentLastName;
            tmp$3 = name$2 !== undefined ? CustomScalars$Thick.NonemptyString.serialize(Caml_option.valFromOption(name$2)) : undefined;
          } else {
            tmp$3 = undefined;
          }
          var passenger_paxDataStatus = /* Status */{
            _0: node.paxDataStatus
          };
          var passenger_idx = node.idx;
          var passenger_accountId = match !== undefined ? CustomScalars$Thick.AccountId.serialize(match.accountId) : undefined;
          var passenger_fullName = match$2 !== undefined ? match$2.fullName : undefined;
          var passenger_firstName = match$3 !== undefined ? CustomScalars$Thick.NonemptyString.serialize(match$3.firstName) : undefined;
          var passenger = {
            paxDataStatus: passenger_paxDataStatus,
            idx: passenger_idx,
            accountId: passenger_accountId,
            email: tmp,
            fullName: passenger_fullName,
            firstName: passenger_firstName,
            lastName: tmp$1,
            legalDocumentFirstName: tmp$2,
            legalDocumentLastName: tmp$3
          };
          if (toAttachOrReplace === undefined) {
            return passenger;
          }
          var pax = Belt_MapInt.get(Caml_option.valFromOption(toAttachOrReplace), node.idx);
          if (pax !== undefined) {
            return pax;
          } else {
            return passenger;
          }
        }));
  var content = {
    numBerths: numBerths,
    passengers: passengers
  };
  React.useEffect((function () {
          var match = diffStruct.attachExistingPax;
          var match$1 = diffStruct.replacePaxExisting;
          var exit = 0;
          if (match !== undefined || match$1 !== undefined) {
            exit = 1;
          } else {
            Curry._1(setToAttachOrReplace, (function (param) {
                    
                  }));
          }
          if (exit === 1) {
            var attachExistingMap = Belt_Option.getWithDefault(diffStruct.attachExistingPax, undefined);
            var replaceExistingMap = Belt_Option.getWithDefault(diffStruct.replacePaxExisting, undefined);
            var fullMap = Belt_MapInt.fromArray(Belt_Array.concat(Belt_MapInt.toArray(attachExistingMap), Belt_MapInt.toArray(replaceExistingMap)));
            var acctIds = Belt_Array.concat(Belt_MapInt.valuesToArray(attachExistingMap), Belt_MapInt.valuesToArray(replaceExistingMap));
            var acctIdToIdx = Belt_MapString.fromArray(Belt_Array.map(Belt_MapInt.toArray(fullMap), (function (param) {
                        return [
                                CustomScalars$Thick.AccountId.serialize(param[1]),
                                param[0]
                              ];
                      })));
            var filter = {
              accountId: {
                in: acctIds
              }
            };
            $$fetch(RelayEnv$Thick.environment, {
                  brandFamily: brandFamily,
                  filter: filter
                }, (function (res) {
                    if (res.TAG === /* Ok */0) {
                      var match = res._0.bfcustUsers;
                      if (match !== undefined) {
                        var idMap = Belt_MapInt.fromArray(Belt_Array.map(match.nodes, (function (node) {
                                    var idx = Belt_MapString.getExn(acctIdToIdx, CustomScalars$Thick.AccountId.serialize(node.accountId));
                                    var email = node.email;
                                    var name = node.lastName;
                                    var name$1 = node.legalDocumentFirstName;
                                    var name$2 = node.legalDocumentLastName;
                                    var node_paxDataStatus = /* Status */{
                                      _0: "CONFIRMED"
                                    };
                                    var node_accountId = CustomScalars$Thick.AccountId.serialize(node.accountId);
                                    var node_email = email !== undefined ? CustomScalars$Thick.Email.serialize(Caml_option.valFromOption(email)) : undefined;
                                    var node_fullName = node.fullName;
                                    var node_firstName = CustomScalars$Thick.NonemptyString.serialize(node.firstName);
                                    var node_lastName = name !== undefined ? CustomScalars$Thick.NonemptyString.serialize(Caml_option.valFromOption(name)) : undefined;
                                    var node_legalDocumentFirstName = name$1 !== undefined ? CustomScalars$Thick.NonemptyString.serialize(Caml_option.valFromOption(name$1)) : undefined;
                                    var node_legalDocumentLastName = name$2 !== undefined ? CustomScalars$Thick.NonemptyString.serialize(Caml_option.valFromOption(name$2)) : undefined;
                                    var node$1 = {
                                      paxDataStatus: node_paxDataStatus,
                                      idx: idx,
                                      accountId: node_accountId,
                                      email: node_email,
                                      fullName: node_fullName,
                                      firstName: node_firstName,
                                      lastName: node_lastName,
                                      legalDocumentFirstName: node_legalDocumentFirstName,
                                      legalDocumentLastName: node_legalDocumentLastName
                                    };
                                    return [
                                            idx,
                                            node$1
                                          ];
                                  })));
                        Curry._1(setToAttachOrReplace, (function (param) {
                                return Caml_option.some(idMap);
                              }));
                      }
                      return ;
                    }
                    console.error(res._0);
                  }), undefined, undefined, undefined);
          }
          
        }), [url.search]);
  return JsxRuntime.jsx(BDBerthsPassengers$BerthsPassengers, {
              content: content,
              isActive: props.isActive,
              snapshot: props.snapshot,
              canManage: props.canManage,
              billPayRec: props.billPayRec,
              versionUrl: props.versionUrl,
              diffStruct: diffStruct,
              brandFamily: brandFamily,
              toAttach: toAttachOrReplace,
              canAddBerth: canAddBerth,
              generatePreviewFromDiff: props.generatePreviewFromDiff,
              voyage: props.voyage
            });
}

var make = BDBerthsPassengers;

exports.VersionFragment = VersionFragment;
exports.FetchAllBfCustUsers = FetchAllBfCustUsers;
exports.BPMenu = BPMenu;
exports.PaxMenu = PaxMenu;
exports.BerthsPassengers = BerthsPassengers;
exports.make = make;
/* react Not a pure module */
