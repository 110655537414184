// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Modal = require("@mui/joy/Modal").default;
var Stack = require("@mui/joy/Stack").default;
var DiffList$Thick = require("./DiffList.bs.js");
var Button = require("@mui/joy/Button").default;
var Divider = require("@mui/joy/Divider").default;
var ApplyDraft$Thick = require("./mutations/ApplyDraft.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var ModalClose = require("@mui/joy/ModalClose").default;
var Typography = require("@mui/joy/Typography").default;
var CustomScalars$Thick = require("../../../utils/CustomScalars.bs.js");
var DialogTitle = require("@mui/joy/DialogTitle").default;
var ModalDialog = require("@mui/joy/ModalDialog").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;

function ApplyDraftModal(props) {
  var onClose = props.onClose;
  var applyArgs = props.applyArgs;
  var match = ApplyDraft$Thick.useMutation(applyArgs, props.redirect);
  var error = match.error;
  return JsxRuntime.jsx(Modal, {
              open: props.open_,
              onClose: onClose,
              slotProps: {
                backdrop: {
                  sx: {
                    backdropFilter: "none",
                    backgroundColor: "rgba(16, 13, 12, 0.7)"
                  }
                }
              },
              children: Caml_option.some(JsxRuntime.jsxs(ModalDialog, {
                        variant: "outlined",
                        layout: "center",
                        sx: {
                          minWidth: "40rem",
                          borderRadius: "4px",
                          boxShadow: "lg"
                        },
                        children: [
                          JsxRuntime.jsx(DialogTitle, {
                                children: "Apply Draft"
                              }),
                          JsxRuntime.jsx(ModalClose, {
                                sx: {
                                  top: "0.8rem",
                                  right: "0.8rem"
                                }
                              }),
                          JsxRuntime.jsx(Divider, {}),
                          JsxRuntime.jsxs(DialogContent, {
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "column",
                                        spacing: 2,
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                level: "title-md",
                                                children: Caml_option.some("You are about to apply your draft and create a new booking version for booking " + CustomScalars$Thick.BookingId.serialize(applyArgs.bookingId) + ". The changes are as follows:")
                                              }),
                                          JsxRuntime.jsx(DiffList$Thick.make, {
                                                paxes: props.paxes,
                                                diffStruct: props.diffStruct,
                                                size: "md"
                                              }),
                                          JsxRuntime.jsx(Typography, {
                                                level: "title-md",
                                                children: "Do you want to apply these changes?"
                                              })
                                        ]
                                      }),
                                  error !== undefined ? JsxRuntime.jsx(Typography, {
                                          color: "danger",
                                          children: Caml_option.some(error)
                                        }) : null
                                ]
                              }),
                          JsxRuntime.jsx("form", {
                                children: JsxRuntime.jsxs(DialogActions, {
                                      children: [
                                        JsxRuntime.jsx(Button, {
                                              loading: match.isMutating,
                                              type: "submit",
                                              children: "Apply"
                                            }),
                                        JsxRuntime.jsx(Button, {
                                              onClick: onClose,
                                              variant: "outlined",
                                              children: "Keep Editing"
                                            })
                                      ]
                                    }),
                                onSubmit: match.onSubmit
                              })
                        ]
                      }))
            });
}

var make = ApplyDraftModal;

exports.make = make;
/* @mui/joy/Modal Not a pure module */
