// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");

function makeRefetchVariables(bookingDiff, bookingId, bookingVersionNumber, voyage, param) {
  return {
          bookingDiff: bookingDiff,
          bookingId: bookingId,
          bookingVersionNumber: bookingVersionNumber,
          voyage: voyage
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {"paxApplicationInput":{"idx":{"b":""},"accountId":{"c":"CustomScalars.AccountId"}},"bookingDiffInput":{"replacePaxExisting":{"r":"paxApplicationInput"},"dropBerths":{"b":"a"},"detachPax":{"b":"a"},"changeBillingContact":{"c":"CustomScalars.AccountId"},"attachExistingPax":{"r":"paxApplicationInput"},"addBerths":{"b":""}},"__root":{"voyage":{"c":"CustomScalars.VoyageSlug"},"bookingVersionNumber":{"b":""},"bookingId":{"c":"CustomScalars.BookingId"},"bookingDiff":{"r":"bookingDiffInput"}}};

var variablesConverterMap = {
  "CustomScalars.AccountId": CustomScalars$Thick.AccountId.serialize,
  "CustomScalars.BookingId": CustomScalars$Thick.BookingId.serialize,
  "CustomScalars.VoyageSlug": CustomScalars$Thick.VoyageSlug.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"proposedChanges_quoteTotals_quoteLineSubtotal":{"b":""},"proposedChanges_quoteTotals_paymentsSubtotal":{"b":""},"proposedChanges_quoteTotals_paymentsPendingSubtotal":{"b":""},"proposedChanges_quoteTotals_balanceWithPendingAmount":{"b":""},"proposedChanges_quoteTotals_balanceAmount":{"b":""},"proposedChanges_quotePayments_processorPmtImageUrl":{"c":"CustomScalars.NonemptyString"},"proposedChanges_quotePayments_processorDescription":{"c":"CustomScalars.NonemptyString"},"proposedChanges_quotePayments_payer":{"c":"CustomScalars.AccountId"},"proposedChanges_quotePayments_grossAmount":{"b":""},"proposedChanges_quotePayments_createdAt":{"b":""},"proposedChanges_quoteLines_lineAmount":{"b":""},"proposedChanges_quoteLines_billingLineDescription":{"c":"CustomScalars.NonemptyString"}}};

var wrapResponseConverterMap = {
  "CustomScalars.NonemptyString": CustomScalars$Thick.NonemptyString.serialize,
  "CustomScalars.AccountId": CustomScalars$Thick.AccountId.serialize
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"proposedChanges_quoteTotals_quoteLineSubtotal":{"b":""},"proposedChanges_quoteTotals_paymentsSubtotal":{"b":""},"proposedChanges_quoteTotals_paymentsPendingSubtotal":{"b":""},"proposedChanges_quoteTotals_balanceWithPendingAmount":{"b":""},"proposedChanges_quoteTotals_balanceAmount":{"b":""},"proposedChanges_quotePayments_processorPmtImageUrl":{"c":"CustomScalars.NonemptyString"},"proposedChanges_quotePayments_processorDescription":{"c":"CustomScalars.NonemptyString"},"proposedChanges_quotePayments_payer":{"c":"CustomScalars.AccountId"},"proposedChanges_quotePayments_grossAmount":{"b":""},"proposedChanges_quotePayments_createdAt":{"b":""},"proposedChanges_quoteLines_lineAmount":{"b":""},"proposedChanges_quoteLines_billingLineDescription":{"c":"CustomScalars.NonemptyString"}}};

var responseConverterMap = {
  "CustomScalars.NonemptyString": CustomScalars$Thick.NonemptyString.parse,
  "CustomScalars.AccountId": CustomScalars$Thick.AccountId.parse
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function pmtLifecycleStatus_decode($$enum) {
  if ($$enum === "PENDING" || $$enum === "CANCELLED" || $$enum === "ERROR" || $$enum === "COMPLETED") {
    return $$enum;
  }
  
}

var pmtLifecycleStatus_fromString = pmtLifecycleStatus_decode;

var Utils = {
  pmtLifecycleStatus_decode: pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: pmtLifecycleStatus_fromString
};

var node = ((function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bookingDiff"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bookingId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bookingVersionNumber"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "voyage"
},
v4 = [
  {
    "alias": "proposedChanges",
    "args": [
      {
        "kind": "Variable",
        "name": "_bookingDiff",
        "variableName": "bookingDiff"
      },
      {
        "kind": "Variable",
        "name": "_bookingId",
        "variableName": "bookingId"
      },
      {
        "kind": "Variable",
        "name": "_bookingVersionNumber",
        "variableName": "bookingVersionNumber"
      },
      {
        "kind": "Variable",
        "name": "_voyageSlug",
        "variableName": "voyage"
      }
    ],
    "concreteType": "QuoteNullable",
    "kind": "LinkedField",
    "name": "previewBookingChanges",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "QuoteLine",
        "kind": "LinkedField",
        "name": "quoteLines",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "billingLineDescription",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lineAmount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Payment",
        "kind": "LinkedField",
        "name": "quotePayments",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lifecycleStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "payer",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "grossAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "processorDescription",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "processorPmtImageUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "QuoteTotal",
        "kind": "LinkedField",
        "name": "quoteTotals",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quoteLineSubtotal",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paymentsSubtotal",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paymentsPendingSubtotal",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "balanceAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "balanceWithPendingAmount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PreviewChangesQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PreviewChangesQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "a55350a1bc1103c2f46af230a35c3201",
    "id": null,
    "metadata": {},
    "name": "PreviewChangesQuery",
    "operationKind": "query",
    "text": "query PreviewChangesQuery(\n  $bookingId: BookingId!\n  $bookingVersionNumber: Int!\n  $voyage: VoyageSlug!\n  $bookingDiff: BookingDiffInput!\n) {\n  proposedChanges: previewBookingChanges(_bookingDiff: $bookingDiff, _bookingId: $bookingId, _bookingVersionNumber: $bookingVersionNumber, _voyageSlug: $voyage) {\n    quoteLines {\n      billingLineDescription\n      lineAmount\n    }\n    quotePayments {\n      createdAt\n      lifecycleStatus\n      payer\n      grossAmount\n      processorDescription\n      processorPmtImageUrl\n    }\n    quoteTotals {\n      quoteLineSubtotal\n      paymentsSubtotal\n      paymentsPendingSubtotal\n      balanceAmount\n      balanceWithPendingAmount\n    }\n  }\n}\n"
  }
};
})());

var include = RescriptRelay.MakeLoadQuery({
      query: node,
      convertVariables: convertVariables
    });

var load = include.load;

var queryRefToObservable = include.queryRefToObservable;

var queryRefToPromise = include.queryRefToPromise;

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
