// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../utils/Common.bs.js");
var Drawer$Thick = require("../common/molecules/Drawer.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var CustomScalars$Thick = require("../../utils/CustomScalars.bs.js");
var BDVersionHistory$Thick = require("./BDVersionHistory.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var Restore = require("@mui/icons-material/Restore").default;
var BDHero_version_graphql$Thick = require("../../__generated__/BDHero_version_graphql.bs.js");

var convertFragment = BDHero_version_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BDHero_version_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDHero_version_graphql$Thick.node, convertFragment);
}

var VersionFragment_lifecycleStatus_decode = BDHero_version_graphql$Thick.Utils.lifecycleStatus_decode;

var VersionFragment_lifecycleStatus_fromString = BDHero_version_graphql$Thick.Utils.lifecycleStatus_fromString;

var VersionFragment = {
  lifecycleStatus_decode: VersionFragment_lifecycleStatus_decode,
  lifecycleStatus_fromString: VersionFragment_lifecycleStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function BDHero$Hero(props) {
  var content = props.content;
  var match = React.useState(function () {
        return false;
      });
  var setDrawerIsOpen = match[1];
  return JsxRuntime.jsxs(Sheet, {
              variant: "solid",
              color: "primary",
              sx: {
                px: 3,
                py: 2,
                borderRadius: "4px"
              },
              children: [
                JsxRuntime.jsxs(Stack, {
                      direction: "column",
                      children: [
                        JsxRuntime.jsxs(Stack, {
                              direction: "row",
                              spacing: 2,
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "h3",
                                      component: "h1",
                                      textColor: "neutral.50",
                                      fontWeight: "400",
                                      children: Caml_option.some(CustomScalars$Thick.BookingId.serialize(content.bookingId))
                                    }),
                                Common$Thick.Lifecycle.makeChip("sm", content.lifecycleStatus, content.bookingVersionNumber)
                              ]
                            }),
                        JsxRuntime.jsx(Stack, {
                              direction: "row",
                              spacing: 1,
                              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        alignItems: "center",
                                        sx: {
                                          cursor: "pointer"
                                        },
                                        onClick: (function (param) {
                                            Curry._1(setDrawerIsOpen, (function (param) {
                                                    return true;
                                                  }));
                                          }),
                                        children: [
                                          JsxRuntime.jsx(Restore, {
                                                color: "inherit",
                                                fontSize: "sm",
                                                sx: {
                                                  mr: "3px"
                                                }
                                              }),
                                          JsxRuntime.jsx(Typography, {
                                                color: "inherit",
                                                level: "body-xs",
                                                sx: {
                                                  textDecoration: "underline"
                                                },
                                                fontWeight: "300",
                                                children: "View version history"
                                              })
                                        ]
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsx(Drawer$Thick.make, {
                      open_: match[0],
                      title: "Version History",
                      onClose: (function (param) {
                          Curry._1(setDrawerIsOpen, (function (param) {
                                  return false;
                                }));
                        }),
                      children: JsxRuntime.jsx(BDVersionHistory$Thick.make, {
                            fragmentRefs: props.historyFrag,
                            redirect: props.redirect,
                            snapshot: props.snapshot,
                            onClose: (function (param) {
                                Curry._1(setDrawerIsOpen, (function (param) {
                                        return false;
                                      }));
                              })
                          })
                    })
              ]
            });
}

var Hero = {
  make: BDHero$Hero
};

function BDHero(props) {
  var status = props.status;
  var version = RescriptRelay_Fragment.useFragment(BDHero_version_graphql$Thick.node, convertFragment, props.fragmentRefs);
  var content_bookingId = version.bookingId;
  var content_bookingVersionNumber = version.bookingVersionNumber;
  var content = {
    bookingId: content_bookingId,
    lifecycleStatus: status,
    bookingVersionNumber: content_bookingVersionNumber
  };
  return JsxRuntime.jsx(BDHero$Hero, {
              content: content,
              historyFrag: props.historyFrag,
              redirect: props.redirect,
              snapshot: props.snapshot,
              status: status
            });
}

var make = BDHero;

exports.VersionFragment = VersionFragment;
exports.Hero = Hero;
exports.make = make;
/* react Not a pure module */
