// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var $$String = require("rescript/lib/js/string.js");
var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Js_array = require("rescript/lib/js/js_array.js");
var Js_string = require("rescript/lib/js/js_string.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../utils/Common.bs.js");
var Input = require("@mui/joy/Input").default;
var Stack = require("@mui/joy/Stack").default;
var QueryVar$Thick = require("../../utils/QueryVar.bs.js");
var Button = require("@mui/joy/Button").default;
var $$Option = require("@mui/joy/Option").default;
var Select = require("@mui/joy/Select").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var Add = require("@mui/icons-material/Add").default;
var FormHelperText = require("@mui/joy/FormHelperText").default;
var Delete = require("@mui/icons-material/Delete").default;

function FilterMenu$FilterRule(props) {
  var setError = props.setError;
  var removeCondition = props.removeCondition;
  var updateCondition = props.updateCondition;
  var conditions = props.conditions;
  var index = props.index;
  var verbOptions = {
    String: [
      [
        "includes",
        "includes",
        "A word or phrase"
      ],
      [
        "equals",
        "is equal to",
        "A word or phrase"
      ],
      [
        "in",
        "is one of",
        "A comma-separated list"
      ]
    ],
    StringRestricted: [
      [
        "equals",
        "is equal to",
        "A word or phrase"
      ],
      [
        "in",
        "is one of",
        "A comma-separated list"
      ]
    ],
    IntRange: [
      [
        "containsEl",
        "contains element",
        "A number"
      ],
      [
        "contains_to",
        "is up to and including",
        "A number"
      ],
      [
        "contains_ir",
        "is in range",
        "A hypen-separated range"
      ],
      [
        "contains_ab",
        "includes and is above",
        "A number"
      ]
    ],
    OccRange: [
      [
        "containsEl",
        "has occupancy of",
        "A number"
      ],
      [
        "contains_to",
        "has maximum",
        "A number"
      ],
      [
        "contains_ir",
        "is within range",
        "A hypen-separated range"
      ],
      [
        "contains_ab",
        "has minimum",
        "A number"
      ]
    ],
    IntArg: [
      [
        "equals",
        "is equal to",
        "A number"
      ],
      [
        "lte",
        "is up to and including",
        "A number"
      ],
      [
        "gte",
        "includes and is above",
        "A number"
      ],
      [
        "lt",
        "is below",
        "A number"
      ],
      [
        "gt",
        "is above",
        "A number"
      ]
    ],
    Boolean: [
      [
        "true",
        "exists",
        ""
      ],
      [
        "false",
        "does not exist",
        ""
      ]
    ]
  };
  var selectedCondition = Belt_Option.getExn(Belt_Array.get(conditions, index));
  var getVerbOptions = function (condition) {
    var column = condition.column;
    if (column == null) {
      return [];
    }
    switch (column) {
      case "cq.booking" :
          return verbOptions.Boolean;
      case "cq.occRange" :
          return verbOptions.OccRange;
      case "cq.privateNote" :
      case "cq.publicNote" :
      case "cq.segment" :
          return verbOptions.String;
      case "cq.cabin" :
      case "cq.category" :
      case "cq.class" :
      case "cq.subclass" :
          return verbOptions.StringRestricted;
      case "cq.totBerths" :
          return verbOptions.IntArg;
      default:
        return [];
    }
  };
  var fst3 = function (param) {
    return param[0];
  };
  var getErrorTextForVerb = function (condition) {
    var verbList = getVerbOptions(condition);
    var selectedVerb = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(condition.verb), "");
    var option = Js_array.find((function (option) {
            return fst3(option) === selectedVerb;
          }), verbList);
    if (option !== undefined) {
      return option[2];
    } else {
      return "";
    }
  };
  var handleError = function (b) {
    if (b) {
      Curry._1(setError, (function (param) {
              return "An error";
            }));
    } else {
      Curry._1(setError, (function (param) {
              
            }));
    }
    return b;
  };
  var isValidInput = function (condition) {
    var verb = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(condition.verb), "");
    var noun = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(condition.noun), "");
    var exit = 0;
    switch (verb) {
      case "contains_ir" :
          var match = Js_string.split("-", noun);
          if (match.length !== 2) {
            return handleError(false);
          }
          var a = match[0];
          var b = match[1];
          var match$1 = Belt_Int.fromString(a.trim());
          var match$2 = Belt_Int.fromString(b.trim());
          if (match$1 !== undefined && match$2 !== undefined) {
            return handleError(true);
          } else {
            return handleError(false);
          }
      case "containsEl" :
      case "contains_ab" :
      case "contains_to" :
          exit = 2;
          break;
      case "in" :
          return Belt_Array.keepMap(Js_string.split(",", noun), (function (v) {
                        var match = $$String.trim(v);
                        if (match === "") {
                          return ;
                        } else {
                          return v;
                        }
                      })).length !== 0;
      case "equals" :
      case "includes" :
          exit = 1;
          break;
      case "false" :
      case "true" :
          return handleError(true);
      default:
        return handleError(false);
    }
    switch (exit) {
      case 1 :
          if (noun === "") {
            return handleError(false);
          } else {
            return handleError(true);
          }
      case 2 :
          var match$3 = Belt_Int.fromString(noun);
          if (match$3 !== undefined) {
            return handleError(true);
          } else {
            return handleError(false);
          }
      
    }
  };
  var shouldDisableColumnOption = function (optionValue, conditions, selectedCondition) {
    var column = selectedCondition.column;
    var isSameAsSelected = (column == null) ? false : column === optionValue;
    if (isSameAsSelected) {
      return false;
    } else {
      return Belt_Array.some(conditions, (function (condition) {
                    var column = condition.column;
                    if (column == null) {
                      return false;
                    } else {
                      return column === optionValue;
                    }
                  }));
    }
  };
  var column = selectedCondition.column;
  var match = selectedCondition.column;
  var verb = selectedCondition.verb;
  var match$1 = selectedCondition.column;
  var tmp;
  var exit = 0;
  if ((match$1 == null) || match$1 !== "cq.booking") {
    exit = 1;
  } else {
    tmp = null;
  }
  if (exit === 1) {
    var match$2 = selectedCondition.verb;
    var noun = selectedCondition.noun;
    tmp = JsxRuntime.jsxs(FormControl, {
          error: !isValidInput(selectedCondition),
          sx: {
            position: "relative",
            width: "10rem"
          },
          children: [
            JsxRuntime.jsx(Input, {
                  size: "sm",
                  placeholder: "Enter value...",
                  disabled: (match$2 == null),
                  sx: {
                    height: "100%",
                    width: "100%"
                  },
                  value: (noun == null) ? "" : noun,
                  onChange: (function (e) {
                      var val = e.currentTarget.value;
                      return Curry._3(updateCondition, index, "noun", val);
                    }),
                  children: Caml_option.some(Belt_Array.map(getVerbOptions(selectedCondition), (function (param) {
                              var val = param[0];
                              return JsxRuntime.jsx($$Option, {
                                          value: val,
                                          children: Caml_option.some(param[1])
                                        }, "" + val + "-select");
                            })))
                }),
            JsxRuntime.jsx(FormHelperText, {
                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                            level: "body-xs",
                            children: Caml_option.some(getErrorTextForVerb(selectedCondition))
                          })),
                  sx: {
                    position: "absolute",
                    top: "1.8rem"
                  }
                })
          ]
        });
  }
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        spacing: 1,
                        sx: {
                          height: "1rem",
                          py: 2.5
                        },
                        children: [
                          JsxRuntime.jsx(Typography, {
                                level: "body-md",
                                children: Caml_option.some("" + (
                                      index === 0 ? "Where" : "and"
                                    ) + "")
                              }),
                          JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                sx: {
                                  width: "100%"
                                },
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        children: [
                                          JsxRuntime.jsx(Select, {
                                                children: Caml_option.some(Belt_Array.map(props.columnOptions, (function (param) {
                                                            var val = param[0];
                                                            return JsxRuntime.jsx($$Option, {
                                                                        value: val,
                                                                        children: Caml_option.some(param[1]),
                                                                        disabled: shouldDisableColumnOption(val, conditions, selectedCondition)
                                                                      }, "" + val + "-select");
                                                          }))),
                                                onChange: (function (param, val) {
                                                    return Curry._3(updateCondition, index, "column", val);
                                                  }),
                                                placeholder: "Select column...",
                                                size: "sm",
                                                sx: {
                                                  width: "10rem",
                                                  height: "1rem"
                                                },
                                                value: (column == null) ? "" : column
                                              }),
                                          JsxRuntime.jsx(Select, {
                                                children: Caml_option.some(Belt_Array.map(getVerbOptions(selectedCondition), (function (param) {
                                                            var val = param[0];
                                                            return JsxRuntime.jsx($$Option, {
                                                                        value: val,
                                                                        children: Caml_option.some(param[1])
                                                                      }, "" + val + "-select");
                                                          }))),
                                                disabled: (match == null),
                                                onChange: (function (param, val) {
                                                    return Curry._3(updateCondition, index, "verb", val);
                                                  }),
                                                placeholder: "Select condition...",
                                                size: "sm",
                                                sx: {
                                                  width: "10rem",
                                                  height: "1rem"
                                                },
                                                value: (verb == null) ? "" : verb
                                              })
                                        ]
                                      }),
                                  tmp,
                                  JsxRuntime.jsx(IconButton, {
                                        onClick: (function (param) {
                                            return Curry._1(removeCondition, selectedCondition);
                                          }),
                                        color: "danger",
                                        children: Caml_option.some(JsxRuntime.jsx(Delete, {}))
                                      })
                                ]
                              })
                        ]
                      }, index))
            });
}

var FilterRule = {
  make: FilterMenu$FilterRule
};

function FilterMenu(props) {
  var columnOptions = props.columnOptions;
  var setSelectedCabins = props.setSelectedCabins;
  var handleClose = props.handleClose;
  var path = props.path;
  var setError = props.setError;
  var updateCondition = props.updateCondition;
  var removeCondition = props.removeCondition;
  var conditions = props.conditions;
  var hasAnyNullValues = function (conditions) {
    return Belt_Array.some(conditions, (function (condition) {
                  if (Caml_obj.equal(condition.column, "cq.booking")) {
                    if ((condition.column == null) || (condition.verb == null) || Caml_obj.equal(condition.column, "")) {
                      return true;
                    } else {
                      return Caml_obj.equal(condition.verb, "");
                    }
                  } else if ((condition.column == null) || (condition.verb == null) || (condition.noun == null) || Caml_obj.equal(condition.column, "") || Caml_obj.equal(condition.verb, "")) {
                    return true;
                  } else {
                    return Caml_obj.equal(condition.noun, "");
                  }
                }));
  };
  var applyFilters = function (conditions) {
    var match = conditions.length;
    var searchStr = match !== 0 ? "?" + QueryVar$Thick.Condition.toSearchParams(conditions) + "" : "";
    Curry._1(setSelectedCabins, (function (param) {
            return Belt_Set.make(Common$Thick.CabinCmp);
          }));
    RescriptReactRouter.push(path + searchStr);
  };
  var onSubmit = function (e) {
    e.preventDefault();
    applyFilters(conditions);
    Curry._1(handleClose, undefined);
  };
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              component: "form",
              spacing: 1,
              sx: {
                p: 2
              },
              onSubmit: onSubmit,
              children: [
                conditions.length !== 0 ? JsxRuntime.jsxs(Stack, {
                        direction: "column",
                        spacing: 1,
                        children: [
                          JsxRuntime.jsx(Typography, {
                                level: "body-sm",
                                children: "Show me cabins..."
                              }),
                          Belt_Array.mapWithIndex(conditions, (function (i, param) {
                                  return JsxRuntime.jsx(FilterMenu$FilterRule, {
                                              index: i,
                                              conditions: conditions,
                                              columnOptions: columnOptions,
                                              updateCondition: updateCondition,
                                              removeCondition: removeCondition,
                                              setError: setError
                                            }, "filter-rule-" + String(i) + "");
                                }))
                        ]
                      }) : JsxRuntime.jsx(Typography, {
                        level: "body-sm",
                        children: "No filters are currently applied."
                      }),
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      spacing: 1,
                      sx: {
                        pt: 1
                      },
                      children: [
                        JsxRuntime.jsx(Button, {
                              onClick: Caml_option.some(props.addNewCondition),
                              startDecorator: Caml_option.some(JsxRuntime.jsx(Add, {})),
                              variant: "outlined",
                              size: "sm",
                              disabled: hasAnyNullValues(conditions) || conditions.length >= columnOptions.length,
                              children: "Add rule"
                            }),
                        JsxRuntime.jsx(Button, {
                              type: "submit",
                              color: "primary",
                              size: "sm",
                              disabled: Caml_obj.equal(conditions, []) && !props.isClearableAtZero || hasAnyNullValues(conditions) || props.condsSameAsParams,
                              children: "Apply filter"
                            })
                      ]
                    })
              ]
            });
}

var make = FilterMenu;

exports.FilterRule = FilterRule;
exports.make = make;
/* Common-Thick Not a pure module */
