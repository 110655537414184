// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");

function makeRefetchVariables(brandFamily, email, param) {
  return {
          brandFamily: brandFamily,
          email: email
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {"__root":{"email":{"c":"CustomScalars.Email"},"brandFamily":{"c":"CustomScalars.BrandFamilySlug"}}};

var variablesConverterMap = {
  "CustomScalars.BrandFamilySlug": CustomScalars$Thick.BrandFamilySlug.serialize,
  "CustomScalars.Email": CustomScalars$Thick.Email.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"currentUserProfiles_nodes":{"f":""}}};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"currentUserProfiles_nodes":{"f":""}}};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function brandfamPermission_decode($$enum) {
  if ($$enum === "VIEW_MANIFEST" || $$enum === "VIEW_ALL_USERS" || $$enum === "ADD_USER" || $$enum === "VIEW_ALL_VOYAGES" || $$enum === "VIEW_BOOKINGS" || $$enum === "MANAGE_BOOKINGS" || $$enum === "VIEW_PRICING" || $$enum === "GRANT_REVOKE" || $$enum === "VIEW_CUSTOMER_DETAILS" || $$enum === "MANAGE_CABIN_INVENTORY" || $$enum === "VIEW_VOYAGE_META" || $$enum === "MANAGE_USER" || $$enum === "MANAGE_PRICING" || $$enum === "MANAGE_BF_CUSTOMERS" || $$enum === "REQUEST_BRAND_FAMILY_GRANT" || $$enum === "WORKFLOW_PARTICIPANT" || $$enum === "VIEW_CABIN_INVENTORY") {
    return $$enum;
  }
  
}

var brandfamPermission_fromString = brandfamPermission_decode;

var Utils = {
  brandfamPermission_decode: brandfamPermission_decode,
  brandfamPermission_fromString: brandfamPermission_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brandFamily"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "brandFamilySlug",
        "variableName": "brandFamily"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "kind": "ObjectValue",
    "name": "condition"
  }
],
v2 = {
  "alias": "userPerms",
  "args": [
    {
      "kind": "Variable",
      "name": "forBrandFamilySlug",
      "variableName": "brandFamily"
    }
  ],
  "kind": "ScalarField",
  "name": "currentUserCanForBrand",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LeftNavQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CurrentUserProfilesConnection",
        "kind": "LinkedField",
        "name": "currentUserProfiles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CurrentUserProfile",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LeftNavMenu_profile"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LeftNavQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CurrentUserProfilesConnection",
        "kind": "LinkedField",
        "name": "currentUserProfiles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CurrentUserProfile",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brandFamilyVoyages",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ]
  },
  "params": {
    "cacheID": "3fc659771ad252fe3861e3163cb27ea0",
    "id": null,
    "metadata": {},
    "name": "LeftNavQuery",
    "operationKind": "query",
    "text": "query LeftNavQuery(\n  $brandFamily: BrandFamilySlug!\n  $email: Email!\n) {\n  currentUserProfiles(condition: {brandFamilySlug: $brandFamily, email: $email}) {\n    nodes {\n      ...LeftNavMenu_profile\n    }\n  }\n  userPerms: currentUserCanForBrand(forBrandFamilySlug: $brandFamily)\n}\n\nfragment LeftNavMenu_profile on CurrentUserProfile {\n  brandFamilyVoyages\n}\n"
  }
};
})());

var include = RescriptRelay.MakeLoadQuery({
      query: node,
      convertVariables: convertVariables
    });

var load = include.load;

var queryRefToObservable = include.queryRefToObservable;

var queryRefToPromise = include.queryRefToPromise;

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
