// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Input = require("@mui/joy/Input").default;
var JsxRuntime = require("react/jsx-runtime");
var VisibilityOutlined = require("@mui/icons-material/VisibilityOutlined").default;
var VisibilityOffOutlined = require("@mui/icons-material/VisibilityOffOutlined").default;

function PasswordInput(props) {
  var error = props.error;
  var variant = props.variant;
  var required = props.required;
  var disabled = props.disabled;
  var color = props.color;
  var size = props.size;
  var fullWidth = props.fullWidth;
  var fullWidth$1 = fullWidth !== undefined ? fullWidth : false;
  var size$1 = size !== undefined ? size : "md";
  var color$1 = color !== undefined ? color : "neutral";
  var disabled$1 = disabled !== undefined ? disabled : false;
  var required$1 = required !== undefined ? required : false;
  var variant$1 = variant !== undefined ? variant : "outlined";
  var error$1 = error !== undefined ? error : false;
  var match = React.useState(function () {
        return "password";
      });
  var setInputType = match[1];
  var inputType = match[0];
  var toggle = function (param) {
    if (inputType === "password") {
      return Curry._1(setInputType, (function (param) {
                    return "text";
                  }));
    } else {
      return Curry._1(setInputType, (function (param) {
                    return "password";
                  }));
    }
  };
  var tmp = inputType === "text" ? JsxRuntime.jsx(VisibilityOffOutlined, {
          sx: {
            "&:hover": {
              cursor: "pointer"
            }
          },
          onClick: toggle
        }) : JsxRuntime.jsx(VisibilityOutlined, {
          sx: {
            "&:hover": {
              cursor: "pointer"
            }
          },
          onClick: toggle
        });
  return JsxRuntime.jsx(Input, {
              className: Belt_Option.getWithDefault(props.className, ""),
              fullWidth: fullWidth$1,
              size: size$1,
              color: color$1,
              placeholder: Belt_Option.getWithDefault(props.placeholder, ""),
              disabled: disabled$1,
              required: required$1,
              variant: variant$1,
              error: error$1,
              role: Belt_Option.getWithDefault(props.role, ""),
              type: inputType,
              value: props.value,
              onChange: Caml_option.some(props.onChange),
              endDecorator: Caml_option.some(tmp),
              slotProps: Caml_option.some(props.slotProps)
            });
}

var make = PasswordInput;

exports.make = make;
/* react Not a pure module */
