// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"occRange_start_value":{"b":""},"occRange_end_value":{"b":""},"numBerths":{"b":""},"cabin_voyageSubclassSlug":{"c":"CustomScalars.CabinSubclassSlug"},"cabin_voyageClassSlug":{"c":"CustomScalars.CabinClassSlug"},"cabin_voyageCategorySlug":{"c":"CustomScalars.CabinCategorySlug"},"cabin_deckNumber":{"c":"CustomScalars.DeckNumber"},"cabinNumber":{"c":"CustomScalars.CabinNumber"}}};

var fragmentConverterMap = {
  "CustomScalars.DeckNumber": CustomScalars$Thick.DeckNumber.parse,
  "CustomScalars.CabinCategorySlug": CustomScalars$Thick.CabinCategorySlug.parse,
  "CustomScalars.CabinClassSlug": CustomScalars$Thick.CabinClassSlug.parse,
  "CustomScalars.CabinSubclassSlug": CustomScalars$Thick.CabinSubclassSlug.parse,
  "CustomScalars.CabinNumber": CustomScalars$Thick.CabinNumber.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "inclusive",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BDCabin_version",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cabinNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numBerths",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cabin",
      "kind": "LinkedField",
      "name": "cabin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "voyageClassSlug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "voyageCategorySlug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "voyageSubclassSlug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deckNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IntRange",
      "kind": "LinkedField",
      "name": "occRange",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "IntRangeBound",
          "kind": "LinkedField",
          "name": "start",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IntRangeBound",
          "kind": "LinkedField",
          "name": "end",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BookingVersion",
  "abstractKey": null
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
