// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var BDButton$Thick = require("../atoms/BDButton.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var BDBillPayTable$Thick = require("./BDBillPayTable.bs.js");
var Add = require("@mui/icons-material/Add").default;
var Check = require("@mui/icons-material/Check").default;
var MoreHoriz = require("@mui/icons-material/MoreHoriz").default;
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;

function BDInvoiceDetails$TableLine(props) {
  var shouldRender = props.shouldRender;
  var line = props.line;
  if (line.TAG === /* Billing */0) {
    var bl = line._0;
    return JsxRuntime.jsxs("tr", {
                children: [
                  JsxRuntime.jsx("td", {
                        children: JsxRuntime.jsx(Typography, {
                              level: "inherit",
                              noWrap: true,
                              sx: {
                                fontStyle: bl.lineAmount <= 0.0 ? "italic" : "normal"
                              },
                              textColor: "inherit",
                              fontWeight: "inherit",
                              children: Caml_option.some(bl.billingLineDescription)
                            })
                      }),
                  JsxRuntime.jsx("td", {
                        children: JsxRuntime.jsx(Stack, {
                              direction: "row",
                              justifyContent: "flex-end",
                              sx: {
                                width: "100%"
                              },
                              children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                        level: "inherit",
                                        noWrap: true,
                                        sx: {
                                          fontStyle: bl.lineAmount <= 0.0 ? "italic" : "normal"
                                        },
                                        textColor: "inherit",
                                        fontWeight: "inherit",
                                        children: Caml_option.some(Common$Thick.formatCurrency(bl.lineAmount))
                                      }))
                            })
                      }),
                  shouldRender ? JsxRuntime.jsx("td", {
                          children: JsxRuntime.jsx(Stack, {
                                direction: "row",
                                justifyContent: "flex-end",
                                children: Caml_option.some(JsxRuntime.jsx(IconButton, {
                                          onClick: (function (param) {
                                              
                                            }),
                                          sx: {
                                            color: "text.secondary"
                                          },
                                          children: Caml_option.some(JsxRuntime.jsx(ModeEditOutlined, {
                                                    fontSize: "md"
                                                  }))
                                        }))
                              })
                        }) : null
                ]
              });
  }
  var pl = line._0;
  var url = pl.paymentMethodImage;
  return JsxRuntime.jsxs("tr", {
              children: [
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "inherit",
                            noWrap: true,
                            sx: {
                              fontStyle: pl.amount <= 0.0 ? "italic" : "normal"
                            },
                            textColor: "inherit",
                            fontWeight: "inherit",
                            children: Caml_option.some(pl.shouldRenderStatus ? pl.lifecycleStatus : pl.date)
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "inherit",
                            noWrap: true,
                            sx: {
                              fontStyle: pl.amount <= 0.0 ? "italic" : "normal"
                            },
                            textColor: "inherit",
                            fontWeight: "inherit",
                            children: Caml_option.some(pl.payer)
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsxs(Stack, {
                            direction: "row",
                            alignItems: "center",
                            children: [
                              url !== undefined ? JsxRuntime.jsx(Stack, {
                                      direction: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      sx: {
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        pr: "4px"
                                      },
                                      children: Caml_option.some(JsxRuntime.jsx("img", {
                                                style: {
                                                  width: "100%"
                                                },
                                                alt: "payment method",
                                                src: url
                                              }))
                                    }) : null,
                              JsxRuntime.jsx(Typography, {
                                    level: "inherit",
                                    noWrap: true,
                                    sx: {
                                      fontStyle: pl.amount <= 0.0 ? "italic" : "normal",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis"
                                    },
                                    textColor: "inherit",
                                    fontWeight: "inherit",
                                    children: Caml_option.some(pl.paymentMethod)
                                  })
                            ]
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Stack, {
                            direction: "row",
                            justifyContent: "flex-end",
                            sx: {
                              width: "100%"
                            },
                            children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                      level: "inherit",
                                      noWrap: true,
                                      sx: {
                                        fontStyle: pl.amount <= 0.0 ? "italic" : "normal"
                                      },
                                      textColor: "inherit",
                                      fontWeight: "inherit",
                                      children: Caml_option.some(Common$Thick.formatCurrency(pl.amount))
                                    }))
                          })
                    }),
                shouldRender ? JsxRuntime.jsx("td", {
                        children: JsxRuntime.jsx(Stack, {
                              direction: "row",
                              justifyContent: "flex-end",
                              children: Caml_option.some(JsxRuntime.jsx(IconButton, {
                                        onClick: (function (param) {
                                            
                                          }),
                                        sx: {
                                          color: "text.secondary"
                                        },
                                        children: Caml_option.some(JsxRuntime.jsx(MoreHoriz, {
                                                  fontSize: "sm"
                                                }))
                                      }))
                            })
                      }) : null
              ]
            });
}

var TableLine = {
  make: BDInvoiceDetails$TableLine
};

function BDInvoiceDetails$InvoiceDetails(props) {
  var canManage = props.canManage;
  var snapshot = props.snapshot;
  var isActive = props.isActive;
  var content = props.content;
  var shouldRender = isActive && !snapshot && canManage;
  var billingColumns = [
    {
      heading: "Description",
      width: "8rem"
    },
    {
      heading: "Amount",
      width: "2rem"
    },
    {
      heading: "Edit",
      width: "1rem"
    }
  ];
  var paymentColumns = [
    {
      heading: "Date",
      width: "3rem"
    },
    {
      heading: "Payer",
      width: "3rem"
    },
    {
      heading: "Payment Method",
      width: "4rem"
    },
    {
      heading: "Amount",
      width: "3rem"
    },
    {
      heading: "Actions",
      width: "2rem"
    }
  ];
  var match = shouldRender ? [
      billingColumns,
      paymentColumns
    ] : [
      Belt_Array.keep(billingColumns, (function (column) {
              return column.heading !== "Edit";
            })),
      Belt_Array.keep(paymentColumns, (function (column) {
              return column.heading !== "Actions";
            }))
    ];
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              spacing: 4,
              children: [
                JsxRuntime.jsxs(Stack, {
                      children: [
                        JsxRuntime.jsxs(Stack, {
                              direction: "row",
                              justifyContent: "space-between",
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "h4",
                                      component: "h4",
                                      children: "Booking Charges"
                                    }),
                                shouldRender ? JsxRuntime.jsx(BDButton$Thick.make, {
                                        endDecorator: Caml_option.some(JsxRuntime.jsx(Add, {
                                                  fontSize: "sm",
                                                  sx: {
                                                    color: "text.secondary"
                                                  }
                                                })),
                                        onClick: (function (param) {
                                            
                                          }),
                                        text: "Add new charge",
                                        disabled: true
                                      }) : null
                              ]
                            }),
                        content.billing.billingLines.length !== 0 ? JsxRuntime.jsx(BDBillPayTable$Thick.make, {
                                columns: match[0],
                                totalDescription: "Total Billed",
                                total: content.billing.billingLineSubtotal,
                                pendingDescription: "Pending Billed",
                                pendingTotal: 0.0,
                                isActive: isActive,
                                snapshot: snapshot,
                                canManage: canManage,
                                children: Belt_Array.mapWithIndex(content.billing.billingLines, (function (i, line) {
                                        return JsxRuntime.jsx(BDInvoiceDetails$TableLine, {
                                                    line: {
                                                      TAG: /* Billing */0,
                                                      _0: line
                                                    },
                                                    shouldRender: shouldRender
                                                  }, String(i));
                                      }))
                              }) : JsxRuntime.jsx(Stack, {
                                direction: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                sx: {
                                  height: "7rem"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                          level: "title-lg",
                                          component: "p",
                                          textColor: "neutral.500",
                                          children: Caml_option.some(isActive && !snapshot ? "No charges have been added yet." : "No charges added.")
                                        }))
                              })
                      ]
                    }),
                JsxRuntime.jsxs(Stack, {
                      children: [
                        JsxRuntime.jsxs(Stack, {
                              direction: "row",
                              justifyContent: "space-between",
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "h4",
                                      component: "h4",
                                      children: "Payments"
                                    }),
                                shouldRender ? JsxRuntime.jsx(BDButton$Thick.make, {
                                        endDecorator: Caml_option.some(JsxRuntime.jsx(Add, {
                                                  fontSize: "sm",
                                                  sx: {
                                                    color: "text.secondary"
                                                  }
                                                })),
                                        onClick: (function (param) {
                                            
                                          }),
                                        text: "Add new payment",
                                        disabled: true
                                      }) : null
                              ]
                            }),
                        content.payment.paymentLines.length !== 0 ? JsxRuntime.jsx(BDBillPayTable$Thick.make, {
                                columns: match[1],
                                totalDescription: "Total Paid",
                                total: content.payment.completedPaymentSubtotal,
                                pendingDescription: "Pending",
                                pendingTotal: content.arePendingPayments ? content.payment.pendingPaymentSubtotal : 0.0,
                                isActive: isActive,
                                snapshot: snapshot,
                                canManage: canManage,
                                children: Belt_Array.mapWithIndex(content.payment.paymentLines, (function (i, line) {
                                        return JsxRuntime.jsx(BDInvoiceDetails$TableLine, {
                                                    line: {
                                                      TAG: /* Payment */1,
                                                      _0: line
                                                    },
                                                    shouldRender: shouldRender
                                                  }, String(i));
                                      }))
                              }) : JsxRuntime.jsx(Stack, {
                                direction: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                sx: {
                                  height: "7rem"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                          level: "title-lg",
                                          component: "p",
                                          textColor: "neutral.500",
                                          children: Caml_option.some(isActive && !snapshot ? "No payments have been made yet." : "No payments made.")
                                        }))
                              }),
                        JsxRuntime.jsx(Sheet, {
                              sx: {
                                border: "1px solid",
                                borderColor: "divider",
                                borderRadius: "4px",
                                p: 1,
                                my: 2
                              },
                              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        justifyContent: "flex-start",
                                        children: [
                                          JsxRuntime.jsx(Check, {
                                                sx: {
                                                  color: "#037E3A",
                                                  pr: 1
                                                }
                                              }),
                                          JsxRuntime.jsxs(Stack, {
                                                direction: "column",
                                                children: [
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "title-md",
                                                        textColor: "#037E3A",
                                                        children: "Remaining Unpaid Balance"
                                                      }),
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "title-md",
                                                        textColor: "text.secondary",
                                                        children: Caml_option.some(Common$Thick.formatCurrency(content.totalAmount))
                                                      }),
                                                  content.arePendingPayments ? JsxRuntime.jsx(Typography, {
                                                          level: "title-sm",
                                                          sx: {
                                                            fontStyle: "italic"
                                                          },
                                                          textColor: "text.secondary",
                                                          fontWeight: "normal",
                                                          children: Caml_option.some("" + Common$Thick.formatCurrency(content.totalAmountIncludingPending) + " after pending payments")
                                                        }) : null
                                                ]
                                              })
                                        ]
                                      }))
                            })
                      ]
                    })
              ]
            });
}

var InvoiceDetails = {
  make: BDInvoiceDetails$InvoiceDetails
};

function BDInvoiceDetails(props) {
  var billPayRec = props.billPayRec;
  if (billPayRec !== undefined) {
    return JsxRuntime.jsx(BDInvoiceDetails$InvoiceDetails, {
                content: billPayRec,
                isActive: props.isActive,
                snapshot: props.snapshot,
                canManage: props.canManage
              });
  } else {
    return JsxRuntime.jsx(Stack, {
                direction: "column",
                justifyContent: "center",
                alignItems: "center",
                sx: {
                  height: "7rem"
                },
                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                          level: "title-lg",
                          component: "p",
                          textColor: "neutral.500",
                          children: "No bill pay information available."
                        }))
              });
  }
}

var make = BDInvoiceDetails;

exports.TableLine = TableLine;
exports.InvoiceDetails = InvoiceDetails;
exports.make = make;
/* Common-Thick Not a pure module */
