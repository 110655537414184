// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Pervasives = require("rescript/lib/js/pervasives.js");
var Core__Array = require("./Core__Array.bs.js");

function fromString(radix, x) {
  var maybeInt = radix !== undefined ? parseInt(x, radix) : parseInt(x);
  if (isNaN(maybeInt) || maybeInt > 2147483647 || maybeInt < -2147483648) {
    return ;
  } else {
    return maybeInt | 0;
  }
}

function rangeWithOptions(start, end, options) {
  var isInverted = start > end;
  var n = options.step;
  var step;
  if (n !== undefined) {
    if (n !== 0) {
      step = n;
    } else {
      if (start !== end) {
        throw new RangeError("Incorrect range arguments");
      }
      step = n;
    }
  } else {
    step = isInverted ? -1 : 1;
  }
  var length;
  if (isInverted === step >= 0) {
    length = 0;
  } else if (step === 0) {
    length = options.inclusive === true ? 1 : 0;
  } else {
    var range = isInverted ? start - end | 0 : end - start | 0;
    var range$1 = options.inclusive === true ? range + 1 | 0 : range;
    length = Math.ceil(range$1 / Pervasives.abs(step)) | 0;
  }
  return Core__Array.fromInitializer(length, (function (i) {
                return start + Math.imul(i, step) | 0;
              }));
}

function range(start, end) {
  return rangeWithOptions(start, end, {});
}

var Constants = {
  minValue: -2147483648,
  maxValue: 2147483647
};

exports.Constants = Constants;
exports.fromString = fromString;
exports.range = range;
exports.rangeWithOptions = rangeWithOptions;
/* No side effect */
