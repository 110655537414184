// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

function makeRefetchVariables(param) {
  
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var wrapResponseConverter = {"__root":{"":{"f":""}}};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"":{"f":""}}};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginRouteQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "StandardLayout_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "LoginPage_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LoginRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentUserEmail",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentUserSignonStatus",
        "storageKey": null
      },
      {
        "alias": "allBrandFamilies",
        "args": null,
        "concreteType": "CurrentUserProfilesConnection",
        "kind": "LinkedField",
        "name": "currentUserProfiles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CurrentUserProfile",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brandFamilySlug",
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "66c67beca8ae24f0c0c044746ef87e81",
    "id": null,
    "metadata": {},
    "name": "LoginRouteQuery",
    "operationKind": "query",
    "text": "query LoginRouteQuery {\n  ...StandardLayout_query\n  ...LoginPage_query\n}\n\nfragment AccountMenu_bfselector on CurrentUserProfile {\n  brandFamilySlug\n}\n\nfragment AccountMenu_query on Query {\n  currentUserEmail\n  allBrandFamilies: currentUserProfiles {\n    nodes {\n      ...AccountMenu_bfselector\n    }\n  }\n}\n\nfragment LoginPage_query on Query {\n  currentUserEmail\n  currentUserSignonStatus\n}\n\nfragment NavBar_profile on Query {\n  currentUserSignonStatus\n  currentUserEmail\n  ...AccountMenu_query\n}\n\nfragment StandardLayout_query on Query {\n  currentUserEmail\n  currentUserSignonStatus\n  ...NavBar_profile\n}\n"
  }
};

var include = RescriptRelay.MakeLoadQuery({
      query: node,
      convertVariables: convertVariables
    });

var load = include.load;

var queryRefToObservable = include.queryRefToObservable;

var queryRefToPromise = include.queryRefToPromise;

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* include Not a pure module */
