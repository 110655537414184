// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var BDHero$Thick = require("../components/booking-details/BDHero.bs.js");
var Common$Thick = require("../utils/Common.bs.js");
var Grid = require("@mui/joy/Grid").default;
var BDCabin$Thick = require("../components/booking-details/BDCabin.bs.js");
var Stack = require("@mui/joy/Stack").default;
var BDComments$Thick = require("../components/booking-details/BDComments.bs.js");
var DraftAlert$Thick = require("../components/booking-details/drafts/DraftAlert.bs.js");
var BDWorkflows$Thick = require("../components/booking-details/BDWorkflows.bs.js");
var BookingDiff$Thick = require("../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var BDPreferences$Thick = require("../components/booking-details/BDPreferences.bs.js");
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var BDBillPaySummary$Thick = require("../components/booking-details/billing-payments/BDBillPaySummary.bs.js");
var BDBillingContact$Thick = require("../components/booking-details/BDBillingContact.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var BDAnnouncementBar$Thick = require("../components/booking-details/BDAnnouncementBar.bs.js");
var BDBerthsPassengers$Thick = require("../components/booking-details/BDBerthsPassengers.bs.js");
var BookingDetailsPage_query_graphql$Thick = require("../__generated__/BookingDetailsPage_query_graphql.bs.js");

var convertFragment = BookingDetailsPage_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BookingDetailsPage_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BookingDetailsPage_query_graphql$Thick.node, convertFragment);
}

var QueryFragment_brandfamPermission_decode = BookingDetailsPage_query_graphql$Thick.Utils.brandfamPermission_decode;

var QueryFragment_brandfamPermission_fromString = BookingDetailsPage_query_graphql$Thick.Utils.brandfamPermission_fromString;

var QueryFragment_lifecycleStatus_decode = BookingDetailsPage_query_graphql$Thick.Utils.lifecycleStatus_decode;

var QueryFragment_lifecycleStatus_fromString = BookingDetailsPage_query_graphql$Thick.Utils.lifecycleStatus_fromString;

var QueryFragment = {
  brandfamPermission_decode: QueryFragment_brandfamPermission_decode,
  brandfamPermission_fromString: QueryFragment_brandfamPermission_fromString,
  lifecycleStatus_decode: QueryFragment_lifecycleStatus_decode,
  lifecycleStatus_fromString: QueryFragment_lifecycleStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function BookingDetailsPage(props) {
  var generatePreviewFromDiff = props.generatePreviewFromDiff;
  var billPayRec = props.billPayRec;
  var snapshot = props.snapshot;
  var latestVersion = props.latestVersion;
  var voyage = props.voyage;
  var brandFamily = props.brandFamily;
  var snapshot$1 = snapshot !== undefined ? snapshot : false;
  var query = RescriptRelay_Fragment.useFragment(BookingDetailsPage_query_graphql$Thick.node, convertFragment, props.fragmentRefs);
  React.useState(function () {
        return "ACTIVE";
      });
  var redirect = "/" + CustomScalars$Thick.BrandFamilySlug.serialize(brandFamily) + "/" + CustomScalars$Thick.VoyageSlug.serialize(voyage) + "/bookings/" + CustomScalars$Thick.BookingId.serialize(props.bookingId) + "";
  var versionUrl = latestVersion !== undefined ? "" + redirect + "/" + String(latestVersion) + "" : redirect;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  Belt_List.toArray(url.path).join(",");
  var match = query.bookingVersions;
  var booking = match !== undefined ? Belt_Array.get(match.nodes, 0) : undefined;
  var userPerms = Belt_Set.fromArray(Belt_Array.keepMap(Belt_Option.getWithDefault(query.currentUserCanForBrand, []), (function (x) {
              if (x !== undefined) {
                return x;
              }
              
            })), Common$Thick.PermCmp);
  var canManage = Belt_Set.has(userPerms, "MANAGE_BOOKINGS");
  React.useEffect((function () {
          if (booking !== undefined) {
            
          } else {
            RescriptReactRouter.push(redirect);
          }
        }), []);
  if (booking === undefined) {
    return null;
  }
  var isActive = booking.lifecycleStatus === "ACTIVE";
  var status = diffStruct.hasDiff ? "DRAFT" : booking.lifecycleStatus;
  return JsxRuntime.jsxs(Stack, {
              sx: {
                height: "100%",
                width: "100%",
                backgroundColor: "primary.50",
                position: "relative"
              },
              children: [
                status === "ACTIVE" ? null : JsxRuntime.jsx(BDAnnouncementBar$Thick.make, {
                        status: status,
                        redirect: redirect,
                        latestVersion: latestVersion,
                        paxes: booking.paxes,
                        diffStruct: diffStruct,
                        applyArgs: Curry._1(generatePreviewFromDiff, diffStruct)
                      }),
                JsxRuntime.jsx(Box, {
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                sx: {
                                  width: "100%",
                                  height: "100%",
                                  maxWidth: "1536px"
                                },
                                children: Caml_option.some(JsxRuntime.jsxs(Grid, {
                                          children: [
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsx(BDHero$Thick.make, {
                                                            fragmentRefs: booking.fragmentRefs,
                                                            snapshot: snapshot$1,
                                                            historyFrag: query.fragmentRefs,
                                                            redirect: redirect,
                                                            status: status
                                                          })),
                                                  xs: 12
                                                }),
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                            spacing: 2,
                                                            children: [
                                                              JsxRuntime.jsx(BDBillingContact$Thick.make, {
                                                                    fragmentRefs: booking.fragmentRefs,
                                                                    snapshot: snapshot$1,
                                                                    isActive: isActive,
                                                                    canManage: canManage,
                                                                    versionUrl: versionUrl,
                                                                    billPayRec: billPayRec,
                                                                    brandFamily: brandFamily,
                                                                    generatePreviewFromDiff: generatePreviewFromDiff
                                                                  }),
                                                              JsxRuntime.jsx(BDCabin$Thick.make, {
                                                                    fragmentRefs: booking.fragmentRefs,
                                                                    snapshot: snapshot$1,
                                                                    isActive: isActive,
                                                                    canManage: canManage,
                                                                    diffStruct: diffStruct
                                                                  }),
                                                              Belt_Set.has(userPerms, "VIEW_CUSTOMER_DETAILS") ? JsxRuntime.jsx(BDBerthsPassengers$Thick.make, {
                                                                      fragmentRefs: booking.fragmentRefs,
                                                                      snapshot: snapshot$1,
                                                                      isActive: isActive,
                                                                      canManage: canManage,
                                                                      billPayRec: billPayRec,
                                                                      versionUrl: versionUrl,
                                                                      brandFamily: brandFamily,
                                                                      generatePreviewFromDiff: generatePreviewFromDiff,
                                                                      voyage: voyage
                                                                    }) : null,
                                                              JsxRuntime.jsx(BDPreferences$Thick.make, {})
                                                            ]
                                                          })),
                                                  xs: 12,
                                                  md: 7
                                                }),
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                            spacing: 2,
                                                            children: [
                                                              JsxRuntime.jsx(BDBillPaySummary$Thick.make, {
                                                                    fragmentRefs: booking.fragmentRefs,
                                                                    snapshot: snapshot$1,
                                                                    isActive: isActive,
                                                                    canManage: canManage,
                                                                    billPayRec: billPayRec
                                                                  }),
                                                              JsxRuntime.jsx(BDWorkflows$Thick.make, {}),
                                                              JsxRuntime.jsx(BDComments$Thick.make, {})
                                                            ]
                                                          })),
                                                  xs: 12,
                                                  md: 5
                                                })
                                          ],
                                          container: true,
                                          rowSpacing: 2,
                                          columnSpacing: 3
                                        }))
                              })),
                      sx: {
                        flex: 1,
                        overflow: "auto",
                        px: 3,
                        pb: 3,
                        pt: status === "ACTIVE" ? 3 : 2,
                        backgroundColor: "primary.50",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        borderLeft: status !== "ACTIVE" ? "3px solid" : "none",
                        borderRight: status !== "ACTIVE" ? "3px solid" : "none",
                        borderColor: Common$Thick.Lifecycle.getPrimaryColor(status)
                      }
                    }),
                status === "DRAFT" ? JsxRuntime.jsx(DraftAlert$Thick.make, {
                        paxes: booking.paxes,
                        diffStruct: diffStruct
                      }) : null
              ]
            });
}

var make = BookingDetailsPage;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
