// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Spinner$Thick = require("../components/common/atoms/Spinner.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var DashboardLayout$Thick = require("../layouts/DashboardLayout.bs.js");
var OrgProtectedRoute$Thick = require("../components/wrappers/OrgProtectedRoute.bs.js");
var BrandProtectedRoute$Thick = require("../components/wrappers/BrandProtectedRoute.bs.js");
var VoyageProtectedRoute$Thick = require("../components/wrappers/VoyageProtectedRoute.bs.js");
var LatestBookingVersionRedirect$Thick = require("../pages/LatestBookingVersionRedirect.bs.js");
var LatestBookingVersionRedirectRouteQuery_graphql$Thick = require("../__generated__/LatestBookingVersionRedirectRouteQuery_graphql.bs.js");

var convertVariables = LatestBookingVersionRedirectRouteQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = LatestBookingVersionRedirectRouteQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = LatestBookingVersionRedirectRouteQuery_graphql$Thick.Internal.convertWrapRawResponse;

function use(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.useQuery(convertVariables, LatestBookingVersionRedirectRouteQuery_graphql$Thick.node, convertResponse, param, param$1, param$2, param$3, param$4);
}

function useLoader(param) {
  return RescriptRelay_Query.useLoader(convertVariables, LatestBookingVersionRedirectRouteQuery_graphql$Thick.node, (function (prim) {
                return prim;
              }), param);
}

function usePreloaded(param) {
  return RescriptRelay_Query.usePreloaded(LatestBookingVersionRedirectRouteQuery_graphql$Thick.node, convertResponse, (function (prim) {
                return prim;
              }), param);
}

function $$fetch(param, param$1, param$2, param$3, param$4, param$5) {
  return RescriptRelay_Query.$$fetch(LatestBookingVersionRedirectRouteQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4, param$5);
}

function fetchPromised(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.fetchPromised(LatestBookingVersionRedirectRouteQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4);
}

function retain(param, param$1) {
  return RescriptRelay_Query.retain(LatestBookingVersionRedirectRouteQuery_graphql$Thick.node, convertVariables, param, param$1);
}

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function LatestBookingVersionRedirectRoute(props) {
  var bookingId = props.bookingId;
  var voyage = props.voyage;
  var brandFamily = props.brandFamily;
  var data = use({
        bookingId: bookingId,
        brandFamily: brandFamily,
        voyage: voyage
      }, undefined, undefined, undefined, undefined);
  var fragmentRefs = data.fragmentRefs;
  return JsxRuntime.jsx(DashboardLayout$Thick.make, {
              fragmentRefs: fragmentRefs,
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(OrgProtectedRoute$Thick.make, {
                              fragmentRefs: fragmentRefs,
                              children: JsxRuntime.jsx(BrandProtectedRoute$Thick.make, {
                                    fragmentRefs: fragmentRefs,
                                    brandFamily: brandFamily,
                                    children: JsxRuntime.jsx(VoyageProtectedRoute$Thick.make, {
                                          fragmentRefs: fragmentRefs,
                                          brandFamily: brandFamily,
                                          voyage: voyage,
                                          children: JsxRuntime.jsx(LatestBookingVersionRedirect$Thick.make, {
                                                fragmentRefs: fragmentRefs,
                                                brandFamily: brandFamily,
                                                voyage: voyage,
                                                bookingId: bookingId
                                              })
                                        })
                                  })
                            })),
                    fallback: Caml_option.some(JsxRuntime.jsx(Spinner$Thick.make, {}))
                  }),
              brandFamily: CustomScalars$Thick.BrandFamilySlug.serialize(brandFamily),
              voyage: Caml_option.some(voyage)
            });
}

var make = LatestBookingVersionRedirectRoute;

exports.Query = Query;
exports.make = make;
/* react Not a pure module */
