// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var BDButton$Thick = require("../atoms/BDButton.bs.js");
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PreviewModal$Thick = require("./PreviewModal.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var PreviewChanges$Thick = require("./PreviewChanges.bs.js");
var Add = require("@mui/icons-material/Add").default;

function AddBerthBtn(props) {
  var disabled = props.disabled;
  var disabled$1 = disabled !== undefined ? disabled : false;
  var match = React.useState(function () {
        return false;
      });
  var match$1 = React.useState(function () {
        return false;
      });
  var setModalOpen = match$1[1];
  var match$2 = React.useState(function () {
        
      });
  var setError = match$2[1];
  var match$3 = React.useState(function () {
        
      });
  var setBillPayNew = match$3[1];
  var onError = function (s) {
    Curry._1(setError, (function (param) {
            return s;
          }));
    Curry._1(setModalOpen, (function (param) {
            return true;
          }));
  };
  var onSuccess = function (b) {
    Curry._1(setBillPayNew, (function (param) {
            return b;
          }));
    Curry._1(setModalOpen, (function (param) {
            return true;
          }));
  };
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var applyDiff = BookingDiff$Thick.Alter.addSingleBerth(diffStruct);
  var match$4 = PreviewChanges$Thick.useQuery(props.generatePreviewFromDiff, applyDiff, match[1], onSuccess, onError);
  var onClick = match$4.onClick;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(BDButton$Thick.make, {
                      endDecorator: Caml_option.some(JsxRuntime.jsx(Add, {
                                fontSize: "sm",
                                sx: {
                                  color: "text.secondary"
                                }
                              })),
                      onClick: (function (param) {
                          Curry._1(onClick, undefined);
                        }),
                      text: "Add Berth",
                      loading: match[0],
                      disabled: disabled$1
                    }),
                JsxRuntime.jsx(PreviewModal$Thick.make, {
                      billPayCurrent: props.billPayCurrent,
                      billPayNew: match$3[0],
                      applyDiff: applyDiff,
                      title: "Add Berth",
                      description: "You are proposing to add a berth to this booking. Please carefully review the changes below.",
                      onClose: (function (param) {
                          Curry._1(setBillPayNew, (function (param) {
                                  
                                }));
                          Curry._1(setError, (function (param) {
                                  
                                }));
                          Curry._1(setModalOpen, (function (param) {
                                  return false;
                                }));
                        }),
                      open_: match$1[0],
                      versionUrl: props.versionUrl,
                      error: match$2[0]
                    })
              ]
            });
}

var make = AddBerthBtn;

exports.make = make;
/* react Not a pure module */
