// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Js_array = require("rescript/lib/js/js_array.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../utils/Common.bs.js");
var Menu = require("@mui/joy/Menu").default;
var Badge = require("@mui/joy/Badge").default;
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var QueryVar$Thick = require("../../utils/QueryVar.bs.js");
var Button = require("@mui/joy/Button").default;
var Tooltip = require("@mui/joy/Tooltip").default;
var FilterMenu$Thick = require("./FilterMenu.bs.js");
var Dropdown = require("@mui/joy/Dropdown").default;
var MenuItem = require("@mui/joy/MenuItem").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var MenuButton = require("@mui/joy/MenuButton").default;
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var BulkActionForm$Thick = require("./BulkActionForm.bs.js");
var BrandPermProtected$Thick = require("../wrappers/BrandPermProtected.bs.js");
var Share = require("@mui/icons-material/Share").default;
var FilterList = require("@mui/icons-material/FilterList").default;
var ChevronLeft = require("@mui/icons-material/ChevronLeft").default;
var ChevronRight = require("@mui/icons-material/ChevronRight").default;
var ArrowDropDown = require("@mui/icons-material/ArrowDropDown").default;

function TableFilter(props) {
  var setSelectedCabins = props.setSelectedCabins;
  var selectedCabins = props.selectedCabins;
  var bottom = props.bottom;
  var page = props.page;
  var onLoadNext = props.onLoadNext;
  var offset = props.offset;
  var total = props.total;
  var fragmentRefs = props.fragmentRefs;
  var bottom$1 = bottom !== undefined ? bottom : false;
  var match = React.useState(function () {
        
      });
  var setActionType = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setIsOpen = match$1[1];
  var isOpen = match$1[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var paramConditions = QueryVar$Thick.Condition.fromSearchParams(url.search);
  var columnOptions = [
    [
      "cq.class",
      "Class",
      "StringRestricted"
    ],
    [
      "cq.subclass",
      "Subclass",
      "StringRestricted"
    ],
    [
      "cq.category",
      "Category",
      "StringRestricted"
    ],
    [
      "cq.cabin",
      "Cabin",
      "String"
    ],
    [
      "cq.booking",
      "Booking",
      "Boolean"
    ],
    [
      "cq.occRange",
      "Sales Occupancy Range",
      "IntRange"
    ],
    [
      "cq.totBerths",
      "Total Berths",
      "IntArg"
    ],
    [
      "cq.segment",
      "Segment",
      "String"
    ],
    [
      "cq.privateNote",
      "Private Note",
      "String"
    ],
    [
      "cq.publicNote",
      "Public Note",
      "String"
    ]
  ];
  var actionOptions = [
    [
      "change_cabin_capacity",
      "Change Cabin Capacity"
    ],
    [
      "change_cabin_segment",
      "Change Cabin Segment"
    ]
  ];
  var match$2 = React.useState(function () {
        return paramConditions;
      });
  var setConditions = match$2[1];
  var conditions = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setCopied = match$3[1];
  var isClearableAtZero = paramConditions.length !== 0;
  var condsSameAsParams = QueryVar$Thick.Condition.arraysEqual(conditions, paramConditions);
  var addNewCondition = function (param) {
    var newConditions = Belt_Array.concat(conditions, [{
            column: null,
            verb: null,
            noun: null
          }]);
    Curry._1(setConditions, (function (param) {
            return newConditions;
          }));
  };
  var updateCondition = function (index, field, value) {
    var condition = Belt_Option.getExn(Belt_Array.get(conditions, index));
    var updatedCondition;
    switch (field) {
      case "column" :
          updatedCondition = {
            column: value,
            verb: condition.verb,
            noun: condition.noun
          };
          break;
      case "noun" :
          updatedCondition = {
            column: condition.column,
            verb: condition.verb,
            noun: value
          };
          break;
      case "verb" :
          updatedCondition = {
            column: condition.column,
            verb: value,
            noun: condition.noun
          };
          break;
      default:
        updatedCondition = condition;
    }
    var newConditions = Belt_Array.mapWithIndex(conditions, (function (i, c) {
            if (i === index) {
              return updatedCondition;
            } else {
              return c;
            }
          }));
    Curry._1(setConditions, (function (param) {
            return newConditions;
          }));
  };
  var removeCondition = function (index) {
    var newConditions = Js_array.filteri((function (i, param) {
            return Caml_obj.notequal(i, index);
          }), conditions);
    Curry._1(setConditions, (function (param) {
            return newConditions;
          }));
  };
  var match$4 = React.useState(function () {
        
      });
  var setError = match$4[1];
  var handleClose = function (param) {
    Curry._1(setIsOpen, (function (param) {
            return !isOpen;
          }));
    Curry._1(setError, (function (param) {
            
          }));
    Curry._1(setConditions, (function (param) {
            return paramConditions;
          }));
  };
  var match$5 = React.useState(function () {
        return false;
      });
  var setActionModalOpen = match$5[1];
  return JsxRuntime.jsxs(Sheet, {
              sx: {
                p: 1
              },
              children: [
                JsxRuntime.jsx(BrandPermProtected$Thick.make, {
                      fragmentRefs: fragmentRefs,
                      neededPerm: "MANAGE_CABIN_INVENTORY",
                      children: JsxRuntime.jsx(BulkActionForm$Thick.make, {
                            fragmentRefs: fragmentRefs,
                            selectedCabins: selectedCabins,
                            isOpen: match$5[0],
                            onClose: (function (param) {
                                Curry._1(setActionModalOpen, (function (param) {
                                        return false;
                                      }));
                                Curry._1(setActionType, (function (param) {
                                        
                                      }));
                              }),
                            actionType: match[0],
                            setSelectedCabins: setSelectedCabins,
                            voyage: props.voyage,
                            brandFamily: props.brandFamily,
                            refetch: props.refetch
                          })
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      justifyContent: "space-between",
                      children: [
                        JsxRuntime.jsx(Stack, {
                              direction: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              children: Caml_option.some(bottom$1 ? JsxRuntime.jsx("div", {}) : JsxRuntime.jsxs(Dropdown, {
                                          children: [
                                            JsxRuntime.jsx(Badge, {
                                                  badgeContent: paramConditions.length,
                                                  children: Caml_option.some(JsxRuntime.jsx(MenuButton, {
                                                            children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                      level: "body-xs",
                                                                      children: "Filter "
                                                                    })),
                                                            endDecorator: Caml_option.some(JsxRuntime.jsx(FilterList, {
                                                                      fontSize: "lg"
                                                                    })),
                                                            size: "sm",
                                                            variant: "outlined"
                                                          })),
                                                  color: "neutral",
                                                  showZero: false,
                                                  size: "sm"
                                                }),
                                            JsxRuntime.jsx(Menu, {
                                                  children: Caml_option.some(JsxRuntime.jsx(FilterMenu$Thick.make, {
                                                            conditions: conditions,
                                                            addNewCondition: addNewCondition,
                                                            removeCondition: removeCondition,
                                                            updateCondition: updateCondition,
                                                            setError: setError,
                                                            error: match$4[0],
                                                            path: props.path,
                                                            handleClose: handleClose,
                                                            isClearableAtZero: isClearableAtZero,
                                                            condsSameAsParams: condsSameAsParams,
                                                            setSelectedCabins: setSelectedCabins,
                                                            columnOptions: columnOptions
                                                          })),
                                                  onClose: handleClose,
                                                  placement: "bottom-start"
                                                })
                                          ],
                                          open: isOpen,
                                          onOpenChange: handleClose
                                        }))
                            }),
                        JsxRuntime.jsxs(Stack, {
                              direction: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              children: [
                                JsxRuntime.jsx(Stack, {
                                      direction: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      spacing: 1,
                                      sx: {
                                        position: "relative"
                                      },
                                      children: Caml_option.some(bottom$1 ? JsxRuntime.jsx("div", {}) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                  children: [
                                                    JsxRuntime.jsx(BrandPermProtected$Thick.make, {
                                                          fragmentRefs: fragmentRefs,
                                                          neededPerm: "MANAGE_CABIN_INVENTORY",
                                                          children: JsxRuntime.jsxs(Dropdown, {
                                                                children: [
                                                                  JsxRuntime.jsx(MenuButton, {
                                                                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                                  level: "body-xs",
                                                                                  textColor: "inherit",
                                                                                  children: "Bulk Actions"
                                                                                })),
                                                                        color: "primary",
                                                                        disabled: Belt_Set.isEmpty(selectedCabins),
                                                                        endDecorator: Caml_option.some(JsxRuntime.jsx(ArrowDropDown, {})),
                                                                        size: "sm",
                                                                        variant: Belt_Set.isEmpty(selectedCabins) ? "outlined" : "solid"
                                                                      }),
                                                                  JsxRuntime.jsx(Menu, {
                                                                        children: Caml_option.some(Belt_Array.map(actionOptions, (function (param) {
                                                                                    var action = param[0];
                                                                                    return JsxRuntime.jsx(MenuItem, {
                                                                                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                                                          level: "body-xs",
                                                                                                          children: Caml_option.some(param[1])
                                                                                                        })),
                                                                                                onClick: (function (param) {
                                                                                                    Curry._1(setActionType, (function (param) {
                                                                                                            return action;
                                                                                                          }));
                                                                                                    Curry._1(setActionModalOpen, (function (param) {
                                                                                                            return true;
                                                                                                          }));
                                                                                                  })
                                                                                              }, action);
                                                                                  })))
                                                                      })
                                                                ]
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(Tooltip, {
                                                          children: JsxRuntime.jsx(Button, {
                                                                onClick: (function (param) {
                                                                    return Common$Thick.copyToClipboard(window.location.href, setCopied);
                                                                  }),
                                                                endDecorator: Caml_option.some(JsxRuntime.jsx(Share, {
                                                                          fontSize: "sm"
                                                                        })),
                                                                variant: "outlined",
                                                                color: "neutral",
                                                                size: "sm",
                                                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                          level: "body-xs",
                                                                          children: "Share Current View"
                                                                        }))
                                                              }),
                                                          arrow: true,
                                                          disableHoverListener: true,
                                                          open: match$3[0],
                                                          placement: "top",
                                                          size: "sm",
                                                          title: "Copied to clipboard!"
                                                        })
                                                  ]
                                                }))
                                    }),
                                JsxRuntime.jsx(IconButton, {
                                      onClick: (function (param) {
                                          return Curry._1(onLoadNext, page - 1 | 0);
                                        }),
                                      variant: "plain",
                                      disabled: offset === 0,
                                      children: Caml_option.some(JsxRuntime.jsx(ChevronLeft, {}))
                                    }),
                                JsxRuntime.jsx(Typography, {
                                      level: "body-xs",
                                      children: Caml_option.some("" + String(total < 1 ? 0 : offset + 1 | 0) + " - " + String(Math.min(offset + 50 | 0, total)) + " / " + String(total) + "")
                                    }),
                                JsxRuntime.jsx(IconButton, {
                                      onClick: (function (param) {
                                          return Curry._1(onLoadNext, page + 1 | 0);
                                        }),
                                      variant: "plain",
                                      disabled: (offset + 50 | 0) >= total,
                                      children: Caml_option.some(JsxRuntime.jsx(ChevronRight, {}))
                                    })
                              ]
                            })
                      ]
                    })
              ]
            });
}

var make = TableFilter;

exports.make = make;
/* react Not a pure module */
