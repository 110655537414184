// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;

function VoyageDashboardPage(props) {
  return JsxRuntime.jsxs(Typography, {
              children: [
                JsxRuntime.jsx("h1", {
                      children: "Welcome to " + props.voyage + ": a " + props.brandFamily + " voyage!"
                    }),
                JsxRuntime.jsx("p", {
                      children: "In a future release, this page will serve as a dashboard for information from across this voyage."
                    })
              ]
            });
}

var make = VoyageDashboardPage;

exports.make = make;
/* react/jsx-runtime Not a pure module */
