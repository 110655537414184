// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var Dialog$Thick = require("../common/molecules/Dialog.bs.js");
var Grid = require("@mui/joy/Grid").default;
var Stack = require("@mui/joy/Stack").default;
var BDButton$Thick = require("./atoms/BDButton.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var CustomScalars$Thick = require("../../utils/CustomScalars.bs.js");
var AspectRatio = require("@mui/joy/AspectRatio").default;
var BDContentBlock$Thick = require("./molecules/BDContentBlock.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var BDCabin_version_graphql$Thick = require("../../__generated__/BDCabin_version_graphql.bs.js");
var PublishedWithChanges = require("@mui/icons-material/PublishedWithChanges").default;

var convertFragment = BDCabin_version_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BDCabin_version_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDCabin_version_graphql$Thick.node, convertFragment);
}

var VersionFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function BDCabin$Cabin$DataStack(props) {
  var value = props.value;
  var label = props.label;
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "body-sm",
                      noWrap: true,
                      sx: {
                        color: "neutral.500"
                      },
                      fontWeight: "normal",
                      children: Caml_option.some(label)
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "row",
                      alignItems: "center",
                      spacing: 1,
                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                level: props.size === "sm" ? "body-sm" : "body-md",
                                noWrap: true,
                                sx: {
                                  color: value !== undefined ? "text.primary" : "neutral.plainDisabledColor"
                                },
                                fontWeight: "normal",
                                children: Caml_option.some(value !== undefined ? value : "No " + label.toLowerCase() + " provided")
                              }))
                    })
              ]
            });
}

var DataStack = {
  make: BDCabin$Cabin$DataStack
};

function BDCabin$Cabin(props) {
  var content = props.content;
  var match = React.useState(function () {
        return false;
      });
  var setModalOpen = match[1];
  var actions = props.isActive && !(props.snapshot || !props.canManage) ? JsxRuntime.jsxs(Stack, {
          direction: "row",
          spacing: 1,
          children: [
            JsxRuntime.jsx(BDButton$Thick.make, {
                  onClick: (function (param) {
                      
                    }),
                  text: "Cancel Reservation",
                  disabled: true
                }),
            JsxRuntime.jsx(BDButton$Thick.make, {
                  endDecorator: Caml_option.some(JsxRuntime.jsx(PublishedWithChanges, {
                            fontSize: "sm",
                            sx: {
                              color: "text.secondary"
                            }
                          })),
                  onClick: (function (param) {
                      Curry._1(setModalOpen, (function (param) {
                              return true;
                            }));
                    }),
                  text: "Change",
                  disabled: true
                })
          ]
        }) : null;
  var match$1 = content.occupancyMin;
  var match$2 = content.occupancyMax;
  var occupancyRange = match$1 !== undefined ? (
      match$2 !== undefined ? String(match$1) + "-" + String(match$2) : String(match$1) + "+"
    ) : (
      match$2 !== undefined ? String(match$2) : undefined
    );
  var image = content.stateroomImage;
  var tmp;
  if (image !== undefined) {
    var slug = content.cabinClassSlug;
    tmp = JsxRuntime.jsx("img", {
          alt: slug !== undefined ? slug : "Cabin",
          src: image
        });
  } else {
    tmp = JsxRuntime.jsx(Typography, {
          level: "body-sm",
          sx: {
            color: "text.secondary"
          },
          children: "No image"
        });
  }
  var slug$1 = content.cabinClassSlug;
  var slug$2 = content.cabinCategorySlug;
  return JsxRuntime.jsxs(BDContentBlock$Thick.make, {
              title: "Cabin",
              actions: Caml_option.some(actions),
              children: [
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      alignItems: "center",
                      spacing: 2,
                      children: [
                        JsxRuntime.jsx(Box, {
                              children: Caml_option.some(JsxRuntime.jsx(AspectRatio, {
                                        children: Caml_option.some(tmp),
                                        objectFit: "cover",
                                        ratio: "4 / 3",
                                        sx: {
                                          backgroundColor: "background.level2",
                                          borderRadius: "4px"
                                        }
                                      })),
                              sx: {
                                width: "6rem"
                              }
                            }),
                        JsxRuntime.jsx(BDCabin$Cabin$DataStack, {
                              label: slug$1 !== undefined ? slug$1 : "Cabin Category",
                              value: slug$2 !== undefined ? slug$2.toUpperCase() : undefined,
                              size: "md"
                            })
                      ]
                    }),
                JsxRuntime.jsxs(Grid, {
                      children: [
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(BDCabin$Cabin$DataStack, {
                                        label: "Cabin Type",
                                        value: content.cabinSubclassSlug,
                                        size: "sm"
                                      })),
                              xs: 2
                            }),
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(BDCabin$Cabin$DataStack, {
                                        label: "Deck",
                                        value: content.deck,
                                        size: "sm"
                                      })),
                              xs: 2
                            }),
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(BDCabin$Cabin$DataStack, {
                                        label: "Cabin Number",
                                        value: content.cabinNumber,
                                        size: "sm"
                                      })),
                              xs: 3
                            }),
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(BDCabin$Cabin$DataStack, {
                                        label: "Berths",
                                        value: String(content.numBerths),
                                        size: "sm"
                                      })),
                              xs: 2
                            }),
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(BDCabin$Cabin$DataStack, {
                                        label: "Occupancy",
                                        value: occupancyRange,
                                        size: "sm"
                                      })),
                              xs: 2
                            })
                      ],
                      container: true,
                      sx: {
                        mt: 2
                      }
                    }),
                JsxRuntime.jsx(Dialog$Thick.make, {
                      open_: match[0],
                      onClose: (function (param) {
                          Curry._1(setModalOpen, (function (param) {
                                  return false;
                                }));
                        }),
                      title: "Change Cabin",
                      children: JsxRuntime.jsx("div", {
                            children: "Change cabin modal goes here"
                          })
                    })
              ]
            });
}

var Cabin = {
  DataStack: DataStack,
  make: BDCabin$Cabin
};

function BDCabin(props) {
  var diffStruct = props.diffStruct;
  var data = RescriptRelay_Fragment.useFragment(BDCabin_version_graphql$Thick.node, convertFragment, props.fragmentRefs);
  var match = diffStruct.addBerths;
  var match$1 = diffStruct.dropBerths;
  var numBerths = match !== undefined ? (
      match$1 !== undefined ? (data.numBerths + match | 0) - match$1.length | 0 : data.numBerths + match | 0
    ) : (
      match$1 !== undefined ? data.numBerths - match$1.length | 0 : data.numBerths
    );
  var match$2 = data.cabin;
  var tmp;
  if (match$2 !== undefined) {
    var num = match$2.deckNumber;
    tmp = num !== undefined ? String(CustomScalars$Thick.DeckNumber.serialize(Caml_option.valFromOption(num))) : undefined;
  } else {
    tmp = undefined;
  }
  var match$3 = data.cabin;
  var tmp$1;
  if (match$3 !== undefined) {
    var slug = match$3.voyageClassSlug;
    tmp$1 = slug !== undefined ? CustomScalars$Thick.CabinClassSlug.serialize(Caml_option.valFromOption(slug)) : undefined;
  } else {
    tmp$1 = undefined;
  }
  var match$4 = data.cabin;
  var tmp$2;
  if (match$4 !== undefined) {
    var slug$1 = match$4.voyageCategorySlug;
    tmp$2 = slug$1 !== undefined ? CustomScalars$Thick.CabinCategorySlug.serialize(Caml_option.valFromOption(slug$1)) : undefined;
  } else {
    tmp$2 = undefined;
  }
  var match$5 = data.cabin;
  var tmp$3;
  if (match$5 !== undefined) {
    var slug$2 = match$5.voyageSubclassSlug;
    tmp$3 = slug$2 !== undefined ? CustomScalars$Thick.CabinSubclassSlug.serialize(Caml_option.valFromOption(slug$2)) : undefined;
  } else {
    tmp$3 = undefined;
  }
  var match$6 = data.occRange.start;
  var match$7 = data.occRange.end;
  var content_cabinNumber = CustomScalars$Thick.CabinNumber.serialize(data.cabinNumber);
  var content_occupancyMin = match$6 !== undefined ? (
      match$6.inclusive ? match$6.value : match$6.value + 1 | 0
    ) : undefined;
  var content_occupancyMax = match$7 !== undefined ? (
      match$7.inclusive ? match$7.value : match$7.value - 1 | 0
    ) : undefined;
  var content_stateroomImage = "https://i.ibb.co/kGcdwMS/stateroomtest.jpg";
  var content = {
    cabinNumber: content_cabinNumber,
    numBerths: numBerths,
    deck: tmp,
    cabinClassSlug: tmp$1,
    cabinCategorySlug: tmp$2,
    cabinSubclassSlug: tmp$3,
    occupancyMin: content_occupancyMin,
    occupancyMax: content_occupancyMax,
    stateroomImage: content_stateroomImage
  };
  return JsxRuntime.jsx(BDCabin$Cabin, {
              content: content,
              snapshot: props.snapshot,
              isActive: props.isActive,
              canManage: props.canManage
            });
}

var make = BDCabin;

exports.VersionFragment = VersionFragment;
exports.Cabin = Cabin;
exports.make = make;
/* react Not a pure module */
