// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var S$RescriptStruct = require("rescript-struct/src/S.bs.js");

var struct = S$RescriptStruct.transform(S$RescriptStruct.string(undefined), (function (v) {
        if (v === "") {
          return ;
        } else {
          return v;
        }
      }), undefined, (function (v) {
        if (v !== undefined) {
          return v;
        } else {
          return "";
        }
      }), undefined);

var OptionalNonEmptyString = {
  struct: struct
};

var struct$1 = S$RescriptStruct.transform(struct, (function (v) {
        if (v !== undefined) {
          return v;
        } else {
          return S$RescriptStruct.fail(undefined, "non empty string is required");
        }
      }), undefined, (function (v) {
        if (v === "") {
          return S$RescriptStruct.fail(undefined, "non empty string is required");
        } else {
          return v;
        }
      }), undefined);

var NonEmptyString = {
  struct: struct$1
};

var struct$2 = S$RescriptStruct.transform(struct, (function (v) {
        return Belt_Option.map(v, (function (s) {
                      var $$int = Belt_Int.fromString(s);
                      if ($$int !== undefined) {
                        return $$int;
                      } else {
                        return S$RescriptStruct.fail(undefined, "requires an int");
                      }
                    }));
      }), undefined, (function (v) {
        return Belt_Option.map(v, (function (prim) {
                      return String(prim);
                    }));
      }), undefined);

var OptionalInt = {
  struct: struct$2
};

var struct$3 = S$RescriptStruct.transform(struct$2, (function (v) {
        if (v !== undefined) {
          return v;
        } else {
          return S$RescriptStruct.fail(undefined, "requires an int");
        }
      }), undefined, (function (v) {
        return v;
      }), undefined);

var Int = {
  struct: struct$3
};

var struct$4 = S$RescriptStruct.refine(struct$3, (function (v) {
        if (v < 1) {
          return S$RescriptStruct.fail(undefined, "int must be greater than 0");
        }
        
      }), undefined, undefined, undefined);

var PositiveInt = {
  struct: struct$4
};

var struct$5 = S$RescriptStruct.transform(S$RescriptStruct.string(undefined), (function (value) {
        return decodeURIComponent(value);
      }), undefined, (function (value) {
        return encodeURIComponent(value);
      }), undefined);

var Token = {
  struct: struct$5
};

var delimiter = "+";

var struct$6 = S$RescriptStruct.transform(S$RescriptStruct.string(undefined), (function (value) {
        return S$RescriptStruct.parseAnyOrRaiseWith(value.split(delimiter), S$RescriptStruct.array(struct$5));
      }), undefined, (function (value) {
        return Belt_Array.map(value, (function (v) {
                        return S$RescriptStruct.serializeOrRaiseWith(v, struct$5);
                      })).join(delimiter);
      }), undefined);

function parse(v) {
  return S$RescriptStruct.parseAnyOrRaiseWith(v, struct$6);
}

function serialize(v) {
  return S$RescriptStruct.serializeToUnknownOrRaiseWith(v, struct$6);
}

var TokenArray = {
  delimiter: delimiter,
  struct: struct$6,
  parse: parse,
  serialize: serialize
};

function make(tupleStruct) {
  return S$RescriptStruct.transform(struct$6, (function (v) {
                var tup = S$RescriptStruct.parseAnyWith(v, tupleStruct);
                if (tup.TAG === /* Ok */0) {
                  return tup._0;
                } else {
                  return S$RescriptStruct.advancedFail(tup._0);
                }
              }), undefined, (function (v) {
                var o = S$RescriptStruct.serializeToUnknownWith(v, tupleStruct);
                if (o.TAG === /* Ok */0) {
                  return o._0;
                } else {
                  return S$RescriptStruct.advancedFail(o._0);
                }
              }), undefined);
}

var Tuple = {
  make: make
};

function make$1(tupleStruct) {
  return S$RescriptStruct.array(make(tupleStruct));
}

var TupleArray = {
  make: make$1
};

function make$2(field, fieldToString, valueStruct, set, get, aggregate, disaggregate) {
  return {
          field: field,
          fieldToString: fieldToString,
          ingest: (function (dest, parameterValues) {
              var successes = Belt_Array.keepMap(parameterValues, (function (individualString) {
                      return Belt_Result.mapWithDefault(S$RescriptStruct.parseAnyWith(S$RescriptStruct.parseAnyOrRaiseWith(individualString, struct$6), valueStruct), undefined, (function (a) {
                                    return Caml_option.some(a);
                                  }));
                    }));
              return Curry._2(set, dest, Curry._1(aggregate, successes));
            }),
          excrete: (function (dest) {
              return Belt_Array.keepMap(Curry._1(disaggregate, Curry._1(get, dest)), (function (v) {
                            return Belt_Result.mapWithDefault(S$RescriptStruct.serializeToUnknownWith(v, valueStruct), undefined, (function (v) {
                                          return S$RescriptStruct.serializeToUnknownOrRaiseWith(v, struct$6);
                                        }));
                          }));
            })
        };
}

function aggregate(useFirstOccurenceOpt, v) {
  var useFirstOccurence = useFirstOccurenceOpt !== undefined ? useFirstOccurenceOpt : false;
  return Belt_Array.get(v, useFirstOccurence ? 0 : v.length - 1 | 0);
}

function disaggregate(v) {
  return Belt_Option.mapWithDefault(v, [], (function (v) {
                return [v];
              }));
}

var Singular = {
  aggregate: aggregate,
  disaggregate: disaggregate
};

var Root = {
  make: make$2,
  Singular: Singular
};

function make$3(field, fieldToString, scalarStruct, useFirstOccurenceOpt, set, get) {
  var useFirstOccurence = useFirstOccurenceOpt !== undefined ? useFirstOccurenceOpt : false;
  var partial_arg = useFirstOccurence;
  return make$2(field, fieldToString, S$RescriptStruct.tuple1(scalarStruct), set, get, (function (param) {
                return aggregate(partial_arg, param);
              }), disaggregate);
}

function make$4(field, fieldToString, scalarStruct, useFirstOccurenceOpt, set, get) {
  var useFirstOccurence = useFirstOccurenceOpt !== undefined ? useFirstOccurenceOpt : false;
  var partial_arg = useFirstOccurence;
  return make$2(field, fieldToString, S$RescriptStruct.array(scalarStruct), set, get, (function (param) {
                return aggregate(partial_arg, param);
              }), disaggregate);
}

var $$Array = {
  make: make$4
};

var Scalar = {
  make: make$3,
  $$Array: $$Array
};

function make$5(field, fieldToString, valueStruct, useFirstOccurenceOpt, set, get) {
  var useFirstOccurence = useFirstOccurenceOpt !== undefined ? useFirstOccurenceOpt : false;
  var partial_arg = useFirstOccurence;
  return make$2(field, fieldToString, valueStruct, set, get, (function (param) {
                return aggregate(partial_arg, param);
              }), disaggregate);
}

function make$6(field, fieldToString, valueStruct, set, get, toKeyValue, fromKeyValue) {
  var aggregate = function (v) {
    var map = Belt_MapInt.fromArray(Belt_Array.map(v, toKeyValue));
    if (Belt_MapInt.isEmpty(map)) {
      return ;
    } else {
      return Caml_option.some(map);
    }
  };
  var disaggregate = function (v) {
    return Belt_Option.mapWithDefault(v, [], (function (map) {
                  return Belt_Array.map(Belt_MapInt.toArray(map), fromKeyValue);
                }));
  };
  return make$2(field, fieldToString, valueStruct, set, get, aggregate, disaggregate);
}

var IntMap = {
  make: make$6
};

var Tuple$1 = {
  make: make$5,
  IntMap: IntMap
};

var Parameter = {
  Root: Root,
  Scalar: Scalar,
  Tuple: Tuple$1
};

var raw = S$RescriptStruct.transform(S$RescriptStruct.string(undefined), (function (v) {
        return Belt_Array.reduce(Belt_Array.keepMap(v.split("&"), (function (parameter) {
                          if (parameter === "") {
                            return ;
                          }
                          var splitted = parameter.split("=");
                          return [
                                  Belt_Array.getExn(splitted, 0),
                                  Belt_Array.sliceToEnd(splitted, 1).join("=")
                                ];
                        })), undefined, (function (carry, param) {
                      var value = param[1];
                      return Belt_MapString.update(carry, param[0], (function (v) {
                                    return Belt_Option.mapWithDefault(v, [value], (function (extantArray) {
                                                  return Belt_Array.concat(extantArray, [value]);
                                                }));
                                  }));
                    }));
      }), undefined, (function (v) {
        return Belt_Array.concatMany(Belt_Array.map(Belt_MapString.toArray(v), (function (param) {
                            var key = param[0];
                            return Belt_Array.map(param[1], (function (value) {
                                          if (value === "") {
                                            return key;
                                          } else {
                                            return "" + key + "=" + value + "";
                                          }
                                        }));
                          }))).join("&");
      }), undefined);

var jsRaw = S$RescriptStruct.transform(raw, (function (v) {
        return Object.fromEntries(Belt_MapString.toArray(v));
      }), undefined, (function (v) {
        return Belt_MapString.fromArray(Object.entries(v));
      }), undefined);

function make$7(postHocTransformationOpt, parameters, emptyDest) {
  var postHocTransformation = postHocTransformationOpt !== undefined ? postHocTransformationOpt : (function (a) {
        return a;
      });
  var paramDict = Belt_MapString.fromArray(Belt_Array.map(parameters, (function (param) {
              return [
                      Curry._1(param.fieldToString, param.field),
                      param
                    ];
            })));
  return S$RescriptStruct.transform(raw, (function (rawParams) {
                return Curry._1(postHocTransformation, Belt_Array.reduce(Belt_MapString.toArray(Belt_MapString.merge(rawParams, paramDict, (function (param, rawParamValArray, paramDescriptor) {
                                          if (rawParamValArray !== undefined && paramDescriptor !== undefined) {
                                            return [
                                                    paramDescriptor,
                                                    rawParamValArray
                                                  ];
                                          }
                                          
                                        }))), emptyDest, (function (accum, param) {
                                  var match = param[1];
                                  return Curry._2(match[0].ingest, accum, match[1]);
                                })));
              }), undefined, (function (fullDest) {
                return Belt_MapString.fromArray(Belt_Array.map(Belt_MapString.toArray(paramDict), (function (param) {
                                  return [
                                          param[0],
                                          Curry._1(param[1].excrete, fullDest)
                                        ];
                                })));
              }), undefined);
}

function serialize$1(struct, queryString) {
  return S$RescriptStruct.serializeToUnknownOrRaiseWith(queryString, struct);
}

function parse$1(struct, queryString) {
  return S$RescriptStruct.parseAnyOrRaiseWith(queryString, struct);
}

var Parser = {
  raw: raw,
  jsRaw: jsRaw,
  make: make$7,
  serialize: serialize$1,
  parse: parse$1
};

exports.OptionalNonEmptyString = OptionalNonEmptyString;
exports.NonEmptyString = NonEmptyString;
exports.OptionalInt = OptionalInt;
exports.Int = Int;
exports.PositiveInt = PositiveInt;
exports.Token = Token;
exports.TokenArray = TokenArray;
exports.Tuple = Tuple;
exports.TupleArray = TupleArray;
exports.Parameter = Parameter;
exports.Parser = Parser;
/* struct Not a pure module */
