// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Js_promise2 = require("rescript/lib/js/js_promise2.js");
var ReactRelay = require("react-relay");
var RescriptRelay = require("./RescriptRelay.bs.js");
var RelayRuntime = require("relay-runtime");
var RescriptRelay_Internal = require("./RescriptRelay_Internal.bs.js");

function useQuery(convertVariables, node, convertResponse, variables, fetchPolicy, fetchKey, networkCacheConfig, param) {
  return RescriptRelay_Internal.internal_useConvertedValue(convertResponse, ReactRelay.useLazyLoadQuery(node, RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(Curry._1(convertVariables, variables)), {
                  fetchKey: fetchKey,
                  fetchPolicy: RescriptRelay.mapFetchPolicy(fetchPolicy),
                  networkCacheConfig: networkCacheConfig
                }));
}

function useLoader(convertVariables, node, mkQueryRef, param) {
  var match = ReactRelay.useQueryLoader(node);
  var loadQueryFn = match[1];
  var loadQuery = React.useMemo((function () {
          return function (param, param$1, param$2, param$3) {
            return Curry._2(loadQueryFn, Curry._1(convertVariables, param), {
                        fetchPolicy: RescriptRelay.mapFetchPolicy(param$1),
                        networkCacheConfig: param$2
                      });
          };
        }), [loadQueryFn]);
  return [
          Curry._1(mkQueryRef, Caml_option.nullable_to_opt(match[0])),
          loadQuery,
          match[2]
        ];
}

function usePreloaded(node, convertResponse, mkQueryRef, queryRef) {
  return RescriptRelay_Internal.internal_useConvertedValue(convertResponse, ReactRelay.usePreloadedQuery(node, Curry._1(mkQueryRef, queryRef)));
}

function $$fetch(node, convertResponse, convertVariables, environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  ReactRelay.fetchQuery(environment, node, Curry._1(convertVariables, variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: RescriptRelay.mapFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            Curry._1(onResult, {
                  TAG: /* Ok */0,
                  _0: Curry._1(convertResponse, res)
                });
          }),
        error: (function (err) {
            Curry._1(onResult, {
                  TAG: /* Error */1,
                  _0: err
                });
          })
      });
}

function fetchPromised(node, convertResponse, convertVariables, environment, variables, networkCacheConfig, fetchPolicy, param) {
  return Js_promise2.then(ReactRelay.fetchQuery(environment, node, Curry._1(convertVariables, variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: RescriptRelay.mapFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return Promise.resolve(Curry._1(convertResponse, res));
              }));
}

function retain(node, convertVariables, environment, variables) {
  return environment.retain(RelayRuntime.createOperationDescriptor(node, Curry._1(convertVariables, variables)));
}

function commitLocalPayload(node, convertVariables, convertWrapRawResponse, environment, variables, payload) {
  environment.commitPayload(RelayRuntime.createOperationDescriptor(node, Curry._1(convertVariables, variables)), Curry._1(convertWrapRawResponse, payload));
}

exports.useQuery = useQuery;
exports.useLoader = useLoader;
exports.usePreloaded = usePreloaded;
exports.$$fetch = $$fetch;
exports.fetchPromised = fetchPromised;
exports.retain = retain;
exports.commitLocalPayload = commitLocalPayload;
/* react Not a pure module */
