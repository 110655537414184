// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"passenger_nodes_idx":{"b":""},"passenger_nodes_bfcustUser_legalDocumentLastName":{"c":"CustomScalars.NonemptyString"},"passenger_nodes_bfcustUser_legalDocumentFirstName":{"c":"CustomScalars.NonemptyString"},"passenger_nodes_bfcustUser_lastName":{"c":"CustomScalars.NonemptyString"},"passenger_nodes_bfcustUser_firstName":{"c":"CustomScalars.NonemptyString"},"passenger_nodes_bfcustUser_email":{"c":"CustomScalars.Email"},"passenger_nodes_bfcustUser_accountId":{"c":"CustomScalars.AccountId"},"occRange_end_value":{"b":""},"numBerths":{"b":""}}};

var fragmentConverterMap = {
  "CustomScalars.AccountId": CustomScalars$Thick.AccountId.parse,
  "CustomScalars.Email": CustomScalars$Thick.Email.parse,
  "CustomScalars.NonemptyString": CustomScalars$Thick.NonemptyString.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function paxDataStatus_decode($$enum) {
  if ($$enum === "INCOMPLETE" || $$enum === "CONFIRMED" || $$enum === "INVITED") {
    return $$enum;
  }
  
}

var paxDataStatus_fromString = paxDataStatus_decode;

var Utils = {
  paxDataStatus_decode: paxDataStatus_decode,
  paxDataStatus_fromString: paxDataStatus_fromString
};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BDBerthsPassengers_version",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numBerths",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "IDX_ASC"
        }
      ],
      "concreteType": "PassengersConnection",
      "kind": "LinkedField",
      "name": "passenger",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Passenger",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paxDataStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "idx",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BfcustUser",
              "kind": "LinkedField",
              "name": "bfcustUser",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "accountId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "legalDocumentFirstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "legalDocumentLastName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "passenger(orderBy:\"IDX_ASC\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IntRange",
      "kind": "LinkedField",
      "name": "occRange",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "IntRangeBound",
          "kind": "LinkedField",
          "name": "end",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "inclusive",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BookingVersion",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* RescriptRelay Not a pure module */
