// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var RelayEnv$Thick = require("../../../RelayEnv.bs.js");
var BillPayInfo$Thick = require("../billing-payments/BillPayInfo.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var PreviewChangesQuery_graphql$Thick = require("../../../__generated__/PreviewChangesQuery_graphql.bs.js");

var convertVariables = PreviewChangesQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = PreviewChangesQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = PreviewChangesQuery_graphql$Thick.Internal.convertWrapRawResponse;

function use(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.useQuery(convertVariables, PreviewChangesQuery_graphql$Thick.node, convertResponse, param, param$1, param$2, param$3, param$4);
}

function useLoader(param) {
  return RescriptRelay_Query.useLoader(convertVariables, PreviewChangesQuery_graphql$Thick.node, (function (prim) {
                return prim;
              }), param);
}

function usePreloaded(param) {
  return RescriptRelay_Query.usePreloaded(PreviewChangesQuery_graphql$Thick.node, convertResponse, (function (prim) {
                return prim;
              }), param);
}

function $$fetch(param, param$1, param$2, param$3, param$4, param$5) {
  return RescriptRelay_Query.$$fetch(PreviewChangesQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4, param$5);
}

function fetchPromised(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.fetchPromised(PreviewChangesQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4);
}

function retain(param, param$1) {
  return RescriptRelay_Query.retain(PreviewChangesQuery_graphql$Thick.node, convertVariables, param, param$1);
}

var Query_pmtLifecycleStatus_decode = PreviewChangesQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var Query_pmtLifecycleStatus_fromString = PreviewChangesQuery_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var Query = {
  pmtLifecycleStatus_decode: Query_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: Query_pmtLifecycleStatus_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function useQuery(generatePreviewFromDiff, applyDiff, setLoading, onSuccess, onError) {
  var preview = function (param) {
    Curry._1(setLoading, (function (param) {
            return true;
          }));
    var previewArgs = Curry._1(generatePreviewFromDiff, applyDiff);
    $$fetch(RelayEnv$Thick.environment, {
          bookingDiff: previewArgs.bookingDiff,
          bookingId: previewArgs.bookingId,
          bookingVersionNumber: previewArgs.bookingVersionNumber,
          voyage: previewArgs.voyageSlug
        }, (function (res) {
            if (res.TAG === /* Ok */0) {
              var changes = res._0.proposedChanges;
              var billPayNew = changes !== undefined ? BillPayInfo$Thick.parse({
                      TAG: /* Proposed */2,
                      _0: changes
                    }) : undefined;
              Curry._1(onSuccess, billPayNew);
              Curry._1(setLoading, (function (param) {
                      return false;
                    }));
              return ;
            }
            Curry._1(onError, "An error occurred");
            Curry._1(setLoading, (function (param) {
                    return false;
                  }));
          }), undefined, undefined, undefined);
  };
  var onSubmit = function (e) {
    e.preventDefault();
    preview(undefined);
  };
  return {
          onClick: preview,
          onSubmit: onSubmit
        };
}

exports.Query = Query;
exports.useQuery = useQuery;
/* RelayEnv-Thick Not a pure module */
