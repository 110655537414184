// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Js_array = require("rescript/lib/js/js_array.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Core__Int = require("@rescript/core/src/Core__Int.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var S$RescriptStruct = require("rescript-struct/src/S.bs.js");

var $$int = S$RescriptStruct.transform(S$RescriptStruct.string(undefined), (function (value) {
        var $$int = Core__Int.fromString(undefined, value);
        if ($$int !== undefined) {
          return $$int;
        } else {
          return S$RescriptStruct.fail(undefined, "int not parseable from string");
        }
      }), undefined, (function (v) {
        return v.toString();
      }), undefined);

function json(innerStruct) {
  return S$RescriptStruct.transform(S$RescriptStruct.string(undefined), (function (value) {
                try {
                  var value$1 = S$RescriptStruct.parseWith(JSON.parse(value), innerStruct);
                  if (value$1.TAG === /* Ok */0) {
                    return value$1._0;
                  } else {
                    return S$RescriptStruct.advancedFail(value$1._0);
                  }
                }
                catch (exn){
                  return S$RescriptStruct.fail(undefined, "doesn't seem to contain JSON");
                }
              }), undefined, (function (v) {
                var value = S$RescriptStruct.serializeWith(v, innerStruct);
                if (value.TAG === /* Ok */0) {
                  return JSON.stringify(value._0);
                } else {
                  return S$RescriptStruct.advancedFail(value._0);
                }
              }), undefined);
}

var verbNoun = S$RescriptStruct.transform(S$RescriptStruct.string(undefined), (function (value) {
        var match = Belt_List.fromArray(value.split("."));
        if (match) {
          return [
                  match.hd,
                  Belt_Array.joinWith(Belt_List.toArray(match.tl), ".", (function (prim) {
                          return prim;
                        }))
                ];
        } else {
          return S$RescriptStruct.fail(undefined, "not a verb.noun");
        }
      }), undefined, (function (param) {
        return "" + param[0] + "." + param[1] + "";
      }), undefined);

var Stringable = {
  $$int: $$int,
  json: json,
  verbNoun: verbNoun
};

function mapOk(res, map) {
  if (res.TAG === /* Ok */0) {
    return Curry._1(map, res._0);
  } else {
    return S$RescriptStruct.advancedFail(res._0);
  }
}

var rangeStruct = S$RescriptStruct.transform(S$RescriptStruct.$$String.min(S$RescriptStruct.string(undefined), undefined, 2), (function (string) {
        var matches = string.match(/^(\d*)-(\d*)$/);
        if (matches == null) {
          return S$RescriptStruct.fail(undefined, "not a range");
        }
        var match = matches.slice(1);
        if (match.length !== 2) {
          return S$RescriptStruct.fail(undefined, "not a range (2)");
        }
        var lnum = match[0];
        if (lnum === "") {
          var rnum = match[1];
          return {
                  TAG: /* UpToAndIncluding */0,
                  _0: Belt_Option.getExn(Core__Int.fromString(undefined, rnum))
                };
        }
        var rnum$1 = match[1];
        if (rnum$1 === "") {
          return {
                  TAG: /* IncludingAndAbove */1,
                  _0: Belt_Option.getExn(Core__Int.fromString(undefined, lnum))
                };
        } else {
          return {
                  TAG: /* RangeInclusive */2,
                  _0: [
                    Belt_Option.getExn(Core__Int.fromString(undefined, lnum)),
                    Belt_Option.getExn(Core__Int.fromString(undefined, rnum$1))
                  ]
                };
        }
      }), undefined, (function (value) {
        switch (value.TAG | 0) {
          case /* UpToAndIncluding */0 :
              return "-" + value._0.toString() + "";
          case /* IncludingAndAbove */1 :
              return "" + value._0.toString() + "-";
          case /* RangeInclusive */2 :
              var match = value._0;
              return "" + match[0].toString() + "-" + match[1].toString() + "";
          
        }
      }), undefined);

var struct = S$RescriptStruct.transform(verbNoun, (function (param) {
        var noun = param[1];
        switch (param[0]) {
          case "contains" :
              return mapOk(S$RescriptStruct.parseAnyWith(noun, rangeStruct), (function (v) {
                            return {
                                    TAG: /* Contains */0,
                                    _0: v
                                  };
                          }));
          case "containsEl" :
              return mapOk(S$RescriptStruct.parseAnyWith(noun, $$int), (function (v) {
                            return {
                                    TAG: /* ContainsElement */1,
                                    _0: v
                                  };
                          }));
          default:
            return S$RescriptStruct.fail(undefined, "unrecognized IntRange.Filter");
        }
      }), undefined, (function (value) {
        if (value.TAG === /* Contains */0) {
          return mapOk(S$RescriptStruct.serializeWith(value._0, rangeStruct), (function (range) {
                        return [
                                "contains",
                                range
                              ];
                      }));
        } else {
          return mapOk(S$RescriptStruct.serializeWith(value._0, $$int), (function (i) {
                        return [
                                "containsEl",
                                i
                              ];
                      }));
        }
      }), undefined);

function input_IntRangeFilter(t) {
  if (t.TAG !== /* Contains */0) {
    return {
            containsElement: t._0
          };
  }
  var range = t._0;
  switch (range.TAG | 0) {
    case /* UpToAndIncluding */0 :
        return {
                lessThanOrEqualTo: {
                  start: {
                    value: 0,
                    inclusive: false
                  },
                  end: {
                    value: range._0,
                    inclusive: true
                  }
                }
              };
    case /* IncludingAndAbove */1 :
        return {
                contains: {
                  start: {
                    value: range._0,
                    inclusive: true
                  },
                  end: {
                    value: 4,
                    inclusive: true
                  }
                }
              };
    case /* RangeInclusive */2 :
        var match = range._0;
        return {
                contains: {
                  start: {
                    value: match[0],
                    inclusive: true
                  },
                  end: {
                    value: match[1],
                    inclusive: true
                  }
                }
              };
    
  }
}

var Filter = {
  struct: struct,
  input_IntRangeFilter: input_IntRangeFilter
};

var IntRange = {
  rangeStruct: rangeStruct,
  Filter: Filter
};

var strWithLenStruct = S$RescriptStruct.$$String.min(S$RescriptStruct.string(undefined), undefined, 1);

var arrayWithLenStruct = S$RescriptStruct.$$Array.min(S$RescriptStruct.array(strWithLenStruct), undefined, 1);

var struct$1 = S$RescriptStruct.transform(verbNoun, (function (param) {
        var noun = param[1];
        switch (param[0]) {
          case "equals" :
              return mapOk(S$RescriptStruct.parseAnyWith(noun, strWithLenStruct), (function (str) {
                            return {
                                    TAG: /* EqualTo */1,
                                    _0: str
                                  };
                          }));
          case "in" :
              return mapOk(S$RescriptStruct.parseAnyWith(noun, json(arrayWithLenStruct)), (function (arr) {
                            return {
                                    TAG: /* In */2,
                                    _0: arr
                                  };
                          }));
          case "includes" :
              return mapOk(S$RescriptStruct.parseAnyWith(noun, strWithLenStruct), (function (v) {
                            return {
                                    TAG: /* IncludesInsensitive */0,
                                    _0: v
                                  };
                          }));
          default:
            return S$RescriptStruct.fail(undefined, "unrecognized StringArg.Filter");
        }
      }), undefined, (function (value) {
        switch (value.TAG | 0) {
          case /* IncludesInsensitive */0 :
              return mapOk(S$RescriptStruct.serializeWith(value._0, strWithLenStruct), (function (str) {
                            return [
                                    "includes",
                                    str
                                  ];
                          }));
          case /* EqualTo */1 :
              return mapOk(S$RescriptStruct.serializeWith(value._0, strWithLenStruct), (function (str) {
                            return [
                                    "equals",
                                    str
                                  ];
                          }));
          case /* In */2 :
              return mapOk(S$RescriptStruct.serializeWith(Belt_SetString.toArray(Belt_SetString.fromArray(value._0)), json(arrayWithLenStruct)), (function (arr) {
                            return [
                                    "in",
                                    arr
                                  ];
                          }));
          
        }
      }), undefined);

function input_StringFilter(t) {
  switch (t.TAG | 0) {
    case /* IncludesInsensitive */0 :
        return {
                includesInsensitive: t._0
              };
    case /* EqualTo */1 :
        return {
                equalTo: t._0
              };
    case /* In */2 :
        return {
                in: t._0
              };
    
  }
}

function input_NonemptyStringFilter(t) {
  switch (t.TAG | 0) {
    case /* IncludesInsensitive */0 :
        return {
                includesInsensitive: t._0
              };
    case /* EqualTo */1 :
        return {
                equalTo: t._0
              };
    case /* In */2 :
        return {
                in: t._0
              };
    
  }
}

var Filter$1 = {
  strWithLenStruct: strWithLenStruct,
  arrayWithLenStruct: arrayWithLenStruct,
  struct: struct$1,
  input_StringFilter: input_StringFilter,
  input_NonemptyStringFilter: input_NonemptyStringFilter
};

var StringArg = {
  Filter: Filter$1
};

var struct$2 = S$RescriptStruct.transform(verbNoun, (function (param) {
        var noun = param[1];
        switch (param[0]) {
          case "equals" :
              return mapOk(S$RescriptStruct.parseAnyWith(noun, $$int), (function (v) {
                            return {
                                    TAG: /* EqualTo */0,
                                    _0: v
                                  };
                          }));
          case "gt" :
              return mapOk(S$RescriptStruct.parseAnyWith(noun, $$int), (function (v) {
                            return {
                                    TAG: /* Above */4,
                                    _0: v
                                  };
                          }));
          case "gte" :
              return mapOk(S$RescriptStruct.parseAnyWith(noun, $$int), (function (v) {
                            return {
                                    TAG: /* IncludingAndAbove */2,
                                    _0: v
                                  };
                          }));
          case "lt" :
              return mapOk(S$RescriptStruct.parseAnyWith(noun, $$int), (function (v) {
                            return {
                                    TAG: /* UpTo */3,
                                    _0: v
                                  };
                          }));
          case "lte" :
              return mapOk(S$RescriptStruct.parseAnyWith(noun, $$int), (function (v) {
                            return {
                                    TAG: /* UpToAndIncluding */1,
                                    _0: v
                                  };
                          }));
          default:
            return S$RescriptStruct.fail(undefined, "unrecognized IntArg.Filter");
        }
      }), undefined, (function (value) {
        switch (value.TAG | 0) {
          case /* EqualTo */0 :
              return mapOk(S$RescriptStruct.serializeWith(value._0, $$int), (function (range) {
                            return [
                                    "equals",
                                    range
                                  ];
                          }));
          case /* UpToAndIncluding */1 :
              return mapOk(S$RescriptStruct.serializeWith(value._0, $$int), (function (range) {
                            return [
                                    "lte",
                                    range
                                  ];
                          }));
          case /* IncludingAndAbove */2 :
              return mapOk(S$RescriptStruct.serializeWith(value._0, $$int), (function (range) {
                            return [
                                    "gte",
                                    range
                                  ];
                          }));
          case /* UpTo */3 :
              return mapOk(S$RescriptStruct.serializeWith(value._0, $$int), (function (range) {
                            return [
                                    "lt",
                                    range
                                  ];
                          }));
          case /* Above */4 :
              return mapOk(S$RescriptStruct.serializeWith(value._0, $$int), (function (range) {
                            return [
                                    "gt",
                                    range
                                  ];
                          }));
          
        }
      }), undefined);

function parse_Int(t) {
  return t._0;
}

function input_OccupancyFilter(t) {
  switch (t.TAG | 0) {
    case /* EqualTo */0 :
        return {
                equalTo: t._0
              };
    case /* UpToAndIncluding */1 :
        return {
                lessThanOrEqualTo: t._0
              };
    case /* IncludingAndAbove */2 :
        return {
                greaterThanOrEqualTo: t._0
              };
    case /* UpTo */3 :
        return {
                lessThan: t._0
              };
    case /* Above */4 :
        return {
                greaterThan: t._0
              };
    
  }
}

var Filter$2 = {
  struct: struct$2,
  parse_Int: parse_Int,
  input_OccupancyFilter: input_OccupancyFilter
};

var IntArg = {
  Filter: Filter$2
};

var struct$3 = S$RescriptStruct.transform(S$RescriptStruct.string(undefined), (function (v) {
        switch (v) {
          case "false" :
              return false;
          case "true" :
              return true;
          default:
            return S$RescriptStruct.fail(undefined, "not a bool");
        }
      }), undefined, (function (v) {
        if (v) {
          return "true";
        } else {
          return "false";
        }
      }), undefined);

var Filter$3 = {
  struct: struct$3
};

var BoolArg = {
  Filter: Filter$3
};

function qp(key, struct, set, get) {
  return {
          key: key,
          parse: (function (all, value) {
              return Belt_Result.mapWithDefault(S$RescriptStruct.parseWith(value, struct), all, (function (parsed) {
                            return Curry._2(set, all, Caml_option.some(parsed));
                          }));
            }),
          serialize: (function (all) {
              return Belt_Option.flatMap(Curry._1(get, all), (function (a) {
                            return Belt_Result.mapWithDefault(S$RescriptStruct.serializeWith(a, struct), undefined, (function (v) {
                                          return [
                                                  key,
                                                  v
                                                ];
                                        }));
                          }));
            })
        };
}

function cabinFilterQp(key, struct, set, get) {
  var set$1 = function (all, qpOption) {
    var all$1;
    if (qpOption !== undefined && key !== "cq.page") {
      var init = all.cabinFilters;
      all$1 = {
        cabinFilters: {
          hasCabinFiltrationEnabled: true,
          occRange: init.occRange,
          totBerths: init.totBerths,
          segment: init.segment,
          class: init.class,
          subclass: init.subclass,
          category: init.category,
          cabin: init.cabin,
          publicNote: init.publicNote,
          privateNote: init.privateNote,
          bookingExist: init.bookingExist
        },
        page: all.page,
        bogus: all.bogus
      };
    } else {
      all$1 = all;
    }
    return Curry._2(set, all$1, qpOption);
  };
  return qp(key, struct, set$1, get);
}

var empty = {
  cabinFilters: {
    hasCabinFiltrationEnabled: false,
    occRange: undefined,
    totBerths: undefined,
    segment: undefined,
    class: undefined,
    subclass: undefined,
    category: undefined,
    cabin: undefined,
    publicNote: undefined,
    privateNote: undefined,
    bookingExist: undefined
  },
  page: undefined,
  bogus: undefined
};

var queryArgs = Belt_MapString.fromArray(Belt_Array.map([
          cabinFilterQp("cq.occRange", struct, (function (all, v) {
                  var init = all.cabinFilters;
                  return {
                          cabinFilters: {
                            hasCabinFiltrationEnabled: init.hasCabinFiltrationEnabled,
                            occRange: v,
                            totBerths: init.totBerths,
                            segment: init.segment,
                            class: init.class,
                            subclass: init.subclass,
                            category: init.category,
                            cabin: init.cabin,
                            publicNote: init.publicNote,
                            privateNote: init.privateNote,
                            bookingExist: init.bookingExist
                          },
                          page: all.page,
                          bogus: all.bogus
                        };
                }), (function (all) {
                  return all.cabinFilters.occRange;
                })),
          cabinFilterQp("cq.totBerths", struct$2, (function (all, v) {
                  var init = all.cabinFilters;
                  return {
                          cabinFilters: {
                            hasCabinFiltrationEnabled: init.hasCabinFiltrationEnabled,
                            occRange: init.occRange,
                            totBerths: v,
                            segment: init.segment,
                            class: init.class,
                            subclass: init.subclass,
                            category: init.category,
                            cabin: init.cabin,
                            publicNote: init.publicNote,
                            privateNote: init.privateNote,
                            bookingExist: init.bookingExist
                          },
                          page: all.page,
                          bogus: all.bogus
                        };
                }), (function (all) {
                  return all.cabinFilters.totBerths;
                })),
          cabinFilterQp("cq.segment", struct$1, (function (all, v) {
                  var init = all.cabinFilters;
                  return {
                          cabinFilters: {
                            hasCabinFiltrationEnabled: init.hasCabinFiltrationEnabled,
                            occRange: init.occRange,
                            totBerths: init.totBerths,
                            segment: v,
                            class: init.class,
                            subclass: init.subclass,
                            category: init.category,
                            cabin: init.cabin,
                            publicNote: init.publicNote,
                            privateNote: init.privateNote,
                            bookingExist: init.bookingExist
                          },
                          page: all.page,
                          bogus: all.bogus
                        };
                }), (function (all) {
                  return all.cabinFilters.segment;
                })),
          cabinFilterQp("cq.class", struct$1, (function (all, v) {
                  var init = all.cabinFilters;
                  return {
                          cabinFilters: {
                            hasCabinFiltrationEnabled: init.hasCabinFiltrationEnabled,
                            occRange: init.occRange,
                            totBerths: init.totBerths,
                            segment: init.segment,
                            class: v,
                            subclass: init.subclass,
                            category: init.category,
                            cabin: init.cabin,
                            publicNote: init.publicNote,
                            privateNote: init.privateNote,
                            bookingExist: init.bookingExist
                          },
                          page: all.page,
                          bogus: all.bogus
                        };
                }), (function (all) {
                  return all.cabinFilters.class;
                })),
          cabinFilterQp("cq.subclass", struct$1, (function (all, v) {
                  var init = all.cabinFilters;
                  return {
                          cabinFilters: {
                            hasCabinFiltrationEnabled: init.hasCabinFiltrationEnabled,
                            occRange: init.occRange,
                            totBerths: init.totBerths,
                            segment: init.segment,
                            class: init.class,
                            subclass: v,
                            category: init.category,
                            cabin: init.cabin,
                            publicNote: init.publicNote,
                            privateNote: init.privateNote,
                            bookingExist: init.bookingExist
                          },
                          page: all.page,
                          bogus: all.bogus
                        };
                }), (function (all) {
                  return all.cabinFilters.subclass;
                })),
          cabinFilterQp("cq.category", struct$1, (function (all, v) {
                  var init = all.cabinFilters;
                  return {
                          cabinFilters: {
                            hasCabinFiltrationEnabled: init.hasCabinFiltrationEnabled,
                            occRange: init.occRange,
                            totBerths: init.totBerths,
                            segment: init.segment,
                            class: init.class,
                            subclass: init.subclass,
                            category: v,
                            cabin: init.cabin,
                            publicNote: init.publicNote,
                            privateNote: init.privateNote,
                            bookingExist: init.bookingExist
                          },
                          page: all.page,
                          bogus: all.bogus
                        };
                }), (function (all) {
                  return all.cabinFilters.category;
                })),
          cabinFilterQp("cq.cabin", struct$1, (function (all, v) {
                  var init = all.cabinFilters;
                  return {
                          cabinFilters: {
                            hasCabinFiltrationEnabled: init.hasCabinFiltrationEnabled,
                            occRange: init.occRange,
                            totBerths: init.totBerths,
                            segment: init.segment,
                            class: init.class,
                            subclass: init.subclass,
                            category: init.category,
                            cabin: v,
                            publicNote: init.publicNote,
                            privateNote: init.privateNote,
                            bookingExist: init.bookingExist
                          },
                          page: all.page,
                          bogus: all.bogus
                        };
                }), (function (all) {
                  return all.cabinFilters.cabin;
                })),
          cabinFilterQp("cq.publicNote", struct$1, (function (all, v) {
                  var init = all.cabinFilters;
                  return {
                          cabinFilters: {
                            hasCabinFiltrationEnabled: init.hasCabinFiltrationEnabled,
                            occRange: init.occRange,
                            totBerths: init.totBerths,
                            segment: init.segment,
                            class: init.class,
                            subclass: init.subclass,
                            category: init.category,
                            cabin: init.cabin,
                            publicNote: v,
                            privateNote: init.privateNote,
                            bookingExist: init.bookingExist
                          },
                          page: all.page,
                          bogus: all.bogus
                        };
                }), (function (all) {
                  return all.cabinFilters.publicNote;
                })),
          cabinFilterQp("cq.privateNote", struct$1, (function (all, v) {
                  var init = all.cabinFilters;
                  return {
                          cabinFilters: {
                            hasCabinFiltrationEnabled: init.hasCabinFiltrationEnabled,
                            occRange: init.occRange,
                            totBerths: init.totBerths,
                            segment: init.segment,
                            class: init.class,
                            subclass: init.subclass,
                            category: init.category,
                            cabin: init.cabin,
                            publicNote: init.publicNote,
                            privateNote: v,
                            bookingExist: init.bookingExist
                          },
                          page: all.page,
                          bogus: all.bogus
                        };
                }), (function (all) {
                  return all.cabinFilters.privateNote;
                })),
          cabinFilterQp("cq.booking", struct$3, (function (all, v) {
                  var init = all.cabinFilters;
                  return {
                          cabinFilters: {
                            hasCabinFiltrationEnabled: init.hasCabinFiltrationEnabled,
                            occRange: init.occRange,
                            totBerths: init.totBerths,
                            segment: init.segment,
                            class: init.class,
                            subclass: init.subclass,
                            category: init.category,
                            cabin: init.cabin,
                            publicNote: init.publicNote,
                            privateNote: init.privateNote,
                            bookingExist: v
                          },
                          page: all.page,
                          bogus: all.bogus
                        };
                }), (function (all) {
                  return all.cabinFilters.bookingExist;
                })),
          cabinFilterQp("cq.page", struct$2, (function (all, v) {
                  return {
                          cabinFilters: all.cabinFilters,
                          page: v,
                          bogus: all.bogus
                        };
                }), (function (all) {
                  return all.page;
                }))
        ], (function (v) {
            return [
                    v.key,
                    v
                  ];
          })));

function parse(searchStr) {
  return Belt_Array.reduce(Array.from(new URLSearchParams(searchStr).entries()), empty, (function (carry, param) {
                var match = Belt_MapString.get(queryArgs, param[0]);
                if (match !== undefined) {
                  return Curry._2(match.parse, carry, param[1]);
                } else {
                  return carry;
                }
              }));
}

function serialize(all) {
  var searchParams = new URLSearchParams({});
  Belt_Array.forEach(Belt_Array.keepMap(Belt_MapString.toArray(queryArgs), (function (param) {
              return Curry._1(param[1].serialize, all);
            })), (function (param) {
          searchParams.append(param[0], param[1]);
        }));
  searchParams.sort();
  return searchParams.toString();
}

function fromSearchParams(s) {
  return Belt_Array.reduce(Array.from(new URLSearchParams(s).entries()), [], (function (carry, param) {
                var key = param[0];
                var match = Belt_MapString.get(queryArgs, key);
                if (match === undefined) {
                  return carry;
                }
                if (key === "cq.page") {
                  return carry;
                }
                var match$1 = Belt_List.fromArray(param[1].split("."));
                if (!match$1) {
                  return carry;
                }
                var noun = match$1.tl;
                var verb = match$1.hd;
                if (verb === "contains") {
                  var matches = Belt_Array.joinWith(Belt_List.toArray(noun), ".", (function (prim) {
                            return prim;
                          })).match(/^(\d*)-(\d*)$/);
                  if (matches == null) {
                    return S$RescriptStruct.fail(undefined, "not a range");
                  }
                  var match$2 = matches.slice(1);
                  if (match$2.length !== 2) {
                    return S$RescriptStruct.fail(undefined, "not a range (2)");
                  }
                  var lnum = match$2[0];
                  if (lnum === "") {
                    var rnum = match$2[1];
                    return Belt_Array.concat(carry, [{
                                  column: key,
                                  verb: "contains_to",
                                  noun: "" + rnum + ""
                                }]);
                  }
                  var rnum$1 = match$2[1];
                  if (rnum$1 === "") {
                    return Belt_Array.concat(carry, [{
                                  column: key,
                                  verb: "contains_ab",
                                  noun: "" + lnum + ""
                                }]);
                  } else {
                    return Belt_Array.concat(carry, [{
                                  column: key,
                                  verb: "contains_ir",
                                  noun: "" + lnum + "-" + rnum$1 + ""
                                }]);
                  }
                }
                var tmp;
                switch (verb) {
                  case "in" :
                      var jsonArray = JSON.parse(Belt_Array.joinWith(Belt_List.toArray(noun), ".", (function (prim) {
                                  return prim;
                                })));
                      var arr = Js_json.decodeArray(jsonArray);
                      tmp = arr !== undefined ? Belt_Array.reduce(arr, "", (function (carry, v) {
                                if (carry === "") {
                                  return v;
                                } else {
                                  return carry + ", " + v;
                                }
                              })) : Belt_Array.joinWith(Belt_List.toArray(noun), ".", (function (prim) {
                                return prim;
                              }));
                      break;
                  case "false" :
                  case "true" :
                      tmp = "";
                      break;
                  default:
                    tmp = Belt_Array.joinWith(Belt_List.toArray(noun), ".", (function (prim) {
                            return prim;
                          }));
                }
                return Belt_Array.concat(carry, [{
                              column: key,
                              verb: verb,
                              noun: tmp
                            }]);
              }));
}

function toSearchParams(conditions) {
  var searchParams = new URLSearchParams({});
  Belt_Array.forEach(conditions, (function (condition) {
          var match = condition.column;
          var match$1 = condition.verb;
          var match$2 = condition.noun;
          if (match == null) {
            return ;
          }
          if (match$1 == null) {
            return ;
          }
          if (match$2 == null) {
            if (match$1 === "true" || match$1 === "false") {
              searchParams.append(match, "" + match$1 + "");
              return ;
            } else {
              return ;
            }
          }
          if (match$1.startsWith("contains_")) {
            switch (match$1) {
              case "contains_ab" :
                  searchParams.append(match, "contains." + match$2 + "-");
                  return ;
              case "contains_ir" :
                  searchParams.append(match, "contains." + match$2 + "");
                  return ;
              case "contains_to" :
                  searchParams.append(match, "contains.-" + match$2 + "");
                  return ;
              default:
                return ;
            }
          } else {
            if (match$1 !== "in") {
              if (match$1 === "true" || match$1 === "false") {
                searchParams.append(match, "" + match$1 + "");
              } else {
                searchParams.append(match, "" + match$1 + "." + match$2 + "");
              }
              return ;
            }
            var arrStr = Belt_Array.joinWith(Belt_Array.map(match$2.split(","), (function (ele) {
                        return "\"" + ele.trim() + "\"";
                      })), ",", (function (prim) {
                    return prim;
                  }));
            searchParams.append(match, "" + match$1 + ".[" + arrStr + "]");
            return ;
          }
        }));
  return searchParams.toString();
}

function conditionEqual(cond1, cond2) {
  if ((cond1.column == null) === (cond2.column == null) && (cond1.verb == null) === (cond2.verb == null) && (cond1.noun == null) === (cond2.noun == null) && (
      (cond1.column == null) === false ? Caml_obj.equal(Caml_option.nullable_to_opt(cond1.column), Caml_option.nullable_to_opt(cond2.column)) : true
    ) && (
      (cond1.verb == null) === false ? Caml_obj.equal(Caml_option.nullable_to_opt(cond1.verb), Caml_option.nullable_to_opt(cond2.verb)) : true
    )) {
    if ((cond1.noun == null) === false) {
      return Caml_obj.equal(Caml_option.nullable_to_opt(cond1.noun), Caml_option.nullable_to_opt(cond2.noun));
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function arraysEqual(arr1, arr2) {
  var len1 = arr1.length;
  var len2 = arr2.length;
  if (len1 === len2) {
    return Js_array.everyi((function (cond1, i) {
                  return conditionEqual(cond1, Belt_Array.getExn(arr2, i));
                }), arr1);
  } else {
    return false;
  }
}

var Condition = {
  fromSearchParams: fromSearchParams,
  toSearchParams: toSearchParams,
  conditionEqual: conditionEqual,
  arraysEqual: arraysEqual
};

var $$String;

var Int;

var Dict;

var $$RegExp;

var $$JSON$1;

exports.$$String = $$String;
exports.Int = Int;
exports.Dict = Dict;
exports.$$RegExp = $$RegExp;
exports.$$JSON = $$JSON$1;
exports.Stringable = Stringable;
exports.mapOk = mapOk;
exports.IntRange = IntRange;
exports.StringArg = StringArg;
exports.IntArg = IntArg;
exports.BoolArg = BoolArg;
exports.qp = qp;
exports.cabinFilterQp = cabinFilterQp;
exports.empty = empty;
exports.queryArgs = queryArgs;
exports.parse = parse;
exports.serialize = serialize;
exports.Condition = Condition;
/* int Not a pure module */
