// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function internal_keepMapFieldsRaw(record, f) {
  return Belt_Option.map(record, (function (obj) {
                return Js_dict.fromArray(Belt_Array.keepMap(Js_dict.entries(obj), f));
              }));
}

function internal_cleanObjectFromUndefinedRaw(record) {
  var v = internal_keepMapFieldsRaw(record, (function (param) {
          var value = param[1];
          if (value !== undefined) {
            return [
                    param[0],
                    Caml_option.valFromOption(value)
                  ];
          }
          
        }));
  if (v !== undefined) {
    return Caml_option.valFromOption(v);
  } else {
    return {};
  }
}

function internal_removeUndefinedAndConvertNullsRaw(record) {
  return internal_keepMapFieldsRaw(record, (function (param) {
                var value = param[1];
                var key = param[0];
                var match = Caml_obj.equal(value, Caml_option.some(undefined));
                if (value !== undefined) {
                  return [
                          key,
                          Caml_option.valFromOption(value)
                        ];
                } else if (match) {
                  return [
                          key,
                          null
                        ];
                } else {
                  return ;
                }
              }));
}

function internal_useConvertedValue(convert, v) {
  return React.useMemo((function () {
                return Curry._1(convert, v);
              }), [v]);
}

function internal_nullableToOptionalExnHandler(x) {
  if (x !== undefined) {
    return (function (maybeExn) {
              return Curry._1(x, (maybeExn == null) ? undefined : Caml_option.some(maybeExn));
            });
  }
  
}

exports.internal_useConvertedValue = internal_useConvertedValue;
exports.internal_cleanObjectFromUndefinedRaw = internal_cleanObjectFromUndefinedRaw;
exports.internal_removeUndefinedAndConvertNullsRaw = internal_removeUndefinedAndConvertNullsRaw;
exports.internal_nullableToOptionalExnHandler = internal_nullableToOptionalExnHandler;
/* react Not a pure module */
