// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../utils/Common.bs.js");
var Chip = require("@mui/joy/Chip").default;
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var CustomScalars$Thick = require("../../utils/CustomScalars.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var RequestBFGrantForm$Thick = require("./RequestBFGrantForm.bs.js");
var Diversity3 = require("@mui/icons-material/Diversity3").default;
var ArrowUpward = require("@mui/icons-material/ArrowUpward").default;
var KeyOutlined = require("@mui/icons-material/KeyOutlined").default;
var BrandFamilyAccessSectionQuery_graphql$Thick = require("../../__generated__/BrandFamilyAccessSectionQuery_graphql.bs.js");
var BrandFamilyAccessSection_query_graphql$Thick = require("../../__generated__/BrandFamilyAccessSection_query_graphql.bs.js");
var BrandFamilyAccessSection_profile_graphql$Thick = require("../../__generated__/BrandFamilyAccessSection_profile_graphql.bs.js");

var convertFragment = BrandFamilyAccessSection_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BrandFamilyAccessSection_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BrandFamilyAccessSection_query_graphql$Thick.node, convertFragment);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

var convertFragment$1 = BrandFamilyAccessSection_profile_graphql$Thick.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(BrandFamilyAccessSection_profile_graphql$Thick.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BrandFamilyAccessSection_profile_graphql$Thick.node, convertFragment$1);
}

var ProfileFragment_brandfamRole_decode = BrandFamilyAccessSection_profile_graphql$Thick.Utils.brandfamRole_decode;

var ProfileFragment_brandfamRole_fromString = BrandFamilyAccessSection_profile_graphql$Thick.Utils.brandfamRole_fromString;

var ProfileFragment = {
  brandfamRole_decode: ProfileFragment_brandfamRole_decode,
  brandfamRole_fromString: ProfileFragment_brandfamRole_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1
};

var convertVariables = BrandFamilyAccessSectionQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = BrandFamilyAccessSectionQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = BrandFamilyAccessSectionQuery_graphql$Thick.Internal.convertWrapRawResponse;

function use$2(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.useQuery(convertVariables, BrandFamilyAccessSectionQuery_graphql$Thick.node, convertResponse, param, param$1, param$2, param$3, param$4);
}

function useLoader(param) {
  return RescriptRelay_Query.useLoader(convertVariables, BrandFamilyAccessSectionQuery_graphql$Thick.node, (function (prim) {
                return prim;
              }), param);
}

function usePreloaded(param) {
  return RescriptRelay_Query.usePreloaded(BrandFamilyAccessSectionQuery_graphql$Thick.node, convertResponse, (function (prim) {
                return prim;
              }), param);
}

function $$fetch(param, param$1, param$2, param$3, param$4, param$5) {
  return RescriptRelay_Query.$$fetch(BrandFamilyAccessSectionQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4, param$5);
}

function fetchPromised(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.fetchPromised(BrandFamilyAccessSectionQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4);
}

function retain(param, param$1) {
  return RescriptRelay_Query.retain(BrandFamilyAccessSectionQuery_graphql$Thick.node, convertVariables, param, param$1);
}

var Query_brandfamPermission_decode = BrandFamilyAccessSectionQuery_graphql$Thick.Utils.brandfamPermission_decode;

var Query_brandfamPermission_fromString = BrandFamilyAccessSectionQuery_graphql$Thick.Utils.brandfamPermission_fromString;

var Query = {
  brandfamPermission_decode: Query_brandfamPermission_decode,
  brandfamPermission_fromString: Query_brandfamPermission_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use$2,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function BrandFamilyAccessSection$BFamily(props) {
  var email = props.email;
  var data = RescriptRelay_Fragment.useFragment(BrandFamilyAccessSection_profile_graphql$Thick.node, convertFragment$1, props.fragmentRefs);
  var match = React.useState(function () {
        return false;
      });
  var setModalIsOpen = match[1];
  var bFam = data.brandFamilySlug;
  if (bFam === undefined) {
    return null;
  }
  var bFam$1 = Caml_option.valFromOption(bFam);
  var query = use$2({
        brandFamily: bFam$1,
        email: email
      }, undefined, undefined, undefined, undefined);
  var perms = Belt_Set.fromArray(Belt_Array.keepMap(Belt_Option.getWithDefault(query.currentUserCanForBrand, []), (function (x) {
              if (x !== undefined) {
                return x;
              }
              
            })), Common$Thick.PermCmp);
  var roles = Belt_Array.keepMap(data.brandFamilyRoles, (function (role) {
          if (role !== undefined) {
            return role;
          }
          
        }));
  var voyages = Belt_Array.keepMap(data.brandFamilyVoyages, (function (voy) {
          if (voy !== undefined) {
            return Caml_option.some(Caml_option.valFromOption(voy));
          }
          
        }));
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              spacing: 1,
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "title-lg",
                      component: "h3",
                      textTransform: "uppercase",
                      fontWeight: "700",
                      children: Caml_option.some(CustomScalars$Thick.BrandFamilySlug.serialize(bFam$1))
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      spacing: 1,
                      children: [
                        JsxRuntime.jsx(Typography, {
                              level: "body-md",
                              children: "Roles: "
                            }),
                        roles.length !== 0 ? Belt_Array.map(roles, (function (role) {
                                  return JsxRuntime.jsx(Chip, {
                                              children: Caml_option.some(Common$Thick.BrandfamRole.toString(Common$Thick.BrandfamRole.toInput(role))),
                                              color: Common$Thick.BrandfamRole.toColor(Common$Thick.BrandfamRole.toInput(role)),
                                              size: "md"
                                            }, Common$Thick.BrandfamRole.toString(Common$Thick.BrandfamRole.toInput(role)));
                                })) : JsxRuntime.jsx(Typography, {
                                sx: {
                                  color: "neutral.400"
                                },
                                children: "None"
                              })
                      ]
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      spacing: 1,
                      children: [
                        JsxRuntime.jsx(Typography, {
                              level: "body-md",
                              children: "Voyages: "
                            }),
                        JsxRuntime.jsx(Stack, {
                              direction: "row",
                              spacing: 1,
                              children: Caml_option.some(voyages.length !== 0 ? Belt_Array.map(voyages, (function (voy) {
                                            return JsxRuntime.jsx(Chip, {
                                                        children: Caml_option.some(CustomScalars$Thick.VoyageSlug.serialize(voy)),
                                                        color: "primary",
                                                        endDecorator: Caml_option.some(JsxRuntime.jsx(ArrowUpward, {
                                                                  sx: {
                                                                    transform: "rotate(45deg)"
                                                                  }
                                                                })),
                                                        onClick: (function (param) {
                                                            RescriptReactRouter.push("/" + CustomScalars$Thick.BrandFamilySlug.serialize(bFam$1) + "/" + CustomScalars$Thick.VoyageSlug.serialize(voy) + "");
                                                          }),
                                                        size: "md"
                                                      }, CustomScalars$Thick.VoyageSlug.serialize(voy));
                                          })) : JsxRuntime.jsx(Typography, {
                                          sx: {
                                            color: "neutral.400"
                                          },
                                          children: "None"
                                        }))
                            })
                      ]
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      spacing: 1,
                      children: [
                        JsxRuntime.jsx(Button, {
                              onClick: (function (param) {
                                  RescriptReactRouter.push("/" + CustomScalars$Thick.BrandFamilySlug.serialize(bFam$1) + "/team");
                                }),
                              startDecorator: Caml_option.some(JsxRuntime.jsx(Diversity3, {})),
                              variant: "soft",
                              color: "neutral",
                              size: "sm",
                              children: "View team"
                            }),
                        Belt_Set.has(perms, "REQUEST_BRAND_FAMILY_GRANT") ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsx(Button, {
                                        fullWidth: false,
                                        onClick: (function (param) {
                                            Curry._1(setModalIsOpen, (function (param) {
                                                    return true;
                                                  }));
                                          }),
                                        startDecorator: Caml_option.some(JsxRuntime.jsx(KeyOutlined, {})),
                                        variant: "soft",
                                        color: "warning",
                                        size: "sm",
                                        children: "Request access"
                                      }),
                                  JsxRuntime.jsx(RequestBFGrantForm$Thick.make, {
                                        email: email,
                                        isOpen: match[0],
                                        onClose: (function (param) {
                                            Curry._1(setModalIsOpen, (function (param) {
                                                    return false;
                                                  }));
                                          }),
                                        brandFamily: bFam$1,
                                        fragmentRefs: query.fragmentRefs
                                      })
                                ]
                              }) : null
                      ]
                    })
              ]
            });
}

var BFamily = {
  make: BrandFamilyAccessSection$BFamily
};

function BrandFamilyAccessSection(props) {
  var currentUserEmail = props.currentUserEmail;
  var data = RescriptRelay_Fragment.useFragment(BrandFamilyAccessSection_query_graphql$Thick.node, convertFragment, props.fragmentRefs);
  var match = data.currentUserProfiles;
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              sx: {
                width: "100%",
                py: 2
              },
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "h4",
                      component: "h2",
                      sx: {
                        mb: 2
                      },
                      children: "Brand family access"
                    }),
                match !== undefined ? Belt_Array.map(match.nodes, (function (node) {
                          return JsxRuntime.jsx(BrandFamilyAccessSection$BFamily, {
                                      fragmentRefs: node.fragmentRefs,
                                      email: currentUserEmail
                                    }, node.__id);
                        })) : JsxRuntime.jsx("div", {
                        children: "No profiles found"
                      })
              ]
            });
}

var make = BrandFamilyAccessSection;

exports.QueryFragment = QueryFragment;
exports.ProfileFragment = ProfileFragment;
exports.Query = Query;
exports.BFamily = BFamily;
exports.make = make;
/* react Not a pure module */
