// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var InsecureJWTStorage$Util = require("util/src/InsecureJWTStorage.bs.js");
var ChangePasswordSubmit_ChangePasswordMutation_graphql$Thick = require("../../../__generated__/ChangePasswordSubmit_ChangePasswordMutation_graphql.bs.js");

var convertVariables = ChangePasswordSubmit_ChangePasswordMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = ChangePasswordSubmit_ChangePasswordMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = ChangePasswordSubmit_ChangePasswordMutation_graphql$Thick.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables, ChangePasswordSubmit_ChangePasswordMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables, ChangePasswordSubmit_ChangePasswordMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param);
}

var ChangePasswordMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(setModalType) {
  var match = use(undefined);
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setNewPass = match$1[1];
  var newPass = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setConfirmPass = match$2[1];
  var match$3 = React.useState(function () {
        
      });
  var setError = match$3[1];
  var onSubmit = function (e, pass) {
    e.preventDefault();
    Curry._1(setError, (function (param) {
            
          }));
    Curry._8(mutate, {
          input: {
            allegedCurrentPass: pass,
            newPass: newPass
          }
        }, undefined, undefined, (function (store, param) {
            store.invalidateStore();
          }), (function (resp, err) {
            if (err !== undefined) {
              return Curry._1(setError, (function (param) {
                            return "An error occurred! Please try again.";
                          }));
            }
            var match = resp.changePassword;
            var match$1 = match.boolean;
            if (match$1 !== undefined) {
              if (match$1) {
                Curry._1(setNewPass, (function (param) {
                        return "";
                      }));
                Curry._1(setConfirmPass, (function (param) {
                        return "";
                      }));
                Curry._1(setModalType, (function (param) {
                        return "";
                      }));
                InsecureJWTStorage$Util.$$delete(undefined);
                return RescriptReactRouter.push("/login?to=/account");
              } else {
                console.log("error");
                return Curry._1(setError, (function (param) {
                              return "An error occurred! Please try again.";
                            }));
              }
            }
            
          }), (function (param) {
            Curry._1(setError, (function (param) {
                    return "An error occurred! Please try again.";
                  }));
          }), undefined, undefined);
  };
  return {
          isMutating: match[1],
          newPass: newPass,
          setNewPass: setNewPass,
          confirmPass: match$2[0],
          setConfirmPass: setConfirmPass,
          error: match$3[0],
          onSubmit: onSubmit
        };
}

exports.ChangePasswordMutation = ChangePasswordMutation;
exports.useMutation = useMutation;
/* react Not a pure module */
