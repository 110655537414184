// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Avatar$Thick = require("../../common/atoms/Avatar.bs.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var List = require("@mui/joy/List").default;
var Sheet = require("@mui/joy/Sheet").default;
var Tooltip = require("@mui/joy/Tooltip").default;
var ListItem = require("@mui/joy/ListItem").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var CustomScalars$Thick = require("../../../utils/CustomScalars.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var ListItemButton = require("@mui/joy/ListItemButton").default;
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;

function VoyageMenu(props) {
  var userPerms = props.userPerms;
  var selectedVoyage = props.selectedVoyage;
  var brandFamily = props.brandFamily;
  var voyage = props.voyage;
  var expandFullNav = props.expandFullNav;
  var leftNavExpanded = props.leftNavExpanded;
  var menuKey = "voyagenav:" + CustomScalars$Thick.VoyageSlug.serialize(voyage) + ":isexpanded";
  var isSelectedVoyage = selectedVoyage !== undefined ? Caml_obj.equal(Caml_option.valFromOption(selectedVoyage), voyage) : false;
  var match = React.useState(function () {
        var match = Common$Thick.LS.get(menuKey);
        if (match !== undefined) {
          switch (match) {
            case "false" :
                return false;
            case "true" :
                return true;
            default:
              Common$Thick.LS.set(menuKey, "false");
              return false;
          }
        } else {
          Common$Thick.LS.set(menuKey, "false");
          return false;
        }
      });
  var setIsExpanded = match[1];
  var isExpanded = match[0];
  var handleExpand = function (param) {
    var val = isExpanded ? "false" : "true";
    Common$Thick.LS.set(menuKey, val);
    Curry._1(setIsExpanded, (function (param) {
            return !isExpanded;
          }));
  };
  var menuLinks = [
    [
      "Voyage Dashboard",
      "",
      "VIEW_VOYAGE_META"
    ],
    [
      "Bookings",
      "bookings",
      "VIEW_CUSTOMER_DETAILS"
    ],
    [
      "Cabin Inventory",
      "cabins",
      "VIEW_CABIN_INVENTORY"
    ],
    [
      "Deck Map",
      "deck-map",
      "VIEW_VOYAGE_META"
    ],
    [
      "Passengers",
      "passengers",
      "VIEW_CUSTOMER_DETAILS"
    ],
    [
      "Waitlist",
      "waitlist",
      "VIEW_CUSTOMER_DETAILS"
    ],
    [
      "Settings",
      "settings",
      "VIEW_VOYAGE_META"
    ]
  ];
  var shouldDisable = function (s) {
    switch (s) {
      case "Bookings" :
      case "Cabin Inventory" :
      case "Voyage Dashboard" :
          return false;
      default:
        return true;
    }
  };
  return JsxRuntime.jsxs(Sheet, {
              sx: {
                m: leftNavExpanded && isExpanded ? 1 : 0,
                borderRadius: "xs",
                backgroundColor: isSelectedVoyage ? "background.level1" : "background.body",
                transition: "background-color 0.2s ease, margin 0.2s ease",
                borderRight: isSelectedVoyage ? "4px solid blue" : "none"
              },
              children: [
                JsxRuntime.jsx(ListItem, {
                      children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                children: JsxRuntime.jsxs(ListItemButton, {
                                      children: [
                                        JsxRuntime.jsx(ListItemDecorator, {
                                              children: Caml_option.some(JsxRuntime.jsx(Avatar$Thick.make, {
                                                        children: "V",
                                                        color: "primary",
                                                        size: "md",
                                                        variant: "solid"
                                                      }))
                                            }),
                                        JsxRuntime.jsx(Typography, {
                                              level: "body-sm",
                                              noWrap: true,
                                              sx: {
                                                opacity: leftNavExpanded ? "1" : "0",
                                                transition: "opacity 0.2s",
                                                overflow: "hidden",
                                                pl: 1
                                              },
                                              children: Caml_option.some(CustomScalars$Thick.VoyageSlug.serialize(voyage))
                                            })
                                      ],
                                      onClick: (function (param) {
                                          if (leftNavExpanded) {
                                            return handleExpand(undefined);
                                          } else {
                                            if (isExpanded) {
                                              Curry._1(expandFullNav, (function (param) {
                                                      return true;
                                                    }));
                                            } else {
                                              Curry._1(expandFullNav, (function (param) {
                                                      return true;
                                                    }));
                                              handleExpand(undefined);
                                            }
                                            return ;
                                          }
                                        })
                                    }),
                                arrow: true,
                                describeChild: true,
                                placement: "right",
                                size: "sm",
                                slotProps: {
                                  root: {
                                    sx: {
                                      display: leftNavExpanded ? "none" : ""
                                    }
                                  }
                                },
                                title: "" + CustomScalars$Thick.VoyageSlug.serialize(voyage) + ""
                              })),
                      sx: {
                        paddingBottom: 0
                      }
                    }),
                JsxRuntime.jsx(List, {
                      children: Caml_option.some(Belt_Array.map(menuLinks, (function (param) {
                                  var path = param[1];
                                  var label = param[0];
                                  if (Belt_Set.has(userPerms, param[2])) {
                                    return JsxRuntime.jsx(ListItem, {
                                                children: Caml_option.some(JsxRuntime.jsx(ListItemButton, {
                                                          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                    level: "body-sm",
                                                                    noWrap: true,
                                                                    sx: {
                                                                      color: shouldDisable(label) ? "neutral.400" : "text.primary"
                                                                    },
                                                                    children: Caml_option.some(label)
                                                                  })),
                                                          disabled: shouldDisable(label),
                                                          onClick: (function (param) {
                                                              RescriptReactRouter.push("/" + CustomScalars$Thick.BrandFamilySlug.serialize(brandFamily) + "/" + CustomScalars$Thick.VoyageSlug.serialize(voyage) + "/" + path + "");
                                                            })
                                                        }))
                                              }, "" + CustomScalars$Thick.VoyageSlug.serialize(voyage) + "-" + (
                                                path === "" ? "dash" : path
                                              ) + "");
                                  } else {
                                    return null;
                                  }
                                }))),
                      size: "sm",
                      sx: {
                        overflowY: "hidden",
                        maxHeight: isExpanded && leftNavExpanded ? "500px" : "0px",
                        opacity: isExpanded && leftNavExpanded ? "1" : "0",
                        transition: "max-height 0.2s ease-in-out, opacity 0.3s ease-in-out"
                      }
                    })
              ]
            });
}

var make = VoyageMenu;

exports.make = make;
/* react Not a pure module */
