// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../utils/Common.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var CustomScalars$Thick = require("../../utils/CustomScalars.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var WorkflowApproveSplitButton$Thick = require("./molecules/WorkflowApproveSplitButton.bs.js");
var BFGrantWorkflowView_workflow_graphql$Thick = require("../../__generated__/BFGrantWorkflowView_workflow_graphql.bs.js");
var BFGrantWorkflowView_DoBrandFamilyWorkflowActionMutation_graphql$Thick = require("../../__generated__/BFGrantWorkflowView_DoBrandFamilyWorkflowActionMutation_graphql.bs.js");

var convertFragment = BFGrantWorkflowView_workflow_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BFGrantWorkflowView_workflow_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BFGrantWorkflowView_workflow_graphql$Thick.node, convertFragment);
}

var WorkflowFragment_brandfamRole_decode = BFGrantWorkflowView_workflow_graphql$Thick.Utils.brandfamRole_decode;

var WorkflowFragment_brandfamRole_fromString = BFGrantWorkflowView_workflow_graphql$Thick.Utils.brandfamRole_fromString;

var WorkflowFragment = {
  brandfamRole_decode: WorkflowFragment_brandfamRole_decode,
  brandfamRole_fromString: WorkflowFragment_brandfamRole_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

var convertVariables = BFGrantWorkflowView_DoBrandFamilyWorkflowActionMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = BFGrantWorkflowView_DoBrandFamilyWorkflowActionMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = BFGrantWorkflowView_DoBrandFamilyWorkflowActionMutation_graphql$Thick.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables, BFGrantWorkflowView_DoBrandFamilyWorkflowActionMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use$1(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables, BFGrantWorkflowView_DoBrandFamilyWorkflowActionMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param);
}

var DoBrandFamilyWorkflowActionMutation_actionT_decode = BFGrantWorkflowView_DoBrandFamilyWorkflowActionMutation_graphql$Thick.Utils.actionT_decode;

var DoBrandFamilyWorkflowActionMutation_actionT_fromString = BFGrantWorkflowView_DoBrandFamilyWorkflowActionMutation_graphql$Thick.Utils.actionT_fromString;

var DoBrandFamilyWorkflowActionMutation = {
  actionT_decode: DoBrandFamilyWorkflowActionMutation_actionT_decode,
  actionT_fromString: DoBrandFamilyWorkflowActionMutation_actionT_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use$1
};

function BFGrantWorkflowView(props) {
  var isActionable = props.isActionable;
  var allUserActions = props.currentUserCan;
  var refreshWorkflows = props.refreshWorkflows;
  var query = RescriptRelay_Fragment.useFragment(BFGrantWorkflowView_workflow_graphql$Thick.node, convertFragment, props.fragmentRefs);
  var workflowInstance = query.workflowInstance;
  var toVoyages = query.toVoyages;
  var asRoles = query.asRoles;
  var allPossibleActions = [
    "APPROVE",
    "EXECUTE",
    "EXECUTE_AS_GRANTEE"
  ];
  var canCancel = Belt_Array.some(allUserActions, (function (action) {
          return action === "CANCEL";
        }));
  var userActions = Belt_Set.fromArray(Belt_Array.keepMap(allUserActions, (function (action) {
              if (action !== "CANCEL") {
                return action;
              }
              
            })), Common$Thick.WorkflowActionCmp);
  var match = Belt_Array.partition(allPossibleActions, (function (action) {
          return Belt_Set.has(userActions, action);
        }));
  var userCan = match[0];
  var allActions = Belt_Array.concat(userCan, match[1]);
  var match$1 = React.useState(function () {
        return Belt_Option.getWithDefault(Belt_Array.get(userCan, 0), "APPROVE");
      });
  var setSelectedAction = match$1[1];
  var selectedAction = match$1[0];
  var match$2 = use$1(undefined);
  var mutate = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var setError = match$3[1];
  var error = match$3[0];
  React.useEffect(function () {
        Curry._1(setSelectedAction, (function (param) {
                return Belt_Option.getWithDefault(Belt_Array.get(userCan, 0), "APPROVE");
              }));
      });
  var doAction = function (actionT) {
    return Curry._8(mutate, {
                input: {
                  forInstance: workflowInstance,
                  actionT: actionT
                }
              }, undefined, undefined, undefined, (function (resp, err) {
                  if (err === undefined) {
                    var match = resp.doBrandFamilyWorkflowAction;
                    if (match !== undefined) {
                      var match$1 = match.boolean;
                      if (match$1 !== undefined && match$1) {
                        console.log("Successfully submitted action response");
                        Curry._1(refreshWorkflows, undefined);
                        return ;
                      }
                      
                    }
                    console.log("Failed to submit workflow action");
                    return Curry._1(setError, (function (param) {
                                  return "Failed to submit workflow action";
                                }));
                  }
                  console.log("Failed to execute action");
                  Curry._1(setError, (function (param) {
                          return "Failed to execute action";
                        }));
                }), (function (param) {
                  console.log("Error altering user");
                  Curry._1(setError, (function (param) {
                          return "Error submitting workflow action";
                        }));
                }), undefined, undefined);
  };
  var match$4 = toVoyages.length;
  var tmp;
  if (match$4 !== 0) {
    var len = toVoyages.length;
    var voyageStr = Belt_Array.reduceWithIndex(toVoyages, "", (function (str, val, i) {
            if (i === 0) {
              if (val !== undefined) {
                return str + CustomScalars$Thick.VoyageSlug.serialize(Caml_option.valFromOption(val));
              } else {
                return str;
              }
            }
            if (val === undefined) {
              return str;
            }
            var val$1 = Caml_option.valFromOption(val);
            if (i === (len - 1 | 0)) {
              return str + " and " + CustomScalars$Thick.VoyageSlug.serialize(val$1);
            } else {
              return str + ", " + CustomScalars$Thick.VoyageSlug.serialize(val$1);
            }
          }));
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            "to be added to voyage" + (
              len > 1 ? "s " : " "
            ) + "",
            JsxRuntime.jsx("span", {
                  children: voyageStr,
                  style: {
                    fontWeight: "bold"
                  }
                })
          ]
        });
  } else {
    tmp = "";
  }
  var match$5 = asRoles.length;
  var match$6 = toVoyages.length;
  var match$7 = asRoles.length;
  var tmp$1;
  if (match$7 !== 0) {
    var len$1 = asRoles.length;
    var roleStr = Belt_Array.reduceWithIndex(asRoles, "", (function (str, val, i) {
            if (i !== 0) {
              if (val !== undefined) {
                if (i === (len$1 - 1 | 0)) {
                  return str + " and " + Common$Thick.BrandfamRole.toString(Common$Thick.BrandfamRole.toInput(val));
                } else {
                  return str + ", " + Common$Thick.BrandfamRole.toString(Common$Thick.BrandfamRole.toInput(val));
                }
              } else {
                return str;
              }
            } else if (val !== undefined) {
              return str + Common$Thick.BrandfamRole.toString(Common$Thick.BrandfamRole.toInput(val));
            } else {
              return str;
            }
          }));
    tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            "the role" + (
              len$1 > 1 ? "s " : " "
            ) + "",
            JsxRuntime.jsx("span", {
                  children: roleStr,
                  style: {
                    fontWeight: "bold"
                  }
                })
          ]
        });
  } else {
    tmp$1 = "to be added to the brand family as a member";
  }
  return JsxRuntime.jsxs(Sheet, {
              variant: "outlined",
              sx: {
                p: 1,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                borderRadius: "sm"
              },
              children: [
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      spacing: 2,
                      children: Caml_option.some(JsxRuntime.jsxs(Typography, {
                                level: "body-sm",
                                children: [
                                  JsxRuntime.jsx("span", {
                                        children: query.inviteeEmail,
                                        style: {
                                          fontWeight: "bold"
                                        }
                                      }),
                                  " " + (
                                    isActionable ? "has" : ""
                                  ) + " requested ",
                                  tmp,
                                  match$5 > 0 && match$6 > 0 ? " and " : "",
                                  tmp$1,
                                  "."
                                ]
                              }))
                    }),
                isActionable ? JsxRuntime.jsxs(Stack, {
                        direction: "row",
                        justifyContent: "center",
                        spacing: 2,
                        children: [
                          JsxRuntime.jsx(Button, {
                                loading: match$2[1],
                                onClick: (function (param) {
                                    return doAction(Common$Thick.WorkflowAction.toInput("CANCEL"));
                                  }),
                                variant: "solid",
                                color: "danger",
                                size: "sm",
                                disabled: !canCancel,
                                children: "Cancel"
                              }),
                          JsxRuntime.jsx(WorkflowApproveSplitButton$Thick.make, {
                                allActions: allActions,
                                userActions: userActions,
                                selectedAction: selectedAction,
                                setSelectedAction: setSelectedAction,
                                handleClick: (function (param) {
                                    return doAction(Common$Thick.WorkflowAction.toInput(selectedAction));
                                  })
                              })
                        ]
                      }) : null,
                error !== "" ? JsxRuntime.jsx(Typography, {
                        color: "danger",
                        children: Caml_option.some(error)
                      }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
              ]
            });
}

var make = BFGrantWorkflowView;

exports.WorkflowFragment = WorkflowFragment;
exports.DoBrandFamilyWorkflowActionMutation = DoBrandFamilyWorkflowActionMutation;
exports.make = make;
/* react Not a pure module */
