// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Id = require("rescript/lib/js/belt_Id.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Js_string = require("rescript/lib/js/js_string.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Chip = require("@mui/joy/Chip").default;
var JsxRuntime = require("react/jsx-runtime");
var WarningAmber = require("@mui/icons-material/WarningAmber").default;
var CheckCircleOutline = require("@mui/icons-material/CheckCircleOutline").default;
var ScheduleSendOutlined = require("@mui/icons-material/ScheduleSendOutlined").default;

function useStateExtended(useStateArgs) {
  var match = React.useState(function () {
        return Curry._1(useStateArgs, undefined);
      });
  var set = match[1];
  var onChange = function (e) {
    Curry._1(set, e.currentTarget.value);
  };
  return [
          match[0],
          set,
          onChange
        ];
}

function formatCurrency($$float) {
  var options = {
    style: "currency",
    currency: "USD"
  };
  var formatter = Intl.NumberFormat("en-US", options);
  return formatter.format($$float);
}

var Clipboard = {};

var $$History = {};

var $$Window = {
  Clipboard: Clipboard,
  $$History: $$History
};

async function copyToClipboard(s, fn) {
  try {
    await window.navigator.clipboard.writeText(s);
    Curry._1(fn, (function (param) {
            return true;
          }));
    setTimeout((function (param) {
            Curry._1(fn, (function (param) {
                    return false;
                  }));
          }), 1500);
    return ;
  }
  catch (exn){
    console.log("Error copying to clipboard");
    return ;
  }
}

var regex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$");

function isValid(email) {
  var match = regex.exec(email);
  return match !== null;
}

var Email = {
  regex: regex,
  isValid: isValid
};

var Voyage = {};

function toString(role) {
  if (role === "INVENTORY_MANAGER") {
    return "Inventory Manager";
  } else if (role === "BRAND_FAMILY_ADMIN") {
    return "Brand Family Admin";
  } else {
    return "Customer Service";
  }
}

function toColor(role) {
  if (role === "INVENTORY_MANAGER") {
    return "success";
  } else if (role === "BRAND_FAMILY_ADMIN") {
    return "danger";
  } else {
    return "neutral";
  }
}

function fromInput(role) {
  return role;
}

function toInput(role) {
  return role;
}

var BrandfamRole = {
  toString: toString,
  toColor: toColor,
  fromInput: fromInput,
  toInput: toInput
};

function toString$1(status) {
  if (status === "INVITED") {
    return "Invited";
  } else if (status === "UNCLAIMED") {
    return "Unclaimed";
  } else if (status === "CLAIMED") {
    return "Claimed";
  } else {
    return "Unknown";
  }
}

function fromInput$1(status) {
  return status;
}

function toInput$1(status) {
  return status;
}

var AccountStatus = {
  toString: toString$1,
  fromInput: fromInput$1,
  toInput: toInput$1
};

function toString$2(action) {
  if (action === "APPROVE") {
    return "Approve";
  } else if (action === "CANCEL") {
    return "Cancel";
  } else if (action === "EXECUTE_AS_GRANTEE") {
    return "Execute as grantee";
  } else if (action === "INITIATE") {
    return "Initiate";
  } else {
    return "Execute";
  }
}

function fromInput$2(action) {
  return action;
}

function toInput$2(action) {
  return action;
}

var WorkflowAction = {
  toString: toString$2,
  fromInput: fromInput$2,
  toInput: toInput$2
};

function set(key, val) {
  localStorage.setItem(key, val);
}

function get(key) {
  return Caml_option.null_to_opt(localStorage.getItem(key));
}

function $$delete(key) {
  localStorage.removeItem(key);
}

function setSelectedBrand(brand) {
  set("grp-bf-selected", brand);
}

function getSelectedBrand(param) {
  return Caml_option.null_to_opt(localStorage.getItem("grp-bf-selected"));
}

function setSelectedVoyage(voyage) {
  set("grp-voyage-selected", voyage);
}

function getSelectedVoyage(param) {
  return Caml_option.null_to_opt(localStorage.getItem("grp-voyage-selected"));
}

var LS = {
  set: set,
  get: get,
  $$delete: $$delete,
  setSelectedBrand: setSelectedBrand,
  getSelectedBrand: getSelectedBrand,
  setSelectedVoyage: setSelectedVoyage,
  getSelectedVoyage: getSelectedVoyage
};

function toString$3(status) {
  if (status === "COMPLETED") {
    return "Completed";
  } else if (status === "ERROR") {
    return "Error";
  } else if (status === "CANCELLED") {
    return "Canceled";
  } else if (status === "PENDING") {
    return "Pending";
  } else {
    return "Unknown";
  }
}

function shouldRenderStatus(status) {
  return status !== "COMPLETED";
}

var PmtLifecycle = {
  toString: toString$3,
  shouldRenderStatus: shouldRenderStatus
};

function toString$4(status) {
  if (status === "ACTIVE") {
    return "Active";
  } else if (status === "DRAFT") {
    return "Draft";
  } else if (status === "HISTORY") {
    return "History";
  } else if (status === "CANCELED") {
    return "Canceled";
  } else if (status === "STAGED") {
    return "Staged";
  } else {
    return "Unknown";
  }
}

function getPrimaryColor(status) {
  if (status === "ACTIVE") {
    return "#037E3A";
  } else if (status === "DRAFT") {
    return "#30557A";
  } else if (status === "HISTORY") {
    return "#B77401";
  } else if (status === "CANCELED") {
    return "#FF4949";
  } else if (status === "STAGED") {
    return "primary.500";
  } else {
    return "#7030A0";
  }
}

function getSecondaryColor(status) {
  if (status === "ACTIVE") {
    return "success.400";
  } else if (status === "DRAFT") {
    return "neutral.400";
  } else if (status === "HISTORY") {
    return "warning.400";
  } else if (status === "CANCELED" || status !== "STAGED") {
    return "danger.400";
  } else {
    return "primary.400";
  }
}

function getBackgroundColor(status) {
  if (status === "ACTIVE") {
    return "#E7FDF1";
  } else if (status === "DRAFT") {
    return "#F0F3F5";
  } else if (status === "HISTORY") {
    return "#FFF9EB";
  } else if (status === "CANCELED") {
    return "#FBF2F2";
  } else if (status === "STAGED") {
    return "primary.100";
  } else {
    return "#DFC9EF";
  }
}

function makeChip(sizeOpt, status, versionNumber) {
  var size = sizeOpt !== undefined ? sizeOpt : "sm";
  return JsxRuntime.jsx(Chip, {
              children: Caml_option.some("• " + (
                    status === "HISTORY" ? "Version " + String(versionNumber) : toString$4(status)
                  ) + ""),
              size: size,
              sx: {
                color: getPrimaryColor(status),
                backgroundColor: getBackgroundColor(status),
                borderRadius: "xs"
              }
            });
}

function fromInput$3(status) {
  return status;
}

function toInput$3(status) {
  return status;
}

var Lifecycle = {
  toString: toString$4,
  getPrimaryColor: getPrimaryColor,
  getSecondaryColor: getSecondaryColor,
  getBackgroundColor: getBackgroundColor,
  makeChip: makeChip,
  fromInput: fromInput$3,
  toInput: toInput$3
};

function toString$5(status) {
  if (!status) {
    return "TBA";
  }
  var match = status._0;
  if (match === "INVITED") {
    return "Invited";
  } else if (match === "CONFIRMED") {
    return "Confirmed";
  } else if (match === "INCOMPLETE") {
    return "Incomplete";
  } else {
    return "Unknown";
  }
}

function getPrimaryColor$1(status) {
  switch (status) {
    case "Confirmed" :
        return "#037E3A";
    case "Incomplete" :
        return "#B77401";
    case "Invited" :
        return "#30557A";
    case "TBA" :
        return "#100D0C";
    default:
      return "#FF4949";
  }
}

function getBackgroundColor$1(status) {
  switch (status) {
    case "Confirmed" :
        return "#E7FDF1";
    case "Incomplete" :
        return "#FFF9EB";
    case "Invited" :
        return "#F0F3F5";
    case "TBA" :
        return "#F4F4F4";
    default:
      return "#FBF2F2";
  }
}

function makeChip$1(sizeOpt, status) {
  var size = sizeOpt !== undefined ? sizeOpt : "sm";
  var tmp;
  if (status) {
    var match = status._0;
    tmp = match === "INVITED" ? JsxRuntime.jsx(ScheduleSendOutlined, {}) : (
        match === "CONFIRMED" ? JsxRuntime.jsx(CheckCircleOutline, {}) : (
            match === "INCOMPLETE" ? JsxRuntime.jsx(WarningAmber, {}) : null
          )
      );
  } else {
    tmp = null;
  }
  return JsxRuntime.jsx(Chip, {
              children: Caml_option.some(toString$5(status)),
              size: size,
              startDecorator: Caml_option.some(tmp),
              sx: {
                color: getPrimaryColor$1(toString$5(status)),
                backgroundColor: getBackgroundColor$1(toString$5(status)),
                borderRadius: "xs"
              }
            });
}

function fromInput$4(status) {
  return status;
}

function toInput$4(status) {
  return status;
}

var PaxDataStatus = {
  toString: toString$5,
  getPrimaryColor: getPrimaryColor$1,
  getBackgroundColor: getBackgroundColor$1,
  makeChip: makeChip$1,
  fromInput: fromInput$4,
  toInput: toInput$4
};

var cmp = Caml_obj.compare;

var CabinCmp = Belt_Id.MakeComparable({
      cmp: cmp
    });

var cmp$1 = Caml_obj.compare;

var PermCmp = Belt_Id.MakeComparable({
      cmp: cmp$1
    });

var cmp$2 = Caml_obj.compare;

var WorkflowActionCmp = Belt_Id.MakeComparable({
      cmp: cmp$2
    });

var cmp$3 = Caml_obj.compare;

var VoyageSlugCmp = Belt_Id.MakeComparable({
      cmp: cmp$3
    });

var cmp$4 = Caml_obj.compare;

var StrCmp = Belt_Id.MakeComparable({
      cmp: cmp$4
    });

function format(s) {
  var str = String(s);
  var delimiter = String("_");
  return Belt_Array.joinWith(Belt_Array.map(Js_string.split(delimiter, str), (function (w) {
                    var capital = Js_string.substring(0, 1, w).toUpperCase();
                    var rest = Js_string.substringToEnd(1, w);
                    return Js_string.concat(rest, capital);
                  })), " ", (function (prim) {
                return prim;
              }));
}

var SegDisplay = {
  format: format
};

exports.useStateExtended = useStateExtended;
exports.formatCurrency = formatCurrency;
exports.$$Window = $$Window;
exports.copyToClipboard = copyToClipboard;
exports.Email = Email;
exports.Voyage = Voyage;
exports.BrandfamRole = BrandfamRole;
exports.AccountStatus = AccountStatus;
exports.WorkflowAction = WorkflowAction;
exports.LS = LS;
exports.PmtLifecycle = PmtLifecycle;
exports.Lifecycle = Lifecycle;
exports.PaxDataStatus = PaxDataStatus;
exports.CabinCmp = CabinCmp;
exports.PermCmp = PermCmp;
exports.WorkflowActionCmp = WorkflowActionCmp;
exports.VoyageSlugCmp = VoyageSlugCmp;
exports.StrCmp = StrCmp;
exports.SegDisplay = SegDisplay;
/* regex Not a pure module */
