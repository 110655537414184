// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../utils/Common.bs.js");
var Dialog$Thick = require("../common/molecules/Dialog.bs.js");
var Input = require("@mui/joy/Input").default;
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var CustomScalars$Thick = require("../../utils/CustomScalars.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var FormHelperText = require("@mui/joy/FormHelperText").default;
var CreateNewAccountSubmit$Thick = require("./mutations/CreateNewAccountSubmit.bs.js");

function CreateNewAccountForm(props) {
  var brandFamily = props.brandFamily;
  var onClose = props.onClose;
  var match = CreateNewAccountSubmit$Thick.useMutation(brandFamily, props.refresh, onClose);
  var setError = match.setError;
  var error = match.error;
  var setLastName = match.setLastName;
  var lastName = match.lastName;
  var setFirstName = match.setFirstName;
  var firstName = match.firstName;
  var setEmail = match.setEmail;
  var email = match.email;
  return JsxRuntime.jsxs(Dialog$Thick.make, {
              open_: props.open_,
              onClose: (function (param) {
                  Curry._1(onClose, undefined);
                  Curry._1(setError, (function (param) {
                          
                        }));
                }),
              title: "Create a New Account",
              children: [
                JsxRuntime.jsx(Typography, {
                      textColor: "text.tertiary",
                      children: Caml_option.some("Create a new " + CustomScalars$Thick.BrandFamilySlug.serialize(brandFamily) + " account with email.")
                    }),
                JsxRuntime.jsx(Typography, {
                      component: "h3",
                      children: "User Information"
                    }),
                JsxRuntime.jsx("form", {
                      children: JsxRuntime.jsxs(Stack, {
                            spacing: 1,
                            children: [
                              JsxRuntime.jsxs(FormControl, {
                                    children: [
                                      JsxRuntime.jsx(FormLabel, {
                                            children: "First Name"
                                          }),
                                      JsxRuntime.jsx(Input, {
                                            required: true,
                                            value: firstName,
                                            onChange: (function (e) {
                                                var val = e.currentTarget.value;
                                                Curry._1(setFirstName, (function (param) {
                                                        return val;
                                                      }));
                                              })
                                          }),
                                      JsxRuntime.jsx(FormHelperText, {
                                            children: Caml_option.some(firstName === "" ? "Please enter first name" : " ")
                                          })
                                    ]
                                  }),
                              JsxRuntime.jsxs(FormControl, {
                                    children: [
                                      JsxRuntime.jsx(FormLabel, {
                                            children: "Last Name"
                                          }),
                                      JsxRuntime.jsx(Input, {
                                            required: true,
                                            value: lastName,
                                            onChange: (function (e) {
                                                var val = e.currentTarget.value;
                                                Curry._1(setLastName, (function (param) {
                                                        return val;
                                                      }));
                                              })
                                          }),
                                      JsxRuntime.jsx(FormHelperText, {
                                            children: Caml_option.some(lastName === "" ? "Please enter last name" : " ")
                                          })
                                    ]
                                  }),
                              JsxRuntime.jsxs(FormControl, {
                                    error: email === "" || !Common$Thick.Email.isValid(email),
                                    children: [
                                      JsxRuntime.jsx(FormLabel, {
                                            children: "Email"
                                          }),
                                      JsxRuntime.jsx(Input, {
                                            required: true,
                                            type: "email",
                                            value: email,
                                            onChange: (function (e) {
                                                var val = e.currentTarget.value;
                                                Curry._1(setEmail, (function (param) {
                                                        return val;
                                                      }));
                                              })
                                          }),
                                      JsxRuntime.jsx(FormHelperText, {
                                            children: Caml_option.some(email === "" || !Common$Thick.Email.isValid(email) ? "Please enter a valid email address" : " ")
                                          })
                                    ]
                                  }),
                              JsxRuntime.jsx(Button, {
                                    loading: match.isMutating,
                                    type: "submit",
                                    disabled: email === "" || !Common$Thick.Email.isValid(email) || firstName === "" || lastName === "",
                                    children: "Create Account"
                                  })
                            ]
                          }),
                      disabled: email === "" || !Common$Thick.Email.isValid(email) || firstName === "" || lastName === "",
                      onSubmit: match.onSubmit
                    }),
                error !== undefined ? JsxRuntime.jsx(Typography, {
                        textColor: "text.error",
                        children: Caml_option.some(error)
                      }) : null
              ]
            });
}

var make = CreateNewAccountForm;

exports.make = make;
/* Common-Thick Not a pure module */
