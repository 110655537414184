// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var RequestBFGrantSubmit_RequestBrandFamilyGrantMutation_graphql$Thick = require("../../../__generated__/RequestBFGrantSubmit_RequestBrandFamilyGrantMutation_graphql.bs.js");

var convertVariables = RequestBFGrantSubmit_RequestBrandFamilyGrantMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = RequestBFGrantSubmit_RequestBrandFamilyGrantMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = RequestBFGrantSubmit_RequestBrandFamilyGrantMutation_graphql$Thick.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables, RequestBFGrantSubmit_RequestBrandFamilyGrantMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables, RequestBFGrantSubmit_RequestBrandFamilyGrantMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param);
}

var RequestBrandFamilyGrantMutation_brandfamRole_decode = RequestBFGrantSubmit_RequestBrandFamilyGrantMutation_graphql$Thick.Utils.brandfamRole_decode;

var RequestBrandFamilyGrantMutation_brandfamRole_fromString = RequestBFGrantSubmit_RequestBrandFamilyGrantMutation_graphql$Thick.Utils.brandfamRole_fromString;

var RequestBrandFamilyGrantMutation = {
  brandfamRole_decode: RequestBrandFamilyGrantMutation_brandfamRole_decode,
  brandfamRole_fromString: RequestBrandFamilyGrantMutation_brandfamRole_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(email, brandFamily, onClose) {
  var match = use(undefined);
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return [];
      });
  var selectedRoles = match$1[0];
  var match$2 = React.useState(function () {
        return [];
      });
  var selectedVoyages = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setError = match$3[1];
  var onSubmit = function (e) {
    e.preventDefault();
    Curry._1(setError, (function (param) {
            
          }));
    var toVoyages = Belt_Array.map(selectedVoyages, (function (voyage) {
            return Caml_option.some(voyage.voyageSlug);
          }));
    var toMatchRoles = Belt_Array.map(selectedRoles, (function (role) {
            return role.brandfamRole;
          }));
    var asRole = Belt_Option.getExn(Belt_Array.getExn(toMatchRoles, 0));
    var asAdditionalRoles = Belt_Array.sliceToEnd(toMatchRoles, 1);
    Curry._8(mutate, {
          input: {
            inviteeEmail: email,
            toBrandFamilySlug: brandFamily,
            toVoyages: toVoyages,
            asRole: asRole,
            asAdditionalRoles: asAdditionalRoles
          }
        }, undefined, undefined, (function (store, param) {
            store.invalidateStore();
          }), (function (resp, err) {
            if (err === undefined) {
              var match = resp.requestBrandFamilyGrant;
              if (match !== undefined && match.workflowInstance !== undefined) {
                console.log("Successfully submitted request");
                Curry._1(onClose, undefined);
                return ;
              }
              console.log("Request failed");
              return Curry._1(setError, (function (param) {
                            return "Request failed";
                          }));
            }
            console.log("Failed to submit request");
            Curry._1(setError, (function (param) {
                    return "Failed to submit request";
                  }));
          }), (function (err) {
            console.log(err);
            console.log("Error submitting request");
            Curry._1(setError, (function (param) {
                    return "Error submitting request";
                  }));
          }), undefined, undefined);
  };
  return {
          isMutating: match[1],
          selectedRoles: selectedRoles,
          setSelectedRoles: match$1[1],
          selectedVoyages: selectedVoyages,
          setSelectedVoyages: match$2[1],
          error: match$3[0],
          setError: setError,
          onSubmit: onSubmit
        };
}

function RequestBFGrantSubmit(props) {
  var size = props.size;
  var color = props.color;
  var variant = props.variant;
  var onError = props.onError;
  var onCompleted = props.onCompleted;
  var toMatchRoles = props.toMatchRoles;
  var toVoyages = props.toVoyages;
  var toBrandFamilySlug = props.toBrandFamilySlug;
  var inviteeEmail = props.inviteeEmail;
  var variant$1 = variant !== undefined ? variant : "solid";
  var color$1 = color !== undefined ? color : "primary";
  var size$1 = size !== undefined ? size : "md";
  var match = use(undefined);
  var mutate = match[0];
  var onClick = function (e) {
    e.preventDefault();
    var asRole = Belt_Option.getExn(Belt_Array.getExn(toMatchRoles, 0));
    var asAdditionalRoles = Belt_Array.sliceToEnd(toMatchRoles, 1);
    return Curry._8(mutate, {
                input: {
                  inviteeEmail: inviteeEmail,
                  toBrandFamilySlug: toBrandFamilySlug,
                  toVoyages: toVoyages,
                  asRole: asRole,
                  asAdditionalRoles: asAdditionalRoles
                }
              }, undefined, undefined, (function (store, param) {
                  store.invalidateStore();
                }), onCompleted, onError, undefined, undefined);
  };
  return JsxRuntime.jsx(Button, {
              loading: match[1],
              type: "submit",
              onClick: onClick,
              variant: variant$1,
              color: color$1,
              size: size$1,
              disabled: props.disabled,
              children: Caml_option.some(props.buttonText)
            });
}

var make = RequestBFGrantSubmit;

exports.RequestBrandFamilyGrantMutation = RequestBrandFamilyGrantMutation;
exports.useMutation = useMutation;
exports.make = make;
/* react Not a pure module */
