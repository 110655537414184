// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var JsxRuntime = require("react/jsx-runtime");

function Spinner(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {}),
                JsxRuntime.jsx("div", {}),
                JsxRuntime.jsx("div", {}),
                JsxRuntime.jsx("div", {})
              ],
              className: "lds-ring"
            });
}

var make = Spinner;

exports.make = make;
/* react/jsx-runtime Not a pure module */
