// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var Modal = require("@mui/joy/Modal").default;
var Stack = require("@mui/joy/Stack").default;
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var ModalClose = require("@mui/joy/ModalClose").default;
var DialogTitle = require("@mui/joy/DialogTitle").default;
var ModalDialog = require("@mui/joy/ModalDialog").default;
var DialogContent = require("@mui/joy/DialogContent").default;

function Dialog(props) {
  return JsxRuntime.jsx(Modal, {
              open: props.open_,
              onClose: props.onClose,
              slotProps: {
                backdrop: {
                  sx: {
                    backdropFilter: "none",
                    backgroundColor: "rgba(16, 13, 12, 0.7)"
                  }
                }
              },
              children: Caml_option.some(JsxRuntime.jsxs(ModalDialog, {
                        variant: "outlined",
                        layout: "center",
                        sx: {
                          minWidth: 300,
                          borderRadius: "4px",
                          boxShadow: "lg"
                        },
                        children: [
                          JsxRuntime.jsx(DialogTitle, {
                                children: Caml_option.some(props.title)
                              }),
                          JsxRuntime.jsx(ModalClose, {
                                sx: {
                                  top: "0.8rem",
                                  right: "0.8rem"
                                }
                              }),
                          JsxRuntime.jsx(Divider, {}),
                          JsxRuntime.jsx(DialogContent, {
                                children: Caml_option.some(JsxRuntime.jsx(Box, {
                                          children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                                    spacing: 2,
                                                    children: Caml_option.some(props.children)
                                                  })),
                                          sx: {
                                            width: "100%"
                                          }
                                        }))
                              })
                        ]
                      }))
            });
}

var make = Dialog;

exports.make = make;
/* @mui/joy/Box Not a pure module */
