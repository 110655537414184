// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var CustomScalars$Thick = require("../../../utils/CustomScalars.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var CreateNewAccountSubmit_CreateBfcustUserMutation_graphql$Thick = require("../../../__generated__/CreateNewAccountSubmit_CreateBfcustUserMutation_graphql.bs.js");

var convertVariables = CreateNewAccountSubmit_CreateBfcustUserMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = CreateNewAccountSubmit_CreateBfcustUserMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = CreateNewAccountSubmit_CreateBfcustUserMutation_graphql$Thick.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables, CreateNewAccountSubmit_CreateBfcustUserMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables, CreateNewAccountSubmit_CreateBfcustUserMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param);
}

var CreateBfcustUserMutation_createBfcustUserErr_decode = CreateNewAccountSubmit_CreateBfcustUserMutation_graphql$Thick.Utils.createBfcustUserErr_decode;

var CreateBfcustUserMutation_createBfcustUserErr_fromString = CreateNewAccountSubmit_CreateBfcustUserMutation_graphql$Thick.Utils.createBfcustUserErr_fromString;

var CreateBfcustUserMutation = {
  createBfcustUserErr_decode: CreateBfcustUserMutation_createBfcustUserErr_decode,
  createBfcustUserErr_fromString: CreateBfcustUserMutation_createBfcustUserErr_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(toBrandFamilySlug, refresh, onClose) {
  var match = use(undefined);
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var match$2 = React.useState(function () {
        return "";
      });
  var email = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var firstName = match$3[0];
  var match$4 = React.useState(function () {
        return "";
      });
  var lastName = match$4[0];
  var onSubmit = function (e) {
    e.preventDefault();
    Curry._1(setError, (function (param) {
            
          }));
    Curry._8(mutate, {
          input: {
            toBrandFamilySlug: toBrandFamilySlug,
            accountEmail: CustomScalars$Thick.Email.parse(email),
            accountFirstName: CustomScalars$Thick.NonemptyString.parse(firstName),
            accountLastName: CustomScalars$Thick.NonemptyString.parse(lastName)
          }
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err === undefined) {
              var match = resp.createBfcustUser;
              if (match === undefined) {
                return Curry._1(setError, (function (param) {
                              return "Something went wrong, please try again.";
                            }));
              }
              var createBfcustUserErr = match.createBfcustUserErr;
              if (createBfcustUserErr !== undefined) {
                var err$1 = createBfcustUserErr === "EMAIL_ALREADY_IN_USE" ? "Email already in use" : (
                    createBfcustUserErr === "USER_CANT_BE_CREATED_ASK_SUPPORT" ? "User can't be created, please contact support" : "Something went wrong, please try again."
                  );
                Curry._1(setError, (function (param) {
                        return err$1;
                      }));
                return ;
              }
              console.log("Success");
              Curry._1(refresh, undefined);
              Curry._1(onClose, undefined);
              return ;
            }
            Curry._1(setError, (function (param) {
                    return "Something went wrong, please try again.";
                  }));
          }), (function (err) {
            Curry._1(setError, (function (param) {
                    return "Something went wrong, please try again.";
                  }));
          }), undefined, undefined);
  };
  return {
          isMutating: match[1],
          email: email,
          setEmail: match$2[1],
          firstName: firstName,
          setFirstName: match$3[1],
          lastName: lastName,
          setLastName: match$4[1],
          error: match$1[0],
          setError: setError,
          onSubmit: onSubmit
        };
}

function CreateNewAccountSubmit(props) {
  var size = props.size;
  var color = props.color;
  var variant = props.variant;
  var onError = props.onError;
  var onCompleted = props.onCompleted;
  var accountLastName = props.accountLastName;
  var accountFirstName = props.accountFirstName;
  var accountEmail = props.accountEmail;
  var toBrandFamilySlug = props.toBrandFamilySlug;
  var variant$1 = variant !== undefined ? variant : "solid";
  var color$1 = color !== undefined ? color : "primary";
  var size$1 = size !== undefined ? size : "md";
  var match = use(undefined);
  var mutate = match[0];
  var onClick = function (e) {
    e.preventDefault();
    return Curry._8(mutate, {
                input: {
                  toBrandFamilySlug: toBrandFamilySlug,
                  accountEmail: accountEmail,
                  accountFirstName: accountFirstName,
                  accountLastName: accountLastName
                }
              }, undefined, undefined, undefined, onCompleted, onError, undefined, undefined);
  };
  return JsxRuntime.jsx(Button, {
              loading: match[1],
              type: "submit",
              onClick: onClick,
              variant: variant$1,
              color: color$1,
              size: size$1,
              disabled: props.disabled,
              children: Caml_option.some(props.buttonText)
            });
}

var make = CreateNewAccountSubmit;

exports.CreateBfcustUserMutation = CreateBfcustUserMutation;
exports.useMutation = useMutation;
exports.make = make;
/* react Not a pure module */
