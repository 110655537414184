// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Modal = require("@mui/joy/Modal").default;
var Stack = require("@mui/joy/Stack").default;
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var RelayEnv$Thick = require("../../../RelayEnv.bs.js");
var Button = require("@mui/joy/Button").default;
var Divider = require("@mui/joy/Divider").default;
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Belt_HashMapString = require("rescript/lib/js/belt_HashMapString.js");
var PreviewModal$Thick = require("./PreviewModal.bs.js");
var ModalClose = require("@mui/joy/ModalClose").default;
var Typography = require("@mui/joy/Typography").default;
var CustomScalars$Thick = require("../../../utils/CustomScalars.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var DialogTitle = require("@mui/joy/DialogTitle").default;
var ModalDialog = require("@mui/joy/ModalDialog").default;
var PreviewChanges$Thick = require("./PreviewChanges.bs.js");
var Autocomplete = require("@mui/joy/Autocomplete").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var AttachReplaceExistingModalQuery_graphql$Thick = require("../../../__generated__/AttachReplaceExistingModalQuery_graphql.bs.js");

var convertVariables = AttachReplaceExistingModalQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = AttachReplaceExistingModalQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = AttachReplaceExistingModalQuery_graphql$Thick.Internal.convertWrapRawResponse;

function use(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.useQuery(convertVariables, AttachReplaceExistingModalQuery_graphql$Thick.node, convertResponse, param, param$1, param$2, param$3, param$4);
}

function useLoader(param) {
  return RescriptRelay_Query.useLoader(convertVariables, AttachReplaceExistingModalQuery_graphql$Thick.node, (function (prim) {
                return prim;
              }), param);
}

function usePreloaded(param) {
  return RescriptRelay_Query.usePreloaded(AttachReplaceExistingModalQuery_graphql$Thick.node, convertResponse, (function (prim) {
                return prim;
              }), param);
}

function $$fetch(param, param$1, param$2, param$3, param$4, param$5) {
  return RescriptRelay_Query.$$fetch(AttachReplaceExistingModalQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4, param$5);
}

function fetchPromised(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.fetchPromised(AttachReplaceExistingModalQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4);
}

function retain(param, param$1) {
  return RescriptRelay_Query.retain(AttachReplaceExistingModalQuery_graphql$Thick.node, convertVariables, param, param$1);
}

var Query_accountStatus_decode = AttachReplaceExistingModalQuery_graphql$Thick.Utils.accountStatus_decode;

var Query_accountStatus_fromString = AttachReplaceExistingModalQuery_graphql$Thick.Utils.accountStatus_fromString;

var Query_custSignonStatus_decode = AttachReplaceExistingModalQuery_graphql$Thick.Utils.custSignonStatus_decode;

var Query_custSignonStatus_fromString = AttachReplaceExistingModalQuery_graphql$Thick.Utils.custSignonStatus_fromString;

var Query_docGender_decode = AttachReplaceExistingModalQuery_graphql$Thick.Utils.docGender_decode;

var Query_docGender_fromString = AttachReplaceExistingModalQuery_graphql$Thick.Utils.docGender_fromString;

var Query_lifecycleStatus_decode = AttachReplaceExistingModalQuery_graphql$Thick.Utils.lifecycleStatus_decode;

var Query_lifecycleStatus_fromString = AttachReplaceExistingModalQuery_graphql$Thick.Utils.lifecycleStatus_fromString;

var Query_paxDataStatus_decode = AttachReplaceExistingModalQuery_graphql$Thick.Utils.paxDataStatus_decode;

var Query_paxDataStatus_fromString = AttachReplaceExistingModalQuery_graphql$Thick.Utils.paxDataStatus_fromString;

var Query_pmtDirection_decode = AttachReplaceExistingModalQuery_graphql$Thick.Utils.pmtDirection_decode;

var Query_pmtDirection_fromString = AttachReplaceExistingModalQuery_graphql$Thick.Utils.pmtDirection_fromString;

var Query_pmtLifecycleStatus_decode = AttachReplaceExistingModalQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var Query_pmtLifecycleStatus_fromString = AttachReplaceExistingModalQuery_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var Query_pmtProcessor_decode = AttachReplaceExistingModalQuery_graphql$Thick.Utils.pmtProcessor_decode;

var Query_pmtProcessor_fromString = AttachReplaceExistingModalQuery_graphql$Thick.Utils.pmtProcessor_fromString;

var Query_subtotalCategory_decode = AttachReplaceExistingModalQuery_graphql$Thick.Utils.subtotalCategory_decode;

var Query_subtotalCategory_fromString = AttachReplaceExistingModalQuery_graphql$Thick.Utils.subtotalCategory_fromString;

var Query = {
  accountStatus_decode: Query_accountStatus_decode,
  accountStatus_fromString: Query_accountStatus_fromString,
  custSignonStatus_decode: Query_custSignonStatus_decode,
  custSignonStatus_fromString: Query_custSignonStatus_fromString,
  docGender_decode: Query_docGender_decode,
  docGender_fromString: Query_docGender_fromString,
  lifecycleStatus_decode: Query_lifecycleStatus_decode,
  lifecycleStatus_fromString: Query_lifecycleStatus_fromString,
  paxDataStatus_decode: Query_paxDataStatus_decode,
  paxDataStatus_fromString: Query_paxDataStatus_fromString,
  pmtDirection_decode: Query_pmtDirection_decode,
  pmtDirection_fromString: Query_pmtDirection_fromString,
  pmtLifecycleStatus_decode: Query_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: Query_pmtLifecycleStatus_fromString,
  pmtProcessor_decode: Query_pmtProcessor_decode,
  pmtProcessor_fromString: Query_pmtProcessor_fromString,
  subtotalCategory_decode: Query_subtotalCategory_decode,
  subtotalCategory_fromString: Query_subtotalCategory_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function AttachReplaceExistingModal(props) {
  var voyage = props.voyage;
  var currentOccupant = props.name;
  var action = props.action;
  var brandFamily = props.brandFamily;
  var shouldAttachToIdx = props.shouldAttachToIdx;
  var onClose = props.onClose;
  var action$1 = action !== undefined ? action : "attachExisting";
  var match = React.useState(function () {
        return false;
      });
  var setLoading = match[1];
  var loading = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setAutocompleteOpen = match$1[1];
  var match$2 = React.useState(function () {
        return [];
      });
  var setSelectOptions = match$2[1];
  var selectOptions = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setError = match$3[1];
  var match$4 = React.useState(function () {
        return false;
      });
  var setPreviewModalOpen = match$4[1];
  var match$5 = React.useState(function () {
        
      });
  var setBillPayNew = match$5[1];
  var match$6 = React.useState(function () {
        return null;
      });
  var setExistingAcct = match$6[1];
  var existingAcct = match$6[0];
  var onError = function (s) {
    Curry._1(setError, (function (param) {
            return s;
          }));
  };
  var onSuccess = function (b) {
    Curry._1(setBillPayNew, (function (param) {
            return b;
          }));
    Curry._1(onClose, undefined);
    Curry._1(setPreviewModalOpen, (function (param) {
            return true;
          }));
  };
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var applyDiff = React.useMemo((function () {
          var accountId = !(existingAcct == null) ? Caml_option.some(CustomScalars$Thick.AccountId.parse(existingAcct)) : undefined;
          if (accountId === undefined) {
            return diffStruct;
          }
          var accountId$1 = Caml_option.valFromOption(accountId);
          if (action$1 === "replaceExisting") {
            return BookingDiff$Thick.Alter.replaceAPax(diffStruct, shouldAttachToIdx, accountId$1);
          } else {
            return BookingDiff$Thick.Alter.attachAPax(diffStruct, shouldAttachToIdx, accountId$1);
          }
        }), [
        existingAcct,
        action$1,
        url.search
      ]);
  var selectMap = React.useMemo((function () {
          return Belt_HashMapString.fromArray(Belt_Array.map(selectOptions, (function (val) {
                            return [
                                    val.accountId,
                                    val.fullName
                                  ];
                          })));
        }), [selectOptions]);
  var toName = React.useMemo((function () {
          if (existingAcct == null) {
            return "an existing user";
          }
          var name = Belt_HashMapString.get(selectMap, existingAcct);
          if (name !== undefined) {
            return "" + name + "";
          } else {
            return "an existing user";
          }
        }), [existingAcct]);
  var match$7 = React.useMemo((function () {
          if (action$1 === "replaceExisting") {
            return [
                    "Select existing user",
                    "Search for an existing user to replace " + Belt_Option.getWithDefault(currentOccupant, "the current occupant") + " on this berth.",
                    "Replace existing user",
                    "Replace existing user",
                    "You are proposing to replace " + Belt_Option.getWithDefault(currentOccupant, "the current occupant") + " with " + toName + " on this berth."
                  ];
          } else {
            return [
                    "Select existing user",
                    "Search for an existing user to attach to this booking.",
                    "Attach existing user",
                    "Attach existing user",
                    "You are proposing to attach " + toName + " to this berth."
                  ];
          }
        }), [
        action$1,
        toName,
        currentOccupant
      ]);
  var createFilter = function (s) {
    return {
            fullName: {
              includesInsensitive: s
            },
            passenger_record: {
              none: {
                and: [
                  {
                    voyageSlug: {
                      equalTo: CustomScalars$Thick.VoyageSlug.serialize(voyage)
                    }
                  },
                  {
                    booking_version: {
                      lifecycleStatus: {
                        equalTo: "ACTIVE"
                      }
                    }
                  }
                ]
              }
            }
          };
  };
  var search = function (val) {
    Curry._1(setLoading, (function (param) {
            return true;
          }));
    Curry._1(setAutocompleteOpen, (function (param) {
            return false;
          }));
    Curry._1(setError, (function (param) {
            
          }));
    $$fetch(RelayEnv$Thick.environment, {
          brandFamily: brandFamily,
          filter: createFilter(val)
        }, (function (res) {
            if (res.TAG === /* Ok */0) {
              var match = res._0.bfcustUsers;
              if (match !== undefined) {
                var attachExistingMap = Belt_Array.map(Belt_MapInt.valuesToArray(Belt_Option.getWithDefault(diffStruct.attachExistingPax, undefined)), (function (id) {
                        return CustomScalars$Thick.AccountId.serialize(id);
                      }));
                var replaceExistingMap = Belt_Array.map(Belt_MapInt.valuesToArray(Belt_Option.getWithDefault(diffStruct.replacePaxExisting, undefined)), (function (id) {
                        return CustomScalars$Thick.AccountId.serialize(id);
                      }));
                var acctIds = Belt_SetString.fromArray(Belt_Array.concat(attachExistingMap, replaceExistingMap));
                var results = Belt_Array.keep(Belt_Array.map(match.nodes, (function (node) {
                            var accountId = CustomScalars$Thick.AccountId.serialize(node.accountId);
                            var fullName = node.fullName;
                            return {
                                    accountId: accountId,
                                    fullName: fullName
                                  };
                          })), (function (node) {
                        return !Belt_SetString.has(acctIds, node.accountId);
                      }));
                Curry._1(setSelectOptions, (function (param) {
                        return results;
                      }));
                Curry._1(setAutocompleteOpen, (function (param) {
                        return true;
                      }));
                Curry._1(setLoading, (function (param) {
                        return false;
                      }));
                return ;
              }
              Curry._1(setError, (function (param) {
                      return "No users found.";
                    }));
              Curry._1(setLoading, (function (param) {
                      return false;
                    }));
              return ;
            }
            console.error(res._0);
            Curry._1(setError, (function (param) {
                    return "Something went wrong. Please try again.";
                  }));
            Curry._1(setLoading, (function (param) {
                    return false;
                  }));
          }), undefined, undefined, undefined);
  };
  var match$8 = PreviewChanges$Thick.useQuery(props.generatePreviewFromDiff, applyDiff, setLoading, onSuccess, onError);
  var onClick = match$8.onClick;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Modal, {
                      open: props.open_,
                      onClose: onClose,
                      slotProps: {
                        backdrop: {
                          sx: {
                            backdropFilter: "none",
                            backgroundColor: "rgba(16, 13, 12, 0.7)"
                          }
                        }
                      },
                      children: Caml_option.some(JsxRuntime.jsxs(ModalDialog, {
                                variant: "outlined",
                                layout: "center",
                                sx: {
                                  minWidth: "30rem",
                                  borderRadius: "4px",
                                  boxShadow: "lg"
                                },
                                children: [
                                  JsxRuntime.jsx(DialogTitle, {
                                        children: Caml_option.some(match$7[0])
                                      }),
                                  JsxRuntime.jsx(ModalClose, {
                                        sx: {
                                          top: "0.8rem",
                                          right: "0.8rem"
                                        }
                                      }),
                                  JsxRuntime.jsx(Divider, {}),
                                  JsxRuntime.jsxs(DialogContent, {
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                level: "title-md",
                                                sx: {
                                                  marginBottom: "1rem"
                                                },
                                                children: Caml_option.some(match$7[1])
                                              }),
                                          JsxRuntime.jsx(Stack, {
                                                direction: "column",
                                                spacing: 1,
                                                children: Caml_option.some(JsxRuntime.jsx(Autocomplete, {
                                                          options: selectOptions,
                                                          getOptionLabel: (function (option) {
                                                              return option.fullName;
                                                            }),
                                                          isOptionEqualToValue: (function (option, value) {
                                                              return option.accountId === value.accountId;
                                                            }),
                                                          loading: loading,
                                                          onChange: (function (param, value) {
                                                              Curry._1(setExistingAcct, (function (param) {
                                                                      return value.accountId;
                                                                    }));
                                                            }),
                                                          onClose: (function (param) {
                                                              Curry._1(setAutocompleteOpen, (function (param) {
                                                                      return false;
                                                                    }));
                                                            }),
                                                          onInputChange: (function (param, v, param$1) {
                                                              if (v === "") {
                                                                Curry._1(setSelectOptions, (function (param) {
                                                                        return [];
                                                                      }));
                                                                Curry._1(setAutocompleteOpen, (function (param) {
                                                                        return false;
                                                                      }));
                                                                Curry._1(setError, (function (param) {
                                                                        
                                                                      }));
                                                                Curry._1(setLoading, (function (param) {
                                                                        return false;
                                                                      }));
                                                              } else {
                                                                search(v);
                                                              }
                                                            }),
                                                          onOpen: (function (param) {
                                                              Curry._1(setAutocompleteOpen, (function (param) {
                                                                      return true;
                                                                    }));
                                                            }),
                                                          open: match$1[0],
                                                          placeholder: "Search for a user"
                                                        }))
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsx(Button, {
                                        loading: loading,
                                        onClick: (function (param) {
                                            Curry._1(onClick, undefined);
                                            Curry._1(setSelectOptions, (function (param) {
                                                    return [];
                                                  }));
                                          }),
                                        disabled: loading,
                                        children: Caml_option.some(match$7[2])
                                      })
                                ]
                              }))
                    }),
                JsxRuntime.jsx(PreviewModal$Thick.make, {
                      billPayCurrent: props.billPayCurrent,
                      billPayNew: match$5[0],
                      applyDiff: applyDiff,
                      title: match$7[3],
                      description: match$7[4],
                      onClose: (function (param) {
                          Curry._1(setBillPayNew, (function (param) {
                                  
                                }));
                          Curry._1(setError, (function (param) {
                                  
                                }));
                          Curry._1(setPreviewModalOpen, (function (param) {
                                  return false;
                                }));
                          Curry._1(setExistingAcct, (function (param) {
                                  return null;
                                }));
                        }),
                      open_: match$4[0],
                      versionUrl: props.versionUrl,
                      error: match$3[0]
                    })
              ]
            });
}

var make = AttachReplaceExistingModal;

exports.Query = Query;
exports.make = make;
/* react Not a pure module */
