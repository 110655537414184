// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Spinner$Thick = require("../components/common/atoms/Spinner.bs.js");
var DraftUtils$Thick = require("../components/booking-details/drafts/DraftUtils.bs.js");
var BillPayInfo$Thick = require("../components/booking-details/billing-payments/BillPayInfo.bs.js");
var BookingDiff$Thick = require("../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var DashboardLayout$Thick = require("../layouts/DashboardLayout.bs.js");
var OrgProtectedRoute$Thick = require("../components/wrappers/OrgProtectedRoute.bs.js");
var BookingDetailsPage$Thick = require("../pages/BookingDetailsPage.bs.js");
var BrandPermProtected$Thick = require("../components/wrappers/BrandPermProtected.bs.js");
var VoyageProtectedRoute$Thick = require("../components/wrappers/VoyageProtectedRoute.bs.js");
var BookingDetailsRouteQuery_graphql$Thick = require("../__generated__/BookingDetailsRouteQuery_graphql.bs.js");

var convertVariables = BookingDetailsRouteQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = BookingDetailsRouteQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = BookingDetailsRouteQuery_graphql$Thick.Internal.convertWrapRawResponse;

function use(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.useQuery(convertVariables, BookingDetailsRouteQuery_graphql$Thick.node, convertResponse, param, param$1, param$2, param$3, param$4);
}

function useLoader(param) {
  return RescriptRelay_Query.useLoader(convertVariables, BookingDetailsRouteQuery_graphql$Thick.node, (function (prim) {
                return prim;
              }), param);
}

function usePreloaded(param) {
  return RescriptRelay_Query.usePreloaded(BookingDetailsRouteQuery_graphql$Thick.node, convertResponse, (function (prim) {
                return prim;
              }), param);
}

function $$fetch(param, param$1, param$2, param$3, param$4, param$5) {
  return RescriptRelay_Query.$$fetch(BookingDetailsRouteQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4, param$5);
}

function fetchPromised(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.fetchPromised(BookingDetailsRouteQuery_graphql$Thick.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4);
}

function retain(param, param$1) {
  return RescriptRelay_Query.retain(BookingDetailsRouteQuery_graphql$Thick.node, convertVariables, param, param$1);
}

var Query_pmtLifecycleStatus_decode = BookingDetailsRouteQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var Query_pmtLifecycleStatus_fromString = BookingDetailsRouteQuery_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var Query = {
  pmtLifecycleStatus_decode: Query_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: Query_pmtLifecycleStatus_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function BookingDetailsRoute(props) {
  var brandFamily = props.brandFamily;
  var voyage = props.voyage;
  var bookingId = props.bookingId;
  var bookingVersionNumber = Belt_Int.fromString(props.bookingVersionNumber);
  if (bookingVersionNumber !== undefined) {
    var url = RescriptReactRouter.useUrl(undefined, undefined);
    var diffStructInitial = BookingDiff$Thick.parse(url.search);
    var diffStruct = React.useDeferredValue(diffStructInitial);
    var berths = diffStruct.dropBerths;
    var berths$1 = diffStruct.addBerths;
    var paxes = diffStruct.detachPax;
    var paxes$1 = diffStruct.attachExistingPax;
    var paxes$2 = diffStruct.replacePaxExisting;
    var bc = diffStruct.changeBillingContact;
    var bookingDiff_dropBerths = berths !== undefined ? Belt_Array.map(berths, (function (b) {
              return b;
            })) : undefined;
    var bookingDiff_addBerths = berths$1 !== undefined ? berths$1 : undefined;
    var bookingDiff_detachPax = paxes !== undefined ? Belt_Array.map(paxes, (function (p) {
              return p;
            })) : undefined;
    var bookingDiff_attachExistingPax = paxes$1 !== undefined ? Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(paxes$1)), (function (p) {
              return {
                      idx: p[0],
                      accountId: p[1]
                    };
            })) : undefined;
    var bookingDiff_replacePaxExisting = paxes$2 !== undefined ? Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(paxes$2)), (function (p) {
              return {
                      idx: p[0],
                      accountId: p[1]
                    };
            })) : undefined;
    var bookingDiff_changeBillingContact = bc !== undefined ? Caml_option.some(Caml_option.valFromOption(bc)) : undefined;
    var bookingDiff = {
      dropBerths: bookingDiff_dropBerths,
      addBerths: bookingDiff_addBerths,
      detachPax: bookingDiff_detachPax,
      attachExistingPax: bookingDiff_attachExistingPax,
      replacePaxExisting: bookingDiff_replacePaxExisting,
      changeBillingContact: bookingDiff_changeBillingContact
    };
    var data = use({
          bookingDiff: bookingDiff,
          bookingId: bookingId,
          bookingVersionNumber: bookingVersionNumber,
          brandFamily: brandFamily,
          voyage: voyage
        }, undefined, undefined, undefined, undefined);
    var fragmentRefs = data.fragmentRefs;
    var match = data.allVersions;
    var latestVersion;
    if (match !== undefined) {
      var match$1 = Belt_Array.get(match.nodes, 0);
      latestVersion = match$1 !== undefined ? match$1.bookingVersionNumber : undefined;
    } else {
      latestVersion = undefined;
    }
    var billPayRec;
    if (diffStruct.hasDiff) {
      var diff = data.diffChanges;
      billPayRec = diff !== undefined ? BillPayInfo$Thick.parse({
              TAG: /* Diff */0,
              _0: diff
            }) : undefined;
    } else {
      var match$2 = data.currentBilling;
      if (match$2 !== undefined) {
        var node = Belt_Array.get(match$2.nodes, 0);
        billPayRec = node !== undefined ? BillPayInfo$Thick.parse({
                TAG: /* Current */1,
                _0: node
              }) : undefined;
      } else {
        billPayRec = undefined;
      }
    }
    var generatePreviewFromDiff = function (param) {
      return DraftUtils$Thick.previewCallback(bookingId, bookingVersionNumber, voyage, param);
    };
    return JsxRuntime.jsx(DashboardLayout$Thick.make, {
                fragmentRefs: fragmentRefs,
                children: JsxRuntime.jsx(React.Suspense, {
                      children: Caml_option.some(JsxRuntime.jsx(OrgProtectedRoute$Thick.make, {
                                fragmentRefs: fragmentRefs,
                                children: JsxRuntime.jsx(BrandPermProtected$Thick.make, {
                                      fragmentRefs: fragmentRefs,
                                      neededPerm: "VIEW_BOOKINGS",
                                      children: JsxRuntime.jsx(VoyageProtectedRoute$Thick.make, {
                                            fragmentRefs: fragmentRefs,
                                            brandFamily: brandFamily,
                                            voyage: voyage,
                                            children: JsxRuntime.jsx(BookingDetailsPage$Thick.make, {
                                                  fragmentRefs: fragmentRefs,
                                                  bookingId: bookingId,
                                                  brandFamily: brandFamily,
                                                  voyage: voyage,
                                                  latestVersion: latestVersion,
                                                  billPayRec: billPayRec,
                                                  generatePreviewFromDiff: generatePreviewFromDiff
                                                })
                                          }),
                                      isRoute: true,
                                      redirect: "/" + CustomScalars$Thick.BrandFamilySlug.serialize(brandFamily) + ""
                                    })
                              })),
                      fallback: Caml_option.some(JsxRuntime.jsx(Spinner$Thick.make, {}))
                    }),
                brandFamily: CustomScalars$Thick.BrandFamilySlug.serialize(brandFamily),
                voyage: Caml_option.some(voyage),
                bookingDetail: true
              });
  }
  React.useEffect((function () {
          RescriptReactRouter.push("/404");
        }), []);
  return null;
}

var make = BookingDetailsRoute;

exports.Query = Query;
exports.make = make;
/* react Not a pure module */
