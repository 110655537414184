// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");

var Types = {};

var variablesConverter = {"createBfcustUserInput":{"toBrandFamilySlug":{"c":"CustomScalars.BrandFamilySlug"},"accountLastName":{"c":"CustomScalars.NonemptyString"},"accountFirstName":{"c":"CustomScalars.NonemptyString"},"accountEmail":{"c":"CustomScalars.Email"}},"__root":{"input":{"r":"createBfcustUserInput"}}};

var variablesConverterMap = {
  "CustomScalars.Email": CustomScalars$Thick.Email.serialize,
  "CustomScalars.NonemptyString": CustomScalars$Thick.NonemptyString.serialize,
  "CustomScalars.BrandFamilySlug": CustomScalars$Thick.BrandFamilySlug.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function createBfcustUserErr_decode($$enum) {
  if ($$enum === "USER_CANT_BE_CREATED_ASK_SUPPORT" || $$enum === "EMAIL_ALREADY_IN_USE") {
    return $$enum;
  }
  
}

var createBfcustUserErr_fromString = createBfcustUserErr_decode;

var Utils = {
  createBfcustUserErr_decode: createBfcustUserErr_decode,
  createBfcustUserErr_fromString: createBfcustUserErr_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateBfcustUserPayload",
    "kind": "LinkedField",
    "name": "createBfcustUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createBfcustUserErr",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateNewAccountSubmit_CreateBfcustUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateNewAccountSubmit_CreateBfcustUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b1f1774b9af984c8ab752e2fa7437556",
    "id": null,
    "metadata": {},
    "name": "CreateNewAccountSubmit_CreateBfcustUserMutation",
    "operationKind": "mutation",
    "text": "mutation CreateNewAccountSubmit_CreateBfcustUserMutation(\n  $input: CreateBfcustUserInput!\n) {\n  createBfcustUser(input: $input) {\n    createBfcustUserErr\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
