// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var InsecureJWTStorage$Util = require("util/src/InsecureJWTStorage.bs.js");
var LogIn_LoginMutation_graphql$Thick = require("../../../__generated__/LogIn_LoginMutation_graphql.bs.js");

var convertVariables = LogIn_LoginMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = LogIn_LoginMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = LogIn_LoginMutation_graphql$Thick.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables, LogIn_LoginMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables, LogIn_LoginMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param);
}

var LoginMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(refetch) {
  var match = use(undefined);
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var email = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var password = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setError = match$3[1];
  RescriptReactRouter.useUrl(undefined, undefined);
  var onSubmit = function (e) {
    e.preventDefault();
    Curry._1(setError, (function (param) {
            
          }));
    Curry._8(mutate, {
          input: {
            allegedEmail: email,
            allegedPass: password
          }
        }, undefined, undefined, (function (store, param) {
            store.invalidateStore();
          }), (function (v1, param) {
            var match = v1.login;
            if (match === undefined) {
              return Curry._1(setError, (function (param) {
                            return "An error occurred while logging in";
                          }));
            }
            var jwtToken = match.jwtToken;
            if (jwtToken !== undefined) {
              InsecureJWTStorage$Util.set(jwtToken);
              Curry._1(refetch, undefined);
              return ;
            } else {
              return Curry._1(setError, (function (param) {
                            return "An error occurred logging in";
                          }));
            }
          }), (function (param) {
            Curry._1(setError, (function (param) {
                    return "An error occurred while logging in";
                  }));
          }), undefined, undefined);
  };
  return {
          isMutating: match[1],
          email: email,
          setEmail: match$1[1],
          password: password,
          setPassword: match$2[1],
          error: match$3[0],
          onSubmit: onSubmit
        };
}

exports.LoginMutation = LoginMutation;
exports.useMutation = useMutation;
/* react Not a pure module */
