// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");

function makeRefetchVariables(brandFamily, email, param) {
  return {
          brandFamily: brandFamily,
          email: email
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {"__root":{"email":{"c":"CustomScalars.Email"},"brandFamily":{"c":"CustomScalars.BrandFamilySlug"}}};

var variablesConverterMap = {
  "CustomScalars.BrandFamilySlug": CustomScalars$Thick.BrandFamilySlug.serialize,
  "CustomScalars.Email": CustomScalars$Thick.Email.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"":{"f":""}}};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"":{"f":""}}};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function brandfamPermission_decode($$enum) {
  if ($$enum === "VIEW_MANIFEST" || $$enum === "VIEW_ALL_USERS" || $$enum === "ADD_USER" || $$enum === "VIEW_ALL_VOYAGES" || $$enum === "VIEW_BOOKINGS" || $$enum === "MANAGE_BOOKINGS" || $$enum === "VIEW_PRICING" || $$enum === "GRANT_REVOKE" || $$enum === "VIEW_CUSTOMER_DETAILS" || $$enum === "MANAGE_CABIN_INVENTORY" || $$enum === "VIEW_VOYAGE_META" || $$enum === "MANAGE_USER" || $$enum === "MANAGE_PRICING" || $$enum === "MANAGE_BF_CUSTOMERS" || $$enum === "REQUEST_BRAND_FAMILY_GRANT" || $$enum === "WORKFLOW_PARTICIPANT" || $$enum === "VIEW_CABIN_INVENTORY") {
    return $$enum;
  }
  
}

var brandfamPermission_fromString = brandfamPermission_decode;

var Utils = {
  brandfamPermission_decode: brandfamPermission_decode,
  brandfamPermission_fromString: brandfamPermission_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brandFamily"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "forBrandFamilySlug",
      "variableName": "brandFamily"
    }
  ],
  "kind": "ScalarField",
  "name": "currentUserCanForBrand",
  "storageKey": null
},
v2 = {
  "kind": "Variable",
  "name": "email",
  "variableName": "email"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "brandFamilySlug",
        "variableName": "brandFamily"
      },
      (v2/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "condition"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BrandFamilyAccessSectionQuery",
    "selections": [
      (v1/*: any*/),
      {
        "args": [
          {
            "kind": "Variable",
            "name": "brandFamily",
            "variableName": "brandFamily"
          },
          (v2/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "RequestBFGrantForm_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BrandFamilyAccessSectionQuery",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "UserRoleGrantsConnection",
        "kind": "LinkedField",
        "name": "userRoleGrants",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserRoleGrant",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brandfamRole",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasRole",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "UserVoyageGrantsConnection",
        "kind": "LinkedField",
        "name": "userVoyageGrants",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserVoyageGrant",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "voyageSlug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasVoyage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7956644bb158c421ac620bea8528ede1",
    "id": null,
    "metadata": {},
    "name": "BrandFamilyAccessSectionQuery",
    "operationKind": "query",
    "text": "query BrandFamilyAccessSectionQuery(\n  $brandFamily: BrandFamilySlug!\n  $email: Email!\n) {\n  currentUserCanForBrand(forBrandFamilySlug: $brandFamily)\n  ...RequestBFGrantForm_query_49qFnv\n}\n\nfragment RequestBFGrantForm_query_49qFnv on Query {\n  userRoleGrants(condition: {email: $email, brandFamilySlug: $brandFamily}) {\n    ...RoleGrantsView_rolegrant\n  }\n  userVoyageGrants(condition: {email: $email, brandFamilySlug: $brandFamily}) {\n    ...VoyageGrantsView_voyagegrant\n  }\n}\n\nfragment RoleGrantsView_rolegrant on UserRoleGrantsConnection {\n  nodes {\n    brandfamRole\n    hasRole\n  }\n}\n\nfragment VoyageGrantsView_voyagegrant on UserVoyageGrantsConnection {\n  nodes {\n    voyageSlug\n    hasVoyage\n  }\n}\n"
  }
};
})());

var include = RescriptRelay.MakeLoadQuery({
      query: node,
      convertVariables: convertVariables
    });

var load = include.load;

var queryRefToObservable = include.queryRefToObservable;

var queryRefToPromise = include.queryRefToPromise;

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
