// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ChangeCabinSegmentSubmit_ChangeCabinSegmentMutation_graphql$Thick = require("../../../__generated__/ChangeCabinSegmentSubmit_ChangeCabinSegmentMutation_graphql.bs.js");

var convertVariables = ChangeCabinSegmentSubmit_ChangeCabinSegmentMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = ChangeCabinSegmentSubmit_ChangeCabinSegmentMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = ChangeCabinSegmentSubmit_ChangeCabinSegmentMutation_graphql$Thick.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables, ChangeCabinSegmentSubmit_ChangeCabinSegmentMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables, ChangeCabinSegmentSubmit_ChangeCabinSegmentMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param);
}

var ChangeCabinSegmentMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(brandFamily, voyage, cabins, refetch, setSelectedCabins, onClose, setError) {
  var match = use(undefined);
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return null;
      });
  var newSegment = match$1[0];
  var onSubmit = function (e) {
    e.preventDefault();
    Curry._1(setError, (function (param) {
            
          }));
    var inputPayload = Belt_Option.map((newSegment == null) ? undefined : Caml_option.some(newSegment), (function (newSegment) {
            return {
                    onBrandFamily: brandFamily,
                    onVoyage: voyage,
                    forCabinNumbers: cabins,
                    newSegment: newSegment
                  };
          }));
    Belt_Option.map(inputPayload, (function (inputPayload) {
            return Curry._8(mutate, {
                        input: inputPayload
                      }, undefined, undefined, undefined, (function (resp, err) {
                          if (err === undefined) {
                            var match = resp.changeCabinSegment;
                            if (match !== undefined) {
                              var match$1 = match.boolean;
                              if (match$1 !== undefined && match$1) {
                                console.log("Successfully changed cabin segment");
                                Curry._1(refetch, undefined);
                                Curry._1(onClose, undefined);
                                Curry._1(setSelectedCabins, (function (param) {
                                        return Belt_Set.make(Common$Thick.CabinCmp);
                                      }));
                                Curry._1(setError, (function (param) {
                                        
                                      }));
                                return ;
                              }
                              
                            }
                            console.log("Failed to change cabin segment");
                            Curry._1(setError, (function (param) {
                                    return "Failed to change cabin segment";
                                  }));
                            return ;
                          }
                          if (err !== undefined) {
                            var error = Belt_Array.reduce(err, "", (function (carry, obj) {
                                    if (carry === "") {
                                      return obj.message;
                                    } else {
                                      return carry + ", " + obj.message;
                                    }
                                  }));
                            console.log(error);
                            Curry._1(setError, (function (param) {
                                    return "Error changing cabin segment";
                                  }));
                            return ;
                          }
                          console.log("Unknown error changing cabin segment");
                        }), (function (err) {
                          console.log(err);
                          Curry._1(setError, (function (param) {
                                  return "Error changing cabin capacity";
                                }));
                        }), undefined, undefined);
          }));
  };
  return {
          isMutating: match[1],
          newSegment: newSegment,
          setNewSegment: match$1[1],
          onSubmit: onSubmit
        };
}

exports.ChangeCabinSegmentMutation = ChangeCabinSegmentMutation;
exports.useMutation = useMutation;
/* react Not a pure module */
