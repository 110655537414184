// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var DateFns = require("date-fns");

function daysUntil(date) {
  var now = new Date();
  return DateFns.differenceInCalendarDays(date, now);
}

var $$Date$1;

exports.$$Date = $$Date$1;
exports.daysUntil = daysUntil;
/* date-fns Not a pure module */
