// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;

function BDDepositScheduleDetails(props) {
  return JsxRuntime.jsx(Stack, {
              children: Caml_option.some(JsxRuntime.jsx(Typography, {
                        level: "h4",
                        component: "h4",
                        children: "Deposit Schedule"
                      }))
            });
}

var make = BDDepositScheduleDetails;

exports.make = make;
/* @mui/joy/Stack Not a pure module */
