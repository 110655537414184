// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var OrgProtectedRoute_query_graphql$Thick = require("../../__generated__/OrgProtectedRoute_query_graphql.bs.js");

var convertFragment = OrgProtectedRoute_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(OrgProtectedRoute_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, OrgProtectedRoute_query_graphql$Thick.node, convertFragment);
}

var QueryFragment_signonStatus_decode = OrgProtectedRoute_query_graphql$Thick.Utils.signonStatus_decode;

var QueryFragment_signonStatus_fromString = OrgProtectedRoute_query_graphql$Thick.Utils.signonStatus_fromString;

var QueryFragment = {
  signonStatus_decode: QueryFragment_signonStatus_decode,
  signonStatus_fromString: QueryFragment_signonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function OrgProtectedRoute(props) {
  var query = RescriptRelay_Fragment.useFragment(OrgProtectedRoute_query_graphql$Thick.node, convertFragment, props.fragmentRefs);
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  React.useEffect((function () {
          var params = url.path;
          var redirect;
          var exit = 0;
          if (params && !(params.hd === "login" && !params.tl)) {
            exit = 1;
          } else {
            redirect = "login";
          }
          if (exit === 1) {
            redirect = encodeURI(Belt_List.reduce(params, "/login?to=", (function (a, b) {
                        return a + "/" + b.trim();
                      })));
          }
          var match = query.currentUserSignonStatus;
          if (match === "SIGNED_ON") {
            
          } else {
            RescriptReactRouter.push(redirect);
          }
        }), [query.currentUserSignonStatus]);
  var match = query.currentUserSignonStatus;
  if (match === "SIGNED_ON") {
    return props.children;
  } else {
    return null;
  }
}

var make = OrgProtectedRoute;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
