// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../common/molecules/Dialog.bs.js");
var Input = require("@mui/joy/Input").default;
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var CustomScalars$Thick = require("../../utils/CustomScalars.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var RoleGrantsView$Thick = require("../dataview/RoleGrantsView.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var VoyageGrantsView$Thick = require("../dataview/VoyageGrantsView.bs.js");
var FormHelperText = require("@mui/joy/FormHelperText").default;
var RequestBFGrantSubmit$Thick = require("./mutations/RequestBFGrantSubmit.bs.js");
var RequestBFGrantForm_query_graphql$Thick = require("../../__generated__/RequestBFGrantForm_query_graphql.bs.js");

var convertFragment = RequestBFGrantForm_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(RequestBFGrantForm_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, RequestBFGrantForm_query_graphql$Thick.node, convertFragment);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function RequestBFGrantForm(props) {
  var brandFamily = props.brandFamily;
  var onClose = props.onClose;
  var email = props.email;
  var data = RescriptRelay_Fragment.useFragment(RequestBFGrantForm_query_graphql$Thick.node, convertFragment, props.fragmentRefs);
  var urgFrag = Belt_Option.getExn(data.userRoleGrants).fragmentRefs;
  var uvgFrag = Belt_Option.getExn(data.userVoyageGrants).fragmentRefs;
  var match = RequestBFGrantSubmit$Thick.useMutation(email, brandFamily, onClose);
  var setError = match.setError;
  var error = match.error;
  return JsxRuntime.jsxs(Dialog$Thick.make, {
              open_: props.isOpen,
              onClose: (function (param) {
                  Curry._1(onClose, undefined);
                  Curry._1(setError, (function (param) {
                          
                        }));
                }),
              title: "Request access",
              children: [
                JsxRuntime.jsx(Typography, {
                      textColor: "text.tertiary",
                      children: Caml_option.some("Request access for " + CustomScalars$Thick.BrandFamilySlug.serialize(brandFamily) + " roles and voyages.")
                    }),
                JsxRuntime.jsx(Typography, {
                      component: "h3",
                      children: "User Information"
                    }),
                JsxRuntime.jsxs(FormControl, {
                      children: [
                        JsxRuntime.jsx(FormLabel, {
                              children: "Email"
                            }),
                        JsxRuntime.jsx(Input, {
                              disabled: true,
                              type: "email",
                              value: CustomScalars$Thick.Email.serialize(email)
                            }),
                        JsxRuntime.jsx(FormHelperText, {
                              children: "User to be updated"
                            })
                      ]
                    }),
                JsxRuntime.jsx(Typography, {
                      component: "h3",
                      children: "Permissions"
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "column",
                      component: "form",
                      spacing: 1,
                      onSubmit: match.onSubmit,
                      children: [
                        JsxRuntime.jsx(RoleGrantsView$Thick.Autocomplete.make, {
                              fragment: urgFrag,
                              setSelectedRoles: match.setSelectedRoles,
                              selectedRoles: match.selectedRoles,
                              disabled: false
                            }),
                        JsxRuntime.jsx(VoyageGrantsView$Thick.Autocomplete.make, {
                              fragment: uvgFrag,
                              setSelectedVoyages: match.setSelectedVoyages,
                              selectedVoyages: match.selectedVoyages,
                              disabled: false
                            }),
                        JsxRuntime.jsx(Button, {
                              loading: match.isMutating,
                              type: "submit",
                              children: "Request"
                            })
                      ]
                    }),
                error !== undefined ? JsxRuntime.jsx(Typography, {
                        color: "danger",
                        children: Caml_option.some(error)
                      }) : null
              ]
            });
}

var make = RequestBFGrantForm;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* Dialog-Thick Not a pure module */
