// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"voyageSubclassSlug":{"c":"CustomScalars.CabinSubclassSlug"},"voyageClassSlug":{"c":"CustomScalars.CabinClassSlug"},"voyageCategorySlug":{"c":"CustomScalars.CabinCategorySlug"},"totBerths":{"b":""},"publicNoteMd":{"c":"CustomScalars.NonemptyString"},"privateNoteMd":{"c":"CustomScalars.NonemptyString"},"occRange_start_value":{"b":""},"occRange_end_value":{"b":""},"cabinNumber":{"c":"CustomScalars.CabinNumber"},"booking_nodes_bookingVersion_nodes_bookingVersionNumber":{"b":""},"booking_nodes_bookingId":{"c":"CustomScalars.BookingId"}}};

var fragmentConverterMap = {
  "CustomScalars.BookingId": CustomScalars$Thick.BookingId.parse,
  "CustomScalars.CabinNumber": CustomScalars$Thick.CabinNumber.parse,
  "CustomScalars.NonemptyString": CustomScalars$Thick.NonemptyString.parse,
  "CustomScalars.CabinCategorySlug": CustomScalars$Thick.CabinCategorySlug.parse,
  "CustomScalars.CabinClassSlug": CustomScalars$Thick.CabinClassSlug.parse,
  "CustomScalars.CabinSubclassSlug": CustomScalars$Thick.CabinSubclassSlug.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "inclusive",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CabinTable_cabin",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "IntRange",
      "kind": "LinkedField",
      "name": "occRange",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "IntRangeBound",
          "kind": "LinkedField",
          "name": "start",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IntRangeBound",
          "kind": "LinkedField",
          "name": "end",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cabinNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "privateNoteMd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicNoteMd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voyageSegment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voyageClassSlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voyageCategorySlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voyageSubclassSlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totBerths",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BookingsConnection",
      "kind": "LinkedField",
      "name": "booking",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Booking",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bookingId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "orderBy",
                  "value": "BOOKING_VERSION_NUMBER_ASC"
                }
              ],
              "concreteType": "BookingVersionsConnection",
              "kind": "LinkedField",
              "name": "bookingVersion",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BookingVersion",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "bookingVersionNumber",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "bookingVersion(orderBy:\"BOOKING_VERSION_NUMBER_ASC\")"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Cabin",
  "abstractKey": null
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
