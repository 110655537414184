// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../utils/Common.bs.js");
var CustomScalars$Thick = require("../../utils/CustomScalars.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var VoyageProtectedRoute_query_graphql$Thick = require("../../__generated__/VoyageProtectedRoute_query_graphql.bs.js");

var convertFragment = VoyageProtectedRoute_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(VoyageProtectedRoute_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, VoyageProtectedRoute_query_graphql$Thick.node, convertFragment);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function VoyageProtectedRoute(props) {
  var brandFamily = props.brandFamily;
  var data = RescriptRelay_Fragment.useFragment(VoyageProtectedRoute_query_graphql$Thick.node, convertFragment, props.fragmentRefs);
  var match = data.brandFamilyVoyages;
  var voyagesOnBrandFamily = match !== undefined ? Belt_Set.fromArray(Belt_Array.map(match.nodes, (function (v) {
                return v.voyageSlug;
              })), Common$Thick.VoyageSlugCmp) : Belt_Set.make(Common$Thick.VoyageSlugCmp);
  var hasVoyage = Belt_Set.has(voyagesOnBrandFamily, props.voyage) && data.currentUserCanForVoyage;
  React.useEffect(function () {
        if (!hasVoyage) {
          RescriptReactRouter.push("/" + CustomScalars$Thick.BrandFamilySlug.serialize(brandFamily) + "");
        }
        
      });
  if (hasVoyage) {
    return props.children;
  } else {
    return null;
  }
}

var make = VoyageProtectedRoute;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
