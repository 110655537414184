// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRelay = require("react-relay");
var RelayRuntime = require("relay-runtime");

function commitMutation(convertVariables, node, convertResponse, convertWrapRawResponse, environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: node,
              variables: Curry._1(convertVariables, variables),
              onCompleted: onCompleted !== undefined ? (function (res, err) {
                    Curry._2(onCompleted, Curry._1(convertResponse, res), (err == null) ? undefined : Caml_option.some(err));
                  }) : undefined,
              onError: onError,
              optimisticResponse: optimisticResponse !== undefined ? Caml_option.some(Curry._1(convertWrapRawResponse, Caml_option.valFromOption(optimisticResponse))) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, response) {
                    Curry._2(updater, store, Curry._1(convertResponse, response));
                  }) : undefined,
              uploadables: uploadables
            });
}

function useMutation(convertVariables, node, convertResponse, convertWrapRawResponse, param) {
  var match = ReactRelay.useMutation(node);
  var mutate = match[0];
  return [
          React.useMemo((function () {
                  return function (param, param$1, param$2, param$3, param$4, param$5, param$6, param$7) {
                    return Curry._1(mutate, {
                                onError: param$5,
                                onCompleted: param$4 !== undefined ? (function (res, err) {
                                      Curry._2(param$4, Curry._1(convertResponse, res), (err == null) ? undefined : Caml_option.some(err));
                                    }) : undefined,
                                optimisticResponse: param$2 !== undefined ? Caml_option.some(Curry._1(convertWrapRawResponse, Caml_option.valFromOption(param$2))) : undefined,
                                optimisticUpdater: param$1,
                                updater: param$3 !== undefined ? (function (store, response) {
                                      Curry._2(param$3, store, Curry._1(convertResponse, response));
                                    }) : undefined,
                                variables: Curry._1(convertVariables, param),
                                uploadables: param$6
                              });
                  };
                }), [mutate]),
          match[1]
        ];
}

exports.commitMutation = commitMutation;
exports.useMutation = useMutation;
/* react Not a pure module */
