// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var AlterUserSubmit_AlterUserAccessMutation_graphql$Thick = require("../../../__generated__/AlterUserSubmit_AlterUserAccessMutation_graphql.bs.js");

var convertVariables = AlterUserSubmit_AlterUserAccessMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = AlterUserSubmit_AlterUserAccessMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = AlterUserSubmit_AlterUserAccessMutation_graphql$Thick.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables, AlterUserSubmit_AlterUserAccessMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables, AlterUserSubmit_AlterUserAccessMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse, param);
}

var AlterUserAccessMutation_brandfamRole_decode = AlterUserSubmit_AlterUserAccessMutation_graphql$Thick.Utils.brandfamRole_decode;

var AlterUserAccessMutation_brandfamRole_fromString = AlterUserSubmit_AlterUserAccessMutation_graphql$Thick.Utils.brandfamRole_fromString;

var AlterUserAccessMutation = {
  brandfamRole_decode: AlterUserAccessMutation_brandfamRole_decode,
  brandfamRole_fromString: AlterUserAccessMutation_brandfamRole_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(email, brandFamily, initialRolesOpt, initialVoyagesOpt, refreshTeam, onClose) {
  var initialRoles = initialRolesOpt !== undefined ? initialRolesOpt : [];
  var initialVoyages = initialVoyagesOpt !== undefined ? initialVoyagesOpt : [];
  var match = use(undefined);
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return initialRoles;
      });
  var selectedRoles = match$1[0];
  var match$2 = React.useState(function () {
        return initialVoyages;
      });
  var selectedVoyages = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setError = match$3[1];
  var onSubmit = function (e) {
    e.preventDefault();
    Curry._1(setError, (function (param) {
            
          }));
    var forTheseVoyagesOnly = Belt_Array.map(selectedVoyages, (function (voyage) {
            return Caml_option.some(voyage.voyageSlug);
          }));
    var toMatchRoles = Belt_Array.map(selectedRoles, (function (role) {
            return role.brandfamRole;
          }));
    Curry._8(mutate, {
          input: {
            forUserWithEmail: email,
            onBrandFamily: brandFamily,
            forTheseVoyagesOnly: forTheseVoyagesOnly,
            toMatchRoles: toMatchRoles
          }
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err === undefined) {
              var match = resp.alterUserAccess.boolean;
              if (match !== undefined && match) {
                console.log("Successfully altered user");
                Curry._1(refreshTeam, undefined);
                Curry._1(onClose, undefined);
                return ;
              }
              console.log("Failed to alter user");
              return Curry._1(setError, (function (param) {
                            return "Failed to alter user";
                          }));
            }
            console.log("Failed to alter user");
            Curry._1(setError, (function (param) {
                    return "Failed to alter user";
                  }));
          }), (function (err) {
            console.log("Error altering user");
            Curry._1(setError, (function (param) {
                    return "Error altering user";
                  }));
          }), undefined, undefined);
  };
  return {
          isMutating: match[1],
          selectedRoles: selectedRoles,
          setSelectedRoles: match$1[1],
          selectedVoyages: selectedVoyages,
          setSelectedVoyages: match$2[1],
          error: match$3[0],
          setError: setError,
          onSubmit: onSubmit
        };
}

exports.AlterUserAccessMutation = AlterUserAccessMutation;
exports.useMutation = useMutation;
/* react Not a pure module */
