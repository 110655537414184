// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");

var Types = {};

var variablesConverter = {"inviteToBrandFamilyInput":{"toVoyages":{"c":"CustomScalars.VoyageSlug"},"toBrandFamilySlug":{"c":"CustomScalars.BrandFamilySlug"},"inviteeEmail":{"c":"CustomScalars.Email"}},"__root":{"input":{"r":"inviteToBrandFamilyInput"}}};

var variablesConverterMap = {
  "CustomScalars.Email": CustomScalars$Thick.Email.serialize,
  "CustomScalars.BrandFamilySlug": CustomScalars$Thick.BrandFamilySlug.serialize,
  "CustomScalars.VoyageSlug": CustomScalars$Thick.VoyageSlug.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function brandfamRole_decode($$enum) {
  if ($$enum === "BRAND_FAMILY_ADMIN" || $$enum === "INVENTORY_MANAGER" || $$enum === "CUSTOMER_SERVICE") {
    return $$enum;
  }
  
}

var brandfamRole_fromString = brandfamRole_decode;

var Utils = {
  brandfamRole_decode: brandfamRole_decode,
  brandfamRole_fromString: brandfamRole_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteUserSubmit_InviteToBrandFamilyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InviteToBrandFamilyPayload",
        "kind": "LinkedField",
        "name": "inviteToBrandFamily",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Query",
            "kind": "LinkedField",
            "name": "query",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InviteUserSubmit_InviteToBrandFamilyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InviteToBrandFamilyPayload",
        "kind": "LinkedField",
        "name": "inviteToBrandFamily",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Query",
            "kind": "LinkedField",
            "name": "query",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aed8b2f3b350f1f39aced721ea4c100a",
    "id": null,
    "metadata": {},
    "name": "InviteUserSubmit_InviteToBrandFamilyMutation",
    "operationKind": "mutation",
    "text": "mutation InviteUserSubmit_InviteToBrandFamilyMutation(\n  $input: InviteToBrandFamilyInput!\n) {\n  inviteToBrandFamily(input: $input) {\n    query {\n      id\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
