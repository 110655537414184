// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../utils/Common.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var BrandPermProtected_query_graphql$Thick = require("../../__generated__/BrandPermProtected_query_graphql.bs.js");

var convertFragment = BrandPermProtected_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BrandPermProtected_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BrandPermProtected_query_graphql$Thick.node, convertFragment);
}

var QueryFragment_brandfamPermission_decode = BrandPermProtected_query_graphql$Thick.Utils.brandfamPermission_decode;

var QueryFragment_brandfamPermission_fromString = BrandPermProtected_query_graphql$Thick.Utils.brandfamPermission_fromString;

var QueryFragment_signonStatus_decode = BrandPermProtected_query_graphql$Thick.Utils.signonStatus_decode;

var QueryFragment_signonStatus_fromString = BrandPermProtected_query_graphql$Thick.Utils.signonStatus_fromString;

var QueryFragment = {
  brandfamPermission_decode: QueryFragment_brandfamPermission_decode,
  brandfamPermission_fromString: QueryFragment_brandfamPermission_fromString,
  signonStatus_decode: QueryFragment_signonStatus_decode,
  signonStatus_fromString: QueryFragment_signonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function BrandPermProtected(props) {
  var redirect = props.redirect;
  var isRoute = props.isRoute;
  var isRoute$1 = isRoute !== undefined ? isRoute : false;
  var redirect$1 = redirect !== undefined ? redirect : "/dashboard";
  var query = RescriptRelay_Fragment.useFragment(BrandPermProtected_query_graphql$Thick.node, convertFragment, props.fragmentRefs);
  var userPerms = Belt_Set.fromArray(Belt_Array.keepMap(Belt_Option.getWithDefault(query.currentUserCanForBrand, []), (function (x) {
              if (x !== undefined) {
                return x;
              }
              
            })), Common$Thick.PermCmp);
  var hasPerm = Belt_Set.has(userPerms, props.neededPerm);
  React.useEffect((function () {
          var match = query.currentUserSignonStatus;
          if (match === "SIGNED_ON" && isRoute$1 && !hasPerm) {
            RescriptReactRouter.push(redirect$1);
          }
          
        }), [
        query.currentUserSignonStatus,
        hasPerm,
        isRoute$1
      ]);
  if (hasPerm) {
    return props.children;
  } else {
    return null;
  }
}

var make = BrandPermProtected;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
