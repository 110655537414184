// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var BDContentBlock$Thick = require("./molecules/BDContentBlock.bs.js");

function BDPreferences(props) {
  return JsxRuntime.jsx(BDContentBlock$Thick.make, {
              title: "Preferences",
              children: JsxRuntime.jsx(Typography, {
                    level: "body-sm",
                    children: "Preferences go here"
                  })
            });
}

var make = BDPreferences;

exports.make = make;
/* react/jsx-runtime Not a pure module */
