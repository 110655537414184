// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

function makeRefetchVariables(param) {
  
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var wrapResponseConverter = {"__root":{"":{"f":""}}};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"":{"f":""}}};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "brandFamilySlug",
  "storageKey": null
},
v1 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountRouteQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "DashboardLayout_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AccountPage_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "OrgProtectedRoute_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentUserSignonStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentUserEmail",
        "storageKey": null
      },
      {
        "alias": "allBrandFamilies",
        "args": null,
        "concreteType": "CurrentUserProfilesConnection",
        "kind": "LinkedField",
        "name": "currentUserProfiles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CurrentUserProfile",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUserProfilesConnection",
        "kind": "LinkedField",
        "name": "currentUserProfiles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CurrentUserProfile",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brandFamilyRoles",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brandFamilyVoyages",
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "106fd7abeea5e00d45d61c6412ca350f",
    "id": null,
    "metadata": {},
    "name": "AccountRouteQuery",
    "operationKind": "query",
    "text": "query AccountRouteQuery {\n  ...DashboardLayout_query\n  ...AccountPage_query\n  ...OrgProtectedRoute_query\n}\n\nfragment AccountMenu_bfselector on CurrentUserProfile {\n  brandFamilySlug\n}\n\nfragment AccountMenu_query on Query {\n  currentUserEmail\n  allBrandFamilies: currentUserProfiles {\n    nodes {\n      ...AccountMenu_bfselector\n    }\n  }\n}\n\nfragment AccountPage_query on Query {\n  currentUserEmail\n  ...BrandFamilyAccessSection_query\n}\n\nfragment BrandFamilyAccessSection_profile on CurrentUserProfile {\n  brandFamilySlug\n  brandFamilyRoles\n  brandFamilyVoyages\n}\n\nfragment BrandFamilyAccessSection_query on Query {\n  currentUserProfiles {\n    nodes {\n      ...BrandFamilyAccessSection_profile\n    }\n  }\n}\n\nfragment DashboardLayout_query on Query {\n  currentUserSignonStatus\n  currentUserEmail\n  ...NavBar_profile\n  ...StandardLayout_query\n}\n\nfragment NavBar_profile on Query {\n  currentUserSignonStatus\n  currentUserEmail\n  ...AccountMenu_query\n}\n\nfragment OrgProtectedRoute_query on Query {\n  currentUserSignonStatus\n}\n\nfragment StandardLayout_query on Query {\n  currentUserEmail\n  currentUserSignonStatus\n  ...NavBar_profile\n}\n"
  }
};
})());

var include = RescriptRelay.MakeLoadQuery({
      query: node,
      convertVariables: convertVariables
    });

var load = include.load;

var queryRefToObservable = include.queryRefToObservable;

var queryRefToPromise = include.queryRefToPromise;

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
