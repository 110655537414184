// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");

function makeRefetchVariables(brandFamily, voyage, param) {
  return {
          brandFamily: brandFamily,
          voyage: voyage
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {"__root":{"voyage":{"c":"CustomScalars.VoyageSlug"},"brandFamily":{"c":"CustomScalars.BrandFamilySlug"}}};

var variablesConverterMap = {
  "CustomScalars.BrandFamilySlug": CustomScalars$Thick.BrandFamilySlug.serialize,
  "CustomScalars.VoyageSlug": CustomScalars$Thick.VoyageSlug.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"pubPrivSegs_nodes_nlevel":{"b":""},"pubPrivSegs_nodes_childSegments_nodes_nlevel":{"b":""},"pubPrivSegs_nodes_childSegments_nodes_childSegments_nodes_nlevel":{"b":""},"pubPrivSegs_nodes_childSegments_nodes_childSegments_nodes_childSegments_nodes_nlevel":{"b":""}}};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"pubPrivSegs_nodes_nlevel":{"b":""},"pubPrivSegs_nodes_childSegments_nodes_nlevel":{"b":""},"pubPrivSegs_nodes_childSegments_nodes_childSegments_nodes_nlevel":{"b":""},"pubPrivSegs_nodes_childSegments_nodes_childSegments_nodes_childSegments_nodes_nlevel":{"b":""}}};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brandFamily"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "voyage"
  }
],
v1 = {
  "kind": "Variable",
  "name": "brandFamilySlug",
  "variableName": "brandFamily"
},
v2 = {
  "kind": "Variable",
  "name": "voyageSlug",
  "variableName": "voyage"
},
v3 = [
  {
    "fields": [
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "condition"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voyageSegment",
  "storageKey": null
},
v5 = [
  {
    "fields": [
      (v1/*: any*/),
      {
        "kind": "Literal",
        "name": "nlevel",
        "value": 2
      },
      (v2/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "condition"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rootSegment",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voyageSegmentArray",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nlevel",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SegmentSelectorQuery",
    "selections": [
      {
        "alias": "allSegs",
        "args": (v3/*: any*/),
        "concreteType": "SegmentsConnection",
        "kind": "LinkedField",
        "name": "segments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Segment",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "pubPrivSegs",
        "args": (v5/*: any*/),
        "concreteType": "SegmentsConnection",
        "kind": "LinkedField",
        "name": "segments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Segment",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v4/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SegmentsConnection",
                "kind": "LinkedField",
                "name": "childSegments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Segment",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SegmentsConnection",
                        "kind": "LinkedField",
                        "name": "childSegments",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Segment",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v4/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SegmentsConnection",
                                "kind": "LinkedField",
                                "name": "childSegments",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Segment",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                      (v4/*: any*/),
                                      (v7/*: any*/),
                                      (v8/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SegmentSelectorQuery",
    "selections": [
      {
        "alias": "allSegs",
        "args": (v3/*: any*/),
        "concreteType": "SegmentsConnection",
        "kind": "LinkedField",
        "name": "segments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Segment",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "pubPrivSegs",
        "args": (v5/*: any*/),
        "concreteType": "SegmentsConnection",
        "kind": "LinkedField",
        "name": "segments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Segment",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v4/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SegmentsConnection",
                "kind": "LinkedField",
                "name": "childSegments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Segment",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SegmentsConnection",
                        "kind": "LinkedField",
                        "name": "childSegments",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Segment",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v4/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SegmentsConnection",
                                "kind": "LinkedField",
                                "name": "childSegments",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Segment",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                      (v4/*: any*/),
                                      (v7/*: any*/),
                                      (v8/*: any*/),
                                      (v9/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7ac6c6a0c0160fe4cfebf28e6a7cd15a",
    "id": null,
    "metadata": {},
    "name": "SegmentSelectorQuery",
    "operationKind": "query",
    "text": "query SegmentSelectorQuery(\n  $brandFamily: BrandFamilySlug!\n  $voyage: VoyageSlug!\n) {\n  allSegs: segments(condition: {brandFamilySlug: $brandFamily, voyageSlug: $voyage}) {\n    nodes {\n      voyageSegment\n      id\n    }\n  }\n  pubPrivSegs: segments(condition: {brandFamilySlug: $brandFamily, voyageSlug: $voyage, nlevel: 2}) {\n    nodes {\n      rootSegment\n      voyageSegment\n      voyageSegmentArray\n      nlevel\n      childSegments {\n        nodes {\n          voyageSegment\n          voyageSegmentArray\n          nlevel\n          childSegments {\n            nodes {\n              voyageSegment\n              voyageSegmentArray\n              nlevel\n              childSegments {\n                nodes {\n                  voyageSegment\n                  voyageSegmentArray\n                  nlevel\n                  id\n                }\n              }\n              id\n            }\n          }\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})());

var include = RescriptRelay.MakeLoadQuery({
      query: node,
      convertVariables: convertVariables
    });

var load = include.load;

var queryRefToObservable = include.queryRefToObservable;

var queryRefToPromise = include.queryRefToPromise;

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
