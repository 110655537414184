// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var DateFns = require("date-fns");
var DateFns$Util = require("./DateFns.bs.js");
var S$RescriptStruct = require("rescript-struct/src/S.bs.js");

var struct = S$RescriptStruct.transform(S$RescriptStruct.$$String.min(S$RescriptStruct.string(undefined), undefined, 8), (function (v) {
        var match = v.split("-");
        var match$1 = DateFns.parseISO(v);
        if (match.length !== 3) {
          return S$RescriptStruct.fail(undefined, "SQL style dates are YYYY-MM-DD");
        } else if (DateFns.isValid(match$1)) {
          return match$1;
        } else {
          return S$RescriptStruct.fail(undefined, "Parsed date does not seem to be a valid date");
        }
      }), undefined, (function (v) {
        return DateFns.formatISO(v, {
                    representation: "date"
                  });
      }), undefined);

function parse(str) {
  return S$RescriptStruct.parseAnyOrRaiseWith(str, struct);
}

function serialize(v) {
  return S$RescriptStruct.serializeToUnknownOrRaiseWith(v, struct);
}

var Exn = {
  parse: parse,
  serialize: serialize
};

function parse$1(str) {
  return S$RescriptStruct.parseAnyWith(str, struct);
}

function month(t) {
  return DateFns.getMonth(t);
}

function day(t) {
  return DateFns.getDate(t);
}

function year(t) {
  return DateFns.getYear(t);
}

function prettyFormatDateRange(l, r) {
  var match = DateFns.getYear(l) === DateFns.getYear(r);
  var match$1 = DateFns.getMonth(l) === DateFns.getMonth(r);
  if (match) {
    if (match$1) {
      return "" + DateFns.format(l, "LLLL d") + " - " + DateFns.format(r, "d, yyyy") + "";
    } else {
      return "" + DateFns.format(l, "LLLL d") + " - " + DateFns.format(r, "LLLL d, yyyy") + "";
    }
  } else {
    return "" + DateFns.format(l, "LLLL d yyyy") + " - " + DateFns.format(r, "LLLL d yyyy") + "";
  }
}

function format(prim0, prim1) {
  return DateFns.format(prim0, prim1);
}

function differenceInCalendarDays(prim0, prim1) {
  return DateFns.differenceInCalendarDays(prim0, prim1);
}

function today(param) {
  return new Date();
}

var SQLDate = {
  struct: struct,
  parse: parse$1,
  Exn: Exn
};

var daysUntil = DateFns$Util.daysUntil;

exports.SQLDate = SQLDate;
exports.month = month;
exports.day = day;
exports.year = year;
exports.prettyFormatDateRange = prettyFormatDateRange;
exports.format = format;
exports.daysUntil = daysUntil;
exports.differenceInCalendarDays = differenceInCalendarDays;
exports.today = today;
/* struct Not a pure module */
